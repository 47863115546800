import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { NgbActiveModal, NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { Observable, of } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { isDate } from "src/app/global";
import { LimitedSearchService } from "src/app/services/limited-search.service";
import { Fakturalinje, FaktureringService } from "../../services/fakturering.service";
import { ModalService } from "../../services/modal.service";
import { Person, PersonService } from "../../services/person.service";
import { FakturaAccess, TilgangService } from "../../services/tilgang.service";

moment.locale("no");


@Component({
  selector: "app-fakturering",
  templateUrl: "./faktura-detaljer.component.html",
  styleUrls: ["./faktura-detaljer.component.scss"]
})
export class FakturaDetaljerComponent implements OnInit {

  constructor(private elementRef: ElementRef, private fakturaService: FaktureringService,
    private tilgangService: TilgangService, private personService: PersonService,
    private limitedSearchService: LimitedSearchService, private modalService: ModalService,
    public activeModal: NgbActiveModal) {
  }

  @Input() systemId?: number;
  @Input() endringId?: number;
  @Input() fakturaId?: number;
  @Input() oppdragId?: number;
  ansvarligBruker: Person;
  harTilgang = false;
  faktura: Fakturalinje;
  rekalkulererPriser = false;
  submitted = false;
  priserKalkulert = false;

  formGroup = new FormGroup({
    utfortAv: new FormControl("", [Validators.required]),
    fraDate: new FormControl(new Date(), [Validators.required]),
    fraTime: new FormControl(this.current30MinuteTime(), [Validators.required]),
    tilDate: new FormControl(new Date(), [Validators.required]),
    tilTime: new FormControl(this.current30MinuteTime(), [Validators.required]),
    normaltid: new FormControl(""),
    overtid: new FormControl(""),
    sumNormaltid: new FormControl(""),
    sumOvertid: new FormControl(""),
    fastBelop: new FormControl(""),
    beskrivelse: new FormControl("", [Validators.required]),
    sertifikat: new FormControl(""),
    godkjent: new FormControl(""),
    fakturert: new FormControl("")
  }, { validators: datoRekkefolgeValidator });

  get f(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  current30MinuteTime(): Date {
    const dt = new Date();
    const minute = Math.round(dt.getMinutes() / 30) * 30;
    dt.setMinutes(minute);
    return dt;
  }

  fra(): Date {
    const date = this.f.fraDate.value as Date;
    const time = this.f.fraTime.value as Date;
    return mergeDateTime(date, time);
  }

  til(): Date {
    const date = this.f.tilDate.value as Date;
    const time = this.f.tilTime.value as Date;
    return mergeDateTime(date, time);
  }

  ngOnInit(): void {
    if (this.faktura) {
      this.prosesserFaktura(this.faktura);
    } else if (this.fakturaId) {
      if (this.oppdragId) {
        this.fakturaService.getOppdragFakturalinje(this.oppdragId, this.fakturaId).subscribe(faktura => {
          this.prosesserFaktura(faktura);
        });
      } else {
        this.fakturaService.getItsystemFakturalinje(this.systemId, this.fakturaId).subscribe(faktura => {
          this.prosesserFaktura(faktura);
        });
      }
    } else {
      this.faktura = {} as Fakturalinje;
      this.faktura.systemId = this.systemId;
      this.faktura.endringId = this.endringId;
      this.faktura.oppdragId = this.oppdragId;
    }

    this.f.godkjent.disable();
    this.f.fakturert.disable();

    this.personService.getSelf().subscribe(person => {
      if (person) {
        if (this.faktura.fakId === undefined) {
          this.settPerson(person);
        }
        this.ansvarligBruker = person;
        let accessObservable: Observable<FakturaAccess>;

        if (this.oppdragId) {
          console.log("BRUKER OPPDRAG ACCESS");
          accessObservable = this.tilgangService.getOppdragFakturaAccess();
        } else {
          console.log("BRUKER ITSYSTEM ACCESS");
          accessObservable = this.tilgangService.getItsystemFakturaAccess();
        }

        accessObservable.subscribe(tilgang => {
          if (tilgang.godkjenn) {
            this.f.godkjent.enable();
          }
        });
      } else {
        this.f.godkjent.disable();
      }
    });

    let accessObservable: Observable<FakturaAccess>;

    if (this.oppdragId) {
      console.log("BRUKER OPPDRAG ACCESS");
      accessObservable = this.tilgangService.getOppdragFakturaAccess();
    } else {
      console.log("BRUKER ITSYSTEM ACCESS");
      accessObservable = this.tilgangService.getItsystemFakturaAccess();
    }

    accessObservable.subscribe(tilgang => {
      if (tilgang.fakturer) {
        this.f.fakturert.enable();
      }
    });
  }

  private settPerson(person: Person) {
    this.faktura.personId = person.personId;
    this.faktura.personNavn = person.navn;
    this.faktura.utfortAv = person.navn;
  }

  private prosesserFaktura(faktura: Fakturalinje) {
    faktura.utfortAv = `${faktura.personNavn}`;
    this.faktura = faktura;
    const person = {
      personId: faktura.personId,
      navn: faktura.personNavn
    } as Person;
    this.f.utfortAv.setValue(person);

    this.f.fraTime.setValue(moment(faktura.fra).toDate());
    this.f.fraDate.setValue(moment(faktura.fra).toDate());

    this.f.tilTime.setValue(moment(faktura.til).toDate());
    this.f.tilDate.setValue(moment(faktura.til).toDate());

    this.f.normaltid.setValue(this.konverterTidsintervall(faktura.normaltid));
    this.f.overtid.setValue(this.konverterTidsintervall(faktura.overtid));
    this.f.sumNormaltid.setValue(faktura.sumNormaltid);
    this.f.sumOvertid.setValue(faktura.sumOvertid);
    this.f.fastBelop.setValue(faktura.fastBelop);
    this.f.beskrivelse.setValue(faktura.beskrivelse);
    this.f.godkjent.setValue(faktura.godkjent);
    this.f.fakturert.setValue(faktura.fakturert);
  }

  datoValgt(fraDatoValgt: boolean): void {
    let recalculate = true;
    if (fraDatoValgt && this.f.tilDate.value.toDateString() != this.f.fraDate.value.toDateString()) {
      this.f.tilDate.setValue(this.f.fraDate.value);
      recalculate = this.priserKalkulert;
    }
    if (recalculate) {
      const fraMoment = moment(mergeDateTime(this.f.fraDate.value, this.f.fraTime.value));
      const tilMoment = moment(mergeDateTime(this.f.tilDate.value, this.f.tilTime.value));

      const fra = fraMoment?.format("YYYY-MM-DDTHH:mm");
      const til = tilMoment?.format("YYYY-MM-DDTHH:mm");

      const fastBelop = this.f.fastBelop.value as number;
      this.rekalkulerPriser(fra, til, fraMoment, tilMoment, fastBelop);
    }
  }

  private rekalkulerPriser(fra: string, til: string, fraMoment: moment.Moment, tilMoment: moment.Moment, fastBelop: number) {
    this.faktura.fra = fra;
    this.faktura.til = til;
    this.priserKalkulert = true;
    if (fraMoment < tilMoment) {
      this.rekalkulererPriser = true;
      this.fakturaService.kalkulerFakturapriser(fra, til).subscribe(faktura => {
        this.rekalkulererPriser = false;
        if (this.faktura.normaltid != faktura.normaltid || !this.faktura.sumNormaltid) {

          this.formGroup.get("normaltid").setValue(this.konverterTidsintervall(faktura.normaltid));
          this.formGroup.get("normaltid").disable();
          this.faktura.normaltid = faktura.normaltid;

          this.formGroup.get("sumNormaltid").setValue(faktura.sumNormaltid);
          this.faktura.sumNormaltid = faktura.sumNormaltid;
        }
        if (this.faktura.overtid != faktura.overtid || !this.faktura.sumOvertid) {
          this.formGroup.get("overtid").setValue(this.konverterTidsintervall(faktura.overtid));
          this.faktura.overtid = faktura.overtid;
          this.formGroup.get("sumOvertid").setValue(faktura.sumOvertid);
          this.faktura.sumOvertid = faktura.sumOvertid;
        }
        if (fastBelop) {
          this.formGroup.get("sumNormaltid").setValue(null);
          this.faktura.sumNormaltid = 0.0;
          this.formGroup.get("sumOvertid").setValue(null);
          this.faktura.sumOvertid = 0.0;
        }
      });
    }
  }

  private oppdaterFaktura() {
    this.faktura.beskrivelse = this.f.beskrivelse.value;
    this.faktura.sertifikat = !!this.f.sertifikat.value;
    if (!this.faktura.godkjent && this.f.godkjent.value && this.ansvarligBruker) {
      this.faktura.godkjent = true;
      this.faktura.godkjentAvId = this.ansvarligBruker.personId;
      this.faktura.godkjentDato = new Date();
    } else if (this.faktura.godkjent && !this.f.godkjent.value) {
      this.faktura.godkjent = false;
      this.faktura.godkjentAvId = undefined;
      this.faktura.godkjentDato = undefined;
    }
    if (!this.faktura.fakturert && this.f.fakturert.value) {
      this.faktura.fakturert = true;
      this.faktura.fakturertDato = new Date();
    } else if (this.faktura.fakturert && !this.f.fakturert.value) {
      this.faktura.fakturert = false;
      this.faktura.fakturertDato = undefined;
    }

    if (!(this.faktura.fra || this.faktura.til)) {

      const fraMoment = moment(mergeDateTime(this.f.fraDate.value, this.f.fraTime.value));
      const tilMoment = moment(mergeDateTime(this.f.tilDate.value, this.f.tilTime.value));

      const fra = fraMoment?.format("YYYY-MM-DDTHH:mm");
      const til = tilMoment?.format("YYYY-MM-DDTHH:mm");

      this.faktura.fra = fra;
      this.faktura.til = til;
    }

  }

  lagreFakturalinjer(): void {
    this.submitted = true;
    this.priserKalkulert = true;
    if (!this.formGroup.invalid) {
      this.oppdaterFaktura();
      if (this.faktura.oppdragId) {
        this.fakturaService.lagreOppdragFakturalinje(this.faktura).subscribe(() => {
          location.reload();
        });
      } else {
        this.fakturaService.lagreFakturalinje(this.faktura).subscribe(() => {
          location.reload();
        });
      }
    }
  }

  erGodkjent(): boolean {
    return this.faktura.godkjent;
  }

  erFakturert(): boolean {
    return this.faktura.fakturert;
  }

  sokPerson = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((searchText) => this.limitedSearchService.autocompletePerson(searchText)),
      catchError(err => of(err))
    );
  }

  formaterPerson(person: Person): string {
    return person.navn;
  }

  inputPerson(person: any): string {
    if (person) {
      return person.navn;
    } else {
      return "";
    }
  }

  konverterTidsintervall(verdi: number): string {
    const tid = verdi / 36000000000;
    return tid + "t";
  }

  velgPerson($event: NgbTypeaheadSelectItemEvent<Person>): void {
    this.settPerson($event.item);
  }

  close(): void {
    // Do nothing
  }

  settFastBelop(): void {
    const fast = this.f.fastBelop.value as number;
    this.faktura.fastBelop = fast;
    if (fast) {
      this.f.sumNormaltid.setValue(null);
      this.faktura.sumNormaltid = 0.0;
      this.f.sumOvertid.setValue(null);
      this.faktura.sumOvertid = 0.0;
    } else {
      this.datoValgt(false);
      this.faktura.fastBelop = 0;
    }
  }
}


function mergeDateTime(date: Date, time: Date): Date {

  if (!date || !time) return null;

  if (!isDate(date) || !isDate(time)) return null;

  date.setHours(time.getHours());
  date.setMinutes(time.getMinutes());
  date.setSeconds(time.getSeconds());
  return new Date(date);
}


export const datoRekkefolgeValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {

  const fraTime = control.get("fraTime").value as Date;
  const tilTime = control.get("tilTime").value as Date;

  const fraDate = control.get("fraDate").value as Date;
  const tilDate = control.get("tilDate").value as Date;

  const fraDateDateTime = mergeDateTime(fraDate, fraTime);
  fraDateDateTime.setSeconds(0);
  const tilDateDateTime = mergeDateTime(tilDate, tilTime);
  tilDateDateTime.setSeconds(0);

  const fastBelop = control.get("fastBelop").value as number;
  return fraDateDateTime && tilDateDateTime && (!fastBelop && tilDateDateTime <= fraDateDateTime) ? { ordering: true } : null;
};
