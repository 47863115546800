import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TabSideBaseClass } from "src/app/common/tab-side/tab-side.component";
import { Tabs } from "src/app/common/tabs/tabs.enum";
import { TabsService } from "src/app/common/tabs/tabs.service";
import { KoblingerService, LeverandorSystemRolle } from "src/app/koblinger/koblinger.service";
import { ModalService } from "src/app/services/modal.service";
import { TilgangService } from "src/app/services/tilgang.service";

@Component({
  selector: "app-system-leverandor-tab",
  templateUrl: "./system-leverandor-tab.component.html",
  styleUrls: ["./system-leverandor-tab.component.scss"]
})
export class SystemLeverandorTabComponent extends TabSideBaseClass<LeverandorSystemRolle> {
  
  tabName(): Tabs {
    return Tabs.leverandorer;
  }

  constructor(tabsService: TabsService, service: KoblingerService, tilgangService: TilgangService, modal: ModalService, route: ActivatedRoute, router: Router) {
    super(tabsService, service, tilgangService, modal, route, router);
  }

  loaderObservable(service: KoblingerService, id: number): Observable<LeverandorSystemRolle[]> {
    return service.getLeverandorSystemRollerForSystem(id);
  }

  pageLinkAccess(service: TilgangService): Observable<boolean> {
    return service.getGlobalRoles().pipe(map(val => val.leverandorer.listAccess));
  }

  removeObservable(service: KoblingerService, item: LeverandorSystemRolle): Observable<object> {
    return service.removeLeverandorSystemRolleForSystem(item.leverandorId, item.systemId, item.rolleId);
  }

  kobleTil(): void {
    this.modal.velgLeverandor().then(leverandor => {
      this.modal.velgLeverandorRolle().then(rolle => {

        if (this.liste.some(t => t.leverandorId === leverandor.id && t.rolleId === rolle.rolleId)) {
          this.modal.warning(`${leverandor.navn} med rolle ${rolle.navn} er allerede koblet til system`, "Kobling feilet");
          return;
        }

        const lsr: LeverandorSystemRolle = { systemId: this.id, leverandorId: leverandor.id, rolleId: rolle.rolleId };
        this.service.postLeverandorSystemRolleForSystem(lsr).toPromise().then(() => {
          this.modal.success("Itsystem, leverandør og rolle", "Kobling opprettet");
          this.load();
        }
        );
      });
    });
  }

}
