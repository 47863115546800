<form [formGroup]="formGroup" (ngSubmit)="lagreFakturalinjer()">
  <div class="modal-header">
    <h5 class="modal-title">Fakturadetaljer</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
      (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="container-flex m-2">
      <div class="row">
        <div class="col-12 col-md-5 col-lg-4">

          <label class="form-label" for="utfortAv">Utført av</label>
          <input class="form-control" id="utfortAv" formControlName="utfortAv" [ngbTypeahead]="sokPerson"
            [resultFormatter]="formaterPerson" [inputFormatter]="inputPerson" (selectItem)="velgPerson($event)"
            [ngClass]="{'is-invalid': submitted && f.utfortAv.errors}">
          <div *ngIf="submitted && f.utfortAv.errors" class="invalid-feedback">
            <div *ngIf="f.utfortAv.errors.required">Utfører mangler</div>
          </div>




          <div class="form-group">
            <label for="fra" class="form-label">Fra</label>

            <app-date-time-picker [formGroup]="formGroup" [formControlNameDate]="'fraDate'"
              [formControlNameTime]="'fraTime'" [submitted]="priserKalkulert" (InputClosed)="datoValgt(true)"></app-date-time-picker>
          </div>

          <div class="form-group">
            <label for="til" class="form-label">Til</label>

            <app-date-time-picker [formGroup]="formGroup" [formControlNameDate]="'tilDate'"
              [formControlNameTime]="'tilTime'" [submitted]="priserKalkulert" (InputClosed)="datoValgt(false)"></app-date-time-picker>

            <div [hidden]="!rekalkulererPriser" class="spinner-border"></div>

          </div>



          <div class="form-group">
            <label class="form-label" for="normaltid">Normaltid</label>
            <input class="form-control" id="normaltid" type="text" formControlName="normaltid"
              [value]="formGroup.get('normaltid').value" readonly>
          </div>

          <div class="form-group">
            <label class="form-label" for="overtid">Overtid</label>
            <input class="form-control" id="overtid" type="text" formControlName="overtid"
              [value]="formGroup.get('overtid').value" readonly>
          </div>

          <div class="form-group">
            <label class="form-label" for="sumNormaltid">Sum normaltid</label>
            <input class="form-control" id="sumNormaltid" type="text" formControlName="sumNormaltid" readonly>
          </div>

          <div class="form-group">
            <label class="form-label" for="sumOvertid">Sum overtid</label>
            <input class="form-control" id="sumOvertid" type="text" formControlName="sumOvertid" readonly>
          </div>
          <div class="form-group">
            <label class="form-label" for="fastBelop">Fast beløp</label>
            <input class="form-control" id="fastBelop" type="number" formControlName="fastBelop"
              (change)="settFastBelop()">
          </div>

          <div class="form-group">
            <label class="control-label required" for="beskrivelse">Beskrivelse</label>
            <textarea class="form-control" id="beskrivelse" formControlName="beskrivelse"
              [ngClass]="{'is-invalid': submitted && f.beskrivelse.errors}"></textarea>
            <div *ngIf="submitted && f.beskrivelse.errors" class="invalid-feedback">
              <div *ngIf="f.beskrivelse.errors.required">Beskrivelse mangler</div>
            </div>
          </div>

          <div class="form-group">
            <div class="form-check">
              <input class="form-check-input" id="sertifikat" type="checkbox" formControlName="sertifikat">
              <label class="form-check-label" for="sertifikat">Sertifikat</label>
            </div>

            <div class="form-check">
              <input class="form-check-input" id="godkjent" type="checkbox" formControlName="godkjent">
              <label class="form-check-label" for="godkjent">Godkjent</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" id="fakturert" type="checkbox" formControlName="fakturert">
              <label class="form-check-label" for="fakturert">Fakturert</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" type="submit">Lagre</button>
    <button class="btn btn-secondary" type="button" (click)="activeModal.dismiss('Cross click')">Lukk</button>

  </div>
</form>
