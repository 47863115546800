import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Field, SearchItem, SearchResults } from "../common/common.model";
import { IServiceInterface } from "../common/feltside/feltside.component";



@Injectable({
  providedIn: "root"
})
export class SertifikaterService implements IServiceInterface<Sertifikat> {

  private readonly basePath = "api/sertifikat/";
  private readonly baseUrl: string;

  constructor(private http: HttpClient, @Inject("BASE_URL") private baseHost: string) {
    this.baseUrl = baseHost + this.basePath;
  }

  search(beskrivelse?: string, sortOrder?: string, sertifikatStatus?: string, pageNumber = 1, pageSize = 30): Observable<SearchResults<SertifikatSearchResult>> {

    let searchParams = new HttpParams();

    if (beskrivelse) { searchParams = searchParams.append("beskrivelse", beskrivelse); }
    if (sortOrder) { searchParams = searchParams.append("sortOrder", sortOrder); }
    if (sertifikatStatus) { searchParams = searchParams.append("sertifikatStatus", sertifikatStatus); }
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<SearchResults<SertifikatSearchResult>>(this.baseUrl, { params: searchParams });
  }

  get(sertifikatId: number): Observable<Sertifikat> {
    return this.http.get<Sertifikat>(this.baseUrl + sertifikatId);
  }

  put(sertifikat: Sertifikat): Observable<Sertifikat> {
    return this.http.put<Sertifikat>(this.baseUrl + sertifikat.sertifikatId, sertifikat);
  }

  post(sertifikat: Sertifikat): Observable<Sertifikat> {
    return this.http.post<Sertifikat>(this.baseUrl, sertifikat);
  }

  getFields(sertifikatId: number): Observable<Field[]> {
    return this.http.get<Field[]>(this.baseUrl + sertifikatId + "/fields");
  }

  getCreateFields(): Observable<Field[]> {
    return this.http.get<Field[]>(this.baseUrl + "create/fields");
  }


}



export interface Sertifikat {
  sertifikatId: number;
  navn: string;
  notBefore: Date;
  notAfter: Date;
  kommentar?: any;
  pris: number;
  sertifikattypeId: number;
  skalFaktureres: boolean;
  kildeUtstederId: number;
}

export interface SertifikatSearchResult extends SearchItem {
  notBefore?: Date;
  notAfter?: Date;
}



