<div *ngIf="item && fields">


  <h1 class="h1 mb-5">{{item.navn}}</h1>

  <app-lagreknapp [isSaving]="isSaving" [formGroup]="formGroup" (onLagre)="lagre()" ></app-lagreknapp>

  <div *ngIf="item && fields">
    <div class="container-flex">
      <div class="row">
        <div class="col-12 col-md-5 col-lg-4">
          <app-feltliste [formGroup]="formGroup" [fields]="col1" [modell]="item"></app-feltliste>

        </div>
        <div class="col-12 col-md-5 col-lg-4">
          <app-feltliste [formGroup]="formGroup" [fields]="col2" [modell]="item"></app-feltliste>
        </div>
      </div>
    </div>

</div>

<app-tabs #tab [tabs]="tabs" [tabsLoading]="tabsLoading" ></app-tabs>
</div>
