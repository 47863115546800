<h1>{{overskrift}}</h1>

<nav class="nav nav-tabs nav-justified">
  <button class="nav-item nav-link btn btn-link" (click)="systemTab()"
    [ngClass]="{'active' : filterParams.fakturatype=='Fagsystem'}">Fagsystem timer</button>
  <button class="nav-item nav-link btn btn-link" (click)="systemDriftTab()"
    [ngClass]="{'active' : filterParams.fakturatype=='SystemDrift'}">Fagsystem drift</button>
  <button class="nav-item nav-link btn btn-link" (click)="programpakkeTab()"
    [ngClass]="{'active' : filterParams.fakturatype=='Programpakke'}">Programpakker timer</button>
  <button class="nav-item nav-link btn btn-link" (click)="oppdragTab()"
    [ngClass]="{'active' : filterParams.fakturatype=='Oppdrag'}">Oppdrag timer</button>
  <button class="nav-item nav-link btn btn-link" (click)="oppdragDriftTab()"
    [ngClass]="{'active' : filterParams.fakturatype=='OppdragDrift'}">Oppdrag drift</button>
</nav>

<div class="mt-2">
  <app-fakturaer-filter #filter (onFilterChange)="onFilterChange($event)" [filterParams]="filterParams"></app-fakturaer-filter>
</div>


<app-paginator *ngIf="!(filterParams.oppdragId || filterParams.systemId)" (onFilterChange)="onFilterChange($event)" [filterParams]="filterParams"></app-paginator>

<app-spinner [loadingData]="loadingData"></app-spinner>

<div *ngIf="searchResults">
  <p class="text-center">Antall treff ({{filterParams.numberOfHits}})</p>

  <div class="col-12 col-md-9" *ngIf="filterParams.fakturatype=='SystemDrift'">
    <button class="btn btn-outline-primary px-5" type="button" (click)="oppdaterFakturalinjer()" [disabled]="disableOppdaterFakturalinje">Oppdater fakturalinjer</button>
  </div>

  <div *ngIf="visTimeFakturalinjer()" class="search-results">


    <table class="table">
      <thead>
        <tr>
          <th>

            <div *ngIf="filterParams.fakturatype == 'Oppdrag'">

              <div class="btn-group" role="group">
                <button [disabled]="!filterParams.oppdragId || loadingData" class="btn btn-outline-secondary"
                  (click)="generateOppdragReport()" type="button" placement="top" ngbTooltip="Generer Rapport"
                  [openDelay]="400" [closeDelay]="200"><i class="fas fa-chart-bar"></i></button>

                <button [disabled]="!filterParams.oppdragId || loadingData" class="btn btn-outline-secondary"
                  (click)="godkjennAlle(searchResults,true)" type="button" placement="top" ngbTooltip="Godkjenn alle"
                  [openDelay]="400" [closeDelay]="200">
                  <i class="fas fa-check-double"></i></button>

                <button [disabled]="!filterParams.oppdragId || loadingData" class="btn btn-outline-secondary"
                  (click)="fakturerAlle(searchResults,true)" type="button" placement="top" ngbTooltip="Fakturer alle"
                  [openDelay]="400" [closeDelay]="200">
                  <i class="fas fa-layer-group"></i></button>
              </div>
            </div>

            <div *ngIf="filterParams.fakturatype == 'Fagsystem' || filterParams.fakturatype == 'Programpakke'">

              <div class="btn-group" role="group">
                <button [disabled]="!filterParams.fra || !filterParams.til || loadingData"
                  class="btn btn-outline-secondary" (click)="generateItsystemerReport()" type="button" placement="top"
                  ngbTooltip="Generer Rapport" [openDelay]="400" [closeDelay]="200"><i
                    class="fas fa-chart-bar"></i></button>

                <button [disabled]="!filterParams.fra || !filterParams.til || loadingData"
                  class="btn btn-outline-secondary" (click)="godkjennAlle(searchResults,false)" type="button"
                  placement="top" ngbTooltip="Godkjenn alle" [openDelay]="400" [closeDelay]="200">
                  <i class="fas fa-check-double"></i></button>

                <button [disabled]="!filterParams.fra || !filterParams.til || loadingData"
                  class="btn btn-outline-secondary" (click)="fakturerAlle(searchResults,false)" type="button"
                  placement="top" ngbTooltip="Fakturer alle" [openDelay]="400" [closeDelay]="200">
                  <i class="fas fa-layer-group"></i></button>
              </div>
            </div>
          </th>





          <th scope="col">
            <button class="btn btn-link" (click)="changeOrder()">Fra
              <i [ngClass]="filterParams.order=='dateDesc' ? 'fas fa-sort-down' : 'fas fa-sort-up'"></i>
            </button>
          </th>

          <th scope="col">Til</th>

          <th *ngIf="filterParams.fakturatype == 'Oppdrag'" scope="col">Oppdrag</th>
          <th *ngIf="filterParams.fakturatype == 'Fagsystem'" scope="col">System</th>
          <th *ngIf="filterParams.fakturatype == 'Programpakke'" scope="col">Programpakke</th>

          <th scope="col">Utført av</th>

          <ng-container *ngIf="filterParams.fakturatype == 'Fagsystem' || filterParams.fakturatype == 'Programpakke'">
            <th scope="col">Endringnummer</th>
            <th scope="col">Saksnummer</th>
          </ng-container>


          <th scope="col">Beskrivelse</th>


          <th scope="col">Normaltid</th>
          <th scope="col">Sum Normaltid</th>

          <th scope="col">Overtid</th>
          <th scope="col">Sum Overtid</th>

          <th scope="col">Fast Beløp</th>

          <th scope="col">Godkjent</th>
          <th scope="col">Fakturert</th>

          <th scope="col">Sertifikat</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let fakturalinje of searchResults; let i = index">

          <td>
            <div class="btn-group" role="group">
              <button type="button" (click)="redigerFakturalinje(fakturalinje)" placement="top" ngbTooltip="Rediger"
                [openDelay]="400" [closeDelay]="200" class="btn btn-secondary"><i class="fas fa-edit"></i></button>

              <button *ngIf="!fakturalinje.godkjent && kanGodkjenne" type="button"
                (click)="godkjennFakturalinje(fakturalinje)" placement="top" ngbTooltip="Godkjenn" [openDelay]="400"
                [closeDelay]="200" class="btn btn-secondary"><i class="fas fa-check"></i></button>

              <button *ngIf="!fakturalinje.fakturert && kanFakturere" type="button"
                (click)="merkFakturaFakturert(fakturalinje)" placement="top" ngbTooltip="Merk fakturert"
                [openDelay]="400" [closeDelay]="200" class="btn btn-secondary"><i class="fas fa-receipt"></i></button>
            </div>
          </td>


          <td>{{fakturalinje.fra | date:dateFormat}}</td>
          <td>{{fakturalinje.til | date:dateFormat}}</td>

          <td scope="row">
            <a *ngIf="filterParams.fakturatype == 'Oppdrag'"
              [routerLink]="['/oppdrag', fakturalinje.oppdragId]">({{fakturalinje.oppdragId}})
              {{fakturalinje.oppdragNavn}}</a>
            <a *ngIf="filterParams.fakturatype == 'Fagsystem' || filterParams.fakturatype == 'Programpakke'"
              [routerLink]="['/itsystemer', fakturalinje.systemId]">({{fakturalinje.systemId}})
              {{fakturalinje.systemNavn}}</a>
          </td>

          <td>{{fakturalinje.personNavn}}</td>

          <ng-container *ngIf="filterParams.fakturatype == 'Fagsystem' || filterParams.fakturatype == 'Programpakke'">
            <td> <a *ngIf="fakturalinje.endring"
                [routerLink]="['/itsystemer', fakturalinje.systemId,'endring',fakturalinje.endringId]">{{fakturalinje?.endring?.endringNummer}}</a>
            </td>
            <td> <a *ngIf="fakturalinje.endring"
                [routerLink]="['/itsystemer', fakturalinje.systemId,'endring',fakturalinje.endringId]">{{fakturalinje?.endring?.saksnummer}}</a>
            </td>
          </ng-container>

          <td>{{fakturalinje.beskrivelse}}</td>

          <td>{{fakturalinje.normaltid / 36000000000}}</td>
          <td>{{fakturalinje.sumNormaltid | currency: 'kr'}}</td>
          <td>{{fakturalinje.overtid / 36000000000}}</td>
          <td>{{fakturalinje.sumOvertid | currency: 'kr'}}</td>
          <td>{{fakturalinje.fastBelop | currency: 'kr'}}</td>
          <td>{{fakturalinje.godkjent ? 'Ja' : 'Nei'}}</td>
          <td>{{fakturalinje.fakturert ? 'Ja' : 'Nei'}}</td>
          <td>{{fakturalinje.sertifikat ? 'Ja' : 'Nei'}}</td>

        </tr>
      </tbody>
    </table>

  </div>







  <div *ngIf="visDriftFakturalinjer()" class="search-results">


    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>

            <th *ngIf="filterParams.fakturatype == 'SystemDrift'">

              <div class="btn-group" role="group">
                <button [disabled]="isGeneratingDriftRapport" class="btn btn-outline-secondary" (click)="generateDriftReport()">
                  <i  [ngClass]=" isGeneratingDriftRapport ? 'fas fa-spinner fa-spin' : 'fas fa-chart-bar'" class="fas fa-chart-bar"></i>
                </button>
              </div>

            </th>

            <th *ngIf="filterParams.fakturatype == 'OppdragDrift'" scope="col"></th>

            <th *ngIf="filterParams.fakturatype == 'SystemDrift'" scope="col">System</th>
            <th *ngIf="filterParams.fakturatype == 'OppdragDrift'" scope="col">Oppdrag</th>

            <th scope="col">Fra</th>
            <th scope="col">Til</th>

            <th *ngIf="filterParams.fakturatype == 'OppdragDrift'" scope="col">Måneder</th>
            <th scope="col">Sum Server</th>
            <th scope="col">Sum MSSQL database</th>
            <th scope="col">Sum Oracle database</th>
            <th scope="col">Sum ikke-std database</th>
            <th scope="col">Sum Tilgjengelighet</th>

            <th *ngIf="filterParams.fakturatype == 'SystemDrift'" scope="col">Sum Integrasjoner</th>
            <th *ngIf="filterParams.fakturatype == 'SystemDrift'" scope="col">Sum Oppdateringer</th>
            <th *ngIf="filterParams.fakturatype == 'SystemDrift'" scope="col">Sum vaktlag</th>

            <th scope="col">Sum tillegg</th>

            <th *ngIf="filterParams.fakturatype == 'OppdragDrift'" scope="col">Godkjent av</th>
            <th *ngIf="filterParams.fakturatype == 'OppdragDrift'" scope="col">Godkjent Dato</th>

            <th *ngIf="filterParams.fakturatype == 'OppdragDrift'" scope="col">Fakturert</th>
            <th *ngIf="filterParams.fakturatype == 'OppdragDrift'" scope="col">Fakturert Dato</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let fakturalinje of searchResults;">

            <td *ngIf="filterParams.fakturatype == 'SystemDrift'"></td>

            <td *ngIf="filterParams.fakturatype == 'OppdragDrift'">
              <div class="btn-group" role="group">
                <button *ngIf="!fakturalinje.godkjent && kanGodkjenne" type="button"
                  (click)="godkjennFakturalinjeOppdragDrift(fakturalinje)" placement="top" ngbTooltip="Godkjenn"
                  [openDelay]="400" [closeDelay]="200" class="btn btn-secondary"><i class="fas fa-check"></i></button>
                <button *ngIf="!fakturalinje.fakturert && kanFakturere" type="button"
                  (click)="merkFakturalinjeOppdragDrift(fakturalinje)" placement="top" ngbTooltip="Merk fakturert"
                  [openDelay]="400" [closeDelay]="200" class="btn btn-secondary"><i class="fas fa-receipt"></i></button>
              </div>
            </td>

            <td *ngIf="filterParams.fakturatype == 'OppdragDrift'"><a
                [routerLink]="['/oppdrag', fakturalinje.oppdragId]">({{fakturalinje.oppdragId}})
                {{fakturalinje.oppdragNavn}}</a></td>

            <td *ngIf="filterParams.fakturatype == 'SystemDrift'"><a
                [routerLink]="['/itsystemer', fakturalinje.systemId]">({{fakturalinje.systemId}})
                {{fakturalinje.systemNavn}}</a></td>

            <td>{{fakturalinje.fra | date:dateFormat}}</td>
            <td>{{fakturalinje.til | date:dateFormat}}</td>
            <td *ngIf="filterParams.fakturatype == 'OppdragDrift'">{{fakturalinje.maneder}}</td>
            <td>{{fakturalinje.sumServer | currency: 'kr'}}</td>
            <td>{{fakturalinje.sumSqlDb | currency: 'kr'}}</td>
            <td>{{fakturalinje.sumOracleDb | currency: 'kr'}}</td>
            <td>{{fakturalinje.sumIkkeStdDb | currency: 'kr'}}</td>
            <td *ngIf="filterParams.fakturatype == 'SystemDrift'">{{fakturalinje.sumTilgjengelighet | currency: 'kr'}}</td>
            <td *ngIf="filterParams.fakturatype == 'OppdragDrift'">{{fakturalinje.sumAppdrift | currency: 'kr'}}</td>

            <td *ngIf="filterParams.fakturatype == 'SystemDrift'">{{fakturalinje.sumIntegrasjoner | currency: 'kr'}}</td>
            <td *ngIf="filterParams.fakturatype == 'SystemDrift'">{{fakturalinje.sumOppdateringer | currency: 'kr'}}</td>
            <td *ngIf="filterParams.fakturatype == 'SystemDrift'">{{fakturalinje.sumVaktlag | currency: 'kr'}}</td>


            <td>{{fakturalinje.sumTillegg | currency: 'kr'}}</td>
            <td *ngIf="filterParams.fakturatype == 'OppdragDrift'">{{fakturalinje.godkjentAvNavn}}</td>
            <td *ngIf="filterParams.fakturatype == 'OppdragDrift'">{{fakturalinje.godkjentDato | date:dateFormat}}</td>
            <td *ngIf="filterParams.fakturatype == 'OppdragDrift'">{{fakturalinje.fakturert ? 'ja' : 'nei'}}</td>
            <td *ngIf="filterParams.fakturatype == 'OppdragDrift'">{{fakturalinje.fakturertDato | date:dateFormat}}</td>

          </tr>
        </tbody>
      </table>
    </div>

  </div>



</div>

<app-ikketilgang [harTilgang]="harTilgang" [loadingData]="loadingData" [overskrift]="overskrift"></app-ikketilgang>
