import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SearchItem, SearchResults } from "../common/common.model";
import { OppdragPerson, PersonLeverandorRolle, PersonSystemRolle } from "../koblinger/koblinger.service";


@Injectable({
  providedIn: "root"
})
export class SokService {

  private readonly basePath = "api/globalsok/";
  private readonly baseUrl: string;

  constructor(private http: HttpClient, @Inject("BASE_URL") private baseHost: string) {
    this.baseUrl = baseHost + this.basePath;
  }

  search(text?: string, pageNumber = 1, pageSize = 30): Observable<SearchResults<SearchItem>> {

    let searchParams = new HttpParams();

    if (text) { searchParams = searchParams.append("text", text); }
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<SearchResults<SearchItem>>(this.baseUrl + "search", { params: searchParams });
  }

  profil(): Observable<DataForPerson> {
    return this.http.get<DataForPerson>(this.baseUrl + "profil");
  }

}


export class DataForPerson {
  oppdragPerson: OppdragPerson[];
  personEndringRolle: any[];
  personLeverandorRolle: PersonLeverandorRolle[];
  personSystemRolle: PersonSystemRolle[];
}



