import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";


@Injectable({
  providedIn: "root"
})
export class NotificationService {

  constructor(private toastr: ToastrService) { }

  success(melding: string, overskrift: string): void {
    this.toastr.success(melding, overskrift, { positionClass: "toast-bottom-full-width" });
  }

  warning(melding: string, overskrift: string): void {
    this.toastr.warning(melding, overskrift, { positionClass: "toast-bottom-full-width" });
  }

  error(melding: string, overskrift: string): void {
    this.toastr.error(melding, overskrift, { positionClass: "toast-bottom-full-width" });
  }

  httpError(httpErrorResponse: HttpErrorResponse, overskrift: string): void {
    if (httpErrorResponse?.error?.expected) {
      this.expectedError(httpErrorResponse.error as CustomError);
    } else {
      this.unexpectedError(httpErrorResponse, overskrift);
    }
  }

  private expectedError(error: CustomError) {

    this.toastr.warning(error.shortMessage, error.title, {
      progressBar: true,
      closeButton: true,
      tapToDismiss: true,
      timeOut: 10000,
      positionClass: "toast-bottom-full-width"
    }).onTap.subscribe(() => { this.showErrorModal(); });
  }

  private showErrorModal() {
    console.log("SHOWING ERROR MODAL");
  }

  private unexpectedError(httpErrorResponse: HttpErrorResponse, overskrift: string) {
    let title = overskrift;
    let errorErrorsHtml = "";
    let message = "";
    let status = 0;
    let statusText = "";
    let statusBeskrivelse = "";

    if (httpErrorResponse.error) {
      title = httpErrorResponse.error.title;

      if (httpErrorResponse.error.errors) {
        const jsonString = JSON.stringify(httpErrorResponse.error.errors, null, 2);
        errorErrorsHtml = `<pre> ${jsonString} </pre>`;
      }
    }
    message = httpErrorResponse.message;
    status = httpErrorResponse.status;
    statusText = httpErrorResponse.statusText;

    if (status == 401) {
      statusBeskrivelse = "Ingen tilgang";
    }

    const html = `
    <h3><i class="fas fa-skull"></i> ${status} ${statusText}</h3>
    <p> ${title}</p>

    <p> ${message} </p>
    <p><i>${statusBeskrivelse}</i></p>

    ${errorErrorsHtml}

    `;

    this.toastr.error(html, overskrift, {
      enableHtml: true,
      closeButton: true,
      disableTimeOut: true,
      titleClass: "h3",
      tapToDismiss: false,
      positionClass: "toast-bottom-full-width"
    });
  }
}

export interface CustomError {
  expected: boolean;
  status: number;
  title: string;
  shortMessage: string;
  longMessage: string;
  additionalInfo: any;
}
