import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FilterParams } from "../../common.model";

@Component({
  selector: "app-filtre",
  templateUrl: "./filtre.component.html"
})
export class ListesideFiltreComponent implements OnInit {

  @Input()
  loadingData = false;

  @Input()
  filterParams: FilterParams;

  filtersCollapsed: boolean;

  @Output()
  onFilterChange = new EventEmitter<FilterParams>();



  ngOnInit(): void {
    // Do nothing
  }

  filterChanged(event: any) : void {
    this.onFilterChange.emit(this.filterParams);
  }

}

