import { Component, Input, OnInit } from "@angular/core";
import { ItsystemSearchResults, SystemType } from "../itsystemer/itsystemer.service";


@Component({
  selector: "app-itsystemsearchresult",
  templateUrl: "./itsystemsearchresult.component.html"
})
export class ItsystemsearchresultComponent implements OnInit {

  @Input() itsystem: ItsystemSearchResults;

  fagsystem = SystemType.Fagsystem;
  infrastruktur = SystemType.Infrastruktur;
  programpakke = SystemType.Programpakke;
  nettjeneste = SystemType.Nettjeneste;

  ngOnInit(): void {
    // Do nothing
   }

}
