import {ChangeDetectorRef, Component, Input} from "@angular/core";
import {FormControl} from "@angular/forms";
import {Itsystem, ItsystemerService} from "../../itsystemer/itsystemer.service";
import {FaktureringService, Pris} from "../../services/fakturering.service";
import {Field} from "../common.model";
import {FeltlisteComponent} from "./feltliste.component";
import {IntegrasjonsharedService} from "../../services/integrasjonshared.service";
import {Subscription} from "rxjs";
import {Oppdrag, OppdragService} from "../../oppdrag/oppdrag.service";

@Component({
  selector: "app-prisfeltliste",
  templateUrl: "./prisfeltliste.component.html",
  styleUrls: ["./prisfeltliste.component.scss"]
})
export class PrisfeltlisteComponent extends FeltlisteComponent {

  private readonly oppdragPrisfelt = new Set<string>(["antallServere", "antallOracleBaser",
    "antallMsSqlBaser", "antallMariaDbBaser", "antallMongoDbBaser", "antallPostgreSqlBaser", "antallGrensesnitt", "antallServereTest",
    "tilleggDriftspris", "tilleggKommentar"]);

  oppdaterIntegrasjon: Subscription;
  tilgjengelighetControl: FormControl;
  vaktlag: FormControl;
  enhetPriser = new Map<number, Pris>();
  priser = new Map<Field, number>();
  prisPrAar: number;
  prisPrMaaned: number;
  prisPrAarOppdragsfase: number;
  prisAppdrift: number;
  prisTilgjengelighet: number;
  prisIntegrasjon: number;
  prisVaktlagPrAar: number;
  prisOppdateringer: number;
  tilgjengelighetNivaa: string;

  @Input()
  parentModell;

  constructor(private fakturaService: FaktureringService, private cd: ChangeDetectorRef, private itSystemService: ItsystemerService, private integrasjonSS: IntegrasjonsharedService, private oppdragService: OppdragService) {
    super();
    this.oppdaterIntegrasjon = this.integrasjonSS.getEventI().subscribe(() => {
      this.kalkulerSum();
      this.formGroup.markAsDirty();
    })
  }

  ngOnInit(): void {
    this.fakturaService.getPriser().subscribe(priser => {
      priser.forEach(pris => {
        this.enhetPriser.set(pris.prisId, pris);
      });
      this.rekalkulerAlle();
      setTimeout(() => this.setupListeners(), 0);
      this.cd.detectChanges()
    });
    super.ngOnInit();
  }

  erItsystem(): boolean {
    return (this.parentModell as Itsystem).systemId !== undefined;
  }

  erOppdrag(): boolean {
    return (this.parentModell as Oppdrag).oppdragId !== undefined;
  }

  setupListeners(): void {
    if (this.erItsystem()) {
      this.tilgjengelighetControl = this.formGroup.parent.get("tilgjengelighetId") as FormControl;
    } else if (this.erOppdrag()) {
      this.tilgjengelighetControl = this.formGroup.get("tilgjengelighetId") as FormControl;
    }
    this.tilgjengelighetControl?.valueChanges.subscribe(() => {
      this.rekalkulerAlle();
    });

    if (this.erItsystem()) {
      this.vaktlag = this.formGroup.parent.get("vaktlag") as FormControl;
      this.vaktlag?.valueChanges.subscribe(() => {
        this.rekalkulerAlle();
      });
    }
  }

  ngOnDestroy(): void {
    // Do nothing
  }

  numDatabases(): number {
    var oracleDbs = this.modell["antallOracleBaser"];
    var msSqlDbs = this.modell["antallMsSqlBaser"];
    var postgresDbs = this.modell["antallPostgreSqlBaser"];
    var mariadbDbs = this.modell["antallMariaDbBaser"];
    var mongoDbDbs = this.modell["antallMongoDbBaser"];

    return oracleDbs +
      msSqlDbs +
      postgresDbs +
      mariadbDbs +
      mongoDbDbs;
  }

  numServers(): number {
    var servere = this.modell["antallServere"];
    return servere;
  }

  getPrisServere(): number {
    return this.numServers() * this.getPrisPrEnhet("Server").beløp;
  }

  kalkulerTilgjengelighet(): void {
    this.parentModell.tilgjengelighetPris = 0;
    this.tilgjengelighetNivaa = "";
    this.prisTilgjengelighet = 0;
    this.prisOppdateringer = 0;
    this.prisVaktlagPrAar = 0;
    const servere = this.numServers();
    const dbs = this.numDatabases();
    const sum = servere + dbs;
    this.parentModell.vaktlag = false;

    if (this.parentModell.tilgjengelighetId == 2) {
      //On premise: Arbeidstid 08-16
      var prisObj = null;

      if (sum > 0.1 && sum < 1.99) {
        prisObj = this.getPrisPrEnhet("On premise: Arbeidstid 08-16. 0,1-1,99 servere/DB");
      } else if (sum >= 2.0 && sum < 4.99) {
        prisObj = this.getPrisPrEnhet("On premise: Arbeidstid 08-16. 2,0-4,99 servere/DB");
      } else if (sum >= 5.0 && sum < 14.99) {
        prisObj = this.getPrisPrEnhet("On premise: Arbeidstid 08-16. 5,0-14,99 servere/DB");
      } else if (sum >= 15.0) {
        prisObj = this.getPrisPrEnhet("On premise: Arbeidstid 08-16. 15,0-  server/DB");
      }
      if (prisObj != null) {
        this.prisTilgjengelighet = prisObj.beløp;
        this.tilgjengelighetNivaa = prisObj.navn;
      }
      this.prisOppdateringer = this.getPrisPrEnhet("Oppdateringer").beløp;
    } else if (this.parentModell.tilgjengelighetId == 3) {
      //On premise: Arbeidstid 24/7
      var prisObj = null;

      if (sum > 0.1 && sum < 1.99) {
        prisObj = this.getPrisPrEnhet("On premise: Arbeidstid 24/7. 0,1-1,99 servere/DB");
      } else if (sum >= 2.0 && sum < 4.99) {
        prisObj = this.getPrisPrEnhet("On premise: Arbeidstid 24/7. 2,0-4,99 servere/DB");
      } else if (sum >= 5.0 && sum < 14.99) {
        prisObj = this.getPrisPrEnhet("On premise: Arbeidstid 24/7. 5,0-14,99 servere/DB");
      } else if (sum >= 15.0) {
        prisObj = this.getPrisPrEnhet("On premise: Arbeidstid 24/7. 15,0- servere/DB");
      }
      if (prisObj != null) {
        this.prisTilgjengelighet = prisObj.beløp;
        this.tilgjengelighetNivaa = prisObj.navn;
      }
      if (this.erItsystem()) {
        this.prisOppdateringer = this.getPrisPrEnhet("Oppdateringer").beløp;
        this.prisVaktlagPrAar = this.getPrisPrEnhet("Vaktlag").beløp;
        this.parentModell.vaktlag = true;
      }
      this.parentModell.Tilgjengelighet
    } else {
      //skysystemer
      this.tilgjengelighetNivaa = "Skytjeneste (8-16)";
    }
  }

  kalkulerSum(): void {
    let sum = 0;
    this.priser.forEach((pris) => {
      sum += pris;
    });
    sum += this.prisTilgjengelighet;
    if (this.erItsystem()) {
      sum += this.prisIntegrasjon;
      sum += this.prisOppdateringer;
      sum += this.prisVaktlagPrAar;
    }
    this.prisPrAar = sum;
  }

  kalkulerSumDrift(): void {
    let oppdragSum = 0.0;
    this.fields.forEach(field => {
      if (this.oppdragPrisfelt.has(field.key)) {
        oppdragSum += this.kalkulerPris(field);
      }
    });
    this.prisPrAarOppdragsfase = oppdragSum;
    this.prisPrMaaned = oppdragSum/12;
  }

  rekalkulerPris(field: Field): void {
    const pris = this.kalkulerPris(field);

    this.priser.set(field, pris);
    this.kalkulerTilgjengelighet();
    this.getPrisIntegrasjon();
    this.kalkulerSum();
    if (this.erOppdrag()) {
      this.kalkulerSumDrift();
    }
  }

  rekalkulerAlle(): void {
    this.fields.forEach(field => {
      if (field.inputType == "number" || field.inputType == "checkbox") {
        const pris = this.kalkulerPris(field);
        this.priser.set(field, pris);
      }
    });
    this.kalkulerTilgjengelighet();
    this.getPrisIntegrasjon();
    this.kalkulerSum();
    if (this.erOppdrag()) {
      this.kalkulerSumDrift();
    }
  }

  kalkulerPris(field: Field): number {
    let pris = 0;
    const enhetPris = this.getPrisPrEnhet(field.key).beløp;
    if (enhetPris != -1) {
      pris = this.modell[field.key] * enhetPris;
    } else if (field.key == "tilleggDriftspris") {
      pris = this.modell[field.key];
    }

    return pris;
  }

  getPrisIntegrasjon() {
    if (this.erOppdrag()) {
      return 0
    }
    this.itSystemService.getIntegrasjonPrisAntall((this.parentModell as Itsystem).systemId).subscribe(integrasjonPrisAntall=>{
      this.prisIntegrasjon = integrasjonPrisAntall.integrasjonSum;
      this.kalkulerSum();
    });
  }

  getPris(field: Field): Pris {
    return this.getPrisPrEnhet(field.key);
  }

  getPrisPrEnhet(key: string): Pris {
    switch (key) {
      case "antallServere":
        return this.enhetPriser.get(121);
      case "antallServereTest":
        return this.enhetPriser.get(5);
      case "antallMsSqlBaser":
        return this.enhetPriser.get(122);
      case "antallOracleBaser":
        return this.enhetPriser.get(123);
      case "antallMariaDbBaser":
        return this.enhetPriser.get(124);
      case "antallMongoDbBaser":
        return this.enhetPriser.get(125);
      case "antallPostgreSqlBaser":
        return this.enhetPriser.get(126);
      case "Vaktlag":
        return this.enhetPriser.get(120);
      case "On premise: Arbeidstid 08-16. 0,1-1,99 servere/DB":
        return this.enhetPriser.get(112);
      case "On premise: Arbeidstid 08-16. 2,0-4,99 servere/DB":
        return this.enhetPriser.get(113);
      case "On premise: Arbeidstid 08-16. 5,0-14,99 servere/DB":
        return this.enhetPriser.get(114);
      case "On premise: Arbeidstid 08-16. 15,0-  server/DB":
        return this.enhetPriser.get(115);
      case "On premise: Arbeidstid 24/7. 0,1-1,99 servere/DB":
        return this.enhetPriser.get(116);
      case "On premise: Arbeidstid 24/7. 2,0-4,99 servere/DB":
        return this.enhetPriser.get(117);
      case "On premise: Arbeidstid 24/7. 5,0-14,99 servere/DB":
        return this.enhetPriser.get(118);
      case "On premise: Arbeidstid 24/7. 15,0- servere/DB":
        return this.enhetPriser.get(119);
      case "Oppdateringer":
        return this.enhetPriser.get(109);
      default:
        return {beløp: -1} as Pris;
    }
  }

  harDognbemannetVaktlag(): boolean {
    return this.parentModell?.tilgjengelighetId == 3;
  }
}
