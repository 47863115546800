import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PageAccess, TilgangService } from "src/app/services/tilgang.service";

@Component({
  selector: "app-opprett-ny-knapp",
  templateUrl: "./opprett-ny-knapp.component.html"
})
export class OpprettNyKnappComponent implements OnInit {

  harTilgangTilOpprettNy = false;

  constructor(private route: ActivatedRoute, private tilgangService: TilgangService) { }

  ngOnInit(): void {
    this.route.url.subscribe(url => {

      const urlString = url.join("/");

      this.tilgangService.getGlobalRoles().subscribe(tilgang => {

        const harTilgang = tilgang[urlString] as PageAccess;

        console.log(harTilgang);

        this.harTilgangTilOpprettNy = harTilgang?.createAccess;

      });
    });

  }

}
