<app-spinner [loadingData]="isLoading" [loadingFailed]="loadingFailed"></app-spinner>

<div *ngFor="let item of liste" class="list-group-flush">

    <a [routerLink]="['/tjenester', item.tjenesteId]"
        class="list-group-item list-group-item-action flex-column align-items-start" [class.disabled]="!linkAccess">
        <h5 class="mb-1"> {{item.tjeneste?.navn}} </h5>
        <div class="text-muted">{{item.tjeneste?.beskrivelse}}</div>
    </a>

</div>