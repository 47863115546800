<div *ngIf="isLoading" class="d-flex justify-content-center mt-5">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div
  *ngIf="itsystem && mainFieldsColumn1 && mainFieldsColumn2 && prisberegningFields">

  <div class="card">
    <div class="card-body">
      <h4 class="card-title">
        <app-editbutton [formGroup]="formGroup" [field]="headingField" [modell]="itsystem"></app-editbutton>

        <a *ngIf="visRisikoGjennomgang" [routerLink]="['/risikogjennomganger', itsystem.systemId]"
           class="float-right mx-2" placement="top" ngbTooltip="Sikkerhetsinfo" [openDelay]="400" [closeDelay]="200">
          <i class="fas fa-shield-alt"></i>
        </a>

        <a *ngIf="visTestinformasjon" [routerLink]="['/testinformasjoner', itsystem.systemId]"
           class="float-right mx-2" placement="top" ngbTooltip="Testinformasjon" [openDelay]="400" [closeDelay]="200">
          <i class="fas fa-tasks"></i>
        </a>

        <button *ngIf="systemTypeId==1" type="button" (click)="generateTjenesteAvtaleVedlegg()"
                class="btn btn-link float-right mx-2" placement="top" ngbTooltip="Generer Tjenesteavtalevedlegg"
                [openDelay]="400" [closeDelay]="200">
          <i class="fas fa-chart-bar"></i>
        </button>


      </h4>
      <h6 class="card-subtitle mb-2 text-muted">{{systemTypeNavn}}</h6>
      <p class="card-text" *ngFor="let field of topFields">
        <app-editbutton [formGroup]="formGroup" [field]="field" [modell]="itsystem"></app-editbutton>
      </p>

      <a *ngIf="slaValidUrl()" href="{{itsystem.tjenesteavtale}}" target="_blank" class="float-right ml-4">
        SLA <i class="fas fa-external-link-alt"></i>
      </a>

      <a *ngIf="slaavikValidUrl()" href="{{itsystem.avvikPaTilgjengelighet}}" target="_blank" class="float-right ml-4">
        SLA-avvik <i class="fas fa-external-link-alt"></i>
      </a>

      <a *ngIf="systemTypeNavn === 'Fagsystem' || systemTypeNavn === 'Infrastruktur'"
         href="{{driftsDokUrl}}{{systemId}}" target="_blank" class="float-right">
        Dokumentasjon i Sharepoint <i class="fas fa-external-link-alt"></i>
      </a>

    </div>
  </div>


  <app-lagreknapp [isSaving]="isSaving" [formGroup]="formGroup" (onLagre)="lagre()"></app-lagreknapp>

  <div class="col-12 col-md-9" *ngIf="tilgangTilFakturalinjeButton">
    <button class="btn btn-outline-primary px-5" type="button" (click)="opprettFakturalinje()" [disabled]="disableFakturalinjeButton">Opprett fakturalinje</button>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-5 col-lg-4 column-container">
        <app-feltliste [formGroup]="formGroup" [fields]="mainFieldsColumn1" [modell]="itsystem"></app-feltliste>
      </div>
      <div class="col-12 col-md-4 col-lg-4 column-container">
        <app-feltliste [formGroup]="formGroup" [fields]="mainFieldsColumn2" [modell]="itsystem"></app-feltliste>
      </div>
      <div class="col-12 col-md-3 col-lg-4 column-container">
        <app-feltliste [formGroup]="formGroup" [fields]="mainFieldsColumn3" [modell]="itsystem"></app-feltliste>
        <app-prisfeltliste *ngIf="itsystem.systemtypeId != programPakke"
                           [formGroup]="getPrisberegning()"
                           [fields]="prisberegningFields" [modell]="itsystem.prisberegning"
                           [parentModell]="itsystem"></app-prisfeltliste>
      </div>
    </div>
  </div>

  <div class="row">
    <div *ngIf="itsystem" class="col">
      <app-tabs #tab [tabs]="tabs"></app-tabs>
    </div>
  </div>

</div>
