import {ChangeDetectorRef, Component, ViewChild} from "@angular/core";
import { AbstractControl, ValidationErrors, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { Field } from "../common/common.model";
import { FeltSideBaseClass } from "../common/feltside/feltside.component";
import {Tab, TabsComponent} from "../common/tabs/tabs.component";
import { Tabs } from "../common/tabs/tabs.enum";
import { NotificationService } from "../services/notification.service";
import { TabAccess, TilgangService } from "../services/tilgang.service";
import { Leverandor, LeverandorerService } from "./leverandor.service";
import {EndringsloggTabComponent} from "../endringslogg-tab-component/endringslogg-tab.component";

@Component({
  selector: "app-leverandor",
  templateUrl: "./leverandor.component.html"
})
export class LeverandorComponent extends FeltSideBaseClass<Leverandor> {


  idParamName = "leverandorId";

  constructor(service: LeverandorerService, private tilgangService: TilgangService, notifyService: NotificationService, route: ActivatedRoute, changeDetectorRef: ChangeDetectorRef) {
    super(service, notifyService, route, changeDetectorRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

  }

  onGetFields(fields: Field[]): void {

    const indexToSplit = (fields.length / 2);
    this.col1 = fields.slice(0, indexToSplit);
    this.col2 = fields.slice(indexToSplit + 1);

    const navnField = fields.filter(f => f.key == "navn")[0];

    navnField.validators = [Validators.required, Validators.minLength(2)];

    const urlField = fields.filter(f => f.key == "url")[0];
    urlField.validators = [Validators.required, this.validateUrl];

  }

  validateUrl(control: AbstractControl): ValidationErrors | null {
    const val = control.value;
    if (val != null) {
      if (!val.startsWith("http")) {
        return { validUrl: "Ugyldig url" };
      }
    }

    return null;
  }

  getTabAccess(): Observable<TabAccess[]> {
    return this.tilgangService.getTabAccess("leverandor", this.id);
  }

  getAvailableTabs(): Tab[] {
    return [
      { key: Tabs.systemer, label: "Systemer", parentRoute: ["/leverandorer", this.id + ""] },
      { key: Tabs.personer, label: "Personer", parentRoute: ["/leverandorer", this.id + ""] },
      { key: Tabs.oppdrag, label: "Oppdrag", parentRoute: ["/leverandorer", this.id + ""] },
      { key: Tabs.endringslogg, label: "Endringslogg", parentRoute: ["/leverandorer", this.id + ""] },
    ];
  }

}
