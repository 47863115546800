<div *ngIf="!harTilgang">
    Du har ikke tilgang til å registrere ny {{getTitle()}}.
</div>

<div *ngIf="harTilgang" class="container">
    <div class="card">
        <div class="card-body">

            <h2>{{getHeadline()}}</h2>

            <div class="row my-4">
                <div *ngIf="customFields[0]" class="col" style="cursor: pointer;">
                    <app-systemvelgerfelt [formGroup]="formGroup" [field]="customFields[0]" [modell]="model">
                    </app-systemvelgerfelt>
                </div>


                <div class="col-auto" *ngIf="customFields.length>0" [ngSwitch]="model['retningId']">
                    <b *ngSwitchCase="1">
                        --&gt;
                    </b>
                    <b *ngSwitchCase="2">
                        &lt;--
                    </b>
                    <b *ngSwitchCase="3">
                        &lt;--&gt;
                    </b>
                    <b *ngSwitchDefault>
                        --
                    </b>
                </div>

                <div *ngIf="customFields[1]" class="col" style="cursor: pointer;">
                    <app-systemvelgerfelt [formGroup]="formGroup" [field]="customFields[1]" [modell]="model">
                    </app-systemvelgerfelt>
                </div>
            </div>

            <app-feltliste *ngIf="nonCustomFields" [formGroup]="formGroup" [fields]="nonCustomFields" [modell]="model">
            </app-feltliste>

            <app-lagreknapp [buttonTitle]="'Opprett'" [isSaving]="isSaving" [formGroup]="formGroup" (onLagre)="lagre()">
            </app-lagreknapp>

        </div>


        <app-spinner [loadingData]="isLoading" [loadingFailed]="errorLoading" (retryAction)="ngOnInit()"></app-spinner>

    </div>

</div>