import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Tabs } from "src/app/common/tabs/tabs.enum";
import { TabsService } from "src/app/common/tabs/tabs.service";
import { TilgangService } from "src/app/services/tilgang.service";
import { TabSideBaseClass } from "../../common/tab-side/tab-side.component";
import {KoblingerService, OppdragSystem, SystemTjeneste} from "../../koblinger/koblinger.service";
import { ModalService } from "../../services/modal.service";
import {Itsystem} from "../../itsystemer/itsystemer.service";

@Component({
  selector: "app-tjenesteitsystem-tab",
  templateUrl: "./tjenesteitsystem-tab.component.html"

})
export class TjenesteItsystemTabComponent extends TabSideBaseClass<SystemTjeneste> {

  constructor(tabsService: TabsService, service: KoblingerService, tilgangService: TilgangService, modal: ModalService, route: ActivatedRoute, router: Router) {
    super(tabsService, service, tilgangService, modal, route, router);
  }

  parentIdParamName = "tjenesteId";

  tabName(): Tabs {
    return Tabs.systemer;
  }

  loaderObservable(service: KoblingerService, id: number): Observable<SystemTjeneste[]> {
    return service.getSystemTjenesterForTjeneste(id);
  }

  pageLinkAccess(service: TilgangService): Observable<boolean> {
    return service.getGlobalRoles().pipe(map(val => val.itsystemer.listAccess));
  }

  removeObservable(service: KoblingerService, item: SystemTjeneste): Observable<object> {
    return service.removeSystemTjenesteForTjeneste(item.systemId, item.tjenesteId);
  }

  kobleTil(): void {
    this.modal.velgSystem().then(system => {

      if (this.liste.some(t => t.systemId === system.systemId)) {
        this.modal.warning(`System '${system.navn}' er allerede koblet til tjenesten`, "Kobling feilet");
        return;
      }

      const systemTjeneste: SystemTjeneste = { systemId: system.systemId, tjenesteId: this.id };
      this.service.postSystemTjenesteForTjeneste(systemTjeneste).subscribe(() => {
        this.modal.success(`Tjeneste koblet til system : ${system.navn}`, "kobling vellykket");
        this.load();
      });
    }, onreject => {
      console.log("REJECT MANNEN")
    }).catch(() => {
      console.log("ERROR")
    });
  }

  public erUtgaatt(system: Itsystem) : boolean {
    return system.systemstatusId == 5;
  }

  compareFn(first: SystemTjeneste, second: SystemTjeneste): number {
    if (first.system.systemstatusId != 5 && second.system.systemstatusId != 5) return 0;
    if (first.system.systemstatusId != 5 && second.system.systemstatusId == 5) return -1;
    else return 1;
  }

}
