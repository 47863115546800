import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class PathResolverService implements Resolve<string | null>  {

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string | Observable<string> | Promise<string> {

    const inputWord = state.url.substring(1);

    const candidates = Object.values(paths).filter(path => Math.abs(path.length - inputWord.length) < 5);

    if (!candidates?.length) {
      return "";
    }

    this.sortByLevenshtein(inputWord, candidates);

    return `/${candidates[0]}`;
  }

  private sortByLevenshtein(inputString: string, arr: string[]) {

    const distances = {} as { [key: string]: number };

    arr.sort((a, b) => {
      if (!(a in distances)) {
        distances[a] = this.levenshtein(a, inputString);
      }
      if (!(b in distances)) {
        distances[b] = this.levenshtein(b, inputString);
      }
      return distances[a] - distances[b];
    });
  }

  // Copied from https://gist.github.com/keesey/e09d0af833476385b9ee13b6d26a2b84
  private levenshtein(a: string, b: string): number {
    const an = a ? a.length : 0;
    const bn = b ? b.length : 0;
    if (an === 0) {
      return bn;
    }
    if (bn === 0) {
      return an;
    }
    const matrix = new Array<number[]>(bn + 1);
    for (let i = 0; i <= bn; ++i) {
      const row = matrix[i] = new Array<number>(an + 1);
      row[0] = i;
    }
    const firstRow = matrix[0];
    for (let j = 1; j <= an; ++j) {
      firstRow[j] = j;
    }
    for (let i = 1; i <= bn; ++i) {
      for (let j = 1; j <= an; ++j) {
        if (b.charAt(i - 1) === a.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1], // substitution
            matrix[i][j - 1], // insertion
            matrix[i - 1][j] // deletion
          ) + 1;
        }
      }
    }
    return matrix[bn][an];
  }
}


export const paths = {
  risikogjennomganger: "risikogjennomganger",
  testinformasjoner: "testinformasjoner",
  itsystemer: "itsystemer",
  itsystemer_ny: "itsystemer/ny",
  diverse: "diverse",
  fakturering: "fakturaer",
  oppdrag: "oppdrag",
  oppdrag_ny: "oppdrag/ny",
  risikogjennomgang: "risikogjennomgang",
  leverandorer: "leverandorer",
  leverandorer_ny: "leverandorer/ny",
  endringer: "endringer",
  personer: "personer",
  personer_ny: "personer/ny",
  sertifikater: "sertifikater",
  tjenester: "tjenester",
  integrasjoner: "integrasjoner",
};










