import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { Tab, TabsComponent } from "../common/tabs/tabs.component";
import { Tabs } from "../common/tabs/tabs.enum";
import { EndringsloggTabComponent } from "../endringslogg-tab-component/endringslogg-tab.component";
import { countDirty } from "../global";
import { BackNavigationComponent } from "../services/backnavigation.guard";
import { ModalService } from "../services/modal.service";
import { NotificationService } from "../services/notification.service";
import { Person, PersonService } from "../services/person.service";
import { TilgangService } from "../services/tilgang.service";

@Component({
  selector: "app-person",
  templateUrl: "./person.component.html",
  styleUrls: ["./person.component.scss"]
})
export class PersonComponent implements OnInit, BackNavigationComponent<Person> {


  tabs: Tab[] = [];
  @ViewChild('tab') tabsComponent: TabsComponent;

  tabsLoading = false;
  ref: string;

  person: Person;
  laster = true;
  isSaving = false;


  harTilgang = false;
  harSkrivetilgang = false;
  registrererNy = false;


  persondetaljerSynlig = false;

  nyPersonForm = new FormGroup({
    brukerOppslag: new FormControl(),
    nyEkstern: new FormControl()
  });

  personForm = new FormGroup({

    personId: new FormControl(),
    brukernavn: new FormControl(),
    navn: new FormControl(),
    fornavn: new FormControl(),
    etternavn: new FormControl(),
    telefon: new FormControl(),
    mobil: new FormControl(),
    epost: new FormControl(),
    firma: new FormControl(),
    avdeling: new FormControl(),
    kontor: new FormControl(),
    sluttet: new FormControl(),
    tittelRolle: new FormControl(),
  });
  submitted: boolean;

  constructor(private personService: PersonService,
    private tilgangService: TilgangService,
    private notificationService: NotificationService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {

    this.tilgangService.getGlobalRoles().subscribe(g => {
      this.harTilgang = g.personer.listAccess;
      this.harSkrivetilgang = g.personer.createAccess;
      this.laster = false;

      if (this.harTilgang) {
        this.hentPerson(this.route);
      }
    });

    this.route.queryParams.subscribe(params => {
      this.ref = params.ref as string;
    });

  }

  brukerOppslag(): FormControl {
    return <FormControl>this.nyPersonForm.get("brukerOppslag");
  }

  private hentPerson(route: ActivatedRoute) {
    route.params.subscribe(params => {
      if (params["personId"] != null) {
        this.setupTabs(+params["personId"]);
        this.personService.getPerson(params["personId"]).subscribe(person => {
          this.person = person;
          this.persondetaljerSynlig = true;
          this.visPersondetaljer(person);
          this.registrererNy = false;
        });
      } else {
        this.person = {} as Person;
        this.registrererNy = true;
      }
    });
  }

  erBergenKommuneEpost(): boolean {
    const epost = this.brukerOppslag().value as string;
    return epost != null && epost.endsWith("@bergen.kommune.no");
  }

  sjekkEpostadresse(): void {
    const epost = this.brukerOppslag().value as string;
    if (epost != null && epost.length > 0) {
      this.personService.epostEksisterer(epost).subscribe(eksisterer => {
        if (eksisterer) {
          this.brukerOppslag().setErrors({ exists: true });
        } else {
          if (epost.endsWith("@bergen.kommune.no")) {
            this.finnPersonAD(epost);
          } else {
            this.person.epost = epost;
            this.visPersondetaljer(this.person);
          }
        }
      });
    } else {
      this.brukerOppslag().setErrors({ empty: true });
    }
  }

  finnPersonAD(epost: string, oppdater = false): void {
    this.personService.lookupAdUser(epost).subscribe(person => {
      if (person != null) {
        const personId = this.person.personId;
        if (oppdater) {
          this.person.personId = personId;
          this.notificationService.success("Brukerinformasjon har blitt oppdatert fra AD", "Bruker oppdatert");
        } else {
          this.person = person;
        }
        this.visPersondetaljer(person);
      } else {
        this.brukerOppslag().setErrors({ unknown: true });
      }
    },
      (error: HttpErrorResponse) => {
        if (error.status == 404) {
          this.brukerOppslag().setErrors({ unknown: true });
        } else {
          this.handleOppslagError(error, epost);
        }
      });
  }

  oppdaterFraAD(): void {
    this.finnPersonAD(this.personForm.get("epost").value, true);
  }

  visPersondetaljer(person: Person): void {
    const redigerNavn = person.brukernavn === undefined || person.brukernavn === null;
    if (person.personId != 0) {
      this.personForm.get("personId").setValue(person.personId);
    }
    this.personForm.get("personId").disable();
    this.personForm.get("navn").setValue(person.navn);
    this.personForm.get("fornavn").setValue(person.fornavn);
    this.personForm.get("etternavn").setValue(person.etternavn);
    this.personForm.get("telefon").setValue(person.telefon);
    this.personForm.get("mobil").setValue(person.mobil);
    this.personForm.get("epost").setValue(person.epost);
    this.personForm.get("firma").setValue(person.firma);
    this.personForm.get("kontor").setValue(person.kontor);
    this.personForm.get("avdeling").setValue(person.avdeling);
    this.personForm.get("sluttet").setValue(person.sluttet);
    this.personForm.get("tittelRolle").setValue(person.tittelRolle);
    this.personForm.get("brukernavn").setValue(person.brukernavn);
    this.personForm.get("brukernavn").disable();
    if (!redigerNavn) {
      this.personForm.get("fornavn").disable();
      this.personForm.get("etternavn").disable();
      this.personForm.get("epost").disable();

    }
    this.persondetaljerSynlig = true;
  }

  savePerson(): void {
    this.isSaving = true;
    const person = this.personForm.getRawValue() as Person;
    if (person.personId) {
      this.personService.updatePerson(person.personId, person).subscribe(oppdatertPerson => {
        this.notificationService.success(`Oppdatert person med id: ${oppdatertPerson.personId}, og epost-adresse: ${oppdatertPerson.epost}`, "Person oppdatert");
        this.person = oppdatertPerson;
        this.isSaving = false;
      });
    } else {
      person.personId = 0;
      this.personService.createPerson(person).subscribe(newPerson => {
        console.log(`Registrert person, id: ${newPerson.personId}`);
        this.notificationService.success(`Ny bruker opprettet, person-id: ${newPerson.personId}, epost-adresse: ${newPerson.epost}`, "Ny person opprettet");

        if (this.ref) {
          this.router.navigate([decodeURIComponent(this.ref)], { queryParams: { refResults: encodeURIComponent(newPerson.fornavn) } })
        } else {
          this.router.navigateByUrl(`/personer/${newPerson.personId}`).then();
        }

        this.isSaving = false;
      });
    }
    let activeTab = this.tabsComponent.getActiveTab();
    if (activeTab instanceof EndringsloggTabComponent) {
      activeTab.hentEndringslogg();
    }
  }

  skjemaErEndret(): boolean {
    return this.registrererNy ||
      (
        this.person.navn != this.personForm.get("navn").value ||
        this.person.fornavn != this.personForm.get("fornavn").value ||
        this.person.etternavn != this.personForm.get("etternavn").value ||
        this.person.mobil != this.personForm.get("mobil").value ||
        this.person.telefon != this.personForm.get("telefon").value ||
        this.person.kontor != this.personForm.get("kontor").value ||
        this.person.avdeling != this.personForm.get("avdeling").value ||
        this.person.firma != this.personForm.get("firma").value ||
        this.person.tittelRolle != this.personForm.get("tittelRolle").value ||
        this.person.sluttet != this.personForm.get("sluttet").value ||
        this.person.fornavn != this.personForm.get("fornavn").value);
  }

  handleOppslagError(error: HttpErrorResponse, epost: string): void {
    console.log(error);
    this.notificationService.httpError(error, `Oppslag etter person med epost ${epost} feilet`);
  }

  handleLagreError(error: HttpErrorResponse, person: Person): void {
    console.log(error);
    const feilmelding = person.personId != null ? `Lagring av ny person med epost ${person.epost} feilet` :
      `Oppdatering av person med id ${person.personId} og epost ${person.epost} feilet`;
    this.notificationService.httpError(error, feilmelding);
  }

  sidenErEndret(): boolean {
    if (!this.harTilgang) return false;
    return this.skjemaErEndret();
  }

  antallEndringer(): number {
    return countDirty(this.personForm);
  }

  saveChanges(): Observable<Person> {
    const person = this.personForm.getRawValue() as Person;
    if (person.personId) {
      return this.personService.updatePerson(person.personId, person)
    } else {
      person.personId = 0;
      return this.personService.createPerson(person);
    }
  }

  tilbakestill(): void {
    this.visPersondetaljer(this.person);
  }



  private setupTabs(personId: number) {

    const tabs = [
      { key: Tabs.systemer, label: "Systemer", parentRoute: ["/personer", personId + ""] },
      { key: Tabs.oppdrag, label: "Oppdrag", parentRoute: ["/personer", personId + ""] },
      { key: Tabs.leverandorer, label: "Leverandører", parentRoute: ["/personer", personId + ""] },
      { key: Tabs.endringslogg, label: "Endringslogg", parentRoute: ["/personer", personId + ""] }
    ];

    this.tabsLoading = true;
    this.tilgangService.getTabAccess("person", personId).subscribe(t => {
      this.tabs = tabs.filter(ft => t.some(tabAccess => tabAccess.key == ft.key));
      this.tabsLoading = false;
    });

  }

}
