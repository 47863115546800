import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {saveAs} from 'file-saver';
import {
  IntegrasjonPrisAntall,
  Itsystem,
  ItsystemerService,
  TilgjengelighetEnum,
  Tjenesteavtalevedlegg,
  TjenesteavtalevedleggParams
} from "src/app/itsystemer/itsystemer.service";
import {Pris} from "src/app/services/fakturering.service";
import {PrisKalkulatorService} from "src/app/services/pris-kalkulator.service";
import {IntegrasjonerService, Overforingsmetode, SystemSystem} from "../../../../integrasjoner/integrasjoner.service";
import {KoblingerService} from "../../../../koblinger/koblinger.service";
import {Observable} from "rxjs";

@Component({
  selector: "app-tjenesteavtalevedlegg",
  templateUrl: "./tjenesteavtalevedlegg.component.html",
  styleUrls: ["./tjenesteavtalevedlegg.component.scss"]
})
export class TjenesteavtalevedleggComponent implements OnInit {

  constructor(public prisKalkulator: PrisKalkulatorService, private itsystemService: ItsystemerService, private koblingerService: KoblingerService,
              private integrasjonerService: IntegrasjonerService) {
  }

  readonly dateFormat = "d.M.y";

  isLoading = true;
  generatingReport = false;
  errorLoading = false;

  fagsystem: Itsystem;
  tjenesteavtalevedlegg: Tjenesteavtalevedlegg;
  prisGrunnlag: Map<number, Pris>;
  kommentarer: string[] = new Array(13);

  prisIntegrasjon: IntegrasjonPrisAntall;

  @Output() onConfirm = new EventEmitter<boolean>();

  integrasjoner: SystemSystem[];

  priser: Pris[];

  overforingsmetoder: Overforingsmetode[];

  driftspris(): number {
    const sum =
      this.prisKalkulator.getPris(this.prisGrunnlag, "antallServere") * this.fagsystem.prisberegning.antallServere +
      this.prisKalkulator.getPris(this.prisGrunnlag, "antallMsSqlBaser") * this.fagsystem.prisberegning.antallMsSqlBaser +
      this.prisKalkulator.getPris(this.prisGrunnlag, "antallOracleBaser") * this.fagsystem.prisberegning.antallOracleBaser +
      this.prisKalkulator.getPris(this.prisGrunnlag, "antallMariaDbBaser") * this.fagsystem.prisberegning.antallMariaDbBaser +
      this.prisKalkulator.getPris(this.prisGrunnlag, "antallMongoDbBaser") * this.fagsystem.prisberegning.antallMongoDbBaser +
      this.prisKalkulator.getPris(this.prisGrunnlag, "antallPostgreSqlBaser") * this.fagsystem.prisberegning.antallPostgreSqlBaser +
      this.prisIntegrasjon.integrasjonSum +
      this.getPrisTilgjengelighet(this.fagsystem) +
      this.prisKalkulator.getVaktlag(this.prisGrunnlag, this.fagsystem.tilgjengelighetId) +
      this.prisKalkulator.getPrisOppdateringer(this.prisGrunnlag, this.fagsystem.tilgjengelighetId) +
      this.fagsystem.prisberegning.tilleggDriftspris;

    return sum;
  }


  ngOnInit(): void {
    // Do nothing
  }

  cancel(): void {
    this.onConfirm.emit(false);
  }

  exportWord(): void {
    this.generatingReport = true;
    console.log("kommentarer", this.kommentarer);

    const tjenesteParams: TjenesteavtalevedleggParams =
      {
        systemId: this.fagsystem.systemId,
        pris: this.driftspris(),
        kommentarer: this.kommentarer,
        prisIntegrasjon: this.prisIntegrasjon,
        prisTilgjengelighet: this.getPrisTilgjengelighet(this.fagsystem)
      }

    this.itsystemService.getVedleggAsWord(tjenesteParams).subscribe(results => {
        saveAs(results, "rapport.docx");
        this.generatingReport = false;
      },
      () => this.generatingReport = false);
  }

  vaktlag(tilgjengeligHetId: number) {
    return tilgjengeligHetId == TilgjengelighetEnum.DøgnDrift;
  }

  oppdateringer(tilgjengelighetId: number) {
    return tilgjengelighetId == TilgjengelighetEnum.DøgnDrift || tilgjengelighetId == TilgjengelighetEnum.NormalDrift
  }

  getPrisTilgjengelighet(fagSystem : Itsystem) {
    return this.prisKalkulator.getPrisTilgjengelighet(this.prisGrunnlag, fagSystem.prisberegning, fagSystem.tilgjengelighetId)
  }

  getOverforingsmetode(metodeId: number) {
    for(let overforingsmetode of this.overforingsmetoder) {
      if (overforingsmetode.metodeId == metodeId) {
        return overforingsmetode.navn;
      }
    }
    return "";
  }

  getRetning(retningId: number): string {
    switch (retningId) {
      case 1:
        return "-->";
      case 2:
        return "<--";
      case 3:
        return "--";
      default:
        return "--";
    }
  }

  getPrisForIntegrasjon(metodeId: number, faktureres: boolean) {
    for (let pris of this.priser) {
      if (faktureres && pris.navn == this.getOverforingsmetode(metodeId)) {
        return pris.beløp;
      }
    }
    return 0.00;
  }
}
