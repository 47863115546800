import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html"
})
export class SpinnerComponent implements OnInit {

  private _loadingFailed = false;

  @Input()
  loadingData = true;
  @Input()
  get loadingFailed(): boolean { return this._loadingFailed; }
  set loadingFailed(loadingFailed: boolean) {
    this._loadingFailed = loadingFailed;
    if (this._loadingFailed) {
      this.loadingData = false;
    }
  }
  @Input()
  loadingText: string;

  @Output() retryAction = new EventEmitter();

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    // Do nothing
  }

  retry(): void {
    this.loadingFailed = false;
    this.retryAction.emit();
  }

}
