<div class="container">
  <div class="col text-center">
    <button class="btn btn-primary mb-2" type="button" (click)="filtersCollapsed = !filtersCollapsed"
      data-toggle="collapse" aria-expanded="false" [attr.aria-expanded]="filtersCollapsed" aria-controls="filters">
      <i [ngClass]="{'fas fa-caret-up': filtersCollapsed}"></i>
      <i [ngClass]="{'fas fa-caret-down': !filtersCollapsed}"></i>
      <span class="mx-2" *ngIf="filtersCollapsed">Skjul Filtre</span>
      <span class="mx-2" *ngIf="!filtersCollapsed">Vis Filtre</span>
    </button>
  </div>
</div>

<div class="collapse" id="filters" [ngbCollapse]="!filtersCollapsed">
  <div class="card card-body">

    <div class="container">



      <div class="row align-items-center">
        <div *ngIf="filterParams.fakturatype == 'Oppdrag' || filterParams.fakturatype == 'OppdragDrift'" class="col">
          <div class="input-group my-3">
            <div class="input-group-prepend">
              <button class="btn btn-outline-secondary" (click)="velgOppdrag()" type="button">Velg Oppdrag</button>
            </div>
            <input #oppdrag type="text" [value]="valgtOppdrag?.navn" class="form-control" readonly placeholder="...">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="clearOppdrag()" type="button"><i
                  class="fas fa-times"></i></button>
            </div>
          </div>
        </div>

        <div *ngIf="filterParams.fakturatype == 'Fagsystem' || filterParams.fakturatype == 'Programpakke'" class="col">
          <div class="input-group my-3">
            <div class="input-group-prepend">
              <button class="btn btn-outline-secondary" (click)="velgSystem(filterParams.fakturatype)"
                type="button">Velg {{filterParams.fakturatype == 'Fagsystem' ? 'System' : 'Programpakke'}}</button>
            </div>
            <input #system type="text" [value]="valgtSystem?.navn" class="form-control" readonly placeholder="...">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="clearSystem()" type="button"><i
                  class="fas fa-times"></i></button>
            </div>
          </div>
        </div>

        <div *ngIf="filterParams.fakturatype == 'SystemDrift'" class="col">
          <app-soketekstfilter (onFilterChange)="filterChanged($event)" [filterParams]="filterParams">
          </app-soketekstfilter>
        </div>

      </div>

      <div *ngIf="filterParams.fakturatype == 'SystemDrift' else other">

        <ng-template #footerTemplateFra>
          <hr class="my-0">
          <div class="text-center">
            <button class="btn btn-primary btn-sm m-2" (click)="clearFraDato();df.close()">Slett</button>
          </div>
        </ng-template>

        <div class="row align-items-center">
          <div class="col">

            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">Fra</span>
              </div>
              <input id="startDato" class="form-control" placeholder="yyyy-mm-dd" name="dpFrom"
                [footerTemplate]="footerTemplateFra" [readOnly]="true" [maxDate]="til"
                (dateSelect)="filterChanged($event)" [(ngModel)]="fra" ngbDatepicker #df="ngbDatepicker">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary fa fa-calendar" (click)="df.toggle()" type="button"></button>
              </div>
            </div>
          </div>

          <div class="col">
            <div *ngIf="systemStatus" class="input-group my-3">
              <div class="input-group-prepend">
                <span class="input-group-text">System Status</span>
              </div>
              <select class="custom-select" [(ngModel)]="filterParams.systemStatus" (change)="filterChanged($event)">
                <option [ngValue]="undefined">Alle</option>
                <option *ngFor="let it of systemStatus" [value]="it.systemstatusId"
                  [attr.selected]="it.systemstatusId==filterParams.systemStatus">
                  {{it.navn}}</option>
              </select>
            </div>

          </div>


          <div class="col">
            <div *ngIf="systemType" class="input-group my-3">
              <div class="input-group-prepend">
                <span class="input-group-text">System Type</span>
              </div>
              <select class="custom-select" [(ngModel)]="filterParams.systemType" (change)="filterChanged($event)">
                <option [ngValue]="undefined">Alle</option>
                <option *ngFor="let st of systemType" [value]="st.systemtypeId"
                  [attr.selected]="st.systemtypeId==filterParams.systemType">
                  {{st.type}}</option>
              </select>
            </div>

          </div>
        </div>


      </div>



      <ng-template #other>

        <ng-template #footerTemplateFra>
          <hr class="my-0">
          <div class="text-center">
            <button class="btn btn-primary btn-sm m-2" (click)="clearFraDato();df.close()">Slett</button>
          </div>
        </ng-template>

        <ng-template #footerTemplateTil>
          <hr class="my-0">
          <div class="text-center">
            <button class="btn btn-primary btn-sm m-2" (click)="clearTilDato();dt.close()">Slett</button>
          </div>
        </ng-template>

        <div class="row align-items-center">
          <div class="col">
            <label for="startDato">Start: </label>
            <div class="input-group">
              <input id="startDato" class="form-control" placeholder="yyyy-mm-dd" name="dpFrom"
                [footerTemplate]="footerTemplateFra" [readOnly]="true" [maxDate]="til"
                (dateSelect)="filterChanged($event)" [(ngModel)]="fra" ngbDatepicker #df="ngbDatepicker">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary fa fa-calendar" (click)="df.toggle()" type="button"></button>
              </div>
            </div>
          </div>
          <div class="col">
            <label for="sluttDato">Slutt: </label>
            <div class="input-group">
              <input id="sluttDato" class="form-control" placeholder="yyyy-mm-dd" name="dpTo"
                [footerTemplate]="footerTemplateTil" [readOnly]="true" [minDate]="fra"
                (dateSelect)="filterChanged($event)" [(ngModel)]="til" ngbDatepicker #dt="ngbDatepicker">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary fa fa-calendar" (click)="dt.toggle()" type="button"></button>
              </div>
            </div>
          </div>
        </div>

        <div class="row align-items-center">
          <div class="col">
            <ul class="list-group list-group-flush">

              <li class="list-group-item">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="visFakturerte"
                    [(ngModel)]="filterParams.visFakturerte" (change)="filterChanged($event)">
                  <label class="form-check-label" for="visFakturerte">Vis fakturerte</label>
                </div>
              </li>

              <li class="list-group-item">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="visGodkjente"
                    [(ngModel)]="filterParams.visGodkjente" (change)="filterChanged($event)" />
                  <label class="form-check-label" for="visGodkjente">Vis godkjente</label>
                </div>
              </li>

            </ul>
          </div>
        </div>

      </ng-template>

    </div>
  </div>
</div>