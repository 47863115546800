import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ModellFeltComponent } from "./modellfelt.component";

@Component({
  selector: "app-prisfelt",
  templateUrl: "./prisfelt.component.html",
  styleUrls: ["./prisfelt.component.scss"]
})
export class PrisfeltComponent extends ModellFeltComponent {

  @Input()
  pris: number;
  @Output()
  valueChanged = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.control.valueChanges.subscribe(value => {
      this.valueChanged.emit(value);
    });
  }

  getPrisLabel(key: string): string {
    switch (key) {
      case "antallMongoDbBaser":
        return "Sum drift MongoDb databaser";
      case "antallMariaDbBaser":
        return "Sum drift MariaDB databaser";
      case "antallPostgreSqlBaser":
        return "Sum drift PostgresSql databaser";
      case "antallServere":
        return "Sum drift servere";
      case "antallServereTest":
        return "Sum drift servere test";
      case "antallMsSqlBaser":
        return "Sum drift MSSQL databaser";
      case "antallOracleBaser":
        return "Sum drift Oracle databaser";
      case "antallIkkeStdBaser":
        return "Sum drift ikke-std databaser";
      case "antallGrensesnitt":
        return "Sum drift grensesnitt";
      case "vaktlag":
        return "Sum drift vaktlag";
      default:
        return "";
    }
  }
}
