import { Component } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Observable } from "rxjs";
import { FilterParams } from "../common/common.model";
import { ListesideBaseClass } from "../common/listeside/listeside.component";
import { TilgangService } from "../services/tilgang.service";
import { TjenesterService } from "./tjenester.service";


@Component({
  selector: "app-tjeneste",
  templateUrl: "./tjenester.component.html"
})
export class TjenesterComponent extends ListesideBaseClass {



  overskrift = "Oversikt over Tjenester";

  constructor(
    protected service: TjenesterService,
    protected tilgangService: TilgangService,
    protected route: ActivatedRoute,
    protected router: Router
  ) { super(route, router, tilgangService); }


  search(params: FilterParams): Observable<any> {

    const name = params.tekst as string;
    const sortOrder = params.order as string;
    const showExpired = params.showExpired as boolean;
    const pageNumber = params.pageNumber as number;
    const pageSize = params.pageSize as number;

    return this.service.search(name, sortOrder, showExpired, pageNumber, pageSize);
  }

  urlParamsToFilterParams(params: Params, filterParams: FilterParams): FilterParams {

    filterParams = filterParams ? filterParams : new FilterParams();

    filterParams.tekst = params.s ? params.s : "";
    filterParams.showExpired = params.showExpired == "true";
    filterParams.order = params.sortOrder ? params.sortOrder : "nameAsc";

    filterParams.pageNumber = params.pageNumber ? params.pageNumber : 1;
    filterParams.pageSize = params.pageSize ? params.pageSize : 30;

    return filterParams;
  }

  onFilterChange(filterParams: FilterParams): void {

    this.router.navigate(["/tjenester"], {
      queryParams:
      {
        "s": filterParams.tekst,
        "sortOrder": filterParams.order,
        "showExpired": filterParams.showExpired,
        "pageNumber": filterParams.pageNumber,
        "pageSize": filterParams.pageSize
      }
    });
  }



}
