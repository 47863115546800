import {Component, ComponentRef, Input, OnInit} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-tabs",
  templateUrl: "./tabs.component.html"
})
export class TabsComponent implements OnInit {


  @Input()
  get tabs(): Tab[] { return this._tabs; }
  set tabs(tabs: Tab[]) {
    this._tabs = tabs;
    this.tabUrlFragments = this.tabs.map(t => t.key);
    this.checkUrlForTabNames(this.router.url.split("/"));
  }
  private _tabs = [];

  @Input() tabsLoading = false;

  @Input() parentRoute: string[];

  @Input() active = "";

  private activeTabComponent : ComponentRef<any>;

  constructor(private router: Router) { }


  tabUrlFragments: string[];

  ngOnInit(): void {

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      const navigationEndEvent = event as NavigationEnd;
      const urlParts = navigationEndEvent.url.split("/");
      this.checkUrlForTabNames(urlParts);
    });

  }

  onActivate(componentRef) {
    this.activeTabComponent = componentRef;
  }

  getActiveTab() : ComponentRef<any>{
    return this.activeTabComponent;
  }

  checkUrlForTabNames(urlParts: string[]): boolean {
    const urlPartWithTabName = urlParts.filter(p => this.tabUrlFragments.includes(p))[0];

    if (urlPartWithTabName) {
      this.active = urlPartWithTabName;
      return true;
    }
    return false;
  }

}

export interface Tab {
  key: string;
  label: string;
  disabled?: boolean;
  parentRoute: string[];
  link?: string[];
}
