<app-spinner [loadingData]="isLoading" [loadingFailed]="loadingFailed"></app-spinner>

<button [disabled]="!createDeleteAccess?.create" (click)="kobleTil()" type='button'
        class='btn btn-outline-primary mb-4'><i class="fas fa-plus"></i>Koble til begrep</button>

<div *ngFor="let item of liste" class="list-group-flush">
  <div class="d-flex flex-row">
    <div class="list-group-item list-group-item-action flex-column align-items-start ">
    <h5 class="mb-1"> <a href="https://bergenkommune.sharepoint.com/sites/BkProsjekt/Lists/Begrepskatalog/DispForm.aspx?ID={{item.begrepId}}"
                         target="_blank">{{item.begrep?.begrep1}} <i class="fa fa-external-link-alt" aria-hidden="true"></i>
    </a></h5>

    <div>{{item.begrep?.beskrivelse}}</div>
    </div>
    <button [disabled]="!createDeleteAccess?.delete" class="btn btn-outline-secondary" placement="top"
            ngbTooltip="Fjern kobling" [openDelay]="400" [closeDelay]="200" (click)="fjern(item)"><i
      class="fas fa-unlink"></i></button>

  </div>
</div>


