import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "../app-routing.module";
import { DynamicTekstComponent } from "./felter/dynamic-tekst.component";
import { EditbuttonComponent } from "./felter/editbutton.component";
import { FeltlisteComponent } from "./felter/feltliste.component";
import { FritekstfeltComponent } from "./felter/fritekstfelt.component";
import { ModellFeltComponent } from "./felter/modellfelt.component";
import { PrisfeltComponent } from "./felter/prisfelt.component";
import { PrisfeltlisteComponent } from "./felter/prisfeltliste.component";
import { FeltenkeltkolonneComponent } from "./feltside/feltenkeltkolonne/feltenkeltkolonne.component";
import { FeltkolonnerComponent } from "./feltside/feltkolonner/feltkolonner.component";
import { LagreknappComponent } from "./feltside/lagreknapp/lagreknapp.component";
import { ListesideFiltreComponent } from "./listeside/filtre/filtre.component";
import { SoketekstFilterComponent } from "./listeside/filtre/soketekstfilter.component";
import { IkketilgangComponent } from "./listeside/ikketilgang/ikketilgang.component";
import { OpprettNyKnappComponent } from "./listeside/opprett-ny-knapp/opprett-ny-knapp.component";
import { SpinnerComponent } from "./listeside/spinner/spinner.component";
import { LeverandorRolleVelgerComponent, PersonRolleVelgerComponent, RolleVelgerComponent, RolleViserComponent } from "./modal/liste-velger/liste-velger.component";
import { ModalComponent } from "./modal/modal.component";
import { ItsystemReportGeneratorComponent } from "./modal/report-generator/itsystem-report-generator/itsystem-report-generator.component";
import { OppdragReportGeneratorComponent } from "./modal/report-generator/oppdrag-report-generator/oppdrag-report-generator.component";
import { TjenesteavtalevedleggComponent } from "./modal/report-generator/tjenesteavtalevedlegg/tjenesteavtalevedlegg.component";
import {
  BruksomradeVelgerComponent,
  BrukerVelgerComponent,
  BegrepVelgerComponent,
  ItsystemVelgerComponent,
  LeverandorVelgerComponent,
  MiljoVelgerComponent,
  OppdragVelgerComponent,
  PersonVelgerComponent,
  SertifikatVelgerComponent,
  SoneVelgerComponent
} from "./modal/soke-velger/sokevelger.component";
import { TabsComponent } from "./tabs/tabs.component";
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';




@NgModule({
  declarations: [
    EditbuttonComponent,
    FeltlisteComponent,
    FritekstfeltComponent,
    ModellFeltComponent,
    PrisfeltComponent,
    PrisfeltlisteComponent,
    ModalComponent,
    LeverandorRolleVelgerComponent,
    PersonRolleVelgerComponent,
    RolleVelgerComponent,
    RolleViserComponent,
    PersonVelgerComponent,
    LeverandorVelgerComponent,
    PersonVelgerComponent,
    ItsystemVelgerComponent,
    OppdragVelgerComponent,
    SertifikatVelgerComponent,
    BruksomradeVelgerComponent,
    BrukerVelgerComponent,
    BegrepVelgerComponent,
    MiljoVelgerComponent,
    SoneVelgerComponent,
    TabsComponent,
    ListesideFiltreComponent,
    SpinnerComponent,
    OpprettNyKnappComponent,
    LagreknappComponent,
    SoketekstFilterComponent,
    IkketilgangComponent,
    FeltkolonnerComponent,
    FeltenkeltkolonneComponent,
    DynamicTekstComponent,
    OppdragReportGeneratorComponent,
    ItsystemReportGeneratorComponent,
    TjenesteavtalevedleggComponent,
    DateTimePickerComponent
  ],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule
  ],
  exports : [
    EditbuttonComponent,
    FeltlisteComponent,
    FritekstfeltComponent,
    ModellFeltComponent,
    PrisfeltComponent,
    PrisfeltlisteComponent,
    ModalComponent,
    LeverandorRolleVelgerComponent,
    PersonRolleVelgerComponent,
    PersonVelgerComponent,
    LeverandorVelgerComponent,
    PersonVelgerComponent,
    ItsystemVelgerComponent,
    OppdragVelgerComponent,
    MiljoVelgerComponent,
    SoneVelgerComponent,
    TabsComponent,
    ListesideFiltreComponent,
    SpinnerComponent,
    OpprettNyKnappComponent,
    LagreknappComponent,
    SoketekstFilterComponent,
    OpprettNyKnappComponent,
    IkketilgangComponent,
    FeltkolonnerComponent,
    FeltenkeltkolonneComponent,
    DateTimePickerComponent,
  ],
})
export class So2CommonModule { }
