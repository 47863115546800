<div *ngIf="!harTilgang">
  Du har ikke tilgang til å registrere ny {{getTitle()}}.
</div>

<div *ngIf="harTilgang" class="container">
  <div class="card">
    <div class="card-body">

      <h2>{{getHeadline()}}</h2>


      <app-modellfelt *ngIf="headingField" [formGroup]="formGroup" [field]="headingField" [modell]="model">
      </app-modellfelt>

      <app-feltliste *ngIf="topFields?.length > 0" [formGroup]="formGroup" [fields]="topFields" [modell]="model">
      </app-feltliste>

      <app-feltliste *ngIf="mainFields?.length > 0" [formGroup]="formGroup" [fields]="mainFields" [modell]="model">
      </app-feltliste>

      <app-feltliste *ngIf="numberFields?.length > 0" [formGroup]="formGroup" [fields]="numberFields" [modell]="model">
      </app-feltliste>

      <app-prisfeltliste *ngIf="model?.systemtypeId != programpakke && kostnadFields?.length > 0"
                         [formGroup]="formGroup"
                         [fields]="kostnadFields" [modell]="model" [parentModell]="model"></app-prisfeltliste>

      <app-feltliste *ngIf="checkboxFields?.length > 0" [formGroup]="formGroup" [fields]="checkboxFields"
                     [modell]="model">
      </app-feltliste>

      <app-feltliste *ngIf="investeringsFields?.length > 0" [formGroup]="formGroup" [fields]="investeringsFields"
                     [modell]="model">
      </app-feltliste>


      <app-lagreknapp [buttonTitle]="'Opprett'" [isSaving]="isSaving" [formGroup]="formGroup" (onLagre)="lagre()">
      </app-lagreknapp>

    </div>


    <app-spinner [loadingData]="isLoading" [loadingFailed]="errorLoading" (retryAction)="ngOnInit()"></app-spinner>

  </div>

</div>
