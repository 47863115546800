import { Component, EventEmitter, isDevMode, OnDestroy, OnInit, Output } from "@angular/core";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { BrowserUtils, InteractionStatus } from "@azure/msal-browser";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { ModalService } from "../services/modal.service";
import { PersonService, SystemRolle } from "../services/person.service";
import { TilgangService } from "../services/tilgang.service";

@Component({
  selector: "app-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.scss"]
})
export class NavBarComponent implements OnInit, OnDestroy {

  navn: string;
  systemRolle: SystemRolle[];
  valgtRolle: string;
  loginDisplay = false;


  menuOpen: boolean;
  @Output() menuToggled = new EventEmitter<boolean>();


  private readonly _destroying$ = new Subject<void>();

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private personService: PersonService,
    private modalService: ModalService,
    private tilgangService: TilgangService) { }


  ngOnInit(): void {

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        this.setAccountName();
      });


    this.personService.getGlobalRolle().subscribe(rolle => {
      if (rolle.includes(SystemRolle.Administrator)) {
        const valgtRolle = localStorage.getItem("rolleOverride");
        if (valgtRolle != null) {
          this.valgtRolle = valgtRolle.valueOf();
        }
      }
      this.systemRolle = rolle;
    });
  }

  checkAndSetActiveAccount(): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  setAccountName(): void {
    this.navn = this.authService.instance?.getActiveAccount()?.username;
  }

  setLoginDisplay(): void {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  loggut(): void {
    this.authService.logoutRedirect({
      onRedirectNavigate: () => {
        return !BrowserUtils.isInIframe();
      }
    });
  }

  erAdministrator(): boolean {
    return this.systemRolle != null && this.systemRolle.includes(SystemRolle.Administrator);
  }

  roller(): string[] {
    return Object.keys(SystemRolle).map(key => SystemRolle[key]).filter(k => !(parseInt(k) >= 0));
  }

  oppdaterRolle(): void {
    this.tilgangService.evictUserCache().subscribe(() => {
      console.log("User cache evicted");
    });
    localStorage.setItem("rolleOverride", this.valgtRolle);
    window.location.reload();
  }

  showRoleModal(): void {
    this.modalService.velgRolle(this.roller()).then(r => { this.valgtRolle = r; this.oppdaterRolle(); })
  }

  showMyRolesModal():void {
    this.modalService.visRolle(this.systemRolle,this.navn)
  }

  isDev(): boolean {
    return isDevMode();
  }

  toggleMenu(): void {
    this.menuOpen = !this.menuOpen;
    this.menuToggled.emit(this.menuOpen);
  }


}
