import { Component } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Observable } from "rxjs";
import { FilterParams } from "../common/common.model";
import { ListesideBaseClass } from "../common/listeside/listeside.component";
import {  LeverandorerService } from "../leverandor/leverandor.service";
import { TilgangService } from "../services/tilgang.service";


@Component({
  selector: "app-leverandorer",
  templateUrl: "../common/listeside/listeside.component.html"
})
export class LeverandorerComponent extends ListesideBaseClass {

  overskrift = "Oversikt over Leverandører";

  constructor(
    private service: LeverandorerService,
     tilgangService: TilgangService, route: ActivatedRoute, router: Router
  ) { super(route, router, tilgangService); }


  search(params: FilterParams): Observable<any> {
    return this.service.search(params.tekst, params.order, params.pageNumber, params.pageSize);
  }

}
