<div class="jumbotron jumbotron-fluid alert alert-warning">
    <div class="container">
        <h1 class="display-4">404 - Page not found <i class="fas fa-search-minus fa-large float-right"></i></h1>
        <p class="lead">No page found at {{activeUrl}}</p>
    </div>

    <ng-container *ngIf="path; else home">
        <hr class="my-4 black">
        <p>Did you mean to go to <a [routerLink]="path">"{{ path }}"</a></p>
    </ng-container>
    <ng-template #home>
        <hr class="my-4 black">
        <p>Go home<a [routerLink]="''">"Home"</a></p>
    </ng-template>

</div>

