import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {TilgangService} from "../../services/tilgang.service";
import {Oppdrag, OppdragCreateOptions, OppdragService} from "../oppdrag.service";

@Component({
  selector: "app-oppdrag-ny",
  templateUrl: "./oppdrag-ny.component.html",
  styleUrls: ["./oppdrag-ny.component.scss"]
})
export class OppdragNyComponent implements OnInit {


  oppdragCreateOptions: OppdragCreateOptions;
  harTilgang = false;
  oppdrag = {} as Oppdrag;
  ref: string;

  private readonly defaultOppdrag = {
    navn: "",
    tilgjengelighetId: 1,
    internettKritikalitetId: 4,
    kundeId: 2,
    oppdragLevStatusId: 1,
    oppdragStatusId: 1,
    oppdragSmStatusId: 1
  };


  constructor(
    private oppdragService: OppdragService,
    private tilgangService: TilgangService,
    private activatedRoute: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit(): void {
    this.tilgangService.getGlobalRoles().subscribe(g => {
      this.harTilgang = g.oppdrag.createAccess;
      if (this.harTilgang) {
        this.oppdragService.getOppdragOptions().subscribe(
          options => {
            this.oppdragCreateOptions = options;
            console.log(this.oppdragCreateOptions);
          });
        Object.assign(this.oppdrag, this.defaultOppdrag);
      }
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.ref = params.ref as string;
    });
  }

  onSubmit(): void {
    this.createOppdrag();
  }

  resetForm(): void {
    this.oppdrag = {} as Oppdrag;
    Object.assign(this.oppdrag, this.defaultOppdrag);
  }

  private createOppdrag() {

    this.oppdrag.oppdragLevStatusId = +this.oppdrag.oppdragLevStatusId;
    this.oppdrag.oppdragStatusId = +this.oppdrag.oppdragStatusId;
    this.oppdrag.oppdragSmStatusId = +this.oppdrag.oppdragSmStatusId;
    this.oppdrag.tilgjengelighetId = +this.oppdrag.tilgjengelighetId;
    this.oppdrag.internettKritikalitetId = +this.oppdrag.internettKritikalitetId;
    this.oppdrag.kundeId = +this.oppdrag.kundeId;

    this.oppdragService.post(this.oppdrag).subscribe(lev => {
      if (this.ref) {
        this.router.navigate([decodeURIComponent(this.ref)], {queryParams: {refResults: encodeURIComponent(this.oppdrag.navn)}})
      } else {
        this.router.navigate([`/oppdrag/${lev.oppdragId}`]);
      }
    }, () => {
      console.log("ERROR MANNEN!");
    });
  }

}
