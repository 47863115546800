import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FilterParams } from "src/app/common/common.model";

@Component({
  selector: "app-tjenester-filter",
  templateUrl: "./tjenester-filter.component.html",
  styleUrls: ["./tjenester-filter.component.scss"]
})
export class TjenesterFilterComponent implements OnInit {


  @Input()
  loadingData = false;

  @Input()
  filterParams: FilterParams;

  @Output()
  onFilterChange = new EventEmitter<FilterParams>();

  filtersCollapsed: boolean;

  filterChanged(event?: any): void {
    this.onFilterChange.emit(this.filterParams);
  }

  ngOnInit(): void {
    // Do nothing
   }

}
