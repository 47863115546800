

<div class="d-flex justify-content-center list-group-item list-group-item-action" (click)="selectSystemForIntegration()">

    <span *ngIf="!system">Velg system</span>

    <span *ngIf="system">
        <h5 class="mb-1"> {{system.navn}} </h5>
        <div class="text-muted">{{system.beskrivelse}}</div>
    </span>

</div>



