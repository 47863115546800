import { Component, OnInit } from "@angular/core";
import { ModellFeltComponent } from "./modellfelt.component";

@Component({
  selector: "app-fritekstfelt",
  templateUrl: "./fritekstfelt.component.html",
  styleUrls: ["./felter.scss"],
})
export class FritekstfeltComponent extends ModellFeltComponent implements OnInit {



  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }



}
