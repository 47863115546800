import {AsyncValidatorFn, ValidatorFn} from "@angular/forms";
import {FakturaType} from "../services/fakturering.service";

export interface Field {
  text: string;
  key: string;
  inputType: string;
  systemtyper: string[];
  permission: FieldPermission;
  options: Option[];
  validators?: ValidatorFn[];
  asyncValidators?: AsyncValidatorFn[];
  validationChecks: ValidationChecks;
  column: number | undefined;
  position: number | undefined;
}

export interface ValidationChecks {
  maxLength?: number;
  required?: boolean;
  fixedLength?: number;
  columnType?: string;
}

export interface FieldPermission {
  r: boolean;
  w: boolean;
}

export interface Option {
  id: number;
  navn: string;
}


export interface SearchResults<T> {
  pageNumber: number;
  numberOfHits: number;
  totalPages: number;
  items: T[];
}


export interface SearchItem {
  id: number;
  parentId: number;
  navn: string;
  beskrivelse: string;
  innholdstype: string;
  aktivert: boolean;
}

export interface Rolle {
  rolleId: number;
  navn: string;
  beskrivelse: string;
  rolletype: string;
}


export class FilterParams {
  [name: string]: any;

  tekst = "";
  order = "nameAsc";
  pageSize: number;
  pageNumber: number;
  totalPages: number;
  numberOfHits: number;
}

export class FakturaFilterParams {
  [name: string]: string | number | Date | boolean;

  fakturatype = FakturaType.Fagsystem;
  order = "nameAsc";
  pageSize: number;
  pageNumber: number;
  totalPages: number;
  numberOfHits: number;
}
