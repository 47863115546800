import {HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse, HttpInterceptor
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { NotificationService } from "./notification.service";

@Injectable({
    providedIn: "root"
  })
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private notificationService: NotificationService) {

    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = "";
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.error.message}`;
                        this.notificationService.error(errorMessage, this.friendlyIshTitleFromRequest(request));

                    } else {
                        console.log(request);
                        console.log(error);
                        // server-side error
                        errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
                       if (error.status >= 400) {
                            this.notificationService.httpError(error, this.friendlyIshTitleFromRequest(request));
                       }
                    }
                    return throwError(error);
                })
            );
    }

    private friendlyIshTitleFromRequest(request: HttpRequest<any>): string {
        let verb = request.method;
        const methodVerbMap = {GET: "Henting", PUT: "Oppdatering", POST: "Opprettelse", DELETE: "Sletting"};
        if (methodVerbMap[request.method]) {
            verb = methodVerbMap[request.method];
        }

        let noun = "";
        const urlParts = request.url.split("/");
        const lastParts = urlParts.slice(4);
        noun = lastParts.join(" ");
        return `${verb} av ${noun} feilet`;
    }

}
