<div *ngIf="!harTilgang">
    Du har ikke tilgang til å registrere ny leverandør.
</div>

<div *ngIf="harTilgang" class="container">
    <div class="card">
        <div class="card-body">

            <h2>Opprett ny Leverandør</h2>

            <form (ngSubmit)="onSubmit()" #leverandorForm="ngForm">

                <div class="form-group my-4">
                    <label for="navn">Navn</label>
                    <span *ngIf="navn.invalid" class="badge badge-pill badge-secondary mx-1">Required</span>
                    <input type="text" class="form-control" id="navn" name="navn" placeholder="Navn" required
                        maxlength="255" [(ngModel)]="leverandor.navn" #navn="ngModel">
                    <small id="navnHelp"
                        class="form-text text-muted float-right">{{leverandor.navn?leverandor.navn.length:0}}/255</small>
                </div>

                <div class="form-group mb-4">
                    <label for="url">Url</label>
                    <input type="url" class="form-control" id="url" placeholder="https://www.eksempel.no/" name="url"
                        maxlength="255" [(ngModel)]="leverandor.url" #url="ngModel">
                    <small id="urlHelp"
                        class="form-text text-muted float-right">{{leverandor.url?leverandor.url.length:0}}/255</small>
                </div>


                <div class="form-group mb-4">
                    <label for="telefon">Telefon</label>
                    <input type="tel" class="form-control" id="telefon" placeholder="+47 123 12 312" name="telefon"
                        maxlength="20" [(ngModel)]="leverandor.telefon" #telefon="ngModel">
                    <small id="telefonHelp"
                        class="form-text text-muted float-right">{{leverandor.telefon?leverandor.telefon.length:0}}/20</small>
                </div>


                <div class="form-group mb-4">
                    <label for="telefon2">Telefon2</label>
                    <input type="tel" class="form-control" id="telefon2" placeholder="+47 123 12 312" name="telefon2"
                        maxlength="20" [(ngModel)]="leverandor.telefon2" #telefon2="ngModel">
                    <small id="telefon2Help"
                        class="form-text text-muted float-right">{{leverandor.telefon2?leverandor.telefon2.length:0}}/20</small>
                </div>


                <div class="form-group mb-4">
                    <label for="epost">Epost</label>
                    <input type="email" class="form-control" id="epost" placeholder="eksempel@epost.no" name="epost"
                        maxlength="255" [(ngModel)]="leverandor.epost" #epost="ngModel" email>
                    <small id="epostHelp"
                        class="form-text text-muted float-right">{{leverandor.epost?leverandor.epost.length:0}}/255</small>
                    <div *ngIf="epost.errors && (epost.invalid || epost.touched)">
                        <small class="text-danger">Please provide a valid email address</small>
                    </div>
                </div>



                <ng-template #rt let-r="result" let-t="term">
                    <ngb-highlight
                        [result]="r.adressenavn + ' ' + r.nummer + ' (' +  r.postnummer + ' ' +  r.poststed + ') '"
                        [term]="t"></ngb-highlight>
                </ng-template>


                <div class="form-group mb-4">
                    <label for="gateadresse">Gateadresse</label>
                    <input type="text" class="form-control" id="gateadresse" placeholder="Slottsplassen 1"
                        name="gateadresse" maxlength="255" [ngbTypeahead]="search" [resultTemplate]="rt"
                        [inputFormatter]="formatter" [editable]='false' (selectItem)="addressSelected($event.item)">
                    <small id="gateadresseHelp"
                        class="form-text text-muted float-right">{{leverandor.gateadresse?leverandor.gateadresse.length:0}}/255</small>
                </div>


                <div class="form-row">
                    <div class="form-group col-sm-7 mb-4">
                        <label for="postnummer">Postnummer</label>
                        <input type="text" class="form-control" id="postnummer" placeholder="0010" name="postnummer"
                            maxlength="10" [(ngModel)]="leverandor.postnummer" (ngModelChange)="postnummerChanged()"
                            #postnummer="ngModel">
                    </div>


                    <div class="form-group col-sm-5 mb-4">
                        <label for="poststed">Poststed</label>
                        <input type="text" class="form-control" id="poststed" placeholder="OSLO" name="poststed"
                            maxlength="255" [(ngModel)]="leverandor.poststed" #poststed="ngModel"
                            [readonly]="poststedSet">
                        <small *ngIf="searchingPoststed" class="form-text text-muted">searching...</small>
                    </div>

                </div>

                <div class="form-group mb-4">
                    <label for="organisasjonsnummer">Organisasjonsnummer</label>
                    <input type="text" class="form-control" id="organisasjonsnummer" placeholder="999999999"
                        name="organisasjonsnummer" minlength="9" maxlength="9"
                        [(ngModel)]="leverandor.organisasjonsnummer" #organisasjonsnummer="ngModel">
                    <small id="organisasjonsnummerHelp"
                        class="form-text text-muted float-right">{{leverandor.organisasjonsnummer?leverandor.organisasjonsnummer.length:0}}/9</small>
                </div>




                <button type="submit" class="btn btn-outline-primary mt-4"
                    [disabled]="!leverandorForm.form.valid">Opprett</button>
                <button type="reset" class="btn btn-outline-secondary mx-2 mt-4" (click)="resetForm()">Reset</button>


            </form>

        </div>
    </div>
</div>