import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, map, switchMap } from "rxjs/operators";
import { Leverandor, LeverandorerService } from "../../leverandor/leverandor.service";
import { Adresse, AdresseService } from "../../services/adresse.service";
import { NotificationService } from "../../services/notification.service";
import { TilgangService } from "../../services/tilgang.service";

@Component({
  selector: "app-leverandorer-ny",
  templateUrl: "./leverandorer-ny.component.html",
  styleUrls: ["./leverandorer-ny.component.scss"]
})
export class LeverandorerNyComponent implements OnInit {


  harTilgang = false;
  ref: string;
  leverandor = {} as Leverandor;

  poststedSet = false;
  searchingPoststed = false;
  searchingAddresses = false;

  constructor(
    private leverandorService: LeverandorerService,
    private adresseService: AdresseService,
    private tilgangService: TilgangService,
    private notifyService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {

    this.tilgangService.getGlobalRoles().subscribe(g => {
      this.harTilgang = g.leverandorer.createAccess;
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.ref = params.ref as string;
    });
  }

  search = (tekst$: Observable<string>) =>
    tekst$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((searchText) => this.adresseService.findAddressSugestions(searchText).pipe(
        map(response => {
          return response;
        }),
        catchError(() => {
          return of([]);
        })))
    )


  formatter = (x: { adressenavn: string, nummer: string }) => `${x.adressenavn} ${x.nummer}`;

  private createLeverandor() {
    this.leverandorService.post(this.leverandor).subscribe(lev => {
      if (this.ref) {
        this.router.navigate([decodeURIComponent(this.ref)], { queryParams: { refResults: encodeURIComponent(this.leverandor.navn) } })
      } else {
        this.router.navigate([`/leverandorer/${lev.leverandorId}`]);
      }
    }, () => {
      console.log("ERROR MANNEN!");
    });
  }

  resetForm(): void {
    this.poststedSet = false;
  }

  onSubmit(): void {
    this.createLeverandor();
  }


  addressSelected(address: Adresse): void {
    this.leverandor.gateadresse = `${address.adressenavn} ${address.nummer}`;
    this.leverandor.postnummer = address.postnummer;
    this.leverandor.poststed = address.poststed;
    this.notifyService.success("Postkode og poststed ble automatisk lastet!", "Postadresse hentet!");
  }

  postnummerChanged(): void {
    if (this.leverandor.postnummer?.length == 4 && !this.leverandor.poststed) {
      this.searchingPoststed = true;
      this.adresseService.findPoststedByPostkode(this.leverandor.postnummer).subscribe(poststed => {
        this.leverandor.poststed = poststed;
        this.searchingPoststed = false;
        if (this.leverandor.poststed) {
          this.poststedSet = true;
        }
      }, () => {
        this.searchingPoststed = false;
        // do nothing
      });
    } else if (this.poststedSet) {
      this.leverandor.poststed = "";
      this.poststedSet = false;
    } else {
      this.poststedSet = false;
    }
  }

}
