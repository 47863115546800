<app-spinner [loadingData]="isLoading" [loadingFailed]="loadingFailed"></app-spinner>


<button [disabled]="!createDeleteAccess?.create" (click)="kobleTil()" type='button'
        class='btn btn-outline-primary mb-4'><i class="fas fa-plus"></i>
        Opprett integrasjon</button>

<div *ngFor="let item of liste" class="list-group-flush">
        <div class="d-flex flex-row">




                <a *ngIf="item.systemId1==id" [routerLink]="['/integrasjoner', item.id]" [class.disabled]="!linkAccess"
                        [ngClass]="item.aktiv ? 'list-group-item list-group-item-action flex-column align-items-start' : 'list-group-item list-group-item-action flex-column align-items-start bg-light' ">
                        <h5 class="mb-1"> {{item.system1Navn}} {{getRetning(item.retningId)}} {{item.system2Navn}}
                                <span *ngIf="!item.aktiv" placement="top" ngbTooltip="Utgått">
                                        <i class="far fa-times-circle"></i>
                                </span>
                        </h5>


                        <div *ngIf="item.navn" class="row">
                                <div class="col-3">
                                        <div class=" text-muted">Navn : </div>
                                </div>

                                <div class="col-9">
                                        <div class=" text-muted">{{item.navn}}</div>
                                </div>
                        </div>

                        <div *ngIf="item.beskrivelse" class="row">
                                <div class="col-3">
                                        <div class=" text-muted">Beskrivelse : </div>
                                </div>

                                <div class="col-9">
                                        <div class=" text-muted">{{item.beskrivelse}}</div>
                                </div>
                        </div>



                </a>

                <a *ngIf="item.systemId2==id" [routerLink]="['/integrasjoner', item.id ]" [class.disabled]="!linkAccess"
                        [ngClass]="item.aktiv ? 'list-group-item list-group-item-action flex-column align-items-start' : 'list-group-item list-group-item-action flex-column align-items-start bg-light' ">
                        <h5 class="mb-1"> {{item.system1Navn}} {{getRetningSystemId2(item.retningId)}} {{item.system2Navn}}
                                <i *ngIf="!item.aktiv" placement="top" ngbTooltip="Utgått"
                                        class="far fa-times-circle"></i>
                        </h5>

                        <div *ngIf="item.navn" class="row">
                                <div class="col-3">
                                        <div class=" text-muted">Navn : </div>
                                </div>

                                <div class="col-9">
                                        <div class=" text-muted">{{item.navn}}</div>
                                </div>
                        </div>

                        <div *ngIf="item.beskrivelse" class="row">
                                <div class="col-3">
                                        <div class=" text-muted">Beskrivelse : </div>
                                </div>

                                <div class="col-9">
                                        <div class=" text-muted">{{item.beskrivelse}}</div>
                                </div>
                        </div>
                </a>

                <button [disabled]="!createDeleteAccess?.delete || item.faktureres" class="btn btn-outline-secondary" placement="top"
                        ngbTooltip="Fjern kobling" [openDelay]="400" [closeDelay]="200" (click)="fjern(item)"><i
                                class="fas fa-unlink"></i></button>

        </div>
</div>

