import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { KoblingerService } from "src/app/koblinger/koblinger.service";
import { SystemRolle } from "src/app/services/person.service";
import { Rolle } from "../../common.model";


@Component({
  selector: "app-rolle-velger",
  templateUrl: "./liste-velger.component.html"
})
export class LeverandorRolleVelgerComponent implements OnInit {

  @Input() title = "Velg leverandør-rolle";
  @Input() content: string;
  @Input() danger: boolean;
  @Output() onConfirm = new EventEmitter<Rolle | boolean>();

  roller: Rolle[];
  text: string;

  constructor(private service: KoblingerService) { }

  ngOnInit(): void {
    this.service.getLeverandorRoller().subscribe(resp => this.roller = resp);
  }

  cancel(): void {
    this.onConfirm.emit(false);
  }

  confirm(rolle: Rolle): void {
    this.onConfirm.emit(rolle);
  }
}


@Component({
  selector: "app-personrolle-velger",
  templateUrl: "./liste-velger.component.html"
})
export class PersonRolleVelgerComponent implements OnInit {

  @Input() title = "Velg person-rolle";
  @Input() content: string;
  @Input() danger: boolean;
  @Output() onConfirm = new EventEmitter<Rolle | boolean>();

  roller: Rolle[];
  text: string;



  constructor(private service: KoblingerService) { }

  ngOnInit(): void {
    this.service.getPersonRoller().subscribe(resp => this.roller = resp);

  }

  cancel(): void {
    this.onConfirm.emit(false);
  }

  confirm(rolle: Rolle): void {
    this.onConfirm.emit(rolle);
  }
}



@Component({
  selector: "app-rolle-velger",
  templateUrl: "./admin-rolle-velger.component.html"
})
export class RolleVelgerComponent implements OnInit {

  @Input() roller: string[];
  @Output() onConfirm = new EventEmitter<string | boolean>();


  constructor() {
    // empty construtor
  }

  ngOnInit(): void {
    // do nothing
  }

  cancel(): void {
    this.onConfirm.emit(false);
  }

  confirm(rolle: string): void {
    this.onConfirm.emit(rolle);
  }
}

@Component({
  selector: "app-rolle-viser",
  templateUrl: "./rolle-viser.component.html"
})
export class RolleViserComponent implements OnInit {

  @Output() onConfirm = new EventEmitter<boolean>();
  @Input() roller: SystemRolle[];
  @Input() brukernavn: string;

  constructor() {
    // empty construtor
  }

  ngOnInit(): void {
    // do nothing
  }

  ok(): void {
    this.onConfirm.emit(true);
  }

}
