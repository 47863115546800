<app-spinner [loadingData]="!oppdrag"></app-spinner>

<div *ngIf="oppdrag && fields" class="mb-5">


  <h1 class="h1 mb-5">{{oppdrag.navn}}</h1>

  <div class="col-12 col-md-9">

    <button *ngIf="kanOverforeTilDrift" [disabled]="isLoading" (click)="overforTilDrift()"
            class="btn btn-secondary float-right"> Overfør til drift
      <i [ngClass]="isLoading ? 'fas fa-spinner fa-spin':'fas fa-angle-double-right'" class=""></i></button>
    <app-lagreknapp [formGroup]="formGroup" [isSaving]="isSaving" (onLagre)="lagre()"></app-lagreknapp>

  </div>

  <div *ngIf="oppdrag && fields">
    <div class="container-flex">
      <div class="row">
        <div class="col-12 col-md-8 col-lg-6">
          <app-feltliste [formGroup]="formGroup" [fields]="col1" [modell]="oppdrag"></app-feltliste>

        </div>
        <div class="col-12 col-md-4 col-lg-2">
          <app-feltliste [formGroup]="formGroup" [fields]="col2" [modell]="oppdrag"></app-feltliste>
        </div>

        <div class="col-12 col-md-4 col-lg-2">
          <app-prisfeltliste *ngIf="kostnadFields" [formGroup]="formGroup" [fields]="kostnadFields" [modell]="oppdrag"
                             [parentModell]="oppdrag">
          </app-prisfeltliste>
        </div>

      </div>
    </div>

  </div>

  <app-tabs #tab [tabs]="tabs"></app-tabs>
</div>
