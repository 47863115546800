<app-spinner [loadingData]="isLoading" [loadingFailed]="loadingFailed"></app-spinner>

<button [disabled]="!createDeleteAccess?.create" type='button' (click)="nyEndring()"
  class='btn btn-outline-primary mb-4'><i class="fas fa-plus"></i> Legg til
  endring</button>

<div *ngIf="endringer">
  <div *ngFor="let endring of activeEndringer" class="list-group-flush">
    <a [routerLink]="['/itsystemer', systemId,'endring',endring.endringId]"
      class="list-group-item list-group-item-action flex-column align-items-start">
      <div>{{ endring.datoUtfort | date:'dd-MM-yyyy'}}</div>
      <div class="text-muted">{{endring.beskrivelse}}</div>
    </a>
  </div>

  <div *ngIf="endringer.length !== activeEndringer.length" class="d-flex justify-content-center my-4">
    <button type="button" (click)="loadMoreActiveElements()" class="btn btn-outline-primary btn-circle btn-xl">Vis
      Flere</button>
  </div>
</div>

