<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
</div>
<div class="modal-body">

  <div *ngIf="isLoading" class="d-flex justify-content-center my-2">
    <i class="fas fa-spinner fa-spin fa-5x"></i>
  </div>

  <div *ngIf="!isLoading" class="d-flex justify-content-center my-2">
    <i class="far fa-save fa-5x"></i>
  </div>


  {{content}}


</div>
<div class="modal-footer">
  <fieldset [disabled]="isLoading">
    <button type="button" class="btn btn-outline-secondary" (click)="saveAndExit()">Ja</button>
    <button type="button" class="btn btn-outline-secondary" (click)="exit()">Nei</button>
    <button type="button" class="btn btn-outline-secondary" (click)="cancel()">Avbryt</button>
    <div class="form-group"></div>
  </fieldset>
</div>