import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { retry, tap, timeout } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class TilgangService {

  private readonly basePath = "api/tilgang/";
  private readonly baseUrl: string;

  constructor(private http: HttpClient, @Inject("BASE_URL") private baseHost: string) {
    this.baseUrl = baseHost + this.basePath;
  }

  private _globalAccess: GlobalPageAccess;
  private _fakturaAccess: FakturaAccess;
  private _oppdragFakturaAccess: FakturaAccess;
  private _itsystemFakturaAccess: FakturaAccess;
  private _overforTilDrift: boolean;

  private _tabAccess: [number, TabAccess[]];
  private _tabCreateDeleteAccess: [number, TabCreateDeleteAccess[]];


  getGlobalRoles(): Observable<GlobalPageAccess> {
    if (!this._globalAccess) {
      console.log("Global roles is undefined, fetching new from backend");
      return this.http.get<GlobalPageAccess>(this.baseUrl + "global").pipe(
        timeout(4000),
        retry(3),
        tap(g => {
          if (!this._globalAccess) {
            this._globalAccess = g;
          }
        }),
      );
    } else {
      console.log("Global roles exists in local variable, using local");
      return of(this._globalAccess);
    }
  }

  evictUserCache():Observable<object>{
    return this.http.get(this.baseUrl +  "evictcache");
  }

  getTabAccess(parentPage: string, elementId: number): Observable<TabAccess[]> {
    if (this._tabAccess && this._tabAccess[0] === elementId && this._tabAccess[1]) {
      console.log("Tab access exists in local variable, using local");
      return of(this._tabAccess[1]);
    } else {
      console.log("Tab access is undefined or wrong id, fetching new from backend");
      return this.http.get<TabAccess[]>(this.baseUrl + "tabs", { params: { parentPage: parentPage, elementId: elementId.toString() } })
        .pipe(tap(g => this._tabAccess = [elementId, g]));
    }
  }

  getTabCreateDeleteAccess(parentPage: string, elementId: number): Observable<TabCreateDeleteAccess[]> {
    if (this._tabCreateDeleteAccess && this._tabCreateDeleteAccess[0] === elementId && this._tabCreateDeleteAccess[1]) {
      console.log("Tab-create-delete access exists in local variable, using local");
      return of(this._tabCreateDeleteAccess[1]);
    } else {
      console.log("Tab-create-delete access is undefined or wrong id, fetching new from backend");
      return this.http.get<TabCreateDeleteAccess[]>(this.baseUrl + "tabs-create-delete", { params: { parentPage: parentPage, elementId: elementId.toString() } })
        .pipe(tap(g => this._tabCreateDeleteAccess = [elementId, g]));
    }
  }



  getFakturaAccess(): Observable<FakturaAccess> {
    if (!this._fakturaAccess) {
      console.log("Faktura access is undefined, fetching new from backend");
      return this.http.get<FakturaAccess>(this.baseUrl + "faktura/access").pipe(tap(g => this._fakturaAccess = g));
    } else {
      console.log("Faktura access exists in local variable, using local");
      return of(this._fakturaAccess);
    }
  }

  getItsystemFakturaAccess(): Observable<FakturaAccess> {
    if (!this._itsystemFakturaAccess) {
      console.log("ItsystemFaktura access is undefined, fetching new from backend");
      return this.http.get<FakturaAccess>(this.baseUrl + "itsystem/faktura/access").pipe(tap(g => this._itsystemFakturaAccess = g));
    } else {
      console.log("ItsystemFaktura access exists in local variable, using local");
      return of(this._itsystemFakturaAccess);
    }
  }

  getOppdragFakturaAccess(): Observable<FakturaAccess> {
    if (!this._oppdragFakturaAccess) {
      console.log("OppdragFaktura access is undefined, fetching new from backend");
      return this.http.get<FakturaAccess>(this.baseUrl + "oppdrag/faktura/access").pipe(tap(g => this._oppdragFakturaAccess = g));
    } else {
      console.log("OppdragFaktura access exists in local variable, using local");
      return of(this._oppdragFakturaAccess);
    }
  }

  getOverforTilDriftAccess(): Observable<boolean> {
    if (this._overforTilDrift === undefined) {
      console.log("OverførTilDrift access is undefined, fetching new from backend");
      return this.http.get<boolean>(this.baseUrl + "oppdrag/todrift").pipe(tap(g => this._overforTilDrift = g));
    } else {
      console.log("OverførTilDrift access exists in local variable, using local");
      return of(this._overforTilDrift);
    }
  }

  getRisikogjennomgangAccess(systemId: number): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + "itsystem/" + systemId + "/risiko");
  }

  getTestinformasjonAccess(systemId: number): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + "itsystem/" + systemId + "/testinformasjon");
  }

}
export interface PageAccess {
  createAccess: boolean;
  listAccess: boolean;
}

export interface GlobalPageAccess {
  fakturaer: PageAccess;
  itsystemer: PageAccess;
  leverandorer: PageAccess;
  oppdrag: PageAccess;
  personer: PageAccess;
  risikogjennomganger: PageAccess;
  sertifikater: PageAccess;
  tjenester: PageAccess;
  integrasjoner: PageAccess;
  timeforing: PageAccess;
}

export interface TabAccess {
  key: string;
  label: string;
}

export interface FakturaAccess {
  godkjenn: boolean;
  fakturer: boolean;
  delete: boolean;
  rediger: boolean;
}

export interface TabCreateDeleteAccess {
  page: string;
  create: boolean;
  delete: boolean;
}
