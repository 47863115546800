<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">


    <button (click)="toggleMenu()" type="button" id="sidebarCollapse" class="btn d-md-none" style="background: #D3DEDC;">
      <i class="fas fa-align-left"></i>
    </button>
    
    <button class="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse"
      data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation">
      <i class="fas fa-angle-double-down"></i>
    </button>


    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">

        <li *ngIf="isDev() && erAdministrator()" class="nav-item">
          <a [routerLink]="['/emptypage']" class="btn btn-outline">
            <i class="fas fa-bug"></i>
          </a>
        </li>

        <li class="nav-item" *ngIf="erAdministrator()">
          <button (click)="showRoleModal()" class="btn btn-outline">
            <i class="fas fa-user-cog"></i>
            <span *ngIf="valgtRolle">{{valgtRolle}}</span>
          </button>
        </li>


        <li class="nav-item">
          <span class="navbar-text">{{navn}}</span>
        </li>

        <li class="nav-item">
          <button (click)="showMyRolesModal()" class="btn btn-outline">
            <i class="fas fa-user"></i>
          </button>
        </li>

        <li class="nav-item">
          <a class="nav-link" href="#" (click)="loggut()">Logg ut</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
