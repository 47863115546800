import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { Field } from "../../common/common.model";
import { NyBaseClass } from "../../common/ny/ny-base";
import { NotificationService } from "../../services/notification.service";
import { TilgangService } from "../../services/tilgang.service";
import { IntegrasjonerService, SystemSystem } from "../integrasjoner.service";

@Component({
  selector: "app-integrasjoner-ny",
  templateUrl: "./integrasjoner-ny.component.html"
})
export class IntegrasjonerNyComponent extends NyBaseClass<SystemSystem> {

  constructor(
    private service: IntegrasjonerService,
    tilgangService: TilgangService,
    route: ActivatedRoute,
    router: Router,
    notificationService: NotificationService
  ) { super(route, router, tilgangService, notificationService); }

  filterCustomFields(fields: Field[]): Field[] {
    return fields.filter(f => f.key.match("systemId"));
  }

  getCreateFields(): Observable<Field[]> {
    return this.service.getCreateFields();
  }
  getHeadline(): string {
    return "Opprett ny integrasjon";
  }
  getTitle(): string {
    return "Integrasjoner";
  }

  create(model: SystemSystem): Observable<SystemSystem> {
    return this.service.post(model);
  }
  getIdFromDataType(model: SystemSystem): number {
    return model.id;
  }
  setInitialValues(): void {
    this.model["retningId"] = 1;
  }

}

