<div *ngIf="harTilgang">
  <app-opprett-ny-knapp> </app-opprett-ny-knapp>

  <h1>{{overskrift}}</h1>

  <app-soketekstfilter (onFilterChange)="onFilterChange($event)" [filterParams]="filterParams"></app-soketekstfilter>
  <app-paginator (onFilterChange)="onFilterChange($event)" [filterParams]="filterParams"></app-paginator>
  <app-spinner [loadingData]="loadingData"></app-spinner>

  <div class="search-results">
    <div *ngFor="let person of searchResults" class="list-group-flush">
      <a [routerLink]="['/personer', person.personId]"
        class="list-group-item list-group-item-action flex-column align-items-start">
        <h5>{{ person.navn }}</h5>
        <div class="text-muted"> {{ person.firma }} / {{ person.avdeling }} / {{ person.kontor }}</div>
      </a>
    </div>
  </div>
</div>
<app-ikketilgang [harTilgang]="harTilgang" [loadingData]="loadingData" [overskrift]="overskrift"></app-ikketilgang>