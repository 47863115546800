import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BackNavigationComponent } from '../../services/backnavigation.guard';

@Component({
  selector: 'app-emptypage',
  templateUrl: './emptypage.component.html',
  styleUrls: ['./emptypage.component.scss']
})
export class EmptypageComponent implements OnInit, BackNavigationComponent {

  antallEndringer(): number {
    return 0;
  }

  sidenErEndret(): boolean {
    return true;
  }

  saveChanges(): Observable<null> {
    return of(null)
  }


  ngOnInit(): void {
    // Do nothing
  }


  someval = 0;


  doStuff(): void {

    // empty
  }

}
