import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { FilterParams } from "../common/common.model";
import { ListesideBaseClass } from "../common/listeside/listeside.component";
import { PersonService, SortOrder } from "../services/person.service";
import { TilgangService } from "../services/tilgang.service";

@Component({
  selector: "app-personer",
  templateUrl: "./personer.component.html",
  styleUrls: ["./personer.component.scss"]
})
export class PersonerComponent extends ListesideBaseClass implements OnInit {

  overskrift = "Oversikt over personer";


  constructor(
    private service: PersonService,
    tilgangService: TilgangService, route: ActivatedRoute,
    router: Router
  ) { super(route, router, tilgangService); }




  search(params: FilterParams): Observable<any> {
    return this.service.searchPersoner(params.tekst, "Etternavn", SortOrder.ASCENDING, params.pageNumber, params.pageSize);
  }




}

