import { FormGroup } from "@angular/forms";

export const GlobalVariables = Object.freeze({
  DATE_FORMAT: "d.M.y H:mm"
});
export function isDate(val: any) {
  return val.getFullYear && val.getMonth && val.getDate && val.getHours && val.getMinutes && val.getSeconds;
}

export function countDirty(formGroup: FormGroup): number {
  let endringer = 0;

  Object.keys(formGroup.controls)
    .forEach(key => {
      const control = formGroup.get(key);
      if (control.dirty) {
        endringer++;
      }
    });

  return endringer;
}
