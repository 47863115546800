import { Component} from '@angular/core';
import {TabSideBaseClass} from "../../common/tab-side/tab-side.component";
import {
  BruksomradeSystem,
  BegrepIntegrasjon,
  KoblingerService
} from "../../koblinger/koblinger.service";
import {Tabs} from "../../common/tabs/tabs.enum";
import {TabsService} from "../../common/tabs/tabs.service";
import {TilgangService} from "../../services/tilgang.service";
import {ModalService} from "../../services/modal.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-itsystem-bruksomrade',
  templateUrl: './itsystem-bruksomrade-tab.component.html'
})
export class ItsystemBruksomradeTabComponent extends TabSideBaseClass<BruksomradeSystem> {

  tabName(): Tabs {
    return Tabs.bruksomrade;
  }

  constructor(tabsService: TabsService, service: KoblingerService, tilgangService: TilgangService, modal: ModalService, route: ActivatedRoute, router: Router) {
    super(tabsService, service, tilgangService, modal, route, router);
  }

  loaderObservable(service: KoblingerService, id: number): Observable<BruksomradeSystem[]> {
    return service.getBruksomradeSystemer(id);
  }

  pageLinkAccess(service: TilgangService): Observable<boolean> {
    return service.getGlobalRoles().pipe(map(val => val.itsystemer.listAccess));
  }

  removeObservable(service: KoblingerService, item: BruksomradeSystem): Observable<object> {
    return service.fjernBruksomradeSystem(item.systemId, item.bruksomradeId);
  }

  kobleTil(): void {
    this.modal.velgBruksomrade().then(bruksomrade => {
      if (this.liste.some(t => t.bruksomradeId == bruksomrade.bruksomradeId)) {
        this.modal.warning(`${bruksomrade.bruksomrade.navn} er allerede koblet til integrasjon`, "Kobling feilet");
        return;
      }

      const item : BruksomradeSystem = { systemId: this.id, bruksomradeId: bruksomrade.bruksomradeId };

      this.service.opprettBruksomradeSystem(item).toPromise().then(() => {
        this.modal.success("", "Bruksområde koblet til itsystem");
        this.load();
      })
    });
  }

}
