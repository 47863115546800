import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TabSideBaseClass } from "src/app/common/tab-side/tab-side.component";
import { Tabs } from "src/app/common/tabs/tabs.enum";
import { TabsService } from "src/app/common/tabs/tabs.service";
import { KoblingerService, OppdragPerson } from "src/app/koblinger/koblinger.service";
import { OppdragStatus } from "src/app/oppdrag/oppdrag.service";
import { ModalService } from "src/app/services/modal.service";
import { TilgangService } from "src/app/services/tilgang.service";



@Component({
  selector: "app-personoppdrag-tab",
  templateUrl: "./personoppdrag-tab.component.html"
})
export class PersonOppdragTabComponent extends TabSideBaseClass<OppdragPerson> {


  tabName(): Tabs {
    return Tabs.oppdrag;
  }

  constructor(tabsService: TabsService, service: KoblingerService, tilgangService: TilgangService, route: ActivatedRoute, modal: ModalService, router: Router) {
    super(tabsService, service, tilgangService, modal, route, router);
  }

  loaderObservable(service: KoblingerService, id: number): Observable<OppdragPerson[]> {
    return service.getOppdragPersonRolleForPerson(id);
  }

  pageLinkAccess(service: TilgangService): Observable<boolean> {
    return service.getGlobalRoles().pipe(map(val => val.oppdrag.listAccess));
  }

  override compareFn(first: OppdragPerson, second: OppdragPerson): number {
    if (!(first.oppdrag && second.oppdrag)) return 0;
    if (first.oppdrag.oppdragStatusId < second.oppdrag.oppdragStatusId) return -1;
    if (first.oppdrag.oppdragStatusId > second.oppdrag.oppdragStatusId) return 1;
    else return 0;
  }

  override canFilter(): boolean {
      return true;
  }

  override filterList(): OppdragPerson[] {
    return this.originalListe.filter(o => o.oppdrag.oppdragStatusId < OppdragStatus.avsluttet);
  }

  statusName(status : number):string {
    return OppdragStatus[status];
  }

  kobleTil(): void {

    this.modal.velgOppdrag().then(oppdrag => {
      this.modal.velgPersonRolle().then(rolle => {

        const op: OppdragPerson = { personId: this.id, oppdragId: oppdrag.id, rolleId: rolle.rolleId };

        this.service.postOppdragPersonRolleForPerson(op).toPromise().then(() => {
          this.modal.success("Oppdrag koblet til person og rolle", "kobling vellykket");
          this.load();
        });
      });
    });
  }
}
