import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";

export enum SystemRolle {
  Administrator = "Administrator",
  UtvidetLese = "UtvidetLese",
  Avdelingsleder = "Avdelingsleder",
  NyeSystemer = "NyeSystemer",
  Oppdrag = "Oppdrag",
  Fakturering = "Fakturering",
  AnsattAnsattservice = "AnsattAnsattservice",
  Direktor = "Direktor",
  Avtaleforvalter = "Avtaleforvalter",
  Systemeier = "Systemeier",
  Systemkoordinator = "Systemkoordinator",
  Digitaliseringskoordinator = "Digitaliseringskoordinator",
  IKTKoordinator = "IKTKoordinator",
  Testansvarlig = "Testansvarlig",
  AnsattBK = "AnsattBK",
  Fagansvarlig = "Fagansvarlig",
  PISK = "PISK"
}

export enum SortOrder {
  ASCENDING,
  DESCENDING
}

@Injectable({
  providedIn: "root"
})
export class PersonService {

  constructor(private http: HttpClient, @Inject("BASE_URL") private baseUrl: string) { }

  getGlobalRolle(): Observable<SystemRolle[]> {
    return this.http.get<SystemRolle[]>(this.baseUrl + "api/person/self/rolle");
  }

  searchPersoner(text: string, sortField?: string, sortOrder?: SortOrder, pageNumber = 1, pageSize = 30): Observable<PersonSearchResult> {
    let searchParams = new HttpParams();
    searchParams = searchParams.append("text", text);
    if (sortField) { searchParams = searchParams.append("sortField", sortField); }
    if (sortOrder) { searchParams = searchParams.append("sortOrder", String(sortOrder.valueOf())); }
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());
    return this.http.get<PersonSearchResult>(this.baseUrl + "api/person/search", { params: searchParams });
  }

  autocompletePerson(text: string): Observable<PersonSearchResult> {
    let searchParams = new HttpParams();
    searchParams = searchParams.append("text", text);
    return this.http.get<PersonSearchResult>(this.baseUrl + "api/person/autocomplete/", { params: searchParams });
  }

  epostEksisterer(epost: string): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + "api/person/exists?epost=" + epost);
  }

  getSelf(): Observable<Person> {
    return this.http.get<Person>(this.baseUrl + "api/person/self");
  }

  getPerson(id: number): Observable<Person> {
    return this.http.get<Person>(this.baseUrl + "api/person/" + id);
  }

  createPerson(person: Person): Observable<Person> {
    return this.http.post<Person>(this.baseUrl + "api/person", person);
  }

  updatePerson(id: number, person: Person): Observable<Person> {
    return this.http.put<Person>(this.baseUrl + "api/person/" + id, person);
  }

  deletePerson(id: number): Observable<object> {
    return this.http.delete<Person>(this.baseUrl + "api/person/" + id);
  }

  lookupAdUser(username: string): Observable<Person> {
    const params = new HttpParams();
    params.append("username", username);
    return this.http.get<Person>(this.baseUrl + "api/person/lookupAD?brukernavn=" + username);
  }


  firmaer(): Observable<object> {
    return this.http.get(this.baseUrl + "api/person/firmaer", { responseType: "json" });
  }


}

export interface PersonSearchResult {
  pageNumber: number;
  numberOfHits: number;
  totalPages: number;
  items: Person[];
}

export interface Person {
  personId: number;
  epost: string;
  fornavn: string;
  etternavn: string;
  telefon: string;
  mobil: string;
  navn:string;
  firma: string;
  avdeling: string;
  kontor: string;
  sluttet: boolean;
  tittelRolle: string;
  brukernavn: string;

}

