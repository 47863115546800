import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { style, animate, transition, trigger } from "@angular/animations";
import { FilterParams } from "../../common/common.model";
import { Kunde } from "../../oppdrag/oppdrag.service";
import { UserSettingsStorageService } from "../../services/user-settings-storage.service";
import { ItsystemerService } from "../itsystemer.service";
import { Observable, of, Subscription } from "rxjs";
import { delay, take } from "rxjs/operators";

@Component({
  selector: "app-itsystemer-filter",
  templateUrl: "./itsystemer-filter.component.html",
  styleUrls: ["./itsystemer-filter.component.scss"],
  animations: [
    trigger("fadeOut", [
      transition(":leave", [   // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class ItsystemerFilterComponent implements OnInit {

  constructor(private itsystemService: ItsystemerService, private userSettingService: UserSettingsStorageService) { }

  @Input()
  loadingData = false;

  @Input()
  filterParams: FilterParams;

  byradsavdelinger: Kunde[];

  filtersCollapsed: boolean;


  delayedFalseObservable: Observable<boolean> = of(false).pipe(delay(1500));
  activeSaveSub : Subscription;
  activeDeleteSub : Subscription;

  filterSaved = false;
  filterDelted = false;

  @Output()
  onFilterChange = new EventEmitter<FilterParams>();

  ngOnInit(): void {
    this.itsystemService.getKunder().subscribe(kunder => {
      this.byradsavdelinger = kunder;
    });
  }

  filterChanged(event: any): void {
    this.onFilterChange.emit(this.filterParams);
  }

  saveFilter(): void {
    this.userSettingService.saveItsystemFilterParams(this.filterParams);
    this.filterSaved = true;
    this.activeSaveSub = this.delayedFalseObservable.subscribe(v => this.filterSaved = v);
  }

  clearFilter(): void {
    this.userSettingService.clearItsystemFilterParams();
    this.filterDelted = true;
    this.activeDeleteSub = this.delayedFalseObservable.subscribe(v => this.filterDelted = v);
  }

  filterExists(): boolean {
    return this.userSettingService.getItsystemFilterParams() ? true : false;
  }

}
