import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { FilterParams } from "src/app/common/common.model";
import { GlobalVariables } from "../../../../global";
import { Itsystem, ItsystemerService } from "../../../../itsystemer/itsystemer.service";
import { Fakturalinje, FaktureringService } from "../../../../services/fakturering.service";
import { saveAs } from 'file-saver';

@Component({
  selector: "app-itsystem-report-generator",
  templateUrl: "./itsystem-report-generator.component.html",
  styleUrls: ["./itsystem-report-generator.component.scss"]
})
export class ItsystemReportGeneratorComponent implements OnInit {

  readonly dateFormat = GlobalVariables.DATE_FORMAT;

  isLoading = true;
  errorLoading = false;

  itsystemer: Itsystem[] = [];
  fakturalinjer: Map<number, Fakturalinje[]> = new Map();

  filterParams: FilterParams;

  @Output() onConfirm = new EventEmitter<boolean>();

  constructor(private itsystemService: ItsystemerService, private fakturaService: FaktureringService) { }

  ngOnInit(): void {
    // Do nothing
  }

  cancel(): void {
    this.onConfirm.emit(false);
  }

  setFakturalinjer(fakturalinjer: Fakturalinje[]): void {
    const observers: Observable<Itsystem>[] = [];
    for (const faktura of fakturalinjer) {
      if (!this.fakturalinjer[faktura.systemId]) {
        this.fakturalinjer[faktura.systemId] = [];
        const observable = this.itsystemService.getItsystem(faktura.systemId);
        observable.subscribe(itsystem => {
          this.itsystemer.push(itsystem);
        });
        observers.push(observable);
      }
      this.fakturalinjer[faktura.systemId].push(faktura);
    }
    forkJoin(observers).subscribe(() => {
      this.isLoading = false;
    });
  }

  exportExcel(): void {
    this.fakturaService.generateExcelReportItsystem(
      this.filterParams.fra,
      this.filterParams.til,
      this.filterParams.order,
      this.filterParams.visFakturerte,
      this.filterParams.visGodkjente,
      this.filterParams.systemId,
      this.filterParams.fakturatype).subscribe(results => {
        saveAs(results, "rapport.xlsx");
      });
  }

  accNormaltid(systemId: number): number {
    const fakturalinjer = this.fakturalinjer[systemId];
    const sum = fakturalinjer.reduce((acc, f) => acc + f.normaltid, 0);
    return sum / 36000000000;
  }
  accOvertid(systemId: number): number {
    const fakturalinjer = this.fakturalinjer[systemId];
    const sum = fakturalinjer.reduce((acc, f) => acc + f.overtid, 0);
    return sum / 36000000000;
  }
  accSumNormaltid(systemId: number): number {
    const fakturalinjer = this.fakturalinjer[systemId];
    const sum = fakturalinjer.reduce((acc, f) => acc + f.sumNormaltid, 0);
    return sum;
  }
  accSumOvertid(systemId: number): number {
    const fakturalinjer = this.fakturalinjer[systemId];
    const sum = fakturalinjer.reduce((acc, f) => acc + f.sumOvertid, 0);
    return sum;
  }

  totalTid(systemId: number): number { return this.accNormaltid(systemId) + this.accOvertid(systemId); }

  accSumFastBelop(systemId: number): number {
    const fakturalinjer = this.fakturalinjer[systemId];
    const sum = fakturalinjer.reduce((acc, f) => acc + f.fastBelop, 0);
    return sum;
  }

  totalSum(systemId: number): number {
    return this.accSumNormaltid(systemId) + this.accSumOvertid(systemId) + this.accSumFastBelop(systemId);
  }

  onBtnPrintClick(): void {
    const printData = document.getElementById("printable");
    this.addPrintSectionToElement(printData);
  }

  removePrintSection(): void {
    let remove = document.getElementById("printSection");
    while (remove) {
      document.body.removeChild(remove);
      remove = document.getElementById("printSection");
    }
  }

  addPrintSectionToElement(element: HTMLElement): void {

    const domClone = element.cloneNode(true);

    const printSection = document.createElement("div");
    printSection.id = "printSection";
    document.body.appendChild(printSection);

    printSection.innerHTML = "";
    printSection.appendChild(domClone);
    window.print();
  }
}
