import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { Field } from "../../common/common.model";
import { NyBaseClass } from "../../common/ny/ny-base";
import { NotificationService } from "../../services/notification.service";
import { TilgangService } from "../../services/tilgang.service";
import { Sertifikat, SertifikaterService } from "../sertifikater.service";

@Component({
  selector: "app-sertifikater-ny",
  templateUrl: "../../common/ny/ny-base.html"
})
export class SertifikaterNyComponent extends NyBaseClass<Sertifikat> {


  constructor(
    private service: SertifikaterService,
    tilgangService: TilgangService,
    route: ActivatedRoute,
    router: Router,
    notificationService: NotificationService
  ) { super(route, router, tilgangService, notificationService); }


  getCreateFields(): Observable<Field[]> {
    return this.service.getCreateFields();
  }
  getHeadline(): string {
    return "Opprett nytt sertifikat";
  }
  getTitle(): string {
    return "Sertifikater";
  }
  create(model: Sertifikat): Observable<Sertifikat> {
    return this.service.post(model);
  }
  getIdFromDataType(model: Sertifikat) {
    return model.sertifikatId;
  }



}
