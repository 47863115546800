<div class="container">
    <div class="col text-center">
        <button class="btn btn-primary mb-2" type="button" (click)="filtersCollapsed = !filtersCollapsed;"
            data-toggle="collapse" aria-expanded="false" [attr.aria-expanded]="filtersCollapsed"
            aria-controls="filters">
            <i [ngClass]="{'fas fa-caret-up': filtersCollapsed}"></i>
            <i [ngClass]="{'fas fa-caret-down': !filtersCollapsed}"></i>
            <span class="mx-2" *ngIf="filtersCollapsed">Skjul Filtre</span>
            <span class="mx-2" *ngIf="!filtersCollapsed">Vis Filtre</span>
        </button>
    </div>
</div>






<div class="collapse" id="filters" [ngbCollapse]="!filtersCollapsed">
    <div class="card card-body">

        <div class="container">
            <div class="row align-items-center">
                <div class="col">
                    <ul class="list-group list-group-flush">

                        <li class="list-group-item">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="showExpired"
                                    [(ngModel)]="filterParams.showExpired" (change)="filterChanged($event)">
                                <label class="form-check-label" for="showExpired">Vis Utgåtte</label>
                            </div>
                        </li>

                        <li class="list-group-item">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="showInfrastruktur"
                                    [(ngModel)]="filterParams.showInfrastruktur" (change)="filterChanged($event)" />
                                <label class="form-check-label" for="showInfrastruktur">Vis Infrastruktur</label>
                            </div>
                        </li>

                        <li class="list-group-item">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="showProgrampakke"
                                    [(ngModel)]="filterParams.showProgrampakke" (change)="filterChanged($event)" />
                                <label class="form-check-label" for="showProgrampakke">Vis Programpakke</label>
                            </div>
                        </li>

                        <li class="list-group-item">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="showFagsystem"
                                    [(ngModel)]="filterParams.showFagsystem" (change)="filterChanged($event)" />
                                <label class="form-check-label" for="showFagsystem">Vis Fagsystem</label>
                            </div>
                        </li>

                        <li class="list-group-item">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="showNettjeneste"
                                    [(ngModel)]="filterParams.showNettjeneste" (change)="filterChanged($event)" />
                                <label class="form-check-label" for="showNettjeneste">Vis Nettjeneste</label>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="col center-block">


                    <div class="card">
                        <div class="card-header">
                            Sikkerhetsinfo oppdatert
                        </div>
                        <div class="card-body">

                            <ul class="list-group-flush">
                                <li class="list-group-item">
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="hgRadio1" name="customRadio"
                                            class="custom-control-input" value="1" (change)="hgChanged(1)"
                                            [checked]="filterParams.hg==1">
                                        <label class="custom-control-label" for="hgRadio1">Alle</label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="hgRadio2" name="customRadio"
                                            class="custom-control-input" value="2" (change)="hgChanged(2)"
                                            [checked]="filterParams.hg==2">
                                        <label class="custom-control-label" for="hgRadio2">Oppdatert</label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="hgRadio3" name="customRadio"
                                            class="custom-control-input" value="3" (change)="hgChanged(3)"
                                            [checked]="filterParams.hg==3">
                                        <label class="custom-control-label" for="hgRadio3">Ikke Oppdatert</label>
                                    </div>
                                </li>

                            </ul>

                        </div>
                    </div>



                </div>

                <div class="col center-block">


                    <div class="input-group my-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroup-orderSelect">Sortering (Navn)</span>
                        </div>
                        <select class="form-control" id="orderSelect" [(ngModel)]="filterParams.order"
                            (change)="filterChanged($event)">
                            <option selected value="nameAsc">(A-Å)</option>
                            <option value="nameDesc">(Å-A)</option>
                        </select>
                    </div>

                    <div class="input-group my-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroup-hgSelect">Sikkerhetsinfo oppdatert</span>
                        </div>
                        <select class="form-control" id="hgSelect" [(ngModel)]="filterParams.hgForetatt"
                            (change)="filterChanged($event)">
                            <option selected value="alle">Alle</option>
                            <option value="thisYear">I År</option>
                            <option value="last12m">Siste 12 Måneder</option>
                            <option value="last3y">Siste 3 År</option>
                            <option value="more3y">Mer enn 3 År</option>
                        </select>
                    </div>

                    <div *ngIf="byradsavdelinger" class="input-group my-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroup-kundeSelect">Kunde</span>
                        </div>
                        <select class="custom-select" [(ngModel)]="filterParams.byradsavdeling"
                            (change)="filterChanged($event)">
                            <option selected value=''>Alle</option>
                            <option *ngFor="let b of byradsavdelinger" [value]="b.kundeId"
                                [attr.selected]="b.kundeId==filterParams.byradsavdeling">
                                {{b.navn}}</option>
                        </select>
                    </div>


                    <div class="input-group my-3">

                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroup-orderSelect">Systemeier</span>
                        </div>
                        <button class="btn btn-outline-secondary"
                            (click)="velgSystemEierPerson()">{{filterParams.systemeier || "Velg"}}</button>
                        <button *ngIf="filterParams.systemeier" class="btn btn-outline-secondary"
                            (click)="clearSystemEierPerson()">X</button>
                    </div>

                    <div class="input-group my-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroup-orderSelect">Systemkoordinator</span>
                        </div>
                        <button class="btn btn-outline-secondary"
                            (click)="velgSystemKoordinatorPerson()">{{filterParams.systemkoordinator ||
                            "Velg"}}</button>
                        <button *ngIf="filterParams.systemkoordinator" class="btn btn-outline-secondary"
                            (click)="clearSystemKoordinatorPerson()">X</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>