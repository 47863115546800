<div>
    <div class="modal-header">
        <h4 class="modal-title">Roller for {{brukernavn}}</h4>
    </div>
    <div class="modal-body">

        <div *ngIf="roller" class="list-group-flushed">
            <div *ngFor="let item of roller"
                class="list-group-item list-group-item-action flex-column align-items-start">
                {{item}}
            </div>

        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="ok()">Ok</button>
    </div>
</div>