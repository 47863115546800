import { Component, DoCheck, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Itsystem, ItsystemerService } from "src/app/itsystemer/itsystemer.service";
import { OppdragService } from "src/app/oppdrag/oppdrag.service";
import { ModalService } from "src/app/services/modal.service";
import { FilterParams, SearchItem } from "../../common/common.model";

@Component({
  selector: "app-timeforing-filter",
  templateUrl: "./timeforing-filter.component.html",
  styleUrls: ["./timeforing-filter.component.scss"]
})
export class TimeforingFilterComponent implements OnInit, DoCheck {

  @Input()
  loadingData = false;

  @Input()
  filterParams: FilterParams;

  private oldSystemId = 0;
  private oldOppdragId = 0;

  private oldFra = null;
  private oldTil = null;

  filtersCollapsed = true;

  @Output()
  onFilterChange = new EventEmitter<FilterParams>();
  fra: NgbDateStruct;
  til: NgbDateStruct;

  valgtOppdrag: SearchItem;
  valgtSystem: Itsystem;

  constructor(private ngbDateFormatter: NgbDateParserFormatter, private modalService: ModalService, private systemService: ItsystemerService, private oppdragService: OppdragService) { }

  ngDoCheck(): void {

    if (this.filterParams.systemId != this.oldSystemId) {
      if (this.filterParams.systemId) {
        this.systemService.getItsystem(+this.filterParams.systemId).subscribe(system => {
          console.log(system.navn);
          this.valgtSystem = system;
        });
      }
      this.oldSystemId = +this.filterParams.systemId;
    }

    if (this.filterParams.oppdragId != this.oldOppdragId) {
      if (this.filterParams.oppdragId) {
        this.oppdragService.get(+this.filterParams.oppdragId).subscribe(oppdrag => {
          this.valgtOppdrag = {
            id: oppdrag.oppdragId,
            beskrivelse: oppdrag.beskrivelse,
            navn: oppdrag.navn,
            parentId: oppdrag.oppdragId,
            aktivert: oppdrag.oppdragStatusId != 3,
            innholdstype: null
          };
        });
      }
      this.oldOppdragId = +this.filterParams.oppdragId;
    }

    if (this.filterParams.fra != this.oldFra) {
      if (this.filterParams.fra) {
        this.fra = this.ngbDateFormatter.parse(this.filterParams.fra as string);
      }
      this.oldFra = this.filterParams.fra;
    }

    if (this.filterParams.til != this.oldTil) {
      if (this.filterParams.til) {
        this.til = this.ngbDateFormatter.parse(this.filterParams.til as string);
      }
      this.oldTil = this.filterParams.til;
    }

  }

  ngOnInit(): void {
    // Do nothing
  }

  filterChanged(event?: any): void {
    if (this.fra) {
      this.filterParams.fra = this.ngbDateFormatter.format(this.fra);
    }
    if (this.til) {
      this.filterParams.til = this.ngbDateFormatter.format(this.til);
    }
    this.onFilterChange.emit(this.filterParams);
  }

  velgOppdrag(): void {
    this.modalService.velgOppdrag().then(oppdrag => {
      this.valgtOppdrag = oppdrag;
      this.filterParams.oppdragId = oppdrag.id;
      this.filterChanged();
    }, () => {
      // Do nothing
    });
  }
  clearOppdrag(): void {
    this.valgtOppdrag = null;
    this.filterParams.oppdragId = null;
    this.filterChanged();
  }

  velgSystem(): void {
    this.modalService.velgSystem().then(system => {
      this.valgtSystem = system;
      this.filterParams.systemId = system.systemId;
      this.filterChanged();
    }, () => {
      // Do nothing
    });
  }
  clearSystem(): void {
    this.valgtSystem = null;
    this.filterParams.systemId = null;
    this.filterChanged();
  }

}
