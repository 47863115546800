import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Field } from "../common/common.model";
import { Tab, TabsComponent } from "../common/tabs/tabs.component";
import { NotificationService } from "../services/notification.service";
import { TilgangService } from "../services/tilgang.service";
import { Oppdrag, OppdragService } from "./oppdrag.service";
import { FormGroup } from "@angular/forms";
import { Tabs } from "../common/tabs/tabs.enum";
import { OppdragFakturalinjerDriftTabComponent } from "./oppdrag-fakturalinjer-drift-tab/oppdrag-fakturalinjer-drift-tab.component";
import { EndringsloggTabComponent } from "../endringslogg-tab-component/endringslogg-tab.component";
import { BackNavigationComponent } from "../services/backnavigation.guard";
import { Observable } from "rxjs";
import { countDirty } from "../global";

@Component({
  selector: "app-oppdrag",
  templateUrl: "./oppdrag.component.html"
})
export class OppdragComponent implements OnInit, BackNavigationComponent<Oppdrag> {

  @ViewChild('tab') tabsComponent: TabsComponent;

  isLoading = false;

  oppdragId: number;

  oppdrag: Oppdrag;

  fields: Field[];

  col1: Field[];

  col2: Field[];

  kostnadFields: Field[];

  formGroup = new FormGroup({});

  tabs: Tab[] = [];
  isSaving = false;
  kanOverforeTilDrift = false;

  constructor(
    private service: OppdragService,
    private tilgangService: TilgangService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService) { }



  sidenErEndret(): boolean {
    return this.formGroup.dirty;
  }

  antallEndringer():number {
    return countDirty(this.formGroup);
  }

  saveChanges(): Observable<Oppdrag> {
    return this.service.put(this.oppdrag);
  }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.oppdragId = +params["oppdragId"];
      this.setupTabs(this.oppdragId);
      this.tilgangService.getOverforTilDriftAccess().subscribe(toDriftTilgang => {
        this.kanOverforeTilDrift = toDriftTilgang;
      });
      this.service.get(this.oppdragId).subscribe(oppdrag => {
        this.oppdrag = oppdrag;
      }, () => {
        console.log("Error fetching risikogjenngang, ERROR HANDLE HERE MANNEN!");
      });

      this.service.getFields(this.oppdragId).subscribe(fields => {
        this.kostnadFields = fields.filter(field => this.kostnadFieldNames().has(field.key));
        this.fields = fields.filter(field => !this.kostnadFields.includes(field));
        const indexToSplit = (this.fields.length / 2);
        this.col1 = this.fields.slice(0, indexToSplit);
        this.col2 = this.fields.slice(indexToSplit + 1);
      });

    });


  }

  private setupTabs(oppdragId: number) {

    const tabs = [
      { key: Tabs.systemer, label: "Systemer", parentRoute: ["/oppdrag", oppdragId + ""] },
      { key: Tabs.personer, label: "Personer", parentRoute: ["/oppdrag", oppdragId + ""] },
      { key: Tabs.leverandorer, label: "Leverandører", parentRoute: ["/oppdrag", oppdragId + ""] },
      { key: Tabs.fakturering, label: "Fakturalinjer timer", parentRoute: ["/oppdrag", oppdragId + ""] },
      { key: Tabs.fakturalinjerDrift, label: "Fakturalinjer drift", parentRoute: ["/oppdrag", oppdragId + ""] },
      { key: Tabs.endringslogg, label: "Endringslogg", parentRoute: ["/oppdrag", oppdragId + ""] },
    ];

    this.tilgangService.getTabAccess("oppdrag", oppdragId).subscribe(t => {
      this.tabs = tabs.filter(ft => t.some(tabAccess => tabAccess.key == ft.key));
    });
  }

  private kostnadFieldNames(): Set<string> {
    return new Set<string>(["antallServere", "antallOracleBaser",
      "antallMsSqlBaser", "antallMariaDbBaser", "antallMongoDbBaser", "antallPostgreSqlBaser", "antallGrensesnitt", "antallServereTest",
      "tilleggDriftspris", "tilleggKommentar"]);
  }

  lagre(): void {
    console.log("Lagre");
    this.isSaving = true;
    this.service.put(this.oppdrag).subscribe(resp => {
      this.oppdrag = resp;
      this.notificationService.success(this.oppdrag.navn, "Lagret");
      this.formGroup.markAsPristine();
      this.isSaving = false;
      let activeTab = this.tabsComponent.getActiveTab();
      if (activeTab instanceof OppdragFakturalinjerDriftTabComponent) {
        activeTab.oppdaterFakturalinjer();
      } else if (activeTab instanceof EndringsloggTabComponent) {
        activeTab.hentEndringslogg();
      }
    }, () => this.isSaving = false);
  }

  tilbakestill(): void {
    window.location.reload();
  }

  overforTilDrift(): void {
    this.isLoading = true;
    this.service.overforTilDrift(this.oppdrag).subscribe(itsystem => {
      console.log(itsystem);
      this.isLoading = false;
      this.router.navigate([`itsystemer/${itsystem.systemId}`]);
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }

}
