import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TabSideBaseClass } from "src/app/common/tab-side/tab-side.component";
import { Tabs } from "src/app/common/tabs/tabs.enum";
import { TabsService } from "src/app/common/tabs/tabs.service";
import { KoblingerService, LeverandorSystemRolle } from "src/app/koblinger/koblinger.service";
import { ModalService } from "src/app/services/modal.service";
import { TilgangService } from "src/app/services/tilgang.service";
import {SystemStatusEnum} from "../../itsystemer/itsystemer.service";


@Component({
  selector: "app-systemer-tab",
  templateUrl: "./leverandorsystemer-tab.component.html"
})
export class LeverandorSystemerTabComponent extends TabSideBaseClass<LeverandorSystemRolle> {

  utgattSystem = SystemStatusEnum.Utgatt;

  tabName(): Tabs {
    return Tabs.systemer;
  }

  constructor(tabsService: TabsService, service: KoblingerService, tilgangService: TilgangService, route: ActivatedRoute, modal: ModalService, router: Router) {
    super(tabsService, service, tilgangService, modal, route, router);
  }

  loaderObservable(service: KoblingerService, id: number): Observable<LeverandorSystemRolle[]> {
    return service.getLeverandorSystemRollerForLeverandor(id);
  }

  pageLinkAccess(service: TilgangService): Observable<boolean> {
    return service.getGlobalRoles().pipe(map(val => val.itsystemer.listAccess));
  }

  removeObservable(service: KoblingerService, item: LeverandorSystemRolle): Observable<object> {
    return service.removeLeverandorSystemRolleForLeverandor(item.leverandorId, item.systemId, item.rolleId);
  }


  compareFn(first: LeverandorSystemRolle, second: LeverandorSystemRolle): number {
    if(first.system?.systemstatusId == second.system?.systemstatusId) return 0;
    if(first.system?.systemstatusId < second.system?.systemstatusId) return -1;
    else return 1;
  }

  kobleTil(): void {
    this.modal.velgSystem().then(system => {
      this.modal.velgLeverandorRolle().then(rolle => {

        if (this.liste.some(t => t.systemId === system.systemId && t.rolleId === rolle.rolleId)) {
          this.modal.warning(`${system.navn} med rolle ${rolle.navn} er allerede koblet til leverandør`, "Kobling feilet");
          return;
        }

        const leverandorSystemRolle: LeverandorSystemRolle = { leverandorId: this.id, systemId: system.systemId, rolleId: rolle.rolleId };
        this.service.postLeverandorSystemRolleForLeverandor(leverandorSystemRolle).toPromise().then(() => {
          this.modal.success("System koblet til oppdrag", "kobling vellykket");
          this.load();
        });
      });
    });
  }
}
