import {HttpClient, HttpParams} from "@angular/common/http";
import {Inject, Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Field, SearchItem, SearchResults} from "../common/common.model";
import {Itsystem} from "../itsystemer/itsystemer.service";


@Injectable({
  providedIn: "root"
})
export class IntegrasjonerService {

  private readonly basePath = "api/systemsystem/";
  private readonly baseUrl: string;

  constructor(private readonly http: HttpClient, @Inject("BASE_URL") private readonly baseHost: string) {
    this.baseUrl = baseHost + this.basePath;
  }

  search(beskrivelse?: string,
         sortOrder?: string,
         overforingsMetode?: number,
         personopplysningsniva?: number,
         showInactive = true,
         pageNumber = 1,
         pageSize = 30): Observable<SearchResults<SearchItem>> {

    let searchParams = new HttpParams();

    if (beskrivelse) {
      searchParams = searchParams.append("beskrivelse", beskrivelse);
    }
    if (sortOrder) {
      searchParams = searchParams.append("sortOrder", sortOrder);
    }
    if (personopplysningsniva) {
      searchParams = searchParams.append("personopplysningsniva", personopplysningsniva.toString());
    }
    if (overforingsMetode) {
      searchParams = searchParams.append("overforingsMetode", overforingsMetode.toString());
    }
    searchParams = searchParams.append("showInactive", String(showInactive));
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<SearchResults<SearchItem>>(this.baseUrl, {params: searchParams});
  }

  get(id: number): Observable<SystemSystem> {
    return this.http.get<SystemSystem>(this.baseUrl + id);
  }

  put(systemsystem: SystemSystem): Observable<SystemSystem> {
    return this.http.put<SystemSystem>(this.baseUrl + systemsystem.id, systemsystem);
  }

  post(systemsystem: SystemSystem): Observable<SystemSystem> {
    return this.http.post<SystemSystem>(this.baseUrl, systemsystem);
  }

  getPersonopplysningsniva(): Observable<Personopplysningsniva[]> {
    return this.http.get<Personopplysningsniva[]>(this.baseUrl + "pon");
  }

  getOverforingsMetode(): Observable<Overforingsmetode[]> {
    return this.http.get<Overforingsmetode[]>(this.baseUrl + "om");
  }

  getFields(integrasjonsId: number): Observable<Field[]> {
    return this.http.get<Field[]>(this.baseUrl + integrasjonsId + "/fields");
  }

  getCreateFields(): Observable<Field[]> {
    return this.http.get<Field[]>(this.baseUrl + "create/fields");
  }

  swapSystem1AndSystem2(systemsystem: SystemSystem): Observable<SystemSystem> {
    return this.http.put<SystemSystem>(`${this.baseUrl}swapSystem1AndSystem2/${systemsystem.id}`, systemsystem);
  }
}


export interface SystemSystem {
  id: number;
  navn: string;
  systemId1: number;
  systemId2: number;
  beskrivelse: string;
  dokumentasjoner: string;
  system1Navn: string;
  system2Navn: string;
  formal: string;
  aktiv: boolean;
  faktureres: boolean;
  ponivaaId?: number;
  metodeId?: number;
  retningId?: number;
  apiNavn: string;

  systemId1Navigation?: Itsystem;
  systemId2Navigation?: Itsystem;
}

export interface Overforingsmetode {
  metodeId: number;
  navn: string;
}

export interface Personopplysningsniva {
  ponivaaId: number;
  navn: string;
}




