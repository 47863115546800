import {Inject, Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Field, Rolle, SearchResults} from "../common/common.model";
import {Kunde} from "../oppdrag/oppdrag.service";
import {Person} from "../services/person.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ItsystemerService {


  private readonly basePath = "api/itsystem/";
  private readonly baseUrl: string;

  constructor(private http: HttpClient, @Inject("BASE_URL") private baseHost: string) {
    this.baseUrl = this.baseHost + this.basePath;
  }

  getItsystemer(): Observable<SearchResults<Itsystem>> {
    return this.http.get<SearchResults<Itsystem>>(this.baseUrl);
  }

  simpleSearchItsystemer(name?: string, systemtype?: string, sortOrder?: string, pageNumber = 1, pageSize = 30): Observable<SearchResults<ItsystemSearchResults>> {

    let searchParams = new HttpParams();
    if (sortOrder) {
      searchParams = searchParams.append("sortOrder", sortOrder);
    }
    if (name) {
      searchParams = searchParams.append("name", name);
    }
    if (systemtype) {
      searchParams = searchParams.append("systemtype", systemtype);
    }
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<SearchResults<ItsystemSearchResults>>(this.baseUrl + "simplesearch", {params: searchParams});
  }


  searchItsystemer(name?: string, sortOrder?: string, kilder?: string, showExpired = false, driftetAvAnsattservice = false, showSeSk = false, extendedSearch = false, kundeId?: number, pageNumber = 1, pageSize = 30): Observable<SearchResults<ItsystemSearchResults>> {

    let searchParams = new HttpParams();
    if (sortOrder) {
      searchParams = searchParams.append("sortOrder", sortOrder);
    }
    if (name) {
      searchParams = searchParams.append("name", name);
    }
    if (kilder) {
      searchParams = searchParams.append("kilder", kilder);
    }
    if (kundeId) {
      searchParams = searchParams.append("kundeId", kundeId.toString());
    }
    searchParams = searchParams.append("showExpired", String(showExpired));
    searchParams = searchParams.append("driftetAvAnsattservice", String(driftetAvAnsattservice));
    searchParams = searchParams.append("showSeSk", String(showSeSk));
    searchParams = searchParams.append("extendedSearch", extendedSearch)
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<SearchResults<ItsystemSearchResults>>(this.baseUrl, {params: searchParams});
  }

  getItsystem(systemId: number): Observable<Itsystem> {
    return this.http.get<Itsystem>(this.baseUrl + systemId);
  }

  putItsystem(itsystem: Itsystem): Observable<Itsystem> {
    return this.http.put<Itsystem>(this.baseUrl + itsystem.systemId, itsystem);
  }

  postItsystem(itsystem: Itsystem): Observable<Itsystem> {
    return this.http.post<Itsystem>(this.baseUrl, itsystem);
  }

  getItsystemFields(systemId: number): Observable<Field[]> {
    return this.http.get<Field[]>(this.baseUrl + systemId + "/fields");
  }

  oppdaterFakturalinje(itsystem: Itsystem) {
    return this.http.put<Itsystem>(this.baseUrl + itsystem.systemId + "/fakturalinje", itsystem);
  }

  registrerFakturalinje(itsystem: Itsystem) {
    return this.http.put<Itsystem>(this.baseUrl + itsystem.systemId + "/nyfakturalinje", itsystem);
  }

  getPrisberegningFields(systemId: number): Observable<Field[]> {
    return this.http.get<Field[]>(`${this.baseHost}api/prisberegning/${systemId}/fields`);
  }

  getIntegrasjonPrisAntall(systemId: number): Observable<IntegrasjonPrisAntall> {
    return this.http.get<IntegrasjonPrisAntall>(`${this.baseHost}api/prisberegning/prisintegrasjon/${systemId}`);
  }

  getItsystemOptions(): Observable<ItsystemCreateOptions> {
    return this.http.get<ItsystemCreateOptions>(this.baseUrl + "options");
  }

  getKunder(): Observable<Kunde[]> {
    return this.http.get<Kunde[]>(this.baseUrl + "byradsavdelinger");
  }

  getVedlegg(systemId: number): Observable<Tjenesteavtalevedlegg> {
    return this.http.get<Tjenesteavtalevedlegg>(this.baseUrl + systemId + "/vedlegg");
  }

  getVedleggAsWord(params: TjenesteavtalevedleggParams): Observable<Blob> {
    return this.http.post(this.baseHost + "api/report/tjenesteavtalevedlegg", params, {responseType: "blob"});
  }

  getSystemStatus(): Observable<SystemStatus[]> {
    return this.http.get<SystemStatus[]>(this.baseUrl + "systemStatus");
  }

  getSystemType(): Observable<SystemType[]> {
    return this.http.get<SystemType[]>(this.baseUrl + "systemType");
  }

  getDefaultPrisberegning() {
    return {
      TilleggDriftspris: 0,
      antallBrukere: 0,
      antallIntegrasjoner: 0,
      antallMariaDbBaser: 0,
      antallMongoDbBaser: 0,
      antallMsSqlBaser: 0,
      antallOracleBaser: 0,
      antallPostgreSqlBaser: 0,
      antallServere: 0,
      tilleggKommentar: ""
    }
  }

  getDefaultTestinformasjon() {
    return {
      beskrivelse: "",
      kilde: "",
      navn: "",
      systemStatusId: 1,
      systemTypeId: 1,
      testOfficeEndring: false,
      testWindowsEndring: false,
      testmaskinOffice: "",
      testmaskinWindows: "",
      tilgjengelighetId: 1
    }
  }

  getDefaultSikkerhetsinfo() {
    return {
      arligTilgangsrevisjon: false,
      databehandleravtale: false,
      datautenforEos: false,
      dpiaforetatt: false,
      eksternleverandortilgangDokumentert: false,
      hgutfort: undefined,
      integrasjonerDokumentert: false,
      mfabenyttes: false,
      oppdateringerFulgt: false,
      personopplysninger: false,
      rbac: false,
      rbacdokumentert: false,
      rosDokumentEksisterer: false,
      rosUtfort: false,
      sensitivePersonopplysninger: false,
      ssobenyttes: false,
      tilgangsoversiktMulig: false,
      tilgangsprosessDokumentert: false,
      tilgangsrevisjonDokumentert: false,
      tjenesterTilEksterne: false,
      tjenesterTilEksterneDokumentert: false
    }
  }

}

export class SystemType {
  static Fagsystem = 1;
  static Infrastruktur = 2;
  static Programpakke = 3;
  static Nettjeneste = 4;
}

export interface TjenesteavtalevedleggParams {
  systemId: number;
  pris: number;
  prisTilgjengelighet: number;
  prisIntegrasjon : IntegrasjonPrisAntall;
  kommentarer: string[];
}

export interface kunder {
  kundeId: number;
  navn: string;
}

export interface SystemStatus {
  systemstatusId: number;
  navn: string;
}

export interface Itsystem {
  systemId: number;
  navn: string;
  beskrivelse: string;
  kommentar: string;
  utgattdato?: Date;
  systemtypeId: number;
  systemtypeNavn: string;
  systemstatusId: number;
  systemstatusNavn: string;
  tilgjengelighetId: number;
  interninfo: string;
  ressursnr: number;
  tjeneste: string;
  kritikalitetId: number;
  kritikalitetNavn: string;
  internettKritikalitetId: number;
  internettKritikalitetNavn: string;
  registrert?: Date;
  saksnummer?: string;
  overlevertDrift: Date;
  prisberegning?: Prisberegning;
  sikkerhetsinfo?: Sikkerhetsinfo;
  testinformasjon?: Testinformasjon;
  tilgjengelighet?: Tilgjengelighet;
  vaktlag: boolean;
  avtaleIngatt: Date;
  avtaleEndret?: Date;
  tjenesteavtale: string;
  avvikPaTilgjengelighet: string;
  prisPrBruker: number;
  prisinfo?: string;
  art?: number;
  ansvarssted?: string;
  prosjektnr?: string;
  program32bit: boolean;
  program64bit: boolean;
  kundeId: number;
  lisensId: number;
  bestillerId: number;
  klassifiseringId: number;
  sideLoadedApp: boolean;
  microsoftStoreApp: boolean;
  chromeWebStore: boolean;
  googlePlay: boolean;
  appStore: boolean;
  behovsdekningId: number;
  antallBrukere: number;
}

export interface ItsystemSearchResults {
  systemId: number;
  systemtypeId: number;
  navn: string;
  beskrivelse: string;
  kilde: string;

  KundeId?: number;

  tilgjengelighetId: number;
  tilgjengelighetNavn: string;

  leverandorId: number;
  leverandorNavn: string;

  systemEier: Person;
  systemKoodinator: Person;
  utgatt: boolean;
  utgattdato: Date;
  rosUtfort: boolean;
  rosForetatt: Date;
}

export interface SystemType {
  systemtypeId: number;
  type: string;
  beskrivelse: string;
}

export interface Programklassifisering {
  klassifiseringId: number;
  navn: string;
  beskrivelse: string;
}

export interface SystemStatus {
  systemstatusId: number;
  navn: string;
}

export interface Tilgjengelighet {
  tilgjengelighetId: number;
  navn: string;
  beskrivelse: string;
}

export interface Kritikalitet {
  kritikalitetId: number;
  navn: string;
}

export interface InternettKritikalitet {
  internettKritikalitetId: number;
  navn: string;
}


export interface PersonSystemRolle {
  personId: number;
  systemId: number;
  rolleId: number;
  person: Person;
  system: Itsystem;
  rolle: Rolle;
}


export interface ItsystemCreateOptions {
  systemtype: SystemType[];
  programklassifisering: Programklassifisering[];
  systemstatus: SystemStatus[];
  tilgjengelighet: Tilgjengelighet[];
  kritikalitet: Kritikalitet[];
  internettKritikalitet: InternettKritikalitet[];
  kunde: Kunde[];
}

export interface Tjenesteavtalevedlegg {
  avdeling: string;
  systemEier: Person;
  systemKoordinator: Person;
  systemLeverandor: string;
}

export interface Prisberegning {
  systemId: number;
  antallServere: number;
  antallIntegrasjoner: number;
  antallMsSqlBaser: number;
  antallOracleBaser: number;
  antallPostgreSqlBaser: number;
  antallMariaDbBaser: number;
  antallMongoDbBaser: number;
  tilleggDriftspris: number;
  tilleggKommentar: string;
}

export interface Testinformasjon {
  systemId: number;
  navn: string;
  beskrivelse: string;
  kundeId?: number;
  systemTypeId: number;
  systemStatusId: number;
  kilde: string;
  tilgjengelighetId: number;
  testOfficeEndring: boolean;
  testWindowsEndring: boolean;
  testmaskinOffice: string;

  testmaskinWindows: string;
  testresultatId?: number;
  testresultatNavn?: string;
  testetDato?: Date
}

export interface Sikkerhetsinfo {
  systemId: number;
  anskaffet?: number;
  bk360referanse?: string;
  databehandleravtale: boolean;
  databehandleravtaleDokument?: string;
  datalagringssted?: string;
  datautenforEos: boolean;
  dpiaforetatt: boolean;
  dpiadokumentasjon?: string;
  eksternleverandortilgangDokumentert: boolean;
  endringsdokumentasjon?: string;
  endringsrutiner?: string;
  hgutfort: Date;
  integrasjonerDokumentert: boolean;
  mfabenyttes: boolean;
  personopplysninger: boolean;
  rbac: boolean;
  rbacdokumentert: boolean;
  rbacdokumentasjon?: boolean;
  rosUtfort: boolean;
  rosDokumentEksisterer: boolean;
  rosForetatt?: Date;
  rosDokument?: string;
  sensitivePersonopplysninger: boolean;
  ssobenyttes: boolean;
  tilgangsoversiktMulig: boolean;
  tilgangsrevisjonForetatt?: Date;
  tilgangsrevisjonDokumentert: boolean;
  tilgangsrevisjonDokumentasjon?: string;
  tilgangsprosessDokumentert: boolean;
  tilgangsprosessDokumentasjon?: string;
  tjenesterTilEksterne: boolean;
  tjenesterTilEksterneDokumentert: boolean;
  tjenesterTilEksterneDokumentasjon?: string;
  arligTilgangsrevisjon: boolean;
  oppdateringerFulgt: boolean;
}

export interface  IntegrasjonPrisAntall {
  antallIntegrasjoner: number;
  integrasjonSum: number;
}

export enum SystemStatusEnum {
  Planlagt = 1,
  UnderUtvikling = 2,
  Test = 3,
  Produksjon = 4,
  Utgatt = 5
}

export enum TilgjengelighetEnum {
  Skytjeneste = 1,
  NormalDrift = 2,
  DøgnDrift = 3
}


