import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { MsalGuard } from "@azure/msal-angular";
import { ItsystemerComponent } from "./itsystemer/itsystemer.component";
import { RisikogjennomgangerComponent } from "./risikogjennomganger/risikogjennomganger.component";
import { RisikogjennomgangComponent } from "./risikogjennomgang/risikogjennomgang.component";
import { ItsystemComponent } from "./itsystem/itsystem.component";
import { OppdragComponent } from "./oppdrag/oppdrag.component";
import { ItsystemerNyComponent } from "./itsystemer/itsystemer-ny/itsystemer-ny.component";
import { PathResolverService } from "./services/path-resolver.service";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { EndringComponent } from "./itsystem/endring-tab/endring.component";
import { EndringsloggComponent } from "./itsystem/endring-tab/endringslogg.component";
import { PersonerComponent } from "./personer/personer.component";
import { PersonComponent } from "./person/person.component";
import { LeverandorComponent } from "./leverandor/leverandor.component";
import { LeverandorerComponent } from "./leverandorer/leverandorer.component";
import { LeverandorerNyComponent } from "./leverandorer/leverandorer-ny/leverandorer-ny.component";
import { OppdragslisteComponent } from "./oppdrag/oppdragsliste.component";
import { ItsystemOppdragTabComponent } from "./itsystem/itsystemoppdrag-tab/itsystemoppdrag-tab.component";
import { BacknavigationGuard } from "./services/backnavigation.guard";
import { OppdragSystemerTabComponent } from "./oppdrag/oppdragsystemer-tab/oppdragsystemer-tab.component";
import { OppdragPersonerTabComponent } from "./oppdrag/oppdragpersoner-tab/oppdragpersoner-tab.component";
import { OppdragLeverandorerTabComponent } from "./oppdrag/oppdragleverandorer-tab/oppdragleverandorer-tab.component";
import { PersonSystemerTabComponent } from "./person/personsystemer-tab/personsystemer-tab.component";
import { PersonOppdragTabComponent } from "./person/personsoppdrag-tab/personoppdrag-tab.component";
import { PersonLeverandorTabComponent } from "./person/personleverandor-tab/personleverandor-tab.component";
import { LeverandorSystemerTabComponent } from "./leverandor/leverandorsystemer-tab/leverandorsystemer-tab.component";
import { LeverandorOppdragTabComponent } from "./leverandor/leverandoroppdrag-tab/leverandoroppdrag-tab.component";
import { LeverandorPersonerTabComponent } from "./leverandor/leverandorpersoner-tab/leverandorpersoner-tab.component";
import { ItsystemPersonerTabComponent } from "./itsystem/itsystempersoner-tab/itsystempersoner-tab.component";
import { SertifikatTabComponent } from "./itsystem/sertifikat-tab/sertifikat-tab.component";
import { SertifikaterComponent } from "./sertifikater/sertifikater.component";
import { SertifikatComponent } from "./sertifikater/sertifikat.component";
import { TjenesterComponent } from "./tjeneste/tjenester.component";
import { IntegrasjonerComponent } from "./integrasjoner/integrasjoner.component";
import { TjenesteComponent } from "./tjeneste/tjeneste.component";
import { IntegrasjonComponent } from "./integrasjoner/integrasjon.component";
import { EndringUtforereComponent } from "./itsystem/endring-tab/endring-utforere/endring-utforere.component";
import { FaktureringTabComponent } from "./itsystem/endring-tab/endring-fakturering/fakturering-tab.component";
import { OppdragNyComponent } from "./oppdrag/oppdrag-ny/oppdrag-ny.component";
import { SertifikatSystemTabComponent } from "./sertifikater/sertifikat-system-tab/sertifikat-system-tab.component";
import { ItsystemTjenesterTabComponent } from "./itsystem/itsystemtjenester-tab/itsystemtjenester-tab.component";
import { TjenesteItsystemTabComponent } from "./tjeneste/tjenesteitsystem-tab/tjenesteitsystem-tab.component";
import { SystemsystemTabComponent } from "./itsystem/systemsystem-tab/systemsystem-tab.component";
import { ItsystemSoneTabComponent } from "./itsystem/itsystemsone-tab/itsystemsone-tab.component";
import { ItsystemMiljoTabComponent } from "./itsystem/itsystemmiljo-tab/itsystemmiljo-tab.component";
import { OppdragFakturalinjerDriftTabComponent } from "./oppdrag/oppdrag-fakturalinjer-drift-tab/oppdrag-fakturalinjer-drift-tab.component";
import { FakturaDetaljerComponent } from "./fakturering/faktura-detaljer/faktura-detaljer.component";
import { FakturaerComponent } from "./fakturaer/fakturaer.component";
import { SertifikaterNyComponent } from "./sertifikater/sertifikater-ny/sertifikater-ny.component";
import { TjenesterNyComponent } from "./tjeneste/tjenester-ny/tjenester-ny.component";
import { IntegrasjonerNyComponent } from "./integrasjoner/integrasjoner-ny/integrasjoner-ny.component";
import { SystemLeverandorTabComponent } from "./itsystem/itsystemleverandor-tab/system-leverandor-tab.component";
import { TimeforingComponent } from "./timeforing/timeforing.component";
import { BrowserUtils } from "@azure/msal-browser";
import { EmptypageComponent } from "./test/emptypage/emptypage.component";
import { EndringsloggTabComponent } from "./endringslogg-tab-component/endringslogg-tab.component";
import { IntegrasjonBegrepTabComponent } from "./integrasjoner/integrasjon-begrep-tab/integrasjon-begrep-tab.component";
import { TestinformasjonComponent } from "./testinformasjon/testinformasjon.component";
import { TestinformasjonerComponent } from "./testinformasjoner/testinformasjoner.component";
import { ItsystemBruksomradeTabComponent } from "./itsystem/itsystem-bruksomrade/itsystem-bruksomrade-tab.component";
import { ItsystemBrukereTabComponent } from "./itsystem/itsystem-brukere-tab/itsystem-brukere-tab.component"





const routes: Routes = [
  { path: "", component: HomeComponent, pathMatch: "full", canActivate: [MsalGuard] },
  { path: "emptypage", component: EmptypageComponent, canActivate: [MsalGuard], canDeactivate: [BacknavigationGuard], },
  { path: "risikogjennomganger", component: RisikogjennomgangerComponent, canActivate: [MsalGuard] },
  { path: "risikogjennomganger/:systemId", component: RisikogjennomgangComponent, canActivate: [MsalGuard], canDeactivate: [BacknavigationGuard], },
  { path: "testinformasjoner", component: TestinformasjonerComponent, canActivate: [MsalGuard] },
  { path: "testinformasjoner/:systemId", component: TestinformasjonComponent, canActivate: [MsalGuard], canDeactivate: [BacknavigationGuard] },
  { path: "itsystemer", component: ItsystemerComponent, canActivate: [MsalGuard] },
  { path: "itsystemer/ny", component: ItsystemerNyComponent, canActivate: [MsalGuard] },
  {
    path: "itsystemer/:systemId", component: ItsystemComponent, canActivate: [MsalGuard], canDeactivate: [BacknavigationGuard],
    children: [
      { path: "leverandorer", component: SystemLeverandorTabComponent, canActivate: [MsalGuard] },
      { path: "endringer", component: EndringsloggComponent, canActivate: [MsalGuard] },
      { path: "endringslogg", component: EndringsloggTabComponent, canActivate: [MsalGuard] },
      { path: "fakturering", component: FaktureringTabComponent, canActivate: [MsalGuard] },
      { path: "oppdrag", component: ItsystemOppdragTabComponent, canActivate: [MsalGuard] },
      { path: "personer", component: ItsystemPersonerTabComponent, canActivate: [MsalGuard] },
      { path: "sertifikat", component: SertifikatTabComponent, canActivate: [MsalGuard] },
      { path: "tjenester", component: ItsystemTjenesterTabComponent, canActivate: [MsalGuard] },
      { path: "integrasjoner", component: SystemsystemTabComponent, canActivate: [MsalGuard] },
      { path: "sone", component: ItsystemSoneTabComponent, canActivate: [MsalGuard] },
      { path: "miljo", component: ItsystemMiljoTabComponent, canActivate: [MsalGuard] },
      { path: "bruksomrade", component: ItsystemBruksomradeTabComponent, canActivate: [MsalGuard] },
      { path: "brukere", component: ItsystemBrukereTabComponent, canActivate: [MsalGuard] },
    ]
  },
  {
    path: "itsystemer/:systemId/endring/:endringId", component: EndringComponent, canActivate: [MsalGuard],
    children: [
      { path: "personer", component: EndringUtforereComponent, canActivate: [MsalGuard] },
      { path: "fakturering", component: FaktureringTabComponent, canActivate: [MsalGuard] }
    ]
  },
  {
    path: "fakturaer", component: FakturaerComponent, canActivate: [MsalGuard],
    children: [
      { path: "detaljer", component: FakturaDetaljerComponent, canActivate: [MsalGuard] }
    ]
  },
  { path: "oppdrag", component: OppdragslisteComponent, canActivate: [MsalGuard] },
  { path: "oppdrag/ny", component: OppdragNyComponent, canActivate: [MsalGuard] },
  {
    path: "oppdrag/:oppdragId", component: OppdragComponent, canActivate: [MsalGuard], canDeactivate: [BacknavigationGuard],
    children: [
      { path: "systemer", component: OppdragSystemerTabComponent, canActivate: [MsalGuard] },
      { path: "personer", component: OppdragPersonerTabComponent, canActivate: [MsalGuard] },
      { path: "leverandorer", component: OppdragLeverandorerTabComponent, canActivate: [MsalGuard] },
      { path: "fakturering", component: FaktureringTabComponent, canActivate: [MsalGuard] },
      { path: "fakturalinjerDrift", component: OppdragFakturalinjerDriftTabComponent, canActivate: [MsalGuard] },
      { path: "endringslogg", component: EndringsloggTabComponent, canActivate: [MsalGuard] },
    ]
  },
  { path: "personer", component: PersonerComponent, canActivate: [MsalGuard] },
  { path: "personer/ny", component: PersonComponent, canActivate: [MsalGuard] },
  {
    path: "personer/:personId", component: PersonComponent, canActivate: [MsalGuard], canDeactivate: [BacknavigationGuard],
    children: [
      { path: "systemer", component: PersonSystemerTabComponent, canActivate: [MsalGuard] },
      { path: "oppdrag", component: PersonOppdragTabComponent, canActivate: [MsalGuard] },
      { path: "leverandorer", component: PersonLeverandorTabComponent, canActivate: [MsalGuard] },
      { path: "endringslogg", component: EndringsloggTabComponent, canActivate: [MsalGuard] }
    ]

  },

  { path: "leverandorer", component: LeverandorerComponent, canActivate: [MsalGuard] },
  { path: "leverandorer/ny", component: LeverandorerNyComponent, canActivate: [MsalGuard] },
  {
    path: "leverandorer/:leverandorId", component: LeverandorComponent, canActivate: [MsalGuard], canDeactivate: [BacknavigationGuard],
    children: [
      { path: "systemer", component: LeverandorSystemerTabComponent, canActivate: [MsalGuard] },
      { path: "oppdrag", component: LeverandorOppdragTabComponent, canActivate: [MsalGuard] },
      { path: "personer", component: LeverandorPersonerTabComponent, canActivate: [MsalGuard] },
      { path: "endringslogg", component: EndringsloggTabComponent, canActivate: [MsalGuard] }
    ]
  },
  { path: "sertifikater", component: SertifikaterComponent, canActivate: [MsalGuard] },
  { path: "sertifikater/ny", component: SertifikaterNyComponent, canActivate: [MsalGuard] },
  {
    path: "sertifikater/:sertifikatId", component: SertifikatComponent, canActivate: [MsalGuard], canDeactivate: [BacknavigationGuard],
    children: [
      { path: "systemer", component: SertifikatSystemTabComponent, canActivate: [MsalGuard] },
      { path: "endringslogg", component: EndringsloggTabComponent, canActivate: [MsalGuard] }
    ]

  },
  { path: "tjenester", component: TjenesterComponent, canActivate: [MsalGuard] },
  { path: "tjenester/ny", component: TjenesterNyComponent, canActivate: [MsalGuard] },
  {
    path: "tjenester/:tjenesteId", component: TjenesteComponent, canActivate: [MsalGuard], canDeactivate: [BacknavigationGuard],
    children: [
      { path: "systemer", component: TjenesteItsystemTabComponent, canActivate: [MsalGuard] },
      { path: "endringslogg", component: EndringsloggTabComponent, canActivate: [MsalGuard] }
    ]
  },

  { path: "integrasjoner", component: IntegrasjonerComponent, canActivate: [MsalGuard] },
  { path: "integrasjoner/ny", component: IntegrasjonerNyComponent, canActivate: [MsalGuard] },
  {
    path: "integrasjoner/:id", component: IntegrasjonComponent, canActivate: [MsalGuard], canDeactivate: [BacknavigationGuard],
    children: [
      { path: "begrep", component: IntegrasjonBegrepTabComponent, canActivate: [MsalGuard] }
    ]
  },

  { path: "timeforing", component: TimeforingComponent, canActivate: [MsalGuard] },

  {
    path: "**", resolve: { path: PathResolverService }, component: PageNotFoundComponent, canActivate: [MsalGuard],
  }

];

const initialNavigation = (!BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()) || window.location.href.indexOf("logout") > 0;

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      relativeLinkResolution: "legacy",
      initialNavigation: initialNavigation ? "enabled" : "disabled"
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
