import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FilterParams } from "../../common.model";

@Component({
  selector: "app-soketekstfilter",
  templateUrl: "./soketekstfilter.component.html"
})
export class SoketekstFilterComponent implements OnInit {

  @Input()
  loadingData = false;

  @Input()
  filterParams: FilterParams;


  @Output()
  onFilterChange = new EventEmitter<FilterParams>();

  ngOnInit(): void {
    // Do nothing
  }

  filterChanged(event: any): void {
    this.filterParams.pageNumber = 1;
    this.onFilterChange.emit(this.filterParams);
  }

}

