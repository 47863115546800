<div *ngIf="model && textAreaCol && col1 && col2 && checkboxCol">

    <div class="container-flex">
      <div class="row">
          <div class="col-12">
              <app-feltliste [formGroup]="formGroup" [fields]="textAreaCol" [modell]="model"></app-feltliste>
          </div>

      </div>        
      <div class="row">
       <div class="col-12 col-md-5 col-lg-4">
          <app-feltliste [formGroup]="formGroup" [fields]="col1" [modell]="model"></app-feltliste>
        </div>
        <div class="col-12 col-md-5 col-lg-4">
          <app-feltliste [formGroup]="formGroup" [fields]="col2" [modell]="model"></app-feltliste>
        </div>
        <div class="col-12 col-md-5 col-lg-4 mt-5">
          <app-feltliste [formGroup]="formGroup" [fields]="checkboxCol" [modell]="model"></app-feltliste>
        </div>
      </div>
  </div>

</div>