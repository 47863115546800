import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { Field } from "src/app/common/common.model";
import { FeltSideBaseClass } from "src/app/common/feltside/feltside.component";
import { Endring, EndringerService } from "src/app/itsystem/endring-tab/endringer.service";
import { Itsystem } from "src/app/itsystemer/itsystemer.service";
import { NotificationService } from "src/app/services/notification.service";
import { TabAccess, TilgangService } from "src/app/services/tilgang.service";
import { Tab } from "../../common/tabs/tabs.component";

@Component({
  selector: "app-endring",
  templateUrl: "endring.component.html"
})
export class EndringComponent extends FeltSideBaseClass<Endring> implements OnInit {



  idParamName = "endringId";



  systemId: number;
  endringId: number;

  itsystem: Itsystem;
  endring: Endring;

  formGroup = new FormGroup({});

  fields: Field[] = [];
  tabs: Tab[];

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    route: ActivatedRoute,
    private endringerService: EndringerService,
    private tilgangService: TilgangService,
    notificationService: NotificationService) { super(endringerService, notificationService, route, changeDetectorRef); }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.systemId = +params["systemId"];
      this.endringId = +params["endringId"];
    });

    this.route.params.subscribe(params => {

      this.id = +params[this.idParamName];
      this.setupTabs();

      this.endringerService.getEndring(this.systemId, this.endringId).subscribe(item => {
        this.item = item;
      }, () => {
        console.log("Error fetching risikogjenngang, ERROR HANDLE HERE MANNEN!");
      });

      this.endringerService.getEndringFields(this.systemId, this.endringId).subscribe(fields => {
        if (!this.retainOrder) {
          fields = this.sortFieldsByTypeAndLabel(fields);
        }
        this.fields = fields;
        this.organizeFields([].concat(fields));

      });
    });
  }



  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }


  getAvailableTabs(): Tab[] {
    return [
      { key: "personer", label: "Personer", parentRoute: ["/itsystemer", this.systemId.toString(), "endring", this.endringId.toString()] },
      { key: "fakturering", label: "Fakturering", parentRoute: ["/itsystemer", this.systemId.toString(), "endring", this.endringId.toString()] }
    ];
  }
  getTabAccess(): Observable<TabAccess[]> {
    return this.tilgangService.getTabAccess("endring", this.id);
  }

}
