<div *ngIf="!harTilgang">
    Du har ikke tilgang til å registrere ny {{getTitle()}}.
</div>

<div class="modal-header">
    <h5 class="modal-title">{{getHeadline()}}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
        (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div *ngIf="harTilgang" class="modal-body">


    <app-modellfelt *ngIf="headingField" [formGroup]="formGroup" [field]="headingField" [modell]="model">
    </app-modellfelt>
    <app-feltliste *ngIf="topFields" [formGroup]="formGroup" [fields]="topFields" [modell]="model">
    </app-feltliste>
    <app-feltliste *ngIf="mainFields" [formGroup]="formGroup" [fields]="mainFields" [modell]="model">
    </app-feltliste>
    <app-feltliste *ngIf="numberFields" [formGroup]="formGroup" [fields]="numberFields" [modell]="model">
    </app-feltliste>
    <app-feltliste *ngIf="checkboxFields" [formGroup]="formGroup" [fields]="checkboxFields" [modell]="model">
    </app-feltliste>
    <app-feltliste *ngIf="investeringsFields" [formGroup]="formGroup" [fields]="investeringsFields" [modell]="model">
    </app-feltliste>


    <app-lagreknapp [buttonTitle]="'Opprett'" [isSaving]="isSaving" [showResetButton]="false" [formGroup]="formGroup" (onLagre)="lagre()">
    </app-lagreknapp>

</div>


<app-spinner [loadingData]="isLoading" [loadingFailed]="errorLoading" (retryAction)="ngOnInit()"></app-spinner>

<div class="modal-footer">
    <button class="btn btn-secondary" type="button" (click)="activeModal.dismiss('Cross click')">Lukk</button>
</div>