import {Component, ViewChild} from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Observable } from "rxjs";
import { FilterParams, SearchItem } from "../common/common.model";
import { ListesideBaseClass } from "../common/listeside/listeside.component";
import { TilgangService } from "../services/tilgang.service";
import { SertifikaterService } from "./sertifikater.service";
import {TabsComponent} from "../common/tabs/tabs.component";

@Component({
  selector: "app-sertifikater",
  templateUrl: "./sertifikater.component.html"
})
export class SertifikaterComponent extends ListesideBaseClass {

  overskrift = "Oversikt over Sertifikater";
  searchResults: SearchItem[];


  harTilgangTilOpprettNy = false;

  constructor(
    private service: SertifikaterService,
    tilgangService: TilgangService, route: ActivatedRoute, router: Router
  ) { super(route, router, tilgangService); }



  onFilterChange(filterParams: FilterParams): void {

    this.router.navigate(["/sertifikater"], {
      queryParams:
      {
        "s": filterParams.tekst,
        "sortOrder": filterParams.order,
        "sertifikatStatus": filterParams.sertifikatStatus,
        "pageNumber": filterParams.pageNumber,
        "pageSize": filterParams.pageSize
      }
    });
  }

  search(params: FilterParams): Observable<any> {

    const name = params.tekst as string;
    const sortOrder = params.order as string;
    const sertifikatStatus = params.sertifikatStatus as string;
    const pageNumber = params.pageNumber as number;
    const pageSize = params.pageSize as number;

    return this.service.search(name, sortOrder, sertifikatStatus, pageNumber, pageSize);
  }

  urlParamsToFilterParams(params: Params, filterParams: FilterParams): FilterParams {

    filterParams = filterParams ? filterParams : new FilterParams();

    filterParams.tekst = params.s ? params.s : "";
    filterParams.order = params.sortOrder ? params.sortOrder : "nameAsc";
    filterParams.sertifikatStatus = params.sertifikatStatus ? params.sertifikatStatus : "alle";
    filterParams.pageNumber = params.pageNumber ? params.pageNumber : 1;
    filterParams.pageSize = params.pageSize ? params.pageSize : 30;

    return filterParams;
  }


}
