import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Tabs } from "src/app/common/tabs/tabs.enum";
import { TabsService } from "src/app/common/tabs/tabs.service";
import { TilgangService } from "src/app/services/tilgang.service";
import { TabSideBaseClass } from "../../common/tab-side/tab-side.component";
import { KoblingerService, SystemSertifikat } from "../../koblinger/koblinger.service";
import { ModalService } from "../../services/modal.service";
import {Sertifikat} from "../../sertifikater/sertifikater.service";

@Component({
  selector: "app-sertifikat-tab",
  templateUrl: "./sertifikat-tab.component.html"
})
export class SertifikatTabComponent extends TabSideBaseClass<SystemSertifikat> {

  tabName(): Tabs {
    return Tabs.sertifikat;
  }

  constructor(tabsService: TabsService, service: KoblingerService, tilgangService: TilgangService, modal: ModalService, route: ActivatedRoute, router: Router) {
    super(tabsService, service, tilgangService, modal, route, router);
  }

  loaderObservable(service: KoblingerService, id: number): Observable<SystemSertifikat[]> {
    return this.service.getSystemSertifikatForSystem(id);
  }

  pageLinkAccess(service: TilgangService): Observable<boolean> {
    return service.getGlobalRoles().pipe(map(val => val.sertifikater.listAccess));
  }

  removeObservable(service: KoblingerService, item: SystemSertifikat): Observable<object> {
    return service.removeSystemSertifikatForSystem(item.systemId, item.sertifikatId);
  }

  erGyldig(sertifikat: Sertifikat) {
    let now = new Date();
    return new Date(sertifikat.notBefore) < now && new Date(sertifikat.notAfter) > now;
  }

  kobleTil(): void {
    this.modal.velgSertifikat().then(sertifikat => {

      if (this.liste.some(t => t.sertifikatId === sertifikat.id)) {
        this.modal.warning(`${sertifikat.navn} er allerede koblet til system`, "Kobling feilet");
        return;
      }

      const srt: SystemSertifikat = { systemId: this.id, sertifikatId: sertifikat.id };
      this.service.postSystemSertifikatForSystem(srt).subscribe(() => {
        this.modal.success("Sertifikat koblet til system", "Kobling opprettet");
        this.load();
      });
    });
  }

}
