import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntegrasjonsharedService {
  private subject = new Subject<any>();

  constructor() { }

  sendEvent() {
    this.subject.next();

  }

  getEventI(): Observable<any>{
    return this.subject.asObservable();
  }
}
