<div *ngIf="loadingData" class="d-flex justify-content-center my-2">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<div class="d-flex justify-content-center my-2">
    <p *ngIf="loadingData && loadingText">{{loadingText}}</p>
</div>

<div *ngIf="loadingFailed" class="d-flex justify-content-center">
    <div class="card text-center" style="width: 18rem;">
        <div class="card-body">
            <i class="fas fa-exclamation-circle fa-3x"></i>
            <h5 class="card-title">Loading failed</h5>
            <button (click)="retry()" class="btn btn-link">Retry</button>
            <a href="/" class="btn btn-link">Go Home</a>
        </div>
    </div>

</div>