<div *ngIf="!harTilgang">
  Du har ikke tilgang til å registrere nytt oppdrag.
</div>

<div *ngIf="harTilgang" class="container">
  <div class="card">
    <div class="card-body">

      <h2>Opprett nytt Oppdrag</h2>

      <form (ngSubmit)="onSubmit()" #oppdragForm="ngForm">

        <div *ngIf="!oppdragCreateOptions" class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <div *ngIf="oppdragCreateOptions">


          <div class="form-group my-4">
            <label for="navn">Navn</label>
            <span *ngIf="navn.invalid" class="badge badge-pill badge-secondary mx-1">Required</span>
            <input type="text" class="form-control" id="navn" name="navn" placeholder="Navn" required
                   maxlength="255" [(ngModel)]="oppdrag.navn" #navn="ngModel">
            <small id="navnHelp"
                   class="form-text text-muted float-right">{{oppdrag.navn ? oppdrag.navn.length : 0}}/255</small>
          </div>


          <div class="form-group">
            <label for="tilgjengelighet">Tilgjengelighet</label>
            <div class="input-group">
              <select class="custom-select" id="tilgjengelighet" [(ngModel)]="oppdrag.tilgjengelighetId"
                      aria-describedby="help-driftsniva-button" name="tilgjengelighet" #tilgjengelighet="ngModel"
                      required>
                <option *ngFor="let tilgjengelighet of oppdragCreateOptions.tilgjengelighet"
                        [value]="tilgjengelighet.tilgjengelighetId"
                        [attr.selected]="tilgjengelighet.tilgjengelighetId==oppdrag.tilgjengelighetId">
                  {{tilgjengelighet.navn}}</option>
              </select>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" id="help-driftsniva-button"
                        data-toggle="modal" data-target="#driftsniva-help"><i
                  class="fas fa-question-circle"></i></button>
              </div>

            </div>
          </div>


          <div class="form-group">
            <label for="internett">Internett kritikalitet</label>
            <div class="input-group">
              <select class="custom-select" id="internett" [(ngModel)]="oppdrag.internettKritikalitetId"
                      name="internett" #internett="ngModel" required>
                <option *ngFor="let internett of oppdragCreateOptions.internett"
                        [value]="internett.internettKritikalitetId"
                        [attr.selected]="internett.internettKritikalitetId==oppdrag.internettKritikalitetId">
                  {{internett.navn}}</option>
              </select>
            </div>
          </div>


          <div class="form-group">
            <label for="kunde">Kunde</label>
            <div class="input-group">
              <select class="custom-select" id="kunde" [(ngModel)]="oppdrag.kundeId"
                      name="kunde" #kunde="ngModel"
                      required>
                <option *ngFor="let kunde of oppdragCreateOptions.kunde"
                        [value]="kunde.kundeId"
                        [attr.selected]="kunde.kundeId==oppdrag.kundeId">
                  {{kunde.navn}}</option>
              </select>
            </div>
          </div>


          <div class="form-group">
            <label for="tilgjengelighet">Status</label>
            <div class="input-group">
              <select class="custom-select" id="oppdragStatus" [(ngModel)]="oppdrag.oppdragStatusId"
                      name="oppdragStatus" #oppdragStatus="ngModel"
                      required>
                <option *ngFor="let oppdragStatus of oppdragCreateOptions.oppdragstatus"
                        [value]="oppdragStatus.oppdragStatusId"
                        [attr.selected]="oppdragStatus.oppdragStatusId==oppdrag.oppdragStatusId">
                  {{oppdragStatus.status}}</option>
              </select>
            </div>
          </div>


          <div class="form-group">
            <label for="tilgjengelighet">Status SM</label>
            <div class="input-group">
              <select class="custom-select" id="oppdragStatusSm" [(ngModel)]="oppdrag.oppdragSmStatusId"
                      name="oppdragStatusSm" #oppdragStatusSm="ngModel"
                      required>
                <option *ngFor="let oppdragStatusSm of oppdragCreateOptions.oppdragstatussm"
                        [value]="oppdragStatusSm.oppdragSmStatusId"
                        [attr.selected]="oppdragStatusSm.oppdragSmStatusId==oppdrag.oppdragSmStatusId">
                  {{oppdragStatusSm.statusSm}}</option>
              </select>
            </div>
          </div>


          <div class="form-group">
            <label for="tilgjengelighet">Leveranse status</label>
            <div class="input-group">
              <select class="custom-select" id="oppdragLev" [(ngModel)]="oppdrag.oppdragLevStatusId"
                      name="oppdragLev" #oppdragLev="ngModel"
                      required>
                <option *ngFor="let oppdragLev of oppdragCreateOptions.oppdragstatusleveranse"
                        [value]="oppdragLev.oppdragLevStatusId"
                        [attr.selected]="oppdragLev.oppdragLevStatusId==oppdrag.oppdragLevStatusId">
                  {{oppdragLev.statusLeveranse}}</option>
              </select>
            </div>
          </div>


          <button type="submit" class="btn btn-outline-primary mt-4" [disabled]="!oppdragForm.form.valid">Opprett
          </button>
          <button type="button" class="btn btn-outline-secondary mx-2 mt-4" (click)="resetForm()">Reset</button>


          <!-- Driftsniva Help-Modal -->
          <div class="modal fade" id="driftsniva-help" tabindex="-1" role="dialog"
               aria-labelledby="driftsniva-help-Title" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="driftsniva-help-Title">Tilgjengelighet</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <ul class="list-group list-group-flush">
                    <li *ngFor="let tilgjengelighet of oppdragCreateOptions.tilgjengelighet"
                        class="list-group-item">
                      <b>{{tilgjengelighet.navn}}</b> {{tilgjengelighet.beskrivelse}}</li>
                  </ul>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>


              </div>
            </div>
          </div>

        </div>
      </form>

    </div>
  </div>


</div>
