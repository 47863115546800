<div xmlns="http://www.w3.org/1999/html">

  <app-spinner [loadingData]="isLoading" [loadingFailed]="errorLoading"></app-spinner>

  <div *ngIf="endringslogg">
    <div class="card" *ngFor="let logglinje of endringsloggActive">
      <div class="card-body">

        <div class="row">
          <div class="col-6">
            Utført av: {{logglinje.endretAvNavn}}
          </div>
          <div class="col-6">
            Dato: {{logglinje.endretTid | date: "medium"}}
          </div>
        </div>
        <div class="row">
          <div class="col">
            Endringstype: {{logglinje.endringsType}}
          </div>
        </div>
        <div class="row">
          <div class="col">
            Beskrivelse: {{logglinje.beskrivelse}}
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="endringslogg.length !== endringsloggActive.length" class="d-flex justify-content-center my-4">
      <button type="button" (click)="loadMoreActiveElements()" class="btn btn-outline-primary btn-circle btn-xl">Vis
        Flere</button>
    </div>

  </div>
  <div *ngIf="!endringslogg && errorLoading">
    Feil ved lasting av endringslogg
  </div>
</div>
