import { Component } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Observable } from "rxjs";
import { FilterParams } from "../common/common.model";
import { ListesideBaseClass } from "../common/listeside/listeside.component";
import { OppdragService } from "../oppdrag/oppdrag.service";
import { TilgangService } from "../services/tilgang.service";


@Component({
  selector: "app-oppdragsliste",
  templateUrl: "./oppdragsliste.component.html"
})
export class OppdragslisteComponent extends ListesideBaseClass {

  overskrift = "Oversikt over oppdrag";

  constructor(
    private service: OppdragService,
    tilgangService: TilgangService,
    route: ActivatedRoute,
    router: Router
  ) { super(route, router, tilgangService); }


  onFilterChange(filterParams: FilterParams) : void {

    this.router.navigate(["/oppdrag"], {
      queryParams:
      {
        "beskrivelse": filterParams.tekst,
        "showExpired": filterParams.showExpired,
        "oppdragStatus": filterParams.oppdragStatus,
        "oppdragStatusSm": filterParams.oppdragStatusSm,
        "oppdragStatusLeveranse": filterParams.oppdragStatusLeveranse,
        "sortOrder": filterParams.order,
        "pageNumber": filterParams.pageNumber,
        "pageSize": filterParams.pageSize
      }
    });
  }

  search(params: FilterParams): Observable<any> {

    const beskrivelse = params.tekst as string;
    const showExpired = params.showExpired as boolean;
    const oppdragStatus = params.oppdragStatus as number;
    const oppdragStatusSm = params.oppdragStatusSm as number;
    const oppdragStatusLeveranse = params.oppdragStatusLeveranse as number;


    const sortOrder = params.order as string;
    const pageNumber = params.pageNumber as number;
    const pageSize = params.pageSize as number;

    return this.service.searchOppdrag(beskrivelse, sortOrder, showExpired, oppdragStatus, oppdragStatusSm, oppdragStatusLeveranse, pageNumber, pageSize);
  }

  urlParamsToFilterParams(params: Params, filterParams: FilterParams): FilterParams {

    filterParams = filterParams ? filterParams : new FilterParams();

    filterParams.oppdragStatus = params.oppdragStatus ? params.oppdragStatus : 0;
    filterParams.oppdragStatusSm = params.oppdragStatusSm ? params.oppdragStatusSm : 0;
    filterParams.oppdragStatusLeveranse = params.oppdragStatusLeveranse ? params.oppdragStatusLeveranse : 0;

    filterParams.tekst = params.beskrivelse ? params.beskrivelse : "";
    filterParams.showExpired = params.showExpired == "true";
    filterParams.order = params.sortOrder ? params.sortOrder : "nameAsc";
    filterParams.pageNumber = params.pageNumber ? params.pageNumber : 1;
    filterParams.pageSize = params.pageSize ? params.pageSize : 30;

    return filterParams;
  }

}


