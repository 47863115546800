<div *ngFor="let item of liste" class="list-group-flush">

    <a [routerLink]="['/itsystemer', item.systemId]"
        [ngClass]="erUtgaatt(item.system) ? 'list-group-item list-group-item-action flex-column align-items-start bg-light' : 'list-group-item list-group-item-action flex-column align-items-start' ">
        <h5 class="mb-1"> {{item.system.navn}}
            <span *ngIf="item.system.systemstatusId == 5" placement="top" ngbTooltip="Utgått">
                <i class="far fa-times-circle"></i>
            </span>
        </h5>
        <div class="text-muted">{{item.system.beskrivelse}}</div>
    </a>

</div>