<div>
  <div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
  </div>

  <app-spinner [loadingData]="isLoading" [loadingFailed]="errorLoading"></app-spinner>

  <div class="modal-body">

    <input class="form-control form-control-lg my-3" type="text" placeholder="Søk" [(ngModel)]="text"
      (keyup)="filterChanged()" name="tekstFilter">


    <div *ngIf="searchResult" class="list-group-flushed">
      <div (click)="confirm(item)" *ngFor="let item of searchResult.items"
        class="list-group-item list-group-item-action flex-column align-items-start">
        {{item.navn}} {{getSystemtype(item)}}

        <span *ngIf="isDeaktivert(item)" placement="top" ngbTooltip="Utgått">
          <i class="far fa-times-circle"></i>
        </span>

      </div>
    </div>

  </div>
  <div class="modal-footer">
    <a class="btn btn-outline-secondary" [ngClass]="{'disabled': !canCreate}" [routerLink]="[createLink()]"
      [queryParams]="{ref:currentUrlEncoded()}" (click)="cancel()" role="button">Ny</a>
    <button type="button" class="btn btn-outline-secondary" (click)="cancel()">Lukk</button>
  </div>
</div>
