import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { KoblingerService, PersonLeverandorRolle } from "../../koblinger/koblinger.service";
import { ModalService } from "../../services/modal.service";
import { TabSideBaseClass } from "../../common/tab-side/tab-side.component";
import { Observable } from "rxjs";
import { Tabs } from "../../common/tabs/tabs.enum";
import { TilgangService } from "../../services/tilgang.service";
import { TabsService } from "../../common/tabs/tabs.service";
import { map } from "rxjs/operators";



@Component({
  selector: "app-personleverandor-tab",
  templateUrl: "./personleverandor-tab.component.html"
})
export class PersonLeverandorTabComponent extends TabSideBaseClass<PersonLeverandorRolle> {
  

  constructor(tabsService: TabsService, service: KoblingerService, tilgangService: TilgangService, route: ActivatedRoute, modal: ModalService, router: Router) {
    super(tabsService, service, tilgangService, modal, route, router);
  }

  loaderObservable(service: KoblingerService, id: number): Observable<PersonLeverandorRolle[]> {
    return this.service.getPersonLeverandorRolleForPerson(this.id);
  }

  pageLinkAccess(service: TilgangService): Observable<boolean> {
    return service.getGlobalRoles().pipe(map(val => val.itsystemer.listAccess));
  }

  tabName(): Tabs {
    return Tabs.leverandorer;
  }

  kobleTil(): void {

    this.modal.velgLeverandor().then(leverandor => {
      this.modal.velgPersonRolle().then(rolle => {
        const psr: PersonLeverandorRolle = { personId: this.id, leverandorId: leverandor.id, rolleId: rolle.rolleId };
        this.service.postPersonLeverandorRolleForPerson(psr).toPromise().then(() => {
          this.modal.success("System koblet til person og rolle", "kobling vellykket");
          this.load();
        });
      });

    });
  }

}
