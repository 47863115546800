import { CommonModule, registerLocaleData } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import localeNo from "@angular/common/locales/nb";
import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService, ProtectedResourceScopes } from "@azure/msal-angular";
import { BrowserCacheLocation, InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { environment } from "../environments/environment";
import { AppConfigService } from "./app-config.service";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PaginatorComponent } from "./common/listeside/paginator/paginator.component";
import { So2CommonModule } from "./common/so2.common.module";
import { FakturaerFilterComponent } from "./fakturaer/fakturaer-filter/fakturaer-filter.component";
import { FakturaerComponent } from "./fakturaer/fakturaer.component";
import { FakturaDetaljerComponent } from "./fakturering/faktura-detaljer/faktura-detaljer.component";
import { HomeComponent } from "./home/home.component";
import { IntegrasjonComponent } from "./integrasjoner/integrasjon.component";
import { IntegrasjonerFilterComponent } from "./integrasjoner/integrasjoner-filter/integrasjoner-filter.component";
import { IntegrasjonerNyComponent } from "./integrasjoner/integrasjoner-ny/integrasjoner-ny.component";
import { SystemvelgerfeltComponent } from "./integrasjoner/integrasjoner-ny/systemvelgerfelt/systemvelgerfelt.component";
import { IntegrasjonerComponent } from "./integrasjoner/integrasjoner.component";
import { FaktureringTabComponent } from "./itsystem/endring-tab/endring-fakturering/fakturering-tab.component";
import { EndringNyComponent } from "./itsystem/endring-tab/endring-ny/endring-ny.component";
import { EndringUtforereComponent } from "./itsystem/endring-tab/endring-utforere/endring-utforere.component";
import { EndringComponent } from "./itsystem/endring-tab/endring.component";
import { EndringsloggComponent } from "./itsystem/endring-tab/endringslogg.component";
import { ItsystemComponent } from "./itsystem/itsystem.component";
import { SystemLeverandorTabComponent } from "./itsystem/itsystemleverandor-tab/system-leverandor-tab.component";
import { ItsystemMiljoTabComponent } from "./itsystem/itsystemmiljo-tab/itsystemmiljo-tab.component";
import { ItsystemOppdragTabComponent } from "./itsystem/itsystemoppdrag-tab/itsystemoppdrag-tab.component";
import { ItsystemPersonerTabComponent } from "./itsystem/itsystempersoner-tab/itsystempersoner-tab.component";
import { ItsystemSoneTabComponent } from "./itsystem/itsystemsone-tab/itsystemsone-tab.component";
import { ItsystemTjenesterTabComponent } from "./itsystem/itsystemtjenester-tab/itsystemtjenester-tab.component";
import { LeverandorRolleComponent } from "./itsystem/leverandor-rolle/leverandor-rolle.component";
import { SertifikatTabComponent } from "./itsystem/sertifikat-tab/sertifikat-tab.component";
import { SystemsystemTabComponent } from "./itsystem/systemsystem-tab/systemsystem-tab.component";
import { ItsystemerFilterComponent } from "./itsystemer/itsystemer-filter/itsystemer-filter.component";
import { ItsystemerNyComponent } from "./itsystemer/itsystemer-ny/itsystemer-ny.component";
import { ItsystemerComponent } from "./itsystemer/itsystemer.component";
import { ItsystemsearchresultComponent } from "./itsystemer/itsystemsearchresult.component";
import { LeverandorComponent } from "./leverandor/leverandor.component";
import { LeverandorOppdragTabComponent } from "./leverandor/leverandoroppdrag-tab/leverandoroppdrag-tab.component";
import { LeverandorPersonerTabComponent } from "./leverandor/leverandorpersoner-tab/leverandorpersoner-tab.component";
import { LeverandorSystemerTabComponent } from "./leverandor/leverandorsystemer-tab/leverandorsystemer-tab.component";
import { LeverandorerNyComponent } from "./leverandorer/leverandorer-ny/leverandorer-ny.component";
import { LeverandorerComponent } from "./leverandorer/leverandorer.component";
import { NavBarComponent } from "./nav-bar/nav-bar.component";
import { OppdragFakturalinjerDriftTabComponent } from "./oppdrag/oppdrag-fakturalinjer-drift-tab/oppdrag-fakturalinjer-drift-tab.component";
import { OppdragFilterComponent } from "./oppdrag/oppdrag-filter/oppdrag-filter.component";
import { OppdragNyComponent } from "./oppdrag/oppdrag-ny/oppdrag-ny.component";
import { OppdragComponent } from "./oppdrag/oppdrag.component";
import { OppdragLeverandorerTabComponent } from "./oppdrag/oppdragleverandorer-tab/oppdragleverandorer-tab.component";
import { OppdragPersonerTabComponent } from "./oppdrag/oppdragpersoner-tab/oppdragpersoner-tab.component";
import { OppdragslisteComponent } from "./oppdrag/oppdragsliste.component";
import { OppdragSystemerTabComponent } from "./oppdrag/oppdragsystemer-tab/oppdragsystemer-tab.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { PersonComponent } from "./person/person.component";
import { PersonLeverandorTabComponent } from "./person/personleverandor-tab/personleverandor-tab.component";
import { PersonOppdragTabComponent } from "./person/personsoppdrag-tab/personoppdrag-tab.component";
import { PersonSystemerTabComponent } from "./person/personsystemer-tab/personsystemer-tab.component";
import { PersonerComponent } from "./personer/personer.component";
import { RisikogjennomgangComponent } from "./risikogjennomgang/risikogjennomgang.component";
import { RisikogjennomgangerFilterComponent } from "./risikogjennomganger/risikogjennomganger-filter/risikogjennomganger-filter.component";
import { RisikogjennomgangerComponent } from "./risikogjennomganger/risikogjennomganger.component";
import { SertifikatSystemTabComponent } from "./sertifikater/sertifikat-system-tab/sertifikat-system-tab.component";
import { SertifikatComponent } from "./sertifikater/sertifikat.component";
import { SertifikaterFilterComponent } from "./sertifikater/sertifikater-filter/sertifikater-filter.component";
import { SertifikaterNyComponent } from "./sertifikater/sertifikater-ny/sertifikater-ny.component";
import { SertifikaterComponent } from "./sertifikater/sertifikater.component";
import { ErrorInterceptor } from "./services/error.interceptor";
import { RolleInterceptor } from "./services/rolle.interceptor";
import { SokComponent } from "./sok/sok.component";
import { TimeforingFilterComponent } from "./timeforing/timeforing-filter/timeforing-filter.component";
import { TimeforingComponent } from "./timeforing/timeforing.component";
import { TjenesteComponent } from "./tjeneste/tjeneste.component";
import { TjenesteItsystemTabComponent } from "./tjeneste/tjenesteitsystem-tab/tjenesteitsystem-tab.component";
import { TjenesterFilterComponent } from "./tjeneste/tjenester-filter/tjenester-filter.component";
import { TjenesterNyComponent } from "./tjeneste/tjenester-ny/tjenester-ny.component";
import { TjenesterComponent } from "./tjeneste/tjenester.component";
import { EmptypageComponent } from './test/emptypage/emptypage.component';
import { EndringsloggTabComponent } from './endringslogg-tab-component/endringslogg-tab.component';
import { IntegrasjonBegrepTabComponent } from './integrasjoner/integrasjon-begrep-tab/integrasjon-begrep-tab.component';
import { TestinformasjonComponent } from "./testinformasjon/testinformasjon.component";
import { TestinformasjonerComponent } from "./testinformasjoner/testinformasjoner.component";
import { TestinformasjonerFilterComponent } from "./testinformasjoner/testinformasjoner-filter/testinformasjoner-filter.component";
import { ItsystemBruksomradeTabComponent } from './itsystem/itsystem-bruksomrade/itsystem-bruksomrade-tab.component';
import { ItsystemBrukereTabComponent } from './itsystem/itsystem-brukere-tab/itsystem-brukere-tab.component';

registerLocaleData(localeNo);

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function initializeApp(appConfigService: AppConfigService) {
  return (): Promise<any> => {
    return appConfigService.load();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ItsystemerComponent,
    ItsystemComponent,
    ItsystemerFilterComponent,
    NavBarComponent,
    ItsystemerNyComponent,
    RisikogjennomgangComponent,
    ItsystemerNyComponent,
    ItsystemTjenesterTabComponent,
    FakturaDetaljerComponent,
    FaktureringTabComponent,
    OppdragComponent,
    OppdragSystemerTabComponent,
    OppdragLeverandorerTabComponent,
    OppdragPersonerTabComponent,
    OppdragslisteComponent,
    OppdragNyComponent,
    TestinformasjonComponent,
    SokComponent,
    EndringsloggComponent,
    ItsystemsearchresultComponent,
    PageNotFoundComponent,
    EndringComponent,
    RisikogjennomgangerComponent,
    TestinformasjonerComponent,
    LeverandorComponent,
    LeverandorerComponent,
    LeverandorRolleComponent,
    ItsystemOppdragTabComponent,
    LeverandorerNyComponent,
    EndringComponent,
    PersonerComponent,
    PersonComponent,
    PersonSystemerTabComponent,
    PersonOppdragTabComponent,
    PersonLeverandorTabComponent,
    ItsystemPersonerTabComponent,
    LeverandorPersonerTabComponent,
    LeverandorOppdragTabComponent,
    LeverandorSystemerTabComponent,
    SertifikatTabComponent,
    SertifikaterComponent,
    SertifikatComponent,
    TjenesterComponent,
    TjenesteComponent,
    IntegrasjonerComponent,
    IntegrasjonComponent,
    SertifikatSystemTabComponent,
    ItsystemTjenesterTabComponent,
    TjenesteItsystemTabComponent,
    ItsystemTjenesterTabComponent,
    SystemsystemTabComponent,
    OppdragFakturalinjerDriftTabComponent,
    ItsystemSoneTabComponent,
    ItsystemMiljoTabComponent,
    RisikogjennomgangerFilterComponent,
    TestinformasjonerFilterComponent,
    OppdragFilterComponent,
    PaginatorComponent,
    FakturaerComponent,
    FakturaerFilterComponent,
    SertifikaterNyComponent,
    TjenesterNyComponent,
    TjenesterFilterComponent,
    SertifikaterFilterComponent,
    IntegrasjonerNyComponent,
    SystemvelgerfeltComponent,
    IntegrasjonerFilterComponent,
    EndringNyComponent,
    EndringUtforereComponent,
    SystemLeverandorTabComponent,
    TimeforingComponent,
    TimeforingFilterComponent,
    EmptypageComponent,
    EndringsloggTabComponent,
    IntegrasjonBegrepTabComponent,
    ItsystemBruksomradeTabComponent,
    ItsystemBrukereTabComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    So2CommonModule,


    MsalModule.forRoot(new PublicClientApplication({ // MSAL Configuration
      auth: {
        clientId: environment.clientId,
        authority: `https://login.microsoftonline.com/${environment.tenantId}`,
        redirectUri: environment.redirectUrl,
        postLogoutRedirectUri: environment.postLogoutRedirectUrl,
        navigateToLoginRequestUrl: isIE,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE, // set to true for IE 11
      },
      system: {
        loggerOptions: {
          //loggerCallback: (logLevel, message, containsPii) => { console.log(`Msal : ${message}`) },
          piiLoggingEnabled: false
        }
      }
    }), {
      interactionType: InteractionType.Redirect, // MSAL Guard Configuration
      authRequest: {
        scopes: ["user.read", "openid", "profile", environment.scopeUri[0]]
      },
      loginFailedRoute: "/login-failed"
    }, {
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
      protectedResourceMap: new Map<string, Array<string | ProtectedResourceScopes> | null>([
        ["https://graph.microsoft.com/v1.0/me", ["user.read"]],
        [environment.redirectUrl, environment.scopeUri]
      ])
    }),

    NgbModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      countDuplicates: true
    })
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RolleInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
  },
  {
    provide: LOCALE_ID, useValue: "nb"
  },
    AppConfigService,
  { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfigService], multi: true }

  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }


