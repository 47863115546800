import {Component, OnInit} from "@angular/core";
import {AbstractControl, FormGroup, ValidationErrors} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {Observable} from "rxjs";
import {Field} from "../common/common.model";
import {countDirty} from "../global";
import {BackNavigationComponent} from "../services/backnavigation.guard";
import {NotificationService} from "../services/notification.service";
import {TilgangService} from "../services/tilgang.service";
import {RisikogjennomgangService, Sikkerhetsinfo} from "./risikogjennomgang.service";

@Component({
  selector: "app-risikogjennomgang",
  templateUrl: "./risikogjennomgang.component.html",
  styleUrls: ["./risikogjennomgang.component.scss"]
})
export class RisikogjennomgangComponent implements OnInit, BackNavigationComponent<Sikkerhetsinfo> {

  constructor(private risikogjennomgangService: RisikogjennomgangService, private tilgangService: TilgangService, private notifyService: NotificationService, private route: ActivatedRoute) {
  }


  isSaving = false;

  hasWriteAccess = false;

  systemId: number;

  risikogjennomgang: Sikkerhetsinfo;

  fields: Field[];

  col1: Field[];

  col2: Field[];

  col3: Field[];

  formGroup = new FormGroup({});


  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.systemId = +params["systemId"];


      this.risikogjennomgangService.getRisikogjennomgang(this.systemId).subscribe(r => {
        this.risikogjennomgang = r;
      });

      this.risikogjennomgangService.getRisikogjennomgangFields(this.systemId).subscribe(fields => {
        this.fields = fields;


        this.col1 = fields.filter(f => f.column === 1).sort((f1, f2) => f1.position - f2.position);
        this.col2 = fields.filter(f => f.column === 2).sort((f1, f2) => f1.position - f2.position);
        this.col3 = fields.filter(f => f.column === 3).sort((f1, f2) => f1.position - f2.position);

        /*         let navnField = fields.filter(f => f.key == 'navn')[0];
                navnField.validators = [Validators.required, Validators.minLength(2)];

                let urlField = fields.filter(f => f.key == 'url')[0];
                urlField.validators = [Validators.required, this.validateUrl]; */

      });
    });

  }

  sidenErEndret(): boolean {
    return this.formGroup.dirty;
  }

  antallEndringer(): number {
    return countDirty(this.formGroup);
  }

  saveChanges(): Observable<Sikkerhetsinfo> {
    return this.risikogjennomgangService.updateRisikogjennomgang(this.risikogjennomgang);
  }

  validateUrl(control: AbstractControl): ValidationErrors | null {
    const val = control.value;
    if (val != null) {
      if (!val.startsWith("http")) {
        return {validUrl: "Ugyldig url"};
      }
    }

    return null;
  }


  lagre(): void {
    console.log("Lagre");
    this.isSaving = true;

    this.risikogjennomgangService.updateRisikogjennomgang(this.risikogjennomgang).subscribe(resp => {
      this.isSaving = false;
      this.risikogjennomgang = resp;
      this.notifyService.success("Sikkerhetsinfo ble oppdatert", "Sikkerhetsinfo oppdatert");
      this.formGroup.markAsPristine();
    }, () => {
      this.isSaving = false;
    });
  }

  tilbakestill(): void {
    window.location.reload();
  }

}
