
<dl class="dl-horizontal">

  <dt class="col-sm-3">{{tekst}}</dt>
  <dd class="col-sm-9">{{modell[key]}} 
    <button *ngIf="permission && permission.w" type="button" class="btn btn-link" (click)="toggle()">
      <span class="btn-label"><small class="far fa-edit"></small></span><span class=""></span>    
    </button>   
  </dd>

</dl>

<div *ngIf="open">
  <div class="inline-form mx-2">              
    <div *ngIf="modell" class="my-1" >      
        <textarea id="{{key}}" class="form-control" rows="3" [formControl]="control"></textarea>           
    </div>
  </div>
</div>
