import {Component, OnInit} from "@angular/core";
import {NgForm} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "src/app/services/notification.service";
import {TilgangService} from "src/app/services/tilgang.service";
import {
  Itsystem,
  ItsystemCreateOptions,
  ItsystemerService,
  Prisberegning,
  Sikkerhetsinfo,
  Testinformasjon
} from "../itsystemer.service";

@Component({
  selector: "app-itsystemer-ny",
  templateUrl: "./itsystemer-ny.component.html",
  styleUrls: ["./itsystemer-ny.component.scss"]
})
export class ItsystemerNyComponent implements OnInit {

  private readonly defaultItsystem = {
    navn: "",
    beskrivelse: "",
    systemtypeId: 1,
    systemstatusId: 1,
    tilgjengelighetId: 1,
    kritikalitetId: 1,
    internettKritikalitetId: 4,
    klassifiseringId: 1,
    lisensId: 1,
    kundeId: undefined
  };

  isSaving = false;
  showHelpers = false;
  harTilgang = false;
  ref: string;


  itsystemCreateOptions: ItsystemCreateOptions;
  itsystem = {} as Itsystem
  sikkerhetsinfo = {} as Sikkerhetsinfo;
  prisberegning = {} as Prisberegning;
  testinformasjon = {} as Testinformasjon;

  constructor(
    private itsystemService: ItsystemerService,
    private tilgangService: TilgangService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService) {
  }

  ngOnInit(): void {

    this.tilgangService.getGlobalRoles().subscribe(g => {
      this.harTilgang = g.itsystemer.createAccess;
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.ref = params.ref as string;
    });

    this.itsystemService.getItsystemOptions().subscribe(
      itOptions => {
        this.itsystemCreateOptions = itOptions;
      },
      () => {
        console.log("Failed to fetch options, TODO : VIEW THIS TO THE USER");
      });
    Object.assign(this.itsystem, this.defaultItsystem);
    Object.assign(this.prisberegning, this.itsystemService.getDefaultPrisberegning());
    Object.assign(this.testinformasjon, this.itsystemService.getDefaultTestinformasjon());
    Object.assign(this.sikkerhetsinfo, this.itsystemService.getDefaultSikkerhetsinfo());

    this.itsystem.prisberegning = this.prisberegning;
    this.itsystem.testinformasjon = this.testinformasjon;
    this.itsystem.sikkerhetsinfo = this.sikkerhetsinfo;

    this.itsystem.prisberegning.systemId = this.itsystem.systemId;
  }

  resetItsystem(): void {
    this.itsystem = {} as Itsystem;
    Object.assign(this.itsystem, this.defaultItsystem);
  }

  onSubmit(form?: NgForm): void {
    this.createItsystem();
  }

  private createItsystem() {

    this.itsystem.systemtypeId = +this.itsystem.systemtypeId;
    this.itsystem.systemstatusId = +this.itsystem.systemstatusId;
    this.itsystem.kritikalitetId = +this.itsystem.kritikalitetId;
    this.itsystem.tilgjengelighetId = +this.itsystem.tilgjengelighetId;
    this.itsystem.kundeId = +this.itsystem.kundeId;

    this.itsystem.testinformasjon.tilgjengelighetId = this.itsystem.tilgjengelighetId;
    this.itsystem.testinformasjon.systemTypeId = this.itsystem.systemtypeId;
    this.itsystem.testinformasjon.systemStatusId = this.itsystem.systemstatusId;
    this.itsystem.testinformasjon.kundeId = this.itsystem.kundeId;

    const systemTyper = this.itsystemCreateOptions.systemtype.filter(stype => stype.systemtypeId == this.itsystem.systemtypeId);
    if (!systemTyper) {
      throw new Error("Selected SystemTypeId does not exist. This should not happen!");
    }

    this.isSaving = true;
    this.itsystemService.postItsystem(this.itsystem).subscribe(
      it => {
        this.isSaving = false;
        this.notificationService.success(`Nytt itsystem opprettet, system-id: ${this.itsystem.systemId}, navn: ${this.itsystem.navn}`, "Nytt system opprettet");
        if (this.ref) {
          this.router.navigate([decodeURIComponent(this.ref)], {queryParams: {refResults: encodeURIComponent(this.itsystem.navn)}})
        } else {
          this.router.navigate([`/itsystemer/${it.systemId}`]);
        }
      },
      () => {
        this.isSaving = false;
      });
  }
}
