import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Tabs } from "src/app/common/tabs/tabs.enum";
import { TabsService } from "src/app/common/tabs/tabs.service";
import { KoblingerService, OppdragLeverandor } from "src/app/koblinger/koblinger.service";
import { ModalService } from "src/app/services/modal.service";
import { TilgangService } from "src/app/services/tilgang.service";
import { TabSideBaseClass } from "../../common/tab-side/tab-side.component";


@Component({
  selector: "app-oppdragleverandorer-tab",
  templateUrl: "./oppdragleverandorer-tab.component.html"
})
export class OppdragLeverandorerTabComponent extends TabSideBaseClass<OppdragLeverandor> {

  tabName(): Tabs {
    return Tabs.leverandorer;
  }

  constructor(tabsService: TabsService, service: KoblingerService, route: ActivatedRoute, tilgangService: TilgangService, modal: ModalService, router: Router) {
    super(tabsService, service, tilgangService, modal, route, router);
  }

  loaderObservable(service: KoblingerService, id: number): Observable<OppdragLeverandor[]> {
    return service.getOppdragLeverandorRolleForOppdrag(id);
  }

  pageLinkAccess(service: TilgangService): Observable<boolean> {
    return service.getGlobalRoles().pipe(map(val => val.leverandorer.listAccess));
  }

  removeObservable(service: KoblingerService, item: OppdragLeverandor): Observable<object> {
    return service.removeOppdragLeverandorRolleForOppdrag(item.leverandorId, item.oppdragId, item.rolleId);
  }

  kobleTil(): void {
    this.modal.velgLeverandor().then(leverandor => {
      this.modal.velgLeverandorRolle().then(rolle => {

        if (this.liste.some(t => t.leverandorId === leverandor.id && t.rolleId === rolle.rolleId)) {
          this.modal.warning(`${leverandor.navn} med rolle ${rolle.navn} er allerede koblet til oppdrag`, "Kobling feilet");
          return;
        }

        const olr: OppdragLeverandor = { oppdragId: this.id, leverandorId: leverandor.id, rolleId: rolle.rolleId };
        this.service.postOppdragLeverandorRolleForOppdrag(olr).toPromise().then(() => {
          this.modal.success("Oppdrag, leverandør og rolle", "Kobling opprettet");
          this.load();
        }
        );
      });
    });
  }
}
