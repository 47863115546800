<div *ngIf="harTilgang">

  <h1>{{overskrift}}</h1>

  <app-soketekstfilter (onFilterChange)="onFilterChange($event)" [filterParams]="filterParams"></app-soketekstfilter>

  <app-testinformasjoner-filter (onFilterChange)="onFilterChange($event)" [filterParams]="filterParams">

  </app-testinformasjoner-filter>

  <app-paginator (onFilterChange)="onFilterChange($event)" [filterParams]="filterParams"></app-paginator>

  <app-spinner [loadingData]="loadingData"></app-spinner>

  <div *ngIf="searchResults">
    <p class="text-center">Antall treff ({{filterParams.numberOfHits}})</p>

    <div class="search-results">
      <div *ngFor="let testinformasjon of searchResults" class="list-group-flush">
        <a [routerLink]="['/testinformasjoner',testinformasjon.systemId]"
           class="list-group-item list-group-item-action flex-column align-items-start">
          <h5>{{testinformasjon.navn}}
            <span class="text-muted">({{testinformasjon.systemId}})</span>
            <i *ngIf="testinformasjon.hgutfort" class="fas fa-check float-right" data-toggle="tooltip"
               data-placement="bottom" title="HG Utført"></i>
          </h5>

          <div class="row">
            <div class="col-md-4">
              <p *ngIf="testinformasjon.systemKoodinator">Systemkoordinator: {{testinformasjon.systemKoodinator?.navn}}
              </p>
              <p *ngIf="testinformasjon.systemEier">Systemeier: {{ testinformasjon.systemEier?.navn}}</p>
              <p *ngIf="testinformasjon.testansvarlig">Testansvarlig: {{ testinformasjon.testansvarlig?.navn}}</p>
            </div>

            <div class="col-md-4">
              <p *ngIf="testinformasjon.leverandorNavn">Leverandør: {{ testinformasjon?.leverandorNavn }} </p>
              <p *ngIf="testinformasjon.tilgjengelighetNavn">
                Tilgjengelighet: {{testinformasjon.tilgjengelighetNavn}} </p>
            </div>

            <div class="col-md-4">
              <p *ngIf="testinformasjon.testetDato">Test
                oppdatert: {{testinformasjon.testetDato | date:'dd.MM.yyyy'}}</p>
              <p *ngIf="testinformasjon.testResultatNavn">Test status: {{testinformasjon.testResultatNavn}}
            </div>
          </div>

        </a>

      </div>
    </div>
  </div>
</div>

<app-ikketilgang [harTilgang]="harTilgang" [loadingData]="loadingData" [overskrift]="overskrift"></app-ikketilgang>
