<div *ngIf="modell && permission && permission.r">

  <div [ngSwitch]="inputType">

    <div class="form-group">

      <div *ngSwitchCase="'select'" class="inline-form mx-2">
        <label for="{{key}}" class="col-form-label"> {{tekst}}<span *ngIf="control.dirty">*</span> </label>
        <select id="{{key}}-select" [ngClass]="control.invalid ? 'custom-select is-invalid' : 'custom-select'"
          [formControl]="control">
          <option disabled>Velg {{tekst}}</option>
          <option *ngFor="let opt of options" [ngValue]="opt.id" [id]="opt.id" [label]="opt.navn"
            [selected]="opt.id===modell[key]">{{opt.navn}}</option>
        </select>
        <div *ngIf="!options" class="alert alert-danger">Option[] mangler for {{key}}</div>
        <small *ngIf="field?.validationChecks?.required && !modell[key]" id="maxLengthHelp"
          class="form-text text-danger float-right">Required</small>
      </div>


      <div *ngSwitchCase="'checkbox'" class="custom-control custom-checkbox mx-2">
        <input id="{{key}}" type="checkbox" class="custom-control-input" [formControl]="control">
        <label for="{{key}}" class="custom-control-label"> {{tekst}}<span *ngIf="control.dirty">*</span> </label>
      </div>


      <!-- REMOVE THIS? -->
      <div *ngSwitchCase="'textarea'">
        <div div *ngIf="modell && field && formGroup">

          <dl class="dl-horizontal">

            <dt class="col-sm-3">{{tekst}}</dt>
            <dd class="col-sm-9">{{modell[key]}}
              <button *ngIf="permission && permission.w" type="button" class="btn btn-link" (click)="toggle()">
                <span class="btn-label"><small class="fas fa-edit"></small></span><span class=""></span>
              </button>
            </dd>

          </dl>

          <div *ngIf="open">
            <div class="inline-form mx-2">
              <div *ngIf="modell" class="my-1">
                <textarea id="{{key}}" class="form-control" rows="3" [formControl]="control"></textarea>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div *ngSwitchDefault class="inline-form mx-2">
        <label for="{{key}}" class="col-form-label"> {{tekst}}<span *ngIf="control.dirty">*</span> </label>
        <div *ngIf="modell">

          <app-dynamic-tekst *ngIf="inputType=='text'" [id]="key" [text]="modell[key]" [fControl]="control"
            [validationChecks]="field.validationChecks ? field.validationChecks : null"></app-dynamic-tekst>


          <input name="number" *ngIf="inputType=='number'" id="{{key}}" type="number"
            [ngClass]=" control.invalid ? 'form-control is-invalid' : 'form-control'" [formControl]="control">


          <input *ngIf="inputType=='date'" id="{{key}}" type="date" (ngModelChange)="dateChanged($event)"
            [ngClass]=" control.invalid ? 'form-control is-invalid' : 'form-control'" [formControl]="control"
            placeholder="dd-mm-yyyy">


          <input *ngIf="inputType=='datetime'" id="{{key}}" type="datetime-local"
            [ngClass]=" control.invalid ? 'form-control is-invalid' : 'form-control'" [formControl]="control"
            placeholder="dd-mm-yyyy">


          <input *ngIf="inputType=='timeinterval'" id="{{key}}" type="text"
            [ngClass]=" control.invalid ? 'form-control is-invalid' : 'form-control'" [formControl]="control"
            [value]="konverterTidsintervall(formGroup.get(key).value)">


          <input *ngIf="inputType=='currency'" id="{{key}}" type="text"
            [ngClass]=" control.invalid ? 'form-control is-invalid' : 'form-control'" [formControl]="control"
            [value]="formGroup.get(key).value | currency: 'kr '">

          <small *ngIf="field?.validationChecks?.required && (!modell[key] && modell[key]!==0)" id="requiredHelp"
            class="form-text text-danger float-right">Required</small>

          <small *ngIf="field?.validationChecks?.maxLength && modell[key]" id="maxLengthHelp"
            [ngClass]=" modell[key].length > field.validationChecks.maxLength ? 'form-text text-danger' : 'form-text text-muted'">{{modell[key].length}}/{{field.validationChecks.maxLength}}</small>

        </div>
      </div>
    </div>

  </div>
</div>