import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Field, FieldPermission, Option } from "../common.model";


@Component({
  selector: "app-modellfelt",
  templateUrl: "./modellfelt.component.html",
  styleUrls: ["./felter.scss"],
  providers: [DatePipe]

})
export class ModellFeltComponent implements OnInit {

  tekst: string;

  key: string;

  inputType: string;

  permission: FieldPermission;

  options: Option[];

  @Input()
  modell: unknown;
  @Input()
  initialModell:unknown;
  @Input()
  field: Field;
  @Input()
  formGroup: FormGroup;


  control: FormControl;

  open = false;

  initialValue: any;

  ngOnInit(): void {

    this.tekst = this.field.text;
    this.key = this.field.key;
    this.inputType = this.field.inputType;
    this.permission = this.field.permission;
    this.options = this.field.options;

    this.initValidators();

    this.initialValue = this.modell[this.key];

    if (this.inputType === "date") {

      if (this.initialValue) {

        const datePart = this.initialValue.slice(0, 10);
        this.control = new FormControl(datePart, this.field.validators, this.field.asyncValidators);

      } else {
        this.control = new FormControl(null, this.field.validators, this.field.asyncValidators);
      }


    } else {
      this.control = new FormControl(this.initialValue, this.field.validators, this.field.asyncValidators);
    }



    this.formGroup.addControl(this.key, this.control);

    if (!this.permission.w) {
      this.control.disable();
    }



    this.control.valueChanges.subscribe(value => {

      this.modell[this.key] = value;

      if (this.initialModell && this.initialModell[this.key] == this.modell[this.key]) {
        this.control.markAsPristine();
        this.control.markAllAsTouched();
      }

    });

  }

  private initValidators() {

    if (!this.field.validators) {
      this.field.validators = [];
    }

    const validationCheck = this.field.validationChecks;

    if (validationCheck) {

      if (validationCheck.maxLength) {
        this.field.validators.push(Validators.maxLength(validationCheck.maxLength));
      }

      if (validationCheck.fixedLength) {
        this.field.validators = this.field.validators.concat([Validators.minLength(validationCheck.fixedLength), Validators.maxLength(validationCheck.fixedLength)]);
      }

      if (validationCheck.required) {
        this.field.validators.push(Validators.required);
      }

      if (validationCheck.columnType) {
        // TODO
      }
    }
  }

  dateChanged(date: Date): void {
    if (!date) {
      delete this.modell[this.key];
    }
  }

  toggle(): void {
    this.open = !this.open;
  }

  konverterTidsintervall(verdi: number): string {
    const tid = verdi / 36000000000;
    return tid + "t";
  }

}
