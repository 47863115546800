<app-spinner [loadingData]="isLoading" [loadingFailed]="errorLoading" [loadingText]="'Genererer Rapport'">
</app-spinner>
<div id="printable">

  <div class="modal-header">
    <h5 class="modal-title">Tjenesteavtalevedlegg</h5>
  </div>
  <div class="modal-body">


    <div *ngIf="fagsystem && tjenesteavtalevedlegg">

      <h5><u>{{fagsystem.navn}}</u></h5>
      <h5>{{fagsystem.tilgjengelighet.navn}}</h5>

      <p>Dette tjenesteavtalevedlegget er et vedlegg til;</p>
      <p>Styringsavtale for digitaliserings- og IKT-tjenester i Bergen Kommune (Bk360: SD-22-76)</p>
      <p>Styringsavtale for HR tjenester levert av Ansattservice i Bergen kommune (Bk360: SD-22-89)</p>

      <p>Det er inngått avtale mellom {{tjenesteavtalevedlegg.avdeling}} /v systemeier
        {{tjenesteavtalevedlegg.systemEier?.navn}} og Ansattservice.</p>

      <p>Systemkoordinator er {{tjenesteavtalevedlegg.systemKoordinator?.navn}}.</p>

      <p>Avtale inngått: {{fagsystem.avtaleIngatt | date: dateFormat}}</p>

      <p>Avtale endret: {{fagsystem.avtaleEndret | date: dateFormat}}</p>

      <p>Alle henvendelser vedr. avtalen rettes til Leveranse og virksomhetsstøtte via BkService:
        <a href="https://bkservice.bergen.kommune.no/app/submit-ticket">Ny henvendelse | BkService (bergen.kommune.no)</a>
      </p>

      <hr/>
      <p>System/Tjeneste : {{fagsystem.navn}}</p>
      <p>{{fagsystem.beskrivelse}}</p>
      <p>Systemleverandør : {{tjenesteavtalevedlegg.systemLeverandor}}</p>
      <hr/>
      <p><b>Avtalt leveranse</b></p>
      <p>
        Ansvar i henhold til styrende dokumenter, reglement for digitalisering og IKT i Bergen kommune (Bk360: SD-21-25):
        <a href="https://allmenningen.bergen.kommune.no/styringsdokument/SD-21-25">https://allmenningen.bergen.kommune.no/styringsdokument/SD-21-25</a>
      </p>
      <p>
        For beskrivelse av fagsystemdrift se allmenningen:
        <a href="https://allmenningen.bergen.kommune.no/ansatthjelpen/informasjonstjenester-og-ikt/systemer-tilganger-og-passord/drift-av-fagsystem">
          https://allmenningen.bergen.kommune.no/ansatthjelpen/informasjonstjenester-og-ikt/systemer-tilganger-og-passord/drift-av-fagsystem</a>
      </p>

      <div class="container">
        <div class="table-responsive">
          <table #table class="table" id="table">
            <thead>
            <tr>
              <th scope="col">Prisgrunnlag</th>
              <th scope="col">Stk. pris</th>
              <th scope="col">Antall</th>
              <th scope="col">Kommentar</th>
              <th class="text-right" scope="col">Sum</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="fagsystem.prisberegning.antallServere">
              <td>Servere</td>
              <td>{{prisKalkulator.getPris(prisGrunnlag, 'antallServere') | currency:'NOK'}}</td>
              <td>{{fagsystem.prisberegning.antallServere|number}}</td>
              <td><input type="text" [(ngModel)]="kommentarer[0]" class="form-control-plaintext"
                         value=""></td>
              <td class="text-right">
                {{prisKalkulator.getPris(prisGrunnlag, 'antallServere', fagsystem.prisberegning.antallServere) |
                currency:'NOK'}}
              </td>
            </tr>

            <tr *ngIf="fagsystem.prisberegning.antallMsSqlBaser">
              <td>Ms Sql-baser</td>
              <td>{{prisKalkulator.getPris(prisGrunnlag, 'antallMsSqlBaser') | currency:'NOK'}}</td>
              <td>{{fagsystem.prisberegning.antallMsSqlBaser|number}}</td>
              <td><input type="text" [(ngModel)]="kommentarer[1]" class="form-control-plaintext"
                         value=""></td>
              <td class="text-right">
                {{prisKalkulator.getPris(prisGrunnlag, 'antallMsSqlBaser', fagsystem.prisberegning.antallMsSqlBaser)
                | currency:'NOK'}}
              </td>
            </tr>

            <tr *ngIf="fagsystem.prisberegning.antallOracleBaser">
              <td>Oracle-baser</td>
              <td>{{prisKalkulator.getPris(prisGrunnlag, 'antallOracleBaser') | currency:'NOK'}}</td>
              <td>{{fagsystem.prisberegning.antallOracleBaser|number}}</td>
              <td><input type="text" [(ngModel)]="kommentarer[2]" class="form-control-plaintext"
                         value=""></td>
              <td class="text-right">
                {{prisKalkulator.getPris(prisGrunnlag, 'antallOracleBaser', fagsystem.prisberegning.antallOracleBaser)
                | currency:'NOK'}}
              </td>
            </tr>

            <tr *ngIf="fagsystem.prisberegning.antallMariaDbBaser">
              <td>MariaDb baser</td>
              <td>{{prisKalkulator.getPris(prisGrunnlag, 'antallMariaDbBaser') | currency:'NOK'}}</td>
              <td>{{fagsystem.prisberegning.antallMariaDbBaser|number}}</td>
              <td><input type="text" [(ngModel)]="kommentarer[3]" class="form-control-plaintext"
                         value=""></td>
              <td class="text-right">
                {{prisKalkulator.getPris(prisGrunnlag, 'antallMariaDbBaser', fagsystem.prisberegning.antallMariaDbBaser)
                | currency:'NOK'}}
              </td>
            </tr>

            <tr *ngIf="fagsystem.prisberegning.antallMongoDbBaser">
              <td>MongoDb baser</td>
              <td>{{prisKalkulator.getPris(prisGrunnlag, 'antallMongoDbBaser') | currency:'NOK'}}</td>
              <td>{{fagsystem.prisberegning.antallMongoDbBaser|number}}</td>
              <td><input type="text" [(ngModel)]="kommentarer[4]" class="form-control-plaintext"
                         value=""></td>
              <td class="text-right">
                {{prisKalkulator.getPris(prisGrunnlag, 'antallMongoDbBaser', fagsystem.prisberegning.antallMongoDbBaser)
                | currency:'NOK'}}
              </td>
            </tr>

            <tr *ngIf="fagsystem.prisberegning.antallPostgreSqlBaser">
              <td>PostgreSql baser</td>
              <td>{{prisKalkulator.getPris(prisGrunnlag, 'antallPostgreSqlBaser') | currency:'NOK'}}</td>
              <td>{{fagsystem.prisberegning.antallPostgreSqlBaser|number}}</td>
              <td><input type="text" [(ngModel)]="kommentarer[5]" class="form-control-plaintext"
                         value=""></td>
              <td class="text-right">
                {{prisKalkulator.getPris(prisGrunnlag, 'antallPostgreSqlBaser', fagsystem.prisberegning.antallPostgreSqlBaser)
                | currency:'NOK'}}
              </td>
            </tr>

            <tr>
              <td>Integrasjoner</td>
              <td></td>
              <td>{{prisIntegrasjon.antallIntegrasjoner|number}}</td>
              <td><input type="text" [(ngModel)]="kommentarer[6]" class="form-control-plaintext"
                         value=""></td>
              <td class="text-right">
                {{prisIntegrasjon.integrasjonSum| currency:'NOK'}}
              </td>
            </tr>

            <tr>
              <td>Tilgjengelighet</td>
              <td></td>
              <td></td>
              <td><input type="text" [(ngModel)]="kommentarer[7]" class="form-control-plaintext"
                         value=""></td>
              <td class="text-right">{{getPrisTilgjengelighet(fagsystem) |
                currency:'NOK'}}
              </td>
            </tr>

            <tr *ngIf="oppdateringer(fagsystem.tilgjengelighetId)">
              <td>Oppdateringer</td>
              <td></td>
              <td></td>
              <td><input type="text" [(ngModel)]="kommentarer[8]" class="form-control-plaintext"
                         value=""></td>
              <td class="text-right">{{prisKalkulator.getPris(prisGrunnlag, "Oppdateringer", 1) |
                currency:'NOK'}}
              </td>
            </tr>

            <tr *ngIf="vaktlag(fagsystem.tilgjengelighetId)">
              <td>Vaktlag nivå 3</td>
              <td></td>
              <td></td>
              <td><input type="text" [(ngModel)]="kommentarer[9]" class="form-control-plaintext"
                         value=""></td>
              <td class="text-right">{{prisKalkulator.getVaktlag(prisGrunnlag, fagsystem.tilgjengelighetId) |
                currency:'NOK'}}</td>
            </tr>

            <tr *ngIf="fagsystem.prisberegning.tilleggDriftspris">
              <td>Tillegg driftspris</td>
              <td></td>
              <td></td>
              <td><input type="text" [(ngModel)]="kommentarer[10]" class="form-control-plaintext"
                         value="{{fagsystem.prisberegning.tilleggKommentar}}"></td>
              <td class="text-right">{{fagsystem.prisberegning.tilleggDriftspris | currency:'NOK'}}</td>
            </tr>

            <tr>
              <td><b>Driftspris pr. år.</b></td>
              <td></td>
              <td></td>
              <td><input type="text" [(ngModel)]="kommentarer[11]" class="form-control-plaintext"
                         value=""></td>
              <td class="text-right"><b>{{driftspris() | currency:'NOK'}}</b></td>
            </tr>

            </tbody>
          </table>

        </div>
      </div>

      <div class="container">
        <div class="table-responsive">
          <table #table class="table" id="tableIntegrasjoner">
            <thead>
            <tr>
              <th scope="col">Integrasjon</th>
              <th scope="col">Overføringstype</th>
              <th scope="col">Pris</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let integrasjon of integrasjoner">
              <tr *ngIf="integrasjon.systemId1 == this.fagsystem.systemId && integrasjon.faktureres && integrasjon.aktiv">
                <td>{{integrasjon.system1Navn}} {{getRetning(integrasjon.retningId)}} {{integrasjon.system2Navn}}</td>
                <td>{{getOverforingsmetode(integrasjon.metodeId)}}</td>
                <td>{{getPrisForIntegrasjon(integrasjon.metodeId, integrasjon.faktureres) | currency:'NOK'}}</td>
              </tr>
            </ng-container>
            </tbody>
          </table>

        </div>
      </div>

      <p><b>Ekstra kommentar</b></p>
      <textarea type="text" [(ngModel)]="kommentarer[12]"  #ekstra class="form-control-plaintext" id="ekstra-kommentar" rows="3" value=""> </textarea>

      <p>Fakturering skjer månedlig, etterskuddsvis til følgende:</p>
      <p>Ansvarssted: {{fagsystem.ansvarssted}}</p>
      <p>Tjeneste: {{fagsystem.tjeneste}}</p>
      <p>Ressursnummer: {{fagsystem.ressursnr}}</p>
    </div>
  </div>

</div>

<div class="modal-footer">

  <button [disabled]="isLoading || generatingReport" type="button" class="btn btn-outline-secondary"
          (click)="exportWord()" placement="top" ngbTooltip="Eksporter til Word" [openDelay]="400" [closeDelay]="200">
    <i [ngClass]=" generatingReport ? 'fas fa-spinner fa-spin' : 'fas fa-file-word'" class="fas fa-file-word"></i>
  </button>

  <button type="button" class="btn btn-outline-secondary ml-4" (click)="cancel()" placement="top" ngbTooltip="Lukk"
          [openDelay]="400" [closeDelay]="200">Lukk
  </button>
</div>
