import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Endringslogg, EndringsloggService } from "../services/endringslogg.service";

@Component({
  selector: 'app-endringslogg-tab-component',
  templateUrl: './endringslogg-tab.component.html'
})
export class EndringsloggTabComponent implements OnInit {


  @Input()
  visibleListSize = 10;

  systemId: number;
  endringslogg: Endringslogg[];
  endringsloggActive: Endringslogg[];

  private numberOfVisibleElements = 0;

  isLoading = false;
  errorLoading = false;

  constructor(private route: ActivatedRoute, private endringsloggService: EndringsloggService) { }

  ngOnInit(): void {
    this.hentEndringslogg();
  }

  hentEndringslogg(): void {
    this.route.parent.url.subscribe(urlSegments => {
      urlSegments.forEach(segment => {
        if (segment.path.includes("oppdrag")) {
          this.hentOppdragEndringslogg();
        } else if (segment.path.includes("itsystemer")) {
          this.hentItsystemEndringslogg();
        } else if (segment.path.includes("personer")) {
          this.hentPersonEndringslogg();
        } else if (segment.path.includes("sertifikater")) {
          this.hentSertifikatEndringslogg();
        } else if (segment.path.includes("tjenester")) {
          this.hentTjenesteEndringslogg();
        } else if (segment.path.includes("leverandorer")) {
          this.hentLeverandorEndringslogg();
        }
      });

    });
  }

  loadMoreActiveElements(): void {
    this.numberOfVisibleElements += this.visibleListSize;
    this.endringsloggActive = this.endringslogg.slice(0, this.numberOfVisibleElements);
  }



  private loadEndringslogg(page: string, id: number): void {
    this.isLoading = true;
    this.endringsloggService.getEndringslogg(page, id).subscribe(endringslogg => {
      this.endringslogg = endringslogg;
      this.loadMoreActiveElements();
      this.isLoading = false;
    },
      () => this.isLoading = false);
  }

  private hentOppdragEndringslogg() {
    this.route.parent.params.subscribe(params => {
      const oppdragId = parseInt(params["oppdragId"]);
      this.loadEndringslogg("oppdrag", oppdragId);
    });
  }

  private hentPersonEndringslogg() {
    this.route.parent.params.subscribe(params => {
      const personId = parseInt(params["personId"]);
      this.loadEndringslogg("person", personId);
    });
  }

  private hentSertifikatEndringslogg() {
    this.route.parent.params.subscribe(params => {
      const sertifikatId = parseInt(params["sertifikatId"]);
      this.loadEndringslogg("sertifikat", sertifikatId);
    });
  }

  private hentTjenesteEndringslogg() {
    this.route.parent.params.subscribe(params => {
      const tjenesteId = parseInt(params["tjenesteId"]);
      this.loadEndringslogg("tjeneste", tjenesteId);
    });
  }

  private hentLeverandorEndringslogg() {
    this.route.parent.params.subscribe(params => {
      const leverandorId = parseInt(params["leverandorId"]);
      this.loadEndringslogg("leverandor", leverandorId);
    });
  }

  private hentItsystemEndringslogg() {
    this.route.parent.params.subscribe(params => {
      const systemId = parseInt(params["systemId"]);
      this.loadEndringslogg("itsystem", systemId);
    });
  }
}
