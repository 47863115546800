import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { Tabs } from "src/app/common/tabs/tabs.enum";
import { TabsService } from "src/app/common/tabs/tabs.service";
import { ModalService } from "src/app/services/modal.service";
import { TilgangService } from "src/app/services/tilgang.service";
import { TabSideBaseClass } from "../../common/tab-side/tab-side.component";
import { KoblingerService, MiljoSystem } from "../../koblinger/koblinger.service";


@Component({
  selector: "app-itsystemmiljo-tab",
  templateUrl: "./itsystemmiljo-tab.component.html"

})
export class ItsystemMiljoTabComponent extends TabSideBaseClass<MiljoSystem> {
  
  tabName(): Tabs {
    return Tabs.miljo;
  }

  constructor(tabsService : TabsService, service: KoblingerService, tilgangService: TilgangService, modal: ModalService, route: ActivatedRoute, router: Router) {
    super(tabsService, service, tilgangService, modal, route, router);
  }

  loaderObservable(service: KoblingerService, id: number): Observable<MiljoSystem[]> {
    return this.service.getMiljoSystemForSystem(id);
  }

  pageLinkAccess(service: TilgangService): Observable<boolean> {
    return of(true);
  }

  removeObservable(service: KoblingerService, item: MiljoSystem): Observable<object> {
    return service.removeMiljoSystemForSystem(item.miljoId, item.systemId);
  }

  kobleTil(): void {
    this.modal.velgMiljo().then(miljo => {

      if (this.liste.some(t => t.miljoId === miljo.miljoId)) {
        this.modal.warning(`${miljo.navn} er allerede koblet til system`, "Kobling feilet");
        return;
      }

      const item: MiljoSystem = { systemId: this.id, miljoId: miljo.miljoId };


      this.service.postMiljoSystemForSystem(item).toPromise().then(() => {
        this.modal.success("", "Miljø koblet til system");
        this.load();
      });
    });
  }
}
