import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Observable } from "rxjs";

@Component({
  selector: "app-modal-component",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"]
})
export class ModalComponent {

  @Input() title: string;
  @Input() content: string;
  @Input() danger: boolean;
  @Input() changes:number;
  @Input() saveObservable?: Observable<any>;
  @Output() onConfirm = new EventEmitter<boolean>();


  isLoading = false;

  cancel(): void {
    this.onConfirm.emit(false);
  }

  saveAndExit(): void {
    if (this.saveObservable) {
      this.isLoading = true;
      this.saveObservable.subscribe(() => {
        this.isLoading = false;
        this.confirm();
      }, () => this.isLoading = false)
    } else {
      this.confirm();
    }
  }

  exit(): void {
    this.confirm();
  }

  private confirm(): void {
    this.onConfirm.emit(true);
  }
}
