<div *ngIf="item && fields">


  <h1 class="h1 mb-5">Integrasjon mellom {{item.system1Navn}} og {{item.system2Navn}}</h1>

  <app-lagreknapp [formGroup]="formGroup" [isSaving]="isSaving" (onLagre)="lagre()"></app-lagreknapp>
  <app-lagreknapp *ngIf="getSwapSystem1And2Button()" [formGroup]="formGroup" [isSaving]="isSaving"
                  [showResetButton]="false" [enabled]="true" (onLagre)="swapSystem1AndSystem2()"
                  [buttonTitle]="getSwapSystem1And2Button().text"></app-lagreknapp>

  <div class="col-12">
    <div *ngIf="item" class="list-group list-group-horizontal">
      <a *ngIf="item.systemId1" [routerLink]="['/itsystemer', item.systemId1]"
         class="list-group-item list-group-item-action flex-column align-items-start">
        <h5 class="mb-1"> {{item.systemId1Navigation.navn}} </h5>
        <div class="text-muted">{{item.systemId1Navigation.beskrivelse}}</div>
      </a>

      <a *ngIf="item.systemId2" [routerLink]="['/itsystemer', item.systemId2]"
         class="list-group-item list-group-item-action flex-column align-items-start">
        <h5 class="mb-1"> {{item.systemId2Navigation.navn}} </h5>
        <div class="text-muted">{{item.systemId2Navigation.beskrivelse}}</div>
      </a>
    </div>
  </div>

  <app-feltkolonner [formGroup]="formGroup" [textAreaCol]="textAreaCol" [col1]="col1" [col2]="col2"
                    [checkboxCol]="checkboxCol" [model]="item"
                    (change)="faktureresCheckedLogic($event)"></app-feltkolonner>
  <app-tabs [tabs]="tabs"></app-tabs>
</div>
