<div *ngIf="item && fields">


  <h1 class="h1 mb-5">{{item.navn}}</h1>

  <app-lagreknapp [formGroup]="formGroup" (onLagre)="lagre()" [isSaving]="isSaving" ></app-lagreknapp>


  <app-feltkolonner [formGroup]="formGroup" [textAreaCol]="textAreaCol" [col1]="col1" [col2]="col2" [checkboxCol]="checkboxCol" [model]="item"></app-feltkolonner>


<app-tabs #tab [tabs]="tabs" ></app-tabs>

</div>
