import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TabCreateDeleteAccess, TilgangService } from "src/app/services/tilgang.service";
import { ModalService } from "../../services/modal.service";
import { EndringNyComponent } from "./endring-ny/endring-ny.component";
import { Endring, EndringerService } from "./endringer.service";


@Component({
  selector: "app-endringslogg",
  templateUrl: "./endringslogg.component.html"
})
export class EndringsloggComponent implements OnInit {

  @Input()
  visibleListSize = 10;

  systemId: number;
  endringer: Endring[];
  activeEndringer: Endring[];

  private numberOfVisibleElements = 0;

  isLoading = false;
  loadingFailed = false;

  createDeleteAccess: TabCreateDeleteAccess;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly endringerService: EndringerService,
    private readonly tilgangService: TilgangService,
    private readonly modal: ModalService) { }

  ngOnInit(): void {
    this.route.parent.params.subscribe(params => {
      this.systemId = +params["systemId"];

      this.tilgangService.getTabCreateDeleteAccess("itsystemer", this.systemId).subscribe(access => {
        this.createDeleteAccess = access.find(ac => ac.page == "endringer");
      });

      this.load();
    });
  }

  load(): void {
    this.isLoading = true;
    this.endringerService.getEndringer(this.systemId).subscribe(endringer => {
      this.endringer = endringer;
      this.sortEndringer(this.endringer);
      this.loadMoreActiveElements();
      this.isLoading = false;
    }, () => { this.loadingFailed = true; });
  }

  sortEndringer(endringer: Endring[]): void {
    this.endringer.sort((a, b) => {
      return a.datoUtfort < b.datoUtfort ? 1 : -1;
    });
  }

  nyEndring(): NgbModalRef {
    const modalRef = this.modal.visModal(EndringNyComponent);
    const modalComponent = modalRef.componentInstance as EndringNyComponent;
    modalComponent.systemId = this.systemId;
    modalComponent.savingComplete.subscribe(() => { this.load(); });
    return modalRef;
  }

  loadMoreActiveElements(): void {
    this.numberOfVisibleElements += this.visibleListSize;
    this.activeEndringer = this.endringer.slice(0, this.numberOfVisibleElements);
  }
}
