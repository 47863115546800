import { Component } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Observable } from "rxjs";
import { FilterParams } from "../common/common.model";
import { ListesideBaseClass } from "../common/listeside/listeside.component";
import { TilgangService } from "../services/tilgang.service";
import { IntegrasjonerService } from "./integrasjoner.service";



@Component({
  selector: "app-integrasjoner",
  templateUrl: "./integrasjoner.component.html"
})
export class IntegrasjonerComponent extends ListesideBaseClass {


  overskrift = "Oversikt over Integrasjoner";

  constructor(
    private service: IntegrasjonerService,
    tilgangService: TilgangService,
    route: ActivatedRoute,
    router: Router
  ) { super(route, router, tilgangService); }

  search(params: FilterParams): Observable<any> {

    const name = params.tekst as string;
    const sortOrder = params.order as string;

    const inactive = params.inactive as boolean;
    const overforingsMetode = params.overforingsMetode as number;
    const personopplysningsniva = params.personopplysningsniva as number;

    const pageNumber = params.pageNumber as number;
    const pageSize = params.pageSize as number;

    return this.service.search(name, sortOrder, overforingsMetode, personopplysningsniva, inactive, pageNumber, pageSize);
  }

  urlParamsToFilterParams(params: Params, filterParams: FilterParams): FilterParams {

    filterParams = filterParams ? filterParams : new FilterParams();

    filterParams.tekst = params.s ? params.s : "";
    filterParams.order = params.sortOrder ? params.sortOrder : "nameAsc";

    filterParams.overforingsMetode = params.overforingsMetode ? params.overforingsMetode : 0;
    filterParams.personopplysningsniva = params.personopplysningsniva ? params.personopplysningsniva : 0;
    filterParams.inactive = params.inactive == "true";

    filterParams.pageNumber = params.pageNumber ? params.pageNumber : 1;
    filterParams.pageSize = params.pageSize ? params.pageSize : 30;

    return filterParams;
  }

  onFilterChange(filterParams: FilterParams): void {

    this.router.navigate(["/integrasjoner"], {
      queryParams:
      {
        "s": filterParams.tekst,
        "sortOrder": filterParams.order,
        "overforingsMetode": filterParams.overforingsMetode,
        "personopplysningsniva": filterParams.personopplysningsniva,
        "inactive": filterParams.inactive,
        "pageNumber": filterParams.pageNumber,
        "pageSize": filterParams.pageSize
      }
    });
  }



}
