export enum Tabs {
    systemer = "systemer",
    endringer = "endringer",
    fakturering = "fakturering",
    fakturalinjerDrift = "fakturalinjerDrift",
    oppdrag = "oppdrag",
    personer = "personer",
    tjenester = "tjenester",
    integrasjoner = "integrasjoner",
    servere = "servere",
    sone = "sone",
    miljo = "miljo",
    sertifikat = "sertifikat",
    leverandorer = "leverandorer",
    endringslogg = "endringslogg",
    begrep = "begrep",
    bruksomrade = "bruksomrade",
    brukere = "brukere"
}
