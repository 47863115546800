<div xmlns="http://www.w3.org/1999/html">

  <app-spinner [loadingData]="isLoading" [loadingFailed]="errorLoading"></app-spinner>

  <button type='button' class='btn btn-outline-primary mb-4' (click)="nyFakturalinje()"><i class="fas fa-plus"></i> Legg
    til fakturalinje</button>

  <div *ngIf="fakturalinjer">
    <div *ngFor="let fakturalinje of activeFakturalinjer" class="card">
      <div class="card-body">

        <div class="row">
          <div class="col-9">
            <div class="row">
              <div class="col">
                Utført av: {{fakturalinje.personNavn}}
              </div>
            </div>
            <div class="row">
              <div class="col">
                Fra: {{fakturalinje.fra | date:'medium'}}
              </div>
              <div class="col">
                Til: {{fakturalinje.til | date:'medium'}}
              </div>
            </div>

            <div class="row">
              <div class="col">
                Beskrivelse:
              </div>
            </div>
            <div class="row">
              <div class="col">
                {{fakturalinje.beskrivelse}}
              </div>
            </div>
            <div class="row">
              <div class="col">
                Normaltid: {{fakturalinje.normaltid / 36000000000}}t. <span *ngIf="!fakturalinje.fastBelop">Pris:
                  {{fakturalinje.sumNormaltid | currency: 'kr '}}</span>
              </div>
              <div class="col">
                Overtid: {{fakturalinje.overtid / 36000000000}}t. <span *ngIf="!fakturalinje.fastBelop">Pris:
                  {{fakturalinje.sumOvertid | currency: 'kr '}}</span>
              </div>
            </div>
            <div class="row" *ngIf="fakturalinje.fastBelop">
              <div class="col">
                Fast beløp: {{fakturalinje.fastBelop | currency: 'kr '}}
              </div>
            </div>
            <div class="row">
              <div class="col">
                Sertifikat: {{fakturalinje.sertifikat ? 'Ja' : 'Nei'}}
              </div>
            </div>
            <div class="row">
              <div class="col">
                Godkjent: {{fakturalinje.godkjent ? 'Ja' : 'Nei'}}
              </div>
              <div class="col" *ngIf="fakturalinje.godkjent">
                Godkjent av: {{fakturalinje.godkjentAvNavn}} dato:
                {{fakturalinje.godkjentDato | date:'medium'}}
              </div>
            </div>
            <div class="row">
              <div class="col">
                Fakturert: {{fakturalinje.fakturert ? 'Ja' : 'Nei'}}
              </div>
              <div class="col" *ngIf="fakturalinje.fakturert">
                Fakturert dato: {{fakturalinje.fakturertDato | date:'medium'}}
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col m-2">
                <button class="btn btn-outline-primary" *ngIf="!(fakturalinje.fakturert || fakturalinje.godkjent) && kanRedigere"
                  (click)="redigerFakturalinje(fakturalinje)">Rediger</button>
              </div>
            </div>
            <div class="row">
              <div class="col m-2">
                <button class="btn btn-outline-primary" *ngIf="!fakturalinje.godkjent && kanGodkjenneFaktura"
                  (click)="godkjennFakturalinje(fakturalinje.fakId)">Godkjenn</button>
              </div>
            </div>

            <div class="row">
              <div class="col m-2">
                <button class="btn btn-outline-primary" *ngIf="!fakturalinje.fakturert && kanMerkeFakturert"
                  (click)="merkFakturaFakturert(fakturalinje.fakId)">Merk fakturert</button>
              </div>
            </div>



            <div class="row">
              <div class="col m-2">
                <button *ngIf="!(fakturalinje.godkjent || fakturalinje.fakturert) && kanSlette"  class="btn btn-outline-secondary"
                  placement="top" ngbTooltip="Slett faktura" [openDelay]="400" [closeDelay]="200"
                  (click)="slettFakturalinje(fakturalinje.fakId)">

                  <i *ngIf="fakturalinje.fakId != deleteId" class="fas fa-trash"></i>
                  <i *ngIf="isLoading && fakturalinje.fakId == deleteId" class="fas fa-spinner fa-spin"></i>

                </button>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>

    <div *ngIf="fakturalinjer.length !== activeFakturalinjer.length" class="d-flex justify-content-center my-4">
      <button type="button" (click)="loadMoreActiveElements()" class="btn btn-outline-primary btn-circle btn-xl">Vis
        Flere</button>
    </div>

  </div>



</div>
