import { Injectable } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { Rolle, SearchItem } from "../common/common.model";
import { LeverandorRolleVelgerComponent, PersonRolleVelgerComponent, RolleVelgerComponent, RolleViserComponent } from "../common/modal/liste-velger/liste-velger.component";
import { ModalComponent } from "../common/modal/modal.component";
import {
  BruksomradeVelgerComponent,
  BegrepVelgerComponent,
  ItsystemVelgerComponent,
  LeverandorVelgerComponent,
  MiljoVelgerComponent,
  OppdragVelgerComponent,
  PersonVelgerComponent,
  SertifikatVelgerComponent,
  SoneVelgerComponent,
  BrukerVelgerComponent
} from "../common/modal/soke-velger/sokevelger.component";
import { Itsystem } from "../itsystemer/itsystemer.service";
import { BruksomradeSystem, Begrep, Miljo, Sone, Resultatenhet } from "../koblinger/koblinger.service";
import { NotificationService } from "./notification.service";
import { Person, SystemRolle } from "./person.service";


@Injectable({
  providedIn: "root"
})
export class ModalService {

  constructor(
    private modalService: NgbModal,
    private notificationService: NotificationService) { }

  showConfirmation(title: string, text: string, danger: boolean, numberOfChanges = 0, saveObservable: Observable<any> = null): Promise<boolean> {
    const modalRef = this.modalService.open(ModalComponent, { animation: false, centered: true });
    const modalComponent = modalRef.componentInstance as ModalComponent;
    modalComponent.title = title;
    modalComponent.content = text;
    modalComponent.danger = danger;
    modalComponent.saveObservable = saveObservable;
    modalComponent.changes = numberOfChanges;


    modalComponent.onConfirm.subscribe(bekreft => {
      if (bekreft) {
        modalRef.close(true);
      } else {
        modalRef.close(false);
      }
    }, error => {
      console.log(error);
      modalRef.close(false)
    });

    return modalRef.result;
  }


  velgLeverandor(): Promise<SearchItem> {

    const modalRef = this.modalService.open(LeverandorVelgerComponent);
    const modalComponent = modalRef.componentInstance as LeverandorVelgerComponent;

    modalComponent.onConfirm.subscribe(leverandorEllerFalse => {

      if (leverandorEllerFalse) {
        modalRef.close(leverandorEllerFalse);
      } else {
        modalRef.dismiss();
      }
    });

    return modalRef.result;
  }

  velgOppdrag(): Promise<SearchItem> {

    const modalRef = this.modalService.open(OppdragVelgerComponent);
    const modalComponent = modalRef.componentInstance as OppdragVelgerComponent;

    modalComponent.onConfirm.subscribe(selected => {

      if (selected) {
        modalRef.close(selected);
      } else {
        modalRef.dismiss();
      }
    });

    return modalRef.result;
  }

  velgMiljo(): Promise<Miljo> {

    const modalRef = this.modalService.open(MiljoVelgerComponent);
    const modalComponent = modalRef.componentInstance as MiljoVelgerComponent;

    modalComponent.onConfirm.subscribe(selected => {

      if (selected) {
        modalRef.close(selected);
      } else {
        modalRef.dismiss();
      }
    });

    return modalRef.result;
  }


  velgSone(): Promise<Sone> {

    const modalRef = this.modalService.open(SoneVelgerComponent);
    const modalComponent = modalRef.componentInstance as SoneVelgerComponent;

    modalComponent.onConfirm.subscribe(selected => {

      if (selected) {
        modalRef.close(selected);
      } else {
        modalRef.dismiss();
      }
    });

    return modalRef.result;
  }

  velgPerson(): Promise<Person> {

    const modalRef = this.modalService.open(PersonVelgerComponent);
    const modalComponent = modalRef.componentInstance as PersonVelgerComponent;

    modalComponent.onConfirm.subscribe(personEllerFalse => {

      if (personEllerFalse) {
        modalRef.close(personEllerFalse);
      } else {
        modalRef.dismiss();
      }
    });

    return modalRef.result;
  }

  velgSertifikat(): Promise<SearchItem> {

    const modalRef = this.modalService.open(SertifikatVelgerComponent);
    const modalComponent = modalRef.componentInstance as SertifikatVelgerComponent;

    modalComponent.onConfirm.subscribe(sertifikatrEllerFalse => {

      if (sertifikatrEllerFalse) {
        modalRef.close(sertifikatrEllerFalse);
      } else {
        modalRef.dismiss();
      }
    });

    return modalRef.result;
  }

  velgBegrep(): Promise<Begrep> {

    const modalRef = this.modalService.open(BegrepVelgerComponent);
    const modalComponent = modalRef.componentInstance as BegrepVelgerComponent;

    modalComponent.onConfirm.subscribe(begrepEllerFalse => {

      if (begrepEllerFalse) {
        modalRef.close(begrepEllerFalse);
      } else {
        modalRef.dismiss();
      }
    });

    return modalRef.result;
  }

  velgSystem(fakturatype?: string): Promise<Itsystem> {

    const modalRef = this.modalService.open(ItsystemVelgerComponent);
    const modalComponent = modalRef.componentInstance as ItsystemVelgerComponent;

    if (fakturatype) {
      modalComponent.systemtype = fakturatype;
      modalComponent.filterChanged();
    }

    modalComponent.onConfirm.subscribe(selected => {

      if (selected) {
        modalRef.close(selected);
      } else {
        modalRef.dismiss();
      }
    });

    return modalRef.result;
  }


  velgLeverandorRolle(): Promise<Rolle> {
    const modalRef = this.modalService.open(LeverandorRolleVelgerComponent);
    const modalComponent = modalRef.componentInstance as LeverandorRolleVelgerComponent;
    modalComponent.onConfirm.subscribe(rolleEllerFalse => {

      if (rolleEllerFalse) {
        modalRef.close(rolleEllerFalse);
      } else {
        modalRef.dismiss();
      }
    });

    return modalRef.result;

  }

  velgPersonRolle(): Promise<Rolle> {
    const modalRef = this.modalService.open(PersonRolleVelgerComponent);
    const modalComponent = modalRef.componentInstance as PersonRolleVelgerComponent;

    modalComponent.onConfirm.subscribe(rolleEllerFalse => {

      if (rolleEllerFalse) {
        modalRef.close(rolleEllerFalse);
      } else {
        modalRef.dismiss();
      }
    });

    return modalRef.result;
  }

  velgBruker():Promise<Resultatenhet> {
    const modalRef = this.modalService.open(BrukerVelgerComponent);
    const modalComponent = modalRef.componentInstance as BrukerVelgerComponent;

    modalComponent.onConfirm.subscribe(rolleEllerFalse => {

      if (rolleEllerFalse) {
        modalRef.close(rolleEllerFalse);
      } else {
        modalRef.dismiss();
      }
    });

    return modalRef.result;
  }

  velgBruksomrade(): Promise<BruksomradeSystem> {
    const modalRef = this.modalService.open(BruksomradeVelgerComponent);
    const modalComponent = modalRef.componentInstance as BruksomradeVelgerComponent;

    modalComponent.onConfirm.subscribe(rolleEllerFalse => {

      if (rolleEllerFalse) {
        modalRef.close(rolleEllerFalse);
      } else {
        modalRef.dismiss();
      }
    });

    return modalRef.result;

  }

  velgRolle(roller: string[]): Promise<string> {
    const modalRef = this.modalService.open(RolleVelgerComponent);
    const modalComponent = modalRef.componentInstance as RolleVelgerComponent;
    modalComponent.roller = roller;

    modalComponent.onConfirm.subscribe(rolleEllerFalse => {

      if (rolleEllerFalse) {
        modalRef.close(rolleEllerFalse);
      } else {
        modalRef.dismiss();
      }
    });

    return modalRef.result;

  }

  visRolle(roller: SystemRolle[], brukernavn: string): void {
    const modalRef = this.modalService.open(RolleViserComponent);
    const modalComponent = modalRef.componentInstance as RolleViserComponent;
    modalComponent.roller = roller;
    modalComponent.brukernavn = brukernavn;

    modalComponent.onConfirm.subscribe(ok => {
      modalRef.dismiss(ok);
    });
  }


  visModal(component: any): NgbModalRef {
    return this.modalService.open(component, {
      centered: true,
      size: "xl"
    });
  }

  success(melding: string, overskrift: string): void {
    this.notificationService.success(melding, overskrift);
  }
  warning(melding: string, overskrift: string): void {
    this.notificationService.warning(melding, overskrift);
  }

}
