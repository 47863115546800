import { Component, OnInit } from "@angular/core";
import { FritekstfeltComponent } from "./fritekstfelt.component";

@Component({
  selector: "app-editbutton",
  templateUrl: "./editbutton.component.html",
  styleUrls: ["./felter.scss"],
})
export class EditbuttonComponent extends FritekstfeltComponent implements OnInit {


  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }


}
