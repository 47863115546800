import { Component, OnInit } from "@angular/core";
import { SearchResults, SearchItem } from "../common/common.model";
import { GlobalPageAccess, TilgangService } from "../services/tilgang.service";
import { DataForPerson, SokService } from "./sok.service";

@Component({
  selector: "app-sok",
  templateUrl: "./sok.component.html"
})
export class SokComponent implements OnInit {

  results: SearchResults<SearchItem>;

  tekst = "";
  loadingData = false;

  private searchObservable: any = null;

  dataForPerson: DataForPerson;
  pageAccess: GlobalPageAccess;

  constructor(private readonly service: SokService, private readonly tilgangService: TilgangService) { }

  ngOnInit(): void {
    this.service.profil().subscribe(dataForPerson => {
      this.dataForPerson = dataForPerson;
    });
    this.tilgangService.getGlobalRoles().subscribe(roles => {
      this.pageAccess = roles;
    });
  }

  filterChanged(): void {
    if (this.searchObservable) {
      this.searchObservable.unsubscribe();
    }

    if (this.tekst == null || this.tekst.length < 2) {
      this.results = null;
      this.loadingData = false;
    } else {
      this.loadingData = true;
      this.results = null;
      this.searchObservable = this.service.search(this.tekst).subscribe(results => {
        this.searchObservable = null;
        this.results = results;
        this.loadingData = false;
      }, () => {
        this.searchObservable = null;
        this.loadingData = false;
      });
    }
  }

}
