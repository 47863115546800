import {HttpClient, HttpParams} from "@angular/common/http";
import {Inject, Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Field, SearchItem, SearchResults} from "src/app/common/common.model";
import {Itsystem} from "../itsystemer/itsystemer.service";
import {OppdragPerson} from "../koblinger/koblinger.service";


@Injectable({
  providedIn: "root"
})
export class OppdragService {

  private readonly basePath = "api/oppdrag/";
  private readonly baseUrl: string;

  constructor(private http: HttpClient, @Inject("BASE_URL") private baseHost: string) {
    this.baseUrl = baseHost + this.basePath;
  }

  searchOppdrag(
    beskrivelse?: string,
    sortOrder?: string,
    showExpired = true,
    oppdragStatus = 0,
    oppdragStatusSm = 0,
    oppdragStatusLeveranse = 0,
    pageNumber = 1,
    pageSize = 30): Observable<SearchResults<SearchItem>> {

    let searchParams = new HttpParams();

    if (beskrivelse) {
      searchParams = searchParams.append("beskrivelse", beskrivelse);
    }
    if (sortOrder) {
      searchParams = searchParams.append("sortOrder", sortOrder);
    }

    searchParams = searchParams.append("showExpired", String(showExpired));

    searchParams = searchParams.append("oppdragStatus", oppdragStatus.toString());
    searchParams = searchParams.append("oppdragStatusSm", oppdragStatusSm.toString());
    searchParams = searchParams.append("oppdragStatusLeveranse", oppdragStatusLeveranse.toString());

    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<SearchResults<SearchItem>>(this.baseUrl, {params: searchParams});
  }

  get(oppdragId: number): Observable<Oppdrag> {
    return this.http.get<Oppdrag>(this.baseUrl + oppdragId);
  }

  put(oppdrag: Oppdrag): Observable<Oppdrag> {
    return this.http.put<Oppdrag>(this.baseUrl + oppdrag.oppdragId, oppdrag);
  }

  post(oppdrag: Oppdrag): Observable<Oppdrag> {
    return this.http.post<Oppdrag>(this.baseUrl, oppdrag);
  }


  getFields(oppdragId: number): Observable<Field[]> {
    return this.http.get<Field[]>(this.baseUrl + oppdragId + "/fields");
  }

  getOppdragOptions(): Observable<OppdragCreateOptions> {
    return this.http.get<OppdragCreateOptions>(this.baseUrl + "options");
  }

  getOppdragStatus(): Observable<Oppdragstatus[]> {
    return this.http.get<Oppdragstatus[]>(this.baseUrl + "oppdragStatus");
  }

  getOppdragStatusSm(): Observable<Oppdragstatussm[]> {
    return this.http.get<Oppdragstatussm[]>(this.baseUrl + "oppdragStatusSm");
  }

  getOppdragstatusleveranse(): Observable<Oppdragstatusleveranse[]> {
    return this.http.get<Oppdragstatusleveranse[]>(this.baseUrl + "oppdragstatusleveranse");
  }

  getOppdragPersonRoller(oppdragId: number): Observable<OppdragPerson[]> {
    return this.http.get<OppdragPerson[]>(this.baseUrl + oppdragId + "/oppdrag-person-roller");
  }

  overforTilDrift(oppdrag: Oppdrag): Observable<Itsystem> {
    return this.http.post<Itsystem>(this.baseUrl + oppdrag.oppdragId + "/todrift", oppdrag);
  }

}

export type OppdragSearchResults = SearchResults<SearchItem>


export interface OppdragCreateOptions {
  tilgjengelighet: Tilgjengelighet[];
  internett: InternettKritikalitet[];
  kunde: Kunde[];
  oppdragstatus: Oppdragstatus[];
  oppdragstatussm: Oppdragstatussm[];
  oppdragstatusleveranse: Oppdragstatusleveranse[];
}

export interface InternettKritikalitet {
  internettKritikalitetId: number;
  navn: string;
}

export interface Oppdragstatus {
  oppdragStatusId: number;
  status: string;
}

export interface Oppdragstatussm {
  oppdragSmStatusId: number;
  statusSm: string;
}

export interface Oppdragstatusleveranse {
  oppdragLevStatusId: number;
  statusLeveranse: string;
}

export interface Kunde {
  kundeId: number;
  navn: string;
}

export interface Tilgjengelighet {
  tilgjengelighetId: number;
  navn: string;
  beskrivelse: string;
}

export interface Oppdrag {
  oppdragId: number;
  navn: string;
  beskrivelse: string;
  kommentar: string;
  avsluttet: Date;
  kansellert?: any;
  saksnummer: string;
  registrert: Date;
  tilbudSendt: Date;
  tilbudAkseptert: Date;
  oppdragStartet: Date;
  ressursnr: string;
  ansvarssted: string;
  tjeneste?: number;
  investeringRessursnr?: any;
  investeringTjeneste?: any;
  investeringArt?: any;
  investeringAnsvar?: any;
  investeringProsjekt?: any;
  investeringObjekt?: any;
  investeringBeskrivelse?: any;
  antallServere: number;
  antallOracleBaser: number;
  antallMsSqlBaser: number;
  antallMariaDb: number;
  antallMongodb: number;
  antallPostgreSql: number;
  antallIntegrasjoner: number;
  antallBrukere: number;
  tilleggDriftspris: number;
  tilleggKommentar?: any;
  vaktlag: boolean;
  driftFakturertTil: Date;
  personopplysninger: boolean;
  sensitivePersonopplysninger: boolean;
  tilgjengelighetId: number;
  kundeId: number;
  internettKritikalitetId: number;
  oppdragStatusId: number;
  oppdragLevStatusId: number;
  oppdragSmStatusId: number;
}

export enum OppdragStatus {
  innmledt = 1,
  pagar = 2,
  avsluttet = 3,
  kansellert = 4
}




