<div *ngIf="risikogjennomgang && fields">


  <h1 class="h1 mb-5">{{risikogjennomgang.navn}}</h1>
  
  <div class="col-12 col-md-9">
    <div class="btn-toolbar my-2" role="toolbar" aria-label="Toolbar with button groups">
      <div class="btn-group mr-4" role="group">
        <button class="btn btn-outline-primary px-5" type="button" (click)="lagre()"
          [disabled]="!formGroup.dirty || formGroup.invalid || isSaving">Lagre
          <span *ngIf="isSaving" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="isSaving" class="sr-only">Loading...</span>
        </button>
      </div>
      <div class="btn-group" role="group">
        <button class="btn btn-outline-secondary px-4" type="button" (click)="tilbakestill()"
          [disabled]="!formGroup.dirty">
          Nullstill
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="risikogjennomgang && fields">
    <div class="container-flex">
      <div class="row">

        <div class="col-12 col-md-5 col-lg-4">
          <app-feltliste [formGroup]="formGroup" [fields]="col1" [modell]="risikogjennomgang"></app-feltliste>
        </div>

        <div class="col-12 col-md-5 col-lg-4">
          <app-feltliste [formGroup]="formGroup" [fields]="col2" [modell]="risikogjennomgang"></app-feltliste>
        </div>

        <div class="col-12 col-md-5 col-lg-4">
          <app-feltliste [formGroup]="formGroup" [fields]="col3" [modell]="risikogjennomgang"></app-feltliste>
        </div>

      </div>
    </div>

  </div>

</div>