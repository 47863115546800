import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Tabs } from "src/app/common/tabs/tabs.enum";
import { TabsService } from "src/app/common/tabs/tabs.service";
import { TilgangService } from "src/app/services/tilgang.service";
import { TabSideBaseClass } from "../../common/tab-side/tab-side.component";
import { KoblingerService, OppdragSystem } from "../../koblinger/koblinger.service";
import { ModalService } from "../../services/modal.service";

@Component({
  selector: "app-oppdrag-tab",
  templateUrl: "./itsystemoppdrag-tab.component.html"
})
export class ItsystemOppdragTabComponent extends TabSideBaseClass<OppdragSystem> {

  tabName(): Tabs {
    return Tabs.oppdrag;
  }

  constructor(tabsService: TabsService, service: KoblingerService, tilgangService: TilgangService, route: ActivatedRoute, modal: ModalService, router: Router) {
    super(tabsService, service, tilgangService, modal, route, router);
  }

  loaderObservable(service: KoblingerService, id: number): Observable<OppdragSystem[]> {
    return this.service.getOppdragSystemForSystem(id);
  }

  pageLinkAccess(service: TilgangService): Observable<boolean> {
    return service.getGlobalRoles().pipe(map(val => val.oppdrag.listAccess));
  }

  removeObservable(service: KoblingerService, item: OppdragSystem): Observable<object> {
    return service.removeOppdragSystemForSystem(item.oppdragId, item.systemId);
  }

  public static oppdragUtgatt(oppdragSystem: OppdragSystem) : boolean {
    return oppdragSystem.oppdrag.oppdragStatusId == 3;
  }

  compareFn(first: OppdragSystem, second: OppdragSystem): number {
    if (!ItsystemOppdragTabComponent.oppdragUtgatt(first) && !ItsystemOppdragTabComponent.oppdragUtgatt(second)) return 0;
    if (!ItsystemOppdragTabComponent.oppdragUtgatt(first) && ItsystemOppdragTabComponent.oppdragUtgatt(first)) return 1;
    else return -1;
  }


  kobleTil(): void {
    this.modal.velgOppdrag().then(oppdrag => {

      if (this.liste.some(t => t.oppdragId === oppdrag.id)) {
        this.modal.warning(`${oppdrag.navn} er allerede koblet til system`, "Kobling feilet");
        return;
      }

      const item: OppdragSystem = { systemId: this.id, oppdragId: oppdrag.id };
      this.service.postOppdragSystemForSystem(item).toPromise().then(() => {
        this.modal.success("", "Oppdrag koblet til system");
        this.load();
      });
    });
  }

}
