import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TabSideBaseClass } from "src/app/common/tab-side/tab-side.component";
import { Tabs } from "src/app/common/tabs/tabs.enum";
import { TabsService } from "src/app/common/tabs/tabs.service";
import {KoblingerService, OppdragPerson, PersonLeverandorRolle} from "src/app/koblinger/koblinger.service";
import { ModalService } from "src/app/services/modal.service";
import { TilgangService } from "src/app/services/tilgang.service";



@Component({
  selector: "app-personer-tab",
  templateUrl: "./oppdragpersoner-tab.component.html"
})
export class OppdragPersonerTabComponent extends TabSideBaseClass<OppdragPerson> {

  tabName(): Tabs {
    return Tabs.personer;
  }

  constructor(tabsService : TabsService,service: KoblingerService, tilgangService: TilgangService, route: ActivatedRoute, modal: ModalService, router: Router) {
    super(tabsService, service, tilgangService, modal, route, router);
  }

  loaderObservable(service: KoblingerService, id: number): Observable<OppdragPerson[]> {
    return service.getOppdragPersonRolleForOppdrag(this.id);
  }

  pageLinkAccess(service: TilgangService): Observable<boolean> {
    return service.getGlobalRoles().pipe(map(val => val.personer.listAccess));
  }

  removeObservable(service: KoblingerService, item: OppdragPerson): Observable<object> {
    return service.removeOppdragPersonRolleForOppdrag(item.oppdragId, item.personId, item.rolleId);
  }

  compareFn(first: OppdragPerson, second: OppdragPerson): number {
    if (!first.person.sluttet && !second.person.sluttet) return 0;
    if (!first.person.sluttet && second.person.sluttet) return -1;
    else return 1;
  }

  kobleTil(): void {

    this.modal.velgPerson().then(person => {
      this.modal.velgPersonRolle().then(rolle => {

        if (this.liste.some(t => t.personId === person.personId && t.rolleId === rolle.rolleId)) {
          this.modal.warning(`${person.navn} med rolle ${rolle.navn} er allerede koblet til oppdrag`, "Kobling feilet");
          return;
        }

        const psr: OppdragPerson = { oppdragId: this.id, personId: person.personId, rolleId: rolle.rolleId };
        this.service.postOppdragPersonRolleForOppdrag(psr).subscribe(() => {
          this.modal.success("Oppdrag, person og rolle", "Kobling opprettet");
          this.load();
        });
      });
    });
  }
}
