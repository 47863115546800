import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TabSideBaseClass } from '../../common/tab-side/tab-side.component';
import { Tabs } from '../../common/tabs/tabs.enum';
import { TabsService } from '../../common/tabs/tabs.service';
import { Resultatenhet, ResultatenhetSystem, KoblingerService } from '../../koblinger/koblinger.service';
import { ModalService } from '../../services/modal.service';
import { TilgangService } from '../../services/tilgang.service';

@Component({
  selector: 'app-itsystem-brukere-tab',
  templateUrl: './itsystem-brukere-tab.component.html',
  styleUrls: ['./itsystem-brukere-tab.component.scss']
})
export class ItsystemBrukereTabComponent extends TabSideBaseClass<Resultatenhet>  {


  constructor(tabsService: TabsService, service: KoblingerService, tilgangService: TilgangService, modal: ModalService, route: ActivatedRoute, router: Router) {
    super(tabsService, service, tilgangService, modal, route, router);
  }

  kobleTil(): void {
    this.modal.velgBruker().then(bruker => {
      if (this.liste.some(t => t.orgenhetId == bruker.orgenhetId)) {
        this.modal.warning(`${bruker.navn} er allerede koblet til system`, "Kobling feilet");
        return;
      }

      const item: ResultatenhetSystem = { orgenhetId: bruker.orgenhetId, systemId: this.id };

      this.service.opprettResultatenhetSystem(item).toPromise().then(() => {
        this.modal.success("", "Bruker koblet til itsystem");
        this.load();
      });
    });
  }


  protected override removeObservable(service: KoblingerService, item: Resultatenhet): Observable<any> {
    return service.fjernResultatenhetSystem(this.id, item.orgenhetId);
  }

  loaderObservable(service: KoblingerService, id: number): Observable<Resultatenhet[]> {
    return service.getResultatenhetForSystemer(id);
  }
  pageLinkAccess(service: TilgangService): Observable<boolean> {
    return of(true);
  }

  tabName(): Tabs {
    return Tabs.brukere;
  }

}
