<div *ngIf="harTilgang">
    <app-opprett-ny-knapp> </app-opprett-ny-knapp>
    
    <h1>{{overskrift}}</h1>
    
    <app-soketekstfilter (onFilterChange) = "onFilterChange($event)" [filterParams]="filterParams"></app-soketekstfilter>
    <app-sertifikater-filter (onFilterChange) = "onFilterChange($event)" [filterParams]="filterParams"></app-sertifikater-filter>
    <app-paginator (onFilterChange)="onFilterChange($event)" [filterParams]="filterParams"></app-paginator>
    <app-spinner [loadingData]="loadingData"></app-spinner>
    
    
    
    <div *ngIf="searchResults">
        <p class="text-center">Antall treff ({{filterParams.numberOfHits}})</p>
        <div class="search-results">
            <div *ngFor="let searchItem of searchResults" class="list-group-flush">
                <a [routerLink]="[searchItem.id]"
                    class="list-group-item list-group-item-action flex-column align-items-end">
                    <h5>{{searchItem.navn}}</h5>
                    <div class="text-muted">{{searchItem.beskrivelse}}</div>
                </a>
            </div>
        </div>
    
    </div>
    </div>
    <app-ikketilgang [harTilgang]="harTilgang" [loadingData]="loadingData" [overskrift]="overskrift"></app-ikketilgang>
    