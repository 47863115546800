import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FaktureringService, OppdragFakturalinjeDrift } from "../../services/fakturering.service";
import { NotificationService } from "../../services/notification.service";
import { TilgangService } from "../../services/tilgang.service";

@Component({
  selector: "app-oppdrag-fakturalinjer-drift-tab",
  templateUrl: "./oppdrag-fakturalinjer-drift-tab.component.html",
  styleUrls: ["./oppdrag-fakturalinjer-drift-tab.component.scss"]
})
export class OppdragFakturalinjerDriftTabComponent implements OnInit {

  @Input()
  visibleListSize = 10;

  oppdragId: number;
  fakturalinjer: OppdragFakturalinjeDrift[];
  activeFakturalinjer: OppdragFakturalinjeDrift[];

  kanGodkjenneFaktura = false;
  kanMerkeFakturert = false;

  isLoading = false;
  errorLoading = false;



  private numberOfVisibleElements = 0;

  constructor(private route: ActivatedRoute, private fakturaService: FaktureringService,
              private tilgangService: TilgangService, private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.route.parent.params.subscribe(params => {
      this.oppdragId = parseInt(params["oppdragId"]);
      this.hentFakturalinjer();

      this.tilgangService.getOppdragFakturaAccess().subscribe(access => {
        this.kanGodkjenneFaktura = access.godkjenn;
        this.kanMerkeFakturert = access.fakturer;
      });
    });
  }

  private hentFakturalinjer() {
    this.isLoading = true;
    this.fakturaService.getOppdragFakturalinjerDrift(this.oppdragId).subscribe(fakturalinjer => {
      this.prosesserFakturalinjer(fakturalinjer);

      this.fakturalinjer.sort((a, b) => {
        return a.fra < b.fra ? 1 : -1;
      });

      this.loadMoreActiveElements();
      this.isLoading = false;
    }, () => this.errorLoading = true);
  }

  public oppdaterFakturalinjer() {
    this.hentFakturalinjer();
  }

  loadMoreActiveElements(): void {
    this.numberOfVisibleElements += this.visibleListSize;
    this.activeFakturalinjer = this.fakturalinjer.slice(0, this.numberOfVisibleElements);
  }

  private prosesserFakturalinjer(fakturalinjer: OppdragFakturalinjeDrift[]) {
    fakturalinjer.forEach(fakturalinje => {
      if (fakturalinje.godkjentAvNavn != "") {
        fakturalinje.godkjentAv = fakturalinje.godkjentAvNavn;
      }
    });
    this.fakturalinjer = fakturalinjer;
  }

  godkjennFakturalinje(fakturaId: number): void {
    this.fakturaService.godkjennOppdragDriftFaktura(fakturaId, this.oppdragId).subscribe(faktura => this.oppdaterFaktura(faktura, true));
  }

  merkFakturaFakturert(fakturaId: number): void {
    this.fakturaService.merkFakturertOppdragDriftFaktura(fakturaId, this.oppdragId).subscribe(faktura => this.oppdaterFaktura(faktura, false));
  }

  private oppdaterFaktura(faktura: OppdragFakturalinjeDrift, godkjent: boolean) {
    const indeks = this.activeFakturalinjer.findIndex(fakturalinje => fakturalinje.id === faktura.id);
    if (indeks != -1) {
      this.activeFakturalinjer[indeks] = faktura;
      if (godkjent) {
        this.notificationService.success(`Faktura med faktura-id ${faktura.id} er godkjent`, "Faktura godkjent");
      } else {
        this.notificationService.success(`Faktura med faktura-id ${faktura.id} er merket som fakturert`, "Faktura fakturert");
      }
    }
  }

}
