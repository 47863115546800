import {Injectable} from "@angular/core";
import {Field} from "../common/common.model";
import {Pris} from "./fakturering.service";
import { Prisberegning } from "../itsystemer/itsystemer.service";

@Injectable({
  providedIn: "root"
})
export class PrisKalkulatorService {

  getPrisTilgjengelighet(enhetPriser: Map<number, Pris>, prisberegning: Prisberegning, tilgjengelighetId : number): number {
    var servere = prisberegning.antallServere
    var dbs = prisberegning.antallMariaDbBaser + prisberegning.antallMongoDbBaser + prisberegning.antallMsSqlBaser + prisberegning.antallOracleBaser + prisberegning.antallPostgreSqlBaser;
    var sum = servere + dbs;
    var pris = 0;

    if (tilgjengelighetId == 2) {
      //On premise: Arbeidstid 08-16
      
      if (sum > 0.1 && sum < 1.99) {
        pris = this.getPrisPrEnhet( enhetPriser, "On premise: Arbeidstid 08-16. 0,1-1,99 servere/DB").beløp;
      } else if(sum >= 2.0 && sum < 4.99) {
        pris = this.getPrisPrEnhet( enhetPriser, "On premise: Arbeidstid 08-16. 2,0-4,99 servere/DB").beløp;
      } else if (sum >= 5.0 && sum < 14.99) {
        pris = this.getPrisPrEnhet( enhetPriser, "On premise: Arbeidstid 08-16. 5,0-14,99 servere/DB").beløp;
      } else if (sum >= 15.0) {
        pris = this.getPrisPrEnhet( enhetPriser, "On premise: Arbeidstid 08-16. 15,0-  server/DB").beløp;
      }
      //this.prisOppdateringer = this.getPrisPrEnhet( enhetPriser, "Oppdateringer").beløp;
    }
    else if(tilgjengelighetId == 3) {
      //On premise: Arbeidstid 24/7

      if (sum > 0.1 && sum < 1.99) {
        pris = this.getPrisPrEnhet( enhetPriser, "On premise: Arbeidstid 24/7. 0,1-1,99 servere/DB").beløp;
      } else if(sum >= 2.0 && sum < 4.99) {
        pris = this.getPrisPrEnhet( enhetPriser, "On premise: Arbeidstid 24/7. 2,0-4,99 servere/DB").beløp;
      } else if (sum >= 5.0 && sum < 14.99) {
        pris = this.getPrisPrEnhet( enhetPriser, "On premise: Arbeidstid 24/7. 5,0-14,99 servere/DB").beløp;
      } else if (sum >= 15.0) {
        pris = this.getPrisPrEnhet( enhetPriser, "On premise: Arbeidstid 24/7. 15,0- servere/DB").beløp;
    }
      //this.prisOppdateringer = this.getPrisPrEnhet( enhetPriser, "Oppdateringer").beløp;
    }
    return pris;
  }

  getVaktlag(enhetPriser: Map<number, Pris>, tilgjengelighetId : number): number {
    if (tilgjengelighetId == 3) {
      return this.getPrisPrEnhet(enhetPriser, "Vaktlag").beløp;
    }
    return 0;
  }

  getPrisOppdateringer(enhetPriser: Map<number, Pris> , tilgjengelighetId: number) {
    if(tilgjengelighetId == 2 || tilgjengelighetId == 3) {
      return this.getPrisPrEnhet(enhetPriser, "Oppdateringer").beløp
    }
    return 0;
  }


  getPrisGrensesnitt(enhetPriser: Map<number, Pris>, tilgjengelighetId: number, antall: number): number {
    const grunnpris = enhetPriser.get(tilgjengelighetId).beløp;
    if (antall == 0) {
      return 0.0;
    } else if (antall <= 5) {
      return grunnpris * 1.5;
    } else if (antall <= 10) {
      return grunnpris * 2;
    } else {
      return grunnpris * 3;
    }
  }

  getPrisBrukere(enhetPriser: Map<number, Pris>, tilgjengelighetId: number, antall: number): number {
    const grunnpris = enhetPriser.get(tilgjengelighetId).beløp;
    if (antall < 20) {
      return 0;
    } else if (antall <= 500) {
      return grunnpris * 1.5;
    } else {
      return grunnpris * 2;
    }
  }

  getPris(enhetPriser: Map<number, Pris>, key: string, antall = 1): number {
    return this.getPrisPrEnhet(enhetPriser, key).beløp * antall;
  }

  getPrisPrEnhet(enhetPriser: Map<number, Pris>, key: string): Pris {
    switch (key) {
      case "antallServere":
        return enhetPriser.get(121);
      case "antallServereTest":
        return enhetPriser.get(5);
      case "antallMsSqlBaser":
        return enhetPriser.get(122);
      case "antallOracleBaser":
        return enhetPriser.get(123);
      case "antallMariaDbBaser":
        return enhetPriser.get(124);
      case "antallMongoDbBaser":
        return enhetPriser.get(125);
      case "antallPostgreSqlBaser":
        return enhetPriser.get(126);
      case "Vaktlag":
        return enhetPriser.get(120);
      case "On premise: Arbeidstid 08-16. 0,1-1,99 servere/DB":
        return enhetPriser.get(112);
      case "On premise: Arbeidstid 08-16. 2,0-4,99 servere/DB":
        return enhetPriser.get(113);
      case "On premise: Arbeidstid 08-16. 5,0-14,99 servere/DB":
        return enhetPriser.get(114);
      case "On premise: Arbeidstid 08-16. 15,0-  server/DB":
        return enhetPriser.get(115);
      case "On premise: Arbeidstid 24/7. 0,1-1,99 servere/DB":
        return enhetPriser.get(116);
      case "On premise: Arbeidstid 24/7. 2,0-4,99 servere/DB":
        return enhetPriser.get(117);
      case "On premise: Arbeidstid 24/7. 5,0-14,99 servere/DB":
        return enhetPriser.get(118);
      case "On premise: Arbeidstid 24/7. 15,0- servere/DB":
        return enhetPriser.get(119);     
      case "Oppdateringer":
        return enhetPriser.get(109);                                           
      default:
        return {beløp: -1} as Pris;
    }
  }

  relevantPrisfelt(felt: Field): boolean {
    return true;
  }

}
