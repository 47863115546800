<a [routerLink]="['/itsystemer', itsystem.systemId]"
    class="list-group-item list-group-item-action flex-column align-items-start my-1">

    <h5 *ngIf="itsystem.utgattdato == null; else expired">{{itsystem.navn}}
        <span class="text-muted">({{itsystem.systemId}})</span>
    </h5>

    <ng-template #expired>
        <h5 class="mb-1">{{itsystem.navn}}
            <i class="fas fa-calendar-times"></i>
            <span class="text-muted">({{itsystem.systemId}})</span>
        </h5>
    </ng-template>

    <small *ngIf="itsystem.systemtypeId == programpakke">Programpakke</small>
    <small *ngIf="itsystem.systemtypeId == fagsystem">Fagsystem</small>
    <small *ngIf="itsystem.systemtypeId == infrastruktur">Infrastruktur</small>
    <small *ngIf="itsystem.systemtypeId == nettjeneste">Nettjeneste</small>

    <p class="my-1">{{itsystem.beskrivelse}}</p>


    <div class="row">
        <div class="col-md">
            <p class="text-muted" *ngIf="itsystem.systemKoodinator">Systemkoordinator:
                {{itsystem.systemKoodinator?.navn}}
            </p>
            <p class="text-muted" *ngIf="itsystem.systemEier">Systemeier:
                {{ itsystem.systemEier?.navn }}</p>
        </div>

        <div class="col-md">
            <p class="text-muted" *ngIf="itsystem.leverandorNavn">Leverandør: {{ itsystem?.leverandorNavn }} </p>
            <p class="text-muted" *ngIf="itsystem.tilgjengelighetNavn"> Tilgjengelighet: {{itsystem.tilgjengelighetNavn}} </p>
        </div>
    </div>


    <div *ngIf="itsystem.utgattdato != null">
        <hr class="my-4">
        <p class="mt-1">Utgått : {{itsystem.utgattdato | date:'dd-MM-yyyy'}}</p>
    </div>

</a>
