import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ValidationChecks } from "../common.model";

@Component({
  selector: "app-dynamic-tekst",
  templateUrl: "./dynamic-tekst.component.html",
  styleUrls: ["./dynamic-tekst.component.scss"]
})
export class DynamicTekstComponent implements OnInit {


  @Input()
  text: string;
  @Input()
  id: string;
  @Input()
  fControl: FormControl;
  @Input()
  validationChecks: ValidationChecks;

  readonly maxLength = 32;

  numRows = 2;

  private viewLoaded = false;


  @ViewChild("textLarge") set textArea(textArea: ElementRef) {
    if (textArea && this.viewLoaded) {
      textArea.nativeElement.focus();
    }
  }

  @ViewChild("textSmall") set textField(textField: ElementRef) {
    if (textField && this.viewLoaded) {
      textField.nativeElement.focus();
    }
  }

  ngOnInit(): void {
    // Do nothing
  }

  ngAfterViewInit(): void {
    this.viewLoaded = true;
  }

  calculateRows(): void {
    // this.numRows = Math.floor(this.text.length / this.maxLength) + 1;
    // console.log(this.numRows)
  }

}
