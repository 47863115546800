import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Field, SearchItem, SearchResults } from "../common/common.model";
import { IServiceInterface } from "../common/feltside/feltside.component";



@Injectable({
  providedIn: "root"
})
export class LeverandorerService implements IServiceInterface<Leverandor> {

  private readonly basePath = "api/leverandor/";
  private readonly baseUrl: string;

  constructor(private http: HttpClient, @Inject("BASE_URL") private baseHost: string) {
    this.baseUrl = baseHost + this.basePath;
  }

  search(beskrivelse: string, sortOrder: string, pageNumber: number, pageSize: number): Observable<SearchResults<SearchItem>> {
    let searchParams = new HttpParams();

    if (beskrivelse) { searchParams = searchParams.append("beskrivelse", beskrivelse); }
    if (sortOrder) { searchParams = searchParams.append("sortOrder", sortOrder); }
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<SearchResults<SearchItem>>(this.baseUrl, { params: searchParams });
  }

  get(leverandorId: number) : Observable<Leverandor> {
    return this.http.get<Leverandor>(this.baseUrl + leverandorId);
  }

  put(leverandor: Leverandor) : Observable<Leverandor> {
    return this.http.put<Leverandor>(this.baseUrl + leverandor.leverandorId, leverandor);
  }

  post(leverandor: Leverandor) : Observable<Leverandor> {
    return this.http.post<Leverandor>(this.baseUrl, leverandor);
  }

  getFields(leverandorId: number) : Observable<Field[]> {
    return this.http.get<Field[]>(this.baseUrl + leverandorId + "/fields");
  }
  getCreateFields(id?: number): Observable<Field[]> {
    throw new Error("Method not implemented.");
  }

}


export interface Leverandor {
  leverandorId: number;
  navn: string;
  url: string;
  telefon: string;
  telefon2: string;
  epost: string;
  gateadresse: string;
  postnummer: string;
  poststed: string;
  organisasjonsnummer: string;
}




