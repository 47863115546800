import { Component } from "@angular/core";
import { TestinformasjonService } from "../testinformasjon/testinformasjon.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { TilgangService } from "../services/tilgang.service";
import { ItsystemerService } from "../itsystemer/itsystemer.service";
import { ListesideBaseClass } from "../common/listeside/listeside.component";
import { Observable } from "rxjs";
import { FilterParams } from "../common/common.model";
import {filter} from "rxjs/operators";

@Component({
  selector: "app-testinformasjoner",
  templateUrl: "./testinformasjoner.component.html",
  styleUrls: ["./testinformasjoner.component.scss"]
})
export class TestinformasjonerComponent extends ListesideBaseClass {

  overskrift = "Oversikt over testinformasjon";

  constructor(
    protected testinformasjonService: TestinformasjonService,
    protected itsystemService: ItsystemerService,
    protected tilgangService: TilgangService,
    protected router: Router,
    protected route: ActivatedRoute
  ) { super(route, router, tilgangService); }


  onFilterChange(filterParams: FilterParams) {

    const kilder = this.getKilderString(filterParams);

    this.router.navigate(["/testinformasjoner"], {
      queryParams:
      {
        "s": filterParams.tekst,
        "systemeier": filterParams.systemeier,
        "systemkoordinator": filterParams.systemkoordinator,
        "testansvarlig": filterParams.testansvarlig,
        "kundeId": filterParams.byradsavdeling,
        "kilder": kilder,
        "sortOrder": filterParams.order,
        "showExpired": filterParams.showExpired,
        "testWindowsEndring": filterParams.testWindowsEndring,
        "testOfficeEndring": filterParams.testOfficeEndring,
        "testet": filterParams.testet,
        "testForetatt": filterParams.testForetatt,
        "pageNumber": filterParams.pageNumber,
        "pageSize" : filterParams.pageSize
      }
    });
  }

  search(params: FilterParams): Observable<any> {

    const name = params.tekst as string;
    const systemeier = params.systemeier as string;
    const systemkoordinator = params.systemkoordinator as string;
    const testansvarlig = params.testansvarlig as string;
    const byradsavdeling = params.byradsavdeling as number;
    const kilder = this.getKilderString(params);
    const sortOrder = params.order as string;
    const testOfficeEndring = params.testOfficeEndring as boolean;
    const testWindowsEndring = params.testWindowsEndring as boolean;
    const testet = params.testet as boolean;
    const testForetatt = params.testForetatt as string;
    const showExpired = params.showExpired as boolean;
    const pageNumber = params.pageNumber as number;
    const pageSize = params.pageSize as number;

    return this.testinformasjonService.searchTestinformasjon(name, systemeier, systemkoordinator, testansvarlig,
      byradsavdeling, kilder, sortOrder, testOfficeEndring, testWindowsEndring, testet, testForetatt, showExpired,
      pageNumber, pageSize);
  }

  urlParamsToFilterParams(params: Params, filterParams: FilterParams): FilterParams {

    filterParams = filterParams ? filterParams : new FilterParams();

    filterParams.tekst = params.s ? params.s : "";
    filterParams.showExpired = params.showExpired == "true";

    if (params.kilder) {
      const kilder = params.kilder;
      filterParams.showProgrampakke = kilder.toLowerCase().includes("p");
      filterParams.showFagsystem = kilder.toLowerCase().includes("f");
    } else {
      filterParams.showProgrampakke = false;
      filterParams.showFagsystem = false;
    }

    filterParams.systemeier = params.systemeier ? params.systemeier : "";
    filterParams.systemkoordinator = params.systemkoordinator ? params.systemkoordinator : "";
    filterParams.testansvarlig = params.testansvarlig ? params.testansvarlig : "";

    filterParams.byradsavdeling = params.kundeId ? params.kundeId : "";
    filterParams.order = params.sortOrder ? params.sortOrder : "nameAsc";
    filterParams.pageNumber = params.pageNumber ? params.pageNumber : 1;
    filterParams.pageSize = params.pageSize ? params.pageSize : 30;

    return filterParams;
  }

  private getKilderString(filterParams: FilterParams): string {
    let kilder = "";
    if (filterParams.showProgrampakke) { kilder = kilder + "P"; }
    if (filterParams.showFagsystem) { kilder = kilder + "F"; }
    return kilder;
  }

}
