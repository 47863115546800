import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { System } from "typescript";
import { Rolle } from "../common/common.model";
import { SystemSystem } from "../integrasjoner/integrasjoner.service";
import { Endring } from "../itsystem/endring-tab/endringer.service";
import { Itsystem } from "../itsystemer/itsystemer.service";
import { Leverandor } from "../leverandor/leverandor.service";
import { Oppdrag } from "../oppdrag/oppdrag.service";
import { Sertifikat } from "../sertifikater/sertifikater.service";
import { Person } from "../services/person.service";
import { Tjeneste } from "../tjeneste/tjenester.service";



@Injectable({
  providedIn: "root"
})
export class KoblingerService {


  private readonly basePath = "api/koblinger/";
  private readonly baseUrl: string;

  constructor(private http: HttpClient, @Inject("BASE_URL") private baseHost: string) {
    this.baseUrl = baseHost + this.basePath;
  }


  // leverandorsystemrolle
  getLeverandorSystemRollerForSystem(systemId: number): Observable<LeverandorSystemRolle[]> {
    return this.http.get<LeverandorSystemRolle[]>(this.baseUrl + "leverandorsystemrolle/system", { params: { systemId: systemId + "" } });
  }
  postLeverandorSystemRolleForSystem(item: LeverandorSystemRolle): Observable<LeverandorSystemRolle> {
    return this.http.post<LeverandorSystemRolle>(this.baseUrl + "leverandorsystemrolle/system", item);
  }
  removeLeverandorSystemRolleForSystem(leverandorId: number, systemId: number, rolleId: number): Observable<object> {
    const lId = leverandorId.toString();
    const sId = systemId.toString();
    const rId = rolleId.toString();
    return this.http.delete(this.baseUrl + "leverandorsystemrolle/system", { params: { leverandorId: lId, systemId: sId, rolleId: rId } });
  }


  getLeverandorSystemRollerForLeverandor(leverandorId: number): Observable<LeverandorSystemRolle[]> {
    return this.http.get<LeverandorSystemRolle[]>(this.baseUrl + "leverandorsystemrolle/leverandor", { params: { leverandorId: leverandorId + "" } });
  }
  postLeverandorSystemRolleForLeverandor(item: LeverandorSystemRolle): Observable<LeverandorSystemRolle> {
    return this.http.post<LeverandorSystemRolle>(this.baseUrl + "leverandorsystemrolle/leverandor", item);
  }
  removeLeverandorSystemRolleForLeverandor(leverandorId: number, systemId: number, rolleId: number): Observable<object> {
    const lId = leverandorId.toString();
    const sId = systemId.toString();
    const rId = rolleId.toString();
    return this.http.delete(this.baseUrl + "leverandorsystemrolle/leverandor", { params: { leverandorId: lId, systemId: sId, rolleId: rId } });
  }



  // personsystemrolle
  getPersonSystemRollerForSystem(systemId: number): Observable<PersonSystemRolle[]> {
    return this.http.get<PersonSystemRolle[]>(this.baseUrl + "personsystemrolle/system", { params: { systemId: systemId + "" } });
  }
  postPersonSystemRolleForSystem(item: PersonSystemRolle): Observable<PersonSystemRolle> {
    return this.http.post<PersonSystemRolle>(this.baseUrl + "personsystemrolle/system", item);
  }
  removePersonSystemRolleForSystem(personId: number, systemId: number, rolleId: number): Observable<object> {
    const pId = personId.toString();
    const sId = systemId.toString();
    const rId = rolleId.toString();
    return this.http.delete(this.baseUrl + "personsystemrolle/system", { params: { personId: pId, systemId: sId, rolleId: rId } });
  }


  getPersonSystemRollerForPerson(personId: number): Observable<PersonSystemRolle[]> {
    return this.http.get<PersonSystemRolle[]>(this.baseUrl + "personsystemrolle/person", { params: { personId: personId + "" } });
  }
  postPersonSystemRolleForPerson(item: PersonSystemRolle): Observable<PersonSystemRolle> {
    return this.http.post<PersonSystemRolle>(this.baseUrl + "personsystemrolle/person", item);
  }
  removePersonSystemRolleForPerson(personId: number, systemId: number, rolleId: number): Observable<object> {
    const pId = personId.toString();
    const sId = systemId.toString();
    const rId = rolleId.toString();
    return this.http.delete(this.baseUrl + "personsystemrolle/person", { params: { personId: pId, systemId: sId, rolleId: rId } });
  }



  // oppdragleverandorrolle
  getOppdragLeverandorRolleForOppdrag(oppdragId: number): Observable<OppdragLeverandor[]> {
    return this.http.get<OppdragLeverandor[]>(this.baseUrl + "oppdragleverandorrolle/oppdrag", { params: { oppdragId: oppdragId + "" } });
  }
  postOppdragLeverandorRolleForOppdrag(item: OppdragLeverandor): Observable<OppdragLeverandor> {
    return this.http.post<OppdragLeverandor>(this.baseUrl + "oppdragleverandorrolle/oppdrag", item);
  }
  removeOppdragLeverandorRolleForOppdrag(leverandorId: number, oppdragId: number, rolleId: number): Observable<object> {
    const lId = leverandorId.toString();
    const oId = oppdragId.toString();
    const rId = rolleId.toString();
    return this.http.delete(this.baseUrl + "oppdragleverandorrolle/oppdrag", { params: { leverandorId: lId, oppdragId: oId, rolleId: rId } });
  }



  getOppdragLeverandorRolleForLeverandor(leverandorId: number): Observable<OppdragLeverandor[]> {
    return this.http.get<OppdragLeverandor[]>(this.baseUrl + "oppdragleverandorrolle/leverandor", { params: { leverandorId: leverandorId + "" } });
  }
  postOppdragLeverandorRolleForLeverandor(item: OppdragLeverandor): Observable<OppdragLeverandor> {
    return this.http.post<OppdragLeverandor>(this.baseUrl + "oppdragleverandorrolle/leverandor", item);
  }
  removeOppdragLeverandorRolleForLeverandor(leverandorId: number, oppdragId: number, rolleId: number): Observable<object> {
    const lId = leverandorId.toString();
    const oId = oppdragId.toString();
    const rId = rolleId.toString();
    return this.http.delete(this.baseUrl + "oppdragleverandorrolle/leverandor", { params: { leverandorId: lId, oppdragId: oId, rolleId: rId } });
  }


  // oppdragpersonrolle
  getOppdragPersonRolleForOppdrag(oppdragId: number): Observable<OppdragPerson[]> {
    return this.http.get<OppdragPerson[]>(this.baseUrl + "oppdragpersonrolle/oppdrag", { params: { oppdragId: oppdragId + "" } });
  }
  postOppdragPersonRolleForOppdrag(item: OppdragPerson): Observable<OppdragPerson> {
    return this.http.post<OppdragPerson>(this.baseUrl + "oppdragpersonrolle/oppdrag", item);
  }
  removeOppdragPersonRolleForOppdrag(oppdragId: number, personId: number, rolleId: number): Observable<object> {
    const pId = personId.toString();
    const oId = oppdragId.toString();
    const rId = rolleId.toString();
    return this.http.delete(this.baseUrl + "oppdragpersonrolle/oppdrag", { params: { oppdragId: oId, personId: pId, rolleId: rId } });
  }


  getOppdragPersonRolleForPerson(personId: number): Observable<OppdragPerson[]> {
    return this.http.get<OppdragPerson[]>(this.baseUrl + "oppdragpersonrolle/person", { params: { personId: personId + "" } });
  }
  postOppdragPersonRolleForPerson(item: OppdragPerson): Observable<OppdragPerson> {
    return this.http.post<OppdragPerson>(this.baseUrl + "oppdragpersonrolle/person", item);
  }
  removeOppdragPersonRolleForPerson(oppdragId: number, personId: number, rolleId: number): Observable<object> {
    const pId = personId.toString();
    const oId = oppdragId.toString();
    const rId = rolleId.toString();
    return this.http.delete(this.baseUrl + "oppdragpersonrolle/person", { params: { oppdragId: oId, personId: pId, rolleId: rId } });
  }


  // oppdragsystem
  getOppdragSystemForOppdrag(oppdragId: number): Observable<OppdragSystem[]> {
    return this.http.get<OppdragSystem[]>(this.baseUrl + "oppdragsystem/oppdrag", { params: { oppdragId: oppdragId + "" } });
  }
  postOppdragSystemForOppdrag(item: OppdragSystem): Observable<OppdragSystem> {
    return this.http.post<OppdragSystem>(this.baseUrl + "oppdragsystem/oppdrag", item);
  }
  removeOppdragSystemForOppdrag(oppdragId: number, systemId: number): Observable<object> {
    const oId = oppdragId.toString();
    const sId = systemId.toString();
    return this.http.delete(this.baseUrl + "oppdragsystem/oppdrag", { params: { oppdragId: oId, systemId: sId } });
  }

  getOppdragSystemForSystem(systemId: number): Observable<OppdragSystem[]> {
    return this.http.get<OppdragSystem[]>(this.baseUrl + "oppdragsystem/system", { params: { systemId: systemId + "" } });
  }
  postOppdragSystemForSystem(item: OppdragSystem): Observable<OppdragSystem> {
    return this.http.post<OppdragSystem>(this.baseUrl + "oppdragsystem/system", item);
  }
  removeOppdragSystemForSystem(oppdragId: number, systemId: number): Observable<object> {
    const oId = oppdragId.toString();
    const sId = systemId.toString();
    return this.http.delete(this.baseUrl + "oppdragsystem/system", { params: { oppdragId: oId, systemId: sId } });
  }


  // personleverandorrolle
  getPersonLeverandorRolleForPerson(personId: number): Observable<PersonLeverandorRolle[]> {
    return this.http.get<PersonLeverandorRolle[]>(this.baseUrl + "personleverandorrolle/person", { params: { personId: personId + "" } });
  }
  postPersonLeverandorRolleForPerson(item: PersonLeverandorRolle): Observable<PersonLeverandorRolle> {
    return this.http.post<PersonLeverandorRolle>(this.baseUrl + "personleverandorrolle/person", item);
  }
  removePersonLeverandorRolleForPerson(leverandorId: number, personId: number, rolleId: number): Observable<object> {
    const pId = personId.toString();
    const lId = leverandorId.toString();
    const rId = rolleId.toString();
    return this.http.delete(this.baseUrl + "personleverandorrolle/person", { params: { leverandorId: lId, personId: pId, rolleId: rId } });
  }


  getPersonLeverandorRolleForLeverandor(leverandorId: number): Observable<PersonLeverandorRolle[]> {
    return this.http.get<PersonLeverandorRolle[]>(this.baseUrl + "personleverandorrolle/leverandor", { params: { leverandorId: leverandorId + "" } });
  }
  postPersonLeverandorRolleForLeverandor(item: PersonLeverandorRolle): Observable<PersonLeverandorRolle> {
    return this.http.post<PersonLeverandorRolle>(this.baseUrl + "personleverandorrolle/leverandor", item);
  }
  removePersonLeverandorRolleForLeverandor(leverandorId: number, personId: number, rolleId: number): Observable<object> {
    const pId = personId.toString();
    const lId = leverandorId.toString();
    const rId = rolleId.toString();
    return this.http.delete(this.baseUrl + "personleverandorrolle/leverandor", { params: { leverandorId: lId, personId: pId, rolleId: rId } });
  }


  getPersonRoller(): Observable<Rolle[]> {
    return this.http.get<Rolle[]>(this.baseUrl + "personroller");
  }
  getLeverandorRoller(): Observable<Rolle[]> {
    return this.http.get<Rolle[]>(this.baseUrl + "leverandorroller");
  }

  // systemsertifikat
  getSystemSertifikatForSystem(systemId: number): Observable<SystemSertifikat[]> {
    return this.http.get<SystemSertifikat[]>(this.baseUrl + "systemsertifikat/system", { params: { systemId: systemId + "" } });
  }
  postSystemSertifikatForSystem(systemsertifikat: SystemSertifikat): Observable<SystemSertifikat> {
    return this.http.post<SystemSertifikat>(this.baseUrl + "systemsertifikat/system", systemsertifikat);
  }
  removeSystemSertifikatForSystem(systemId: number, sertifikatId: number): Observable<object> {
    const sId = systemId.toString();
    const cId = sertifikatId.toString();
    return this.http.delete(this.baseUrl + "systemsertifikat/system", { params: { systemId: sId, sertifikatId: cId } });
  }


  getSystemSertifikatForSertifikat(sertifikatId: number): Observable<SystemSertifikat[]> {
    return this.http.get<SystemSertifikat[]>(this.baseUrl + "systemsertifikat/sertifikat", { params: { sertifikatId: sertifikatId + "" } });
  }
  postSystemSertifikatForSertifikat(systemsertifikat: SystemSertifikat): Observable<SystemSertifikat> {
    return this.http.post<SystemSertifikat>(this.baseUrl + "systemsertifikat/sertifikat", systemsertifikat);
  }
  removeSystemSertifikatForSertifikat(systemId: number, sertifikatId: number): Observable<object> {
    const sId = systemId.toString();
    const cId = sertifikatId.toString();
    return this.http.delete(this.baseUrl + "systemsertifikat/sertifikat", { params: { systemId: sId, sertifikatId: cId } });
  }



  // systemtjenester
  getSystemTjenesterForSystem(systemId: number): Observable<SystemTjeneste[]> {
    return this.http.get<SystemTjeneste[]>(this.baseUrl + "systemtjeneste/system", { params: { systemId: systemId + "" } });
  }
  postSystemTjenesteForSystem(systemtjeneste: SystemTjeneste): Observable<SystemTjeneste> {
    return this.http.post<SystemTjeneste>(this.baseUrl + "systemtjeneste/system", systemtjeneste);
  }
  removeSystemTjenesteForSystem(systemId: number, tjenesteId: number): Observable<object> {
    const sId = systemId.toString();
    const tId = tjenesteId.toString();
    return this.http.delete(this.baseUrl + "systemtjeneste/system", { params: { systemId: sId, tjenesteId: tId } });
  }


  getSystemTjenesterForTjeneste(tjenesteId: number): Observable<SystemTjeneste[]> {
    return this.http.get<SystemTjeneste[]>(this.baseUrl + "systemtjeneste/tjeneste", { params: { tjenesteId: tjenesteId + "" } });
  }
  postSystemTjenesteForTjeneste(systemtjeneste: SystemTjeneste): Observable<SystemTjeneste> {
    return this.http.post<SystemTjeneste>(this.baseUrl + "systemtjeneste/tjeneste", systemtjeneste);
  }
  removeSystemTjenesteForTjeneste(systemId: number, tjenesteId: number): Observable<object> {
    const sId = systemId.toString();
    const tId = tjenesteId.toString();
    return this.http.delete(this.baseUrl + "systemtjeneste/tjeneste", { params: { systemId: sId, tjenesteId: tId } });
  }


  // Systemsystem
  getIntegrasjonerForSystem(systemId: number): Observable<SystemSystem[]> {
    return this.http.get<SystemSystem[]>(this.baseUrl + "systemsystem/system", { params: { systemId: systemId + "" } });
  }
  postSystemSystem(systemsystem: SystemSystem): Observable<SystemTjeneste> {
    return this.http.post<SystemTjeneste>(this.baseUrl + "systemsystem", systemsystem);
  }
  removeSystemSystem(systemsystemId: number, system1Id: number): Observable<object> {
    const sId = system1Id.toString();
    const ssid = systemsystemId.toString();
    return this.http.delete(this.baseUrl + "systemsystem", { params: { systemId: sId, systemsystemId: ssid } });
  }




  // sonesystem
  getSoneSystemForSystem(systemId: number): Observable<SoneSystem[]> {
    return this.http.get<SoneSystem[]>(this.baseUrl + "sonesystem/system", { params: { systemId: systemId + "" } });
  }
  removeSoneSystemForSystem(soneId: number, systemId: number): Observable<object> {
    const syId = systemId.toString();
    const soId = soneId.toString();
    return this.http.delete(this.baseUrl + "sonesystem/system", { params: { soneId: soId, systemId: syId } });
  }
  postSoneSystemForSystem(item: SoneSystem): Observable<SoneSystem> {
    return this.http.post<SoneSystem>(this.baseUrl + "sonesystem/system", item);
  }

  getSoneSystemForSone(soneId: number): Observable<SoneSystem[]> {
    return this.http.get<SoneSystem[]>(this.baseUrl + "sonesystem/sone", { params: { soneId: soneId + "" } });
  }
  removeSoneSystemForSone(soneId: number, systemId: number): Observable<object> {
    const syId = systemId.toString();
    const soId = soneId.toString();
    return this.http.delete(this.baseUrl + "sonesystem/sone", { params: { soneId: soId, systemId: syId } });
  }
  postSoneSystemForSone(item: SoneSystem): Observable<SoneSystem> {
    return this.http.post<SoneSystem>(this.baseUrl + "sonesystem/sone", item);
  }


  // miljosystem/system
  getMiljoSystemForSystem(systemId: number): Observable<MiljoSystem[]> {
    return this.http.get<MiljoSystem[]>(this.baseUrl + "miljosystem/system", { params: { systemId: systemId + "" } });
  }
  removeMiljoSystemForSystem(miljoId: number, systemId: number): Observable<object> {
    const sId = systemId.toString();
    const mId = miljoId.toString();
    return this.http.delete(this.baseUrl + "miljosystem/system", { params: { miljoId: mId, systemId: sId } });
  }
  postMiljoSystemForSystem(item: MiljoSystem): Observable<MiljoSystem> {
    return this.http.post<MiljoSystem>(this.baseUrl + "miljosystem/system", item);
  }

  getMiljoSystemForMiljo(miljoId: number): Observable<MiljoSystem[]> {
    return this.http.get<MiljoSystem[]>(this.baseUrl + "miljosystem/miljo", { params: { miljoId: miljoId + "" } });
  }
  removeMiljoSystemForMiljo(miljoId: number, systemId: number): Observable<object> {
    const sId = systemId.toString();
    const mId = miljoId.toString();
    return this.http.delete(this.baseUrl + "miljosystem/miljo", { params: { miljoId: mId, systemId: sId } });
  }
  postMiljoSystemForMiljo(item: MiljoSystem): Observable<MiljoSystem> {
    return this.http.post<MiljoSystem>(this.baseUrl + "miljosystem/miljo", item);
  }


  // personEndring
  getPersonEndringForEndring(endringId: number): Observable<PersonEndringRolle[]> {
    return this.http.get<PersonEndringRolle[]>(this.baseUrl + "personendring/endring", { params: { endringId: endringId + "" } });
  }

  postPersonEndringRolle(item: PersonEndringRolle): Observable<PersonEndringRolle> {
    return this.http.post<PersonEndringRolle>(this.baseUrl + "personendring", item);
  }

  removePersonEndringRolle(endringId: number, personId: number, rolleId: number): Observable<object> {
    const eId = endringId.toString();
    const pId = personId.toString();
    const rId = rolleId.toString();
    return this.http.delete(this.baseUrl + "personendring", { params: { endringId: eId, personId: pId, rolleId: rId } });
  }

  // BegrepIntegrasjon
  getBegrepIntegrasjoner(integrasjonsId: number): Observable<BegrepIntegrasjon[]> {
    return this.http.get<BegrepIntegrasjon[]>(this.baseUrl + integrasjonsId + "/begrep");
  }

  opprettBegrepIntegrasjon(item: BegrepIntegrasjon): Observable<BegrepIntegrasjon> {
    return this.http.post<BegrepIntegrasjon>(this.baseUrl + item.integrasjonId + "/begrep", item);
  }

  fjernBegrepIntegrasjon(integrasjonId: number, begrepIntegrasjonId: number) {
    return this.http.delete(this.baseUrl + integrasjonId + "/begrep/" + begrepIntegrasjonId);
  }

  // BruksområdeSystem
  getBruksomradeSystemer(systemId: number): Observable<BruksomradeSystem[]> {
    return this.http.get<BruksomradeSystem[]>(this.baseUrl + systemId + "/bruksomrade");
  }

  opprettBruksomradeSystem(item: BruksomradeSystem): Observable<BruksomradeSystem> {
    return this.http.post<BruksomradeSystem>(this.baseUrl + item.systemId + "/bruksomrade", item);
  }

  fjernBruksomradeSystem(systemId: number, bruksomradeId: number) {
    return this.http.delete(this.baseUrl + systemId + "/bruksomrade/" + bruksomradeId);
  }


  // ResultatenhetSystem
  getResultatenhetForSystemer(systemId: number): Observable<Resultatenhet[]> {
    return this.http.get<Resultatenhet[]>(this.baseUrl + systemId + "/brukere");
  }

  opprettResultatenhetSystem(item: ResultatenhetSystem): Observable<ResultatenhetSystem> {
    return this.http.post<ResultatenhetSystem>(this.baseUrl + item.systemId + "/brukere", item);
  }

  fjernResultatenhetSystem(systemId: number, brukerId: number) {
    return this.http.delete(this.baseUrl + systemId + "/brukere/" + brukerId);
  }

}


export interface PersonEndringRolle {
  personId: number;
  endringId: number;
  rolleId: number;

  person?: Person;
  endring?: Endring;
  rolle?: Rolle;
}

export interface LeverandorSystemRolle {
  leverandorId: number;
  systemId: number;
  rolleId: number;
  eksternTilgang?: boolean;
  eksternTilgangDokumentasjon?: boolean;
  rolle?: Rolle;
  system?: Itsystem;
  leverandor?: Leverandor;
}


export interface PersonLeverandorRolle {
  leverandorId: number;
  personId: number;
  rolleId: number;
  rolle?: Rolle;
  person?: Person;
  leverandor?: Leverandor;
}

export interface OppdragSystem {
  systemId: number;
  oppdragId: number;
  system?: Itsystem;
  oppdrag?: Oppdrag;
}

export interface OppdragLeverandor {

  leverandorId: number;
  oppdragId: number;
  rolleId: number;
  leverandor?: Leverandor;
  oppdrag?: Oppdrag;
  rolle?: Rolle;
}

export interface OppdragPerson {
  oppdragId: number;
  personId: number;
  rolleId: number;
  oppdrag?: Oppdrag;
  person?: Person;
  rolle?: Rolle;
}

export interface PersonSystemRolle {

  personId: number;
  systemId: number;
  rolleId: number;

  person?: Person;
  system?: Itsystem;
  rolle?: Rolle;

}

export interface SystemSertifikat {
  systemId: number;
  sertifikatId: number;
  sertifikat?: Sertifikat;
  system?: Itsystem;
}

export interface SystemTjeneste {
  systemId: number;
  tjenesteId: number;
  tjeneste?: Tjeneste;
  system?: Itsystem;
}

export interface SoneSystem {
  systemId: number;
  soneId: number;
  sone?: Sone;
  system?: Itsystem;
}

export interface Sone {
  soneId: number;
  navn: string;
  beskrivelse: string;
}

export interface MiljoSystem {
  systemId: number;
  miljoId: number;
  miljo?: Miljo;
  system?: Itsystem;
}
export interface Miljo {
  miljoId: number;
  navn: string;
}

export interface BegrepIntegrasjon {
  integrasjonId: number;
  begrepId: number;
  begrep?: Begrep;
  integrasjon?: SystemSystem;
}

export interface Begrep {
  begrepId: number,
  begrep1: string,
  beskrivelse: string,
  begrepStatusId: number
}

export interface BruksomradeSystem {
  systemId: number,
  bruksomradeId: number,
  bruksomrade?: Bruksomrade
}

export interface Bruksomrade {
  bruksomradeId: number;
  navn: string,
  beskrivelse: string
}

export interface ResultatenhetSystem {
  orgenhetId: number;
  systemId: number;
}

export interface Resultatenhet {
  orgenhetId: number;
  navn: string;
}

