<h1>{{overskrift}}</h1>

<nav class="nav nav-tabs nav-justified">
    <button class="nav-item nav-link btn btn-link" (click)="systemTab()"
        [ngClass]="{'active' : filterParams.fakturatype=='Fagsystem'}">Fagsystemer</button>
    <button class="nav-item nav-link btn btn-link" (click)="oppdragTab()"
        [ngClass]="{'active' : filterParams.fakturatype=='Oppdrag'}">Oppdrag timer</button>
</nav>

<div class="mt-2">
    <app-timeforing-filter (onFilterChange)="onFilterChange($event)" [filterParams]="filterParams">
    </app-timeforing-filter>
</div>

<app-paginator (onFilterChange)="onFilterChange($event)" [filterParams]="filterParams"></app-paginator>

<app-spinner [loadingData]="loadingData"></app-spinner>

<div *ngIf="searchResults">
    <p class="text-center">Antall treff ({{filterParams.numberOfHits}})</p>

    <div *ngIf="visTimeFakturalinjer()" class="search-results">

        <table class="table">
            <thead>
                <tr>

                    <th>

                        <div *ngIf="filterParams.fakturatype == 'Oppdrag'">
                            <div class="btn-group" role="group">
                                <button [disabled]="loadingData || !filterParams.oppdragId"
                                    class="btn btn-outline-secondary" (click)="nyFakturaOppdrag()" type="button"
                                    placement="top" ngbTooltip="Registrer ny" [openDelay]="400" [closeDelay]="200"><i
                                        class="fas fa-plus-circle"></i></button>
                            </div>
                        </div>

                        <div *ngIf="filterParams.fakturatype == 'Fagsystem'">
                            <div class="btn-group" role="group">
                                <button [disabled]="loadingData || !filterParams.systemId"
                                    class="btn btn-outline-secondary" (click)="nyFakturaItsystem()" type="button"
                                    placement="top" ngbTooltip="Registrer ny" [openDelay]="400" [closeDelay]="200"><i
                                        class="fas fa-plus-circle"></i></button>
                            </div>
                        </div>

                    </th>




                    <th scope="col">
                        <button class="btn btn-link" (click)="changeOrder()">Fra
                            <i [ngClass]="filterParams.order=='dateDesc' ? 'fas fa-sort-down' : 'fas fa-sort-up'"></i>
                        </button>
                    </th>

                    <th scope="col">Til</th>

                    <th *ngIf="filterParams.fakturatype == 'Oppdrag'" scope="col">Oppdrag</th>
                    <th *ngIf="filterParams.fakturatype == 'Fagsystem'" scope="col">System</th>

                    <th scope="col">Utført av</th>

                    <ng-container *ngIf="filterParams.fakturatype == 'Fagsystem'">
                        <th scope="col">Endringnummer</th>
                        <th scope="col">Saksnummer</th>
                    </ng-container>


                    <th scope="col">Beskrivelse</th>


                    <th scope="col">Normaltid</th>
                    <th scope="col">Sum Normaltid</th>

                    <th scope="col">Overtid</th>
                    <th scope="col">Sum Overtid</th>

                    <th scope="col">Fast Beløp</th>

                    <th scope="col">Godkjent</th>
                    <th scope="col">Fakturert</th>

                    <th scope="col">Sertifikat</th>


                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let fakturalinje of searchResults; let i = index">

                    <td>
                        <div class="btn-group" role="group">


                            <ng-container *ngIf="filterParams.fakturatype == 'Fagsystem'">
                                <button
                                    *ngIf="systemAccess.rediger && !(fakturalinje.godkjent || fakturalinje.fakturert)"
                                    type="button" (click)="redigerFakturalinje(fakturalinje)" placement="top"
                                    ngbTooltip="Rediger" [openDelay]="400" [closeDelay]="200"
                                    class="btn btn-secondary"><i class="fas fa-edit"></i></button>

                                <button
                                    *ngIf="systemAccess.delete && !(fakturalinje.godkjent || fakturalinje.fakturert)"
                                    type="button" (click)="slettFakturalinje(fakturalinje.fakId,fakturalinje.systemId)"
                                    placement="top" ngbTooltip="Slett fagsystem faktura" [openDelay]="400"
                                    [closeDelay]="200" class="btn btn-secondary"><i class="fas fa-trash"></i></button>
                            </ng-container>

                            <ng-container *ngIf="filterParams.fakturatype == 'Oppdrag'">
                                <button
                                    *ngIf="oppdragAccess.rediger && !(fakturalinje.godkjent || fakturalinje.fakturert)"
                                    type="button" (click)="redigerFakturalinje(fakturalinje)" placement="top"
                                    ngbTooltip="Rediger" [openDelay]="400" [closeDelay]="200"
                                    class="btn btn-secondary"><i class="fas fa-edit"></i></button>

                                <button
                                    *ngIf="oppdragAccess.delete && !(fakturalinje.godkjent || fakturalinje.fakturert)"
                                    type="button" (click)="slettFakturalinje(fakturalinje.fakId,fakturalinje.oppdragId)"
                                    placement="top" ngbTooltip="Slett oppdrag faktura" [openDelay]="400"
                                    [closeDelay]="200" class="btn btn-secondary"><i class="fas fa-trash"></i></button>
                            </ng-container>


                        </div>
                    </td>




                    <td>{{fakturalinje.fra | date:dateFormat}}</td>
                    <td>{{fakturalinje.til | date:dateFormat}}</td>

                    <td scope="row">
                        <a *ngIf="filterParams.fakturatype == 'Oppdrag'"
                            [routerLink]="['/oppdrag', fakturalinje.oppdragId]">({{fakturalinje.oppdragId}})
                            {{fakturalinje.oppdragNavn}}</a>
                        <a *ngIf="filterParams.fakturatype == 'Fagsystem'"
                            [routerLink]="['/itsystemer', fakturalinje.systemId]">({{fakturalinje.systemId}})
                            {{fakturalinje.systemNavn}}</a>
                    </td>

                    <td>{{fakturalinje.personNavn}}</td>

                    <ng-container *ngIf="filterParams.fakturatype == 'Fagsystem'">
                        <td> <a *ngIf="fakturalinje.endring"
                                [routerLink]="['/itsystemer', fakturalinje.systemId,'endring',fakturalinje.endringId]">{{fakturalinje?.endring?.endringNummer}}</a>
                        </td>
                        <td> <a *ngIf="fakturalinje.endring"
                                [routerLink]="['/itsystemer', fakturalinje.systemId,'endring',fakturalinje.endringId]">{{fakturalinje?.endring?.saksnummer}}</a>
                        </td>
                    </ng-container>



                    <td>{{fakturalinje.beskrivelse}}</td>

                    <td>{{fakturalinje.normaltid / 36000000000}}</td>
                    <td>{{fakturalinje.sumNormaltid | currency: 'kr '}}</td>
                    <td>{{fakturalinje.overtid / 36000000000}}</td>
                    <td>{{fakturalinje.sumOvertid | currency: 'kr '}}</td>
                    <td>{{fakturalinje.fastBelop | currency: 'kr '}}</td>
                    <td>{{fakturalinje.godkjent ? 'Ja' : 'Nei'}}</td>
                    <td>{{fakturalinje.fakturert ? 'Ja' : 'Nei'}}</td>
                    <td>{{fakturalinje.sertifikat ? 'Ja' : 'Nei'}}</td>


                </tr>
            </tbody>
        </table>

    </div>


</div>

<app-ikketilgang [harTilgang]="harTilgang" [loadingData]="loadingData" [overskrift]="overskrift"></app-ikketilgang>