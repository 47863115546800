<app-opprett-ny-knapp> </app-opprett-ny-knapp>

<h1>{{overskrift}}</h1>

<app-soketekstfilter (onFilterChange)="onFilterChange($event)" [filterParams]="filterParams"></app-soketekstfilter>

<app-itsystemer-filter (onFilterChange)="onFilterChange($event)" [filterParams]="filterParams"></app-itsystemer-filter>

<app-paginator (onFilterChange)="onFilterChange($event)" [filterParams]="filterParams"></app-paginator>

<app-spinner [loadingFailed]="loadingFailed" [loadingData]="loadingData"></app-spinner>

<div *ngIf="searchResults">
  <p class="text-center">Antall treff ({{filterParams.numberOfHits}})</p>
  <div class="search-results">
    <app-itsystemsearchresult *ngFor="let itsystem of searchResults" class="list-group-flush" [itsystem]="itsystem">
    </app-itsystemsearchresult>
  </div>
</div>

<app-ikketilgang [harTilgang]="harTilgang" [loadingData]="loadingData" [overskrift]="overskrift"></app-ikketilgang>