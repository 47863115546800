<app-spinner [loadingData]="isLoading" [loadingFailed]="loadingFailed"></app-spinner>

<div class="row mb-4">

    <button [disabled]="!createDeleteAccess?.create" (click)="kobleTil()" type='button'
        class='btn btn-outline-primary mr-2'><i class="fas fa-plus"></i> Koble
        til oppdrag</button>

    <div *ngIf="canFilter()" class="btn-group-toggle" data-toggle="buttons">
        <label class="btn btn-outline-primary">
            <input type="checkbox" checked autocomplete="off" [(ngModel)]="showFiltered"> Vis utgåtte/avsluttede
        </label>
    </div>

</div>

<div *ngFor="let item of showFiltered ? originalListe : liste" class="list-group-flush">
    <div class="d-flex flex-row">

        <ng-template #tipContent>{{statusName(item.oppdrag.oppdragStatusId)}}</ng-template>

        <a [routerLink]="['/oppdrag', item.oppdragId]"
            [ngClass]="item.oppdrag.oppdragStatusId < 3 ? 'list-group-item list-group-item-action flex-column align-items-start' : 'list-group-item list-group-item-action flex-column align-items-start bg-light' ">
            <h5 class="mb-1">{{item.oppdrag.navn}}
                <span *ngIf="item.oppdrag.oppdragStatusId > 2" placement="top" [ngbTooltip]="tipContent">
                    <i class="far fa-times-circle"></i>
                </span>
            </h5>
            <div class="text-muted">{{item.oppdrag.beskrivelse}}</div>
        </a>

    </div>
</div>