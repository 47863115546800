import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Field } from "../../common.model";

@Component({
  selector: "app-feltkolonner",
  templateUrl: "./feltkolonner.component.html"

})
export class FeltkolonnerComponent implements OnInit {

  @Input()
  model: any;

  @Input()
  textAreaCol: Field[];

  @Input()
  col1: Field[];


  @Input()
  col2: Field[];


  @Input()
  checkboxCol: Field[];


  @Input()
  formGroup: FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

}
