<body>

  <div class="wrapper">
    <!-- Sidebar  -->
    <nav id="sidebar">
      <div class="sidebar-header">
        <a (click)="closeMenu()"  [routerLink]='["/"]'>  <h5 class="text-center">Systemoversikt</h5></a>
      </div>

      <ul class="list-unstyled components">

        <li *ngIf="!filtrertMeny">
          <app-spinner [ngClass]="{'my-1': !menuOpen}" [loadingData]="!filtrertMeny"></app-spinner>
        </li>

        <li *ngIf="rolesFailed">
          <button class="list-group-item list-group-item-action bg-light" (click)="ngOnInit()">
            <i class="fas fa-redo fa-lg my-1 float-right"></i>
          </button>
        </li>

        <li *ngFor="let menuItem of filtrertMeny" (click)="closeMenu()">
          <a data-toggle="tooltip" data-placement="top" title="{{menuItem.title}}"
            [routerLink]="menuItem.route">{{menuItem.title}}
            <i *ngIf="menuItem.icon" class="{{menuItem.icon}} my-1 float-right"></i>
            <b class="float-right" *ngIf="!menuItem.icon">{{menuItem.title.substr(0,2).toUpperCase()}}</b>
          </a>
        </li>
      </ul>

    </nav>

    <!-- Page Content  -->
    <div id="content">

      <app-nav-bar (menuToggled)="toggleMenu()"></app-nav-bar>
      <div class="container-fluid mt-2">
        <router-outlet></router-outlet>
      </div>

    </div>


    <div id="overlay" class="overlay" #overlay></div>

  </div>






</body>