<div xmlns="http://www.w3.org/1999/html">


  <app-spinner [loadingData]="isLoading" [loadingFailed]="errorLoading"></app-spinner>

  <div *ngIf="fakturalinjer">


    <div *ngFor="let fakturalinje of activeFakturalinjer" class="card">
      <div class="row card-body">
        <div class="col-9">
          <div class="row">
            <div class="col-4">
              Fra: {{fakturalinje.fra | date}}
            </div>
            <div *ngIf="fakturalinje.til" class="col-4">
              Til: {{fakturalinje.til | date}}
            </div>
            <div *ngIf="fakturalinje.til" class="col-4">
              Måneder: {{fakturalinje.maneder}}
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              Sum server: {{fakturalinje.sumServer | currency: 'kr '}}
            </div>
            <div class="col-4">
              Sum server test: {{fakturalinje.sumServerTest | currency: 'kr '}}
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              Sum MSSQL database: {{fakturalinje.sumSqlDb  | currency: 'kr '}}
            </div>
            <div class="col-4">
              Sum Oracle database: {{fakturalinje.sumOracleDb  | currency: 'kr '}}
            </div>
            <div class="col-4">
              Sum ikke-std database: {{fakturalinje.sumIkkeStdDb  | currency: 'kr '}}
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              Sum appdrift: {{fakturalinje.sumAppdrift | currency: 'kr '}}
            </div>
            <div class="col-4">
              Sum tillegg: {{fakturalinje.sumTillegg | currency: 'kr '}}
            </div>
          </div>
          <div class="row">
            <div *ngIf="fakturalinje.godkjent" class="col-4">
              Godkjent av: {{fakturalinje.godkjentAv}}
            </div>
            <div class="col-4" *ngIf="fakturalinje.godkjent">
              Dato: {{fakturalinje.godkjentDato | date}}
            </div>
            <div class="col" *ngIf="!fakturalinje.godkjent">
              Godkjent: nei
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              Fakturert: {{fakturalinje.fakturert ? 'ja' : 'nei'}}
            </div>
            <div *ngIf="fakturalinje.fakturert" class="col">
              Dato: {{fakturalinje.fakturertDato | date}}
            </div>
          </div>
        </div>

        <div class="col-3">
          <div class="row">
            <div class="col m-2">
              <button class="btn btn-outline-primary" *ngIf="!fakturalinje.godkjent && kanGodkjenneFaktura"
                      (click)="godkjennFakturalinje(fakturalinje.id)">Godkjenn</button>
            </div>
          </div>

          <div class="row">
            <div class="col m-2">
              <button class="btn btn-outline-primary" *ngIf="!fakturalinje.fakturert && kanMerkeFakturert"
                      (click)="merkFakturaFakturert(fakturalinje.id)">Merk fakturert</button>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div *ngIf="fakturalinjer.length !== activeFakturalinjer.length" class="d-flex justify-content-center my-4">
      <button type="button" (click)="loadMoreActiveElements()" class="btn btn-outline-primary btn-circle btn-xl">Vis
        Flere</button>
    </div>

  </div>



</div>
