import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EndringsloggService {

  constructor(private http: HttpClient, @Inject("BASE_URL") private baseUrl: string) { }

  getEndringslogg(type: string, id: number) : Observable<Endringslogg[]> {
    return this.http.get<Endringslogg[]>(this.baseUrl + "api/" + type + "/" + id + "/endringslogg");
  }
}

export interface Endringslogg {
  endringsloggId: number;
  endretAvNavn: string;
  endretTid: Date;
  endringsType: string;
  beskrivelse: string;

}
