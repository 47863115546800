<div *ngIf="harTilgang">

    <app-opprett-ny-knapp> </app-opprett-ny-knapp>

    <h1>{{overskrift}}</h1>

    <app-soketekstfilter (onFilterChange)="onFilterChange($event)" [filterParams]="filterParams"></app-soketekstfilter>

    <app-oppdrag-filter (onFilterChange)="onFilterChange($event)" [filterParams]="filterParams"></app-oppdrag-filter>

    <app-paginator (onFilterChange)="onFilterChange($event)" [filterParams]="filterParams"></app-paginator>

    <app-spinner [loadingData]="loadingData"></app-spinner>

    <div *ngIf="searchResults">
        <p class="text-center">Antall treff ({{filterParams.numberOfHits}})</p>

        <div class="search-results">
            <div *ngFor="let oppdrag of searchResults" class="list-group-flush">
                <a [routerLink]="['/oppdrag',oppdrag.id]"
                    class="list-group-item list-group-item-action flex-column align-items-start">
                    <h5>{{oppdrag.navn}}
                        <span class="text-muted">({{oppdrag.id}})</span>
                    </h5>
                    <div class="text-muted">{{oppdrag.beskrivelse}}</div>
                </a>

            </div>
        </div>
    </div>
</div>

<app-ikketilgang [harTilgang]="harTilgang" [loadingData]="loadingData" [overskrift]="overskrift"></app-ikketilgang>
<app-spinner [loadingData]="loadingData && searchResults?.length>1"></app-spinner>