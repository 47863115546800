<div>

    <div id="printable">

        <div class="modal-header">
            <h4 class="modal-title">Fakturering Oppdrag </h4>
            <!--             <button type="button" (click)="true" data-toggle="tooltip" data-placement="right" title="Åpne i print-mode"
                class="btn btn-secondary"><i class="fas fa-external-link-alt"></i></button> -->
        </div>
        <div class="modal-body">

            <app-spinner [loadingData]="isLoading" [loadingFailed]="errorLoading" [loadingText]="'Genererer Rapport'">
            </app-spinner>

            <div *ngIf="oppdrag && fakturalinjer != undefined && fakturalinjerDrift != undefined">

                <h3><u>{{oppdrag.navn}}</u></h3>
                <h5><b>Prosjekteier : {{prosjektEier?.navn}}</b></h5>
                <p>Ressursnummer : {{oppdrag.ressursnr}}</p>
                <p>Ansvarssted : {{oppdrag.ansvarssted}}</p>
                <p>Saksnummer : {{oppdrag.saksnummer}}</p>
                <p>Tjeneste : {{oppdrag.tjeneste}}</p>
                <b>
                    <hr />
                </b>
                <h5><b>Leveranseansvarlig Ansattservice :
                        {{leveranseansvarlig?.navn}}</b></h5>
                <p>For spørsmål angående fakturagrunnlag, kontakt leveranseansvarlig</p>

                <hr />
                <h5>Timer</h5>
                <hr />

                <div class="container">
                    <div class="table-responsive">
                        <table #tOppdrag class="table" id="tOppdrag">
                            <thead>
                                <tr>
                                    <th scope="col">Navn</th>
                                    <th scope="col">Dato</th>
                                    <th scope="col">Normaltid</th>
                                    <th scope="col">Overtid</th>
                                    <th scope="col">Total tid</th>
                                    <th scope="col">Fast beløp</th>
                                    <th scope="col">Beskrivelse</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let fakturalinje of fakturalinjer; let i = index">
                                    <td>{{fakturalinje.personNavn}}</td>
                                    <td>{{fakturalinje.fra | date: dateFormat}}</td>
                                    <td>{{fakturalinje.normaltid / 36000000000}}</td>
                                    <td>{{fakturalinje.overtid / 36000000000}}</td>
                                    <td>{{(fakturalinje.normaltid + fakturalinje.overtid)/36000000000}}</td>
                                    <td>{{fakturalinje.fastBelop}}</td>
                                    <td>{{fakturalinje.beskrivelse}}</td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr class="table-secondary">
                                    <th scope="col">Sum normaltid</th>
                                    <th scope="col">Sum overtid</th>
                                    <th scope="col">Normaltid</th>
                                    <th scope="col">Overtid</th>
                                    <th scope="col">Totaltid</th>
                                    <th scope="col">Sum fast beløp</th>
                                    <th scope="col">Totalt</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="table-secondary">
                                    <td>{{accSumNormaltid()| currency: 'kr '}}</td>
                                    <td>{{accSumOvertid()| currency: 'kr '}}</td>
                                    <td>{{accNormaltid()}}</td>
                                    <td>{{accOvertid()}}</td>
                                    <td>{{totalTid()}}</td>
                                    <td>{{accSumFastBelop()| currency: 'kr '}}</td>
                                    <td><b>{{totalSumTimer()| currency: 'kr '}}</b></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <hr />
                <h5>Drift</h5>
                <hr />

                <div class="container">
                    <div class="table-responsive">
                        <table #tDrift class="table" id="tDrift">
                            <thead>
                                <tr>
                                    <th scope="col">Fra</th>
                                    <th scope="col">Til</th>
                                    <th scope="col">Sum server</th>
                                    <th scope="col">Sum server test</th>
                                    <th scope="col">Sum MSSQL database</th>
                                    <th scope="col">Sum Oracle database</th>
                                    <th scope="col">Sum ikke-std database</th>
                                    <th scope="col">Sum appdrift</th>
                                    <th scope="col">Sum tillegg</th>
                                    <th scope="col">#</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let fakturalinjeDrift of fakturalinjerDrift; let i = index">
                                    <td>{{fakturalinjeDrift.fra | date: dateFormat}}</td>
                                    <td>{{fakturalinjeDrift.til | date: dateFormat}}</td>
                                    <td>{{fakturalinjeDrift.sumServer| currency: 'kr '}}</td>
                                    <td>{{fakturalinjeDrift.sumServerTest| currency: 'kr '}}</td>
                                    <td>{{fakturalinjeDrift.sumSqlDb| currency: 'kr '}}</td>
                                    <td>{{fakturalinjeDrift.sumOracleDb| currency: 'kr '}}</td>
                                    <td>{{fakturalinjeDrift.sumIkkeStdDb| currency: 'kr '}}</td>
                                    <td>{{fakturalinjeDrift.sumAppdrift| currency: 'kr '}}</td>
                                    <td>{{fakturalinjeDrift.sumTillegg| currency: 'kr '}}</td>
                                    <td></td>
                                </tr>
                            </tbody>

                            <thead>
                                <tr class="table-secondary">
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col">Sum server</th>
                                    <th scope="col">Sum server test</th>
                                    <th scope="col">Sum MSSQL database</th>
                                    <th scope="col">Sum Oracle database</th>
                                    <th scope="col">Sum ikke-std database</th>
                                    <th scope="col">Sum appdrift</th>
                                    <th scope="col">Sum tillegg</th>
                                    <th scope="col">Totalt</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="table-secondary">
                                    <td></td>
                                    <td></td>
                                    <td>{{accSumServer()| currency: 'kr '}}</td>
                                    <td>{{accSumServerTest()| currency: 'kr '}}</td>
                                    <td>{{accSumMsSql()| currency: 'kr '}}</td>
                                    <td>{{accSumOracle()| currency: 'kr '}}</td>
                                    <td>{{accSumIkkeStd()| currency: 'kr '}}</td>
                                    <td>{{accSumAppdrift()| currency: 'kr '}}</td>
                                    <td>{{accSumTillegg()| currency: 'kr '}}</td>
                                    <td><b>{{totalSumDrift()| currency: 'kr '}}</b></td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>


                <div class="container">
                    <div class="table-responsive">
                        <table class="table" id="tSum">
                            <thead>
                                <tr class="table-secondary">
                                    <th scope="col">Sum timer og drift</th>
                                    <th scope="col"><b class="float-right">{{totalSum()| currency: 'kr '}}</b></th>
                                </tr>
                            </thead>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">

        <button type="button" class="btn btn-outline-secondary" (click)="exportExcel()" placement="top"
            ngbTooltip="Eksporter til Excel" [openDelay]="400" [closeDelay]="200"><i
                class="fas fa-file-excel"></i></button>

        <button type="button" class="btn btn-outline-secondary" (click)="onBtnPrintClick()" placement="top"
            ngbTooltip="Print" [openDelay]="400" [closeDelay]="200"><i class="fas fa-print"></i></button>

        <button type="button" class="btn btn-outline-secondary ml-4" (click)="cancel()" placement="top"
            ngbTooltip="Lukk" [openDelay]="400" [closeDelay]="200">Lukk</button>
    </div>
</div>
