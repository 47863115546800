import { formatDate } from '@angular/common';
import { Component, EventEmitter, Inject, Injectable, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbDateAdapter, NgbDateStruct, NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';



@Injectable()
export class MyNgbDateAdapter extends NgbDateAdapter<Date> {
  fromModel(value: Date): NgbDateStruct {
    if (!value) return null;
    return { day: value.getDate(), month: value.getMonth() + 1, year: value.getFullYear() };
  }
  toModel(date: NgbDateStruct): Date {
    if (!date) return null;
    return new Date(date.year, date.month - 1, date.day);
  }
}

@Injectable()
export class MyNgbTimeAdapter extends NgbTimeAdapter<Date> {
  fromModel(value: Date): NgbTimeStruct {

    console.log(value)

    if (!value) return null;
    return { hour: value.getHours(), minute: value.getMinutes(), second: value.getSeconds() };
  }
  toModel(time: NgbTimeStruct): Date {

    console.log(time)

    if (!time) return null;
    const dt = new Date();
    dt.setHours(time.hour);
    dt.setMinutes(Math.round(time.minute / 30) * 30);
    dt.setSeconds(time.second);
    return dt;
  }
}


@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [{ provide: NgbTimeAdapter, useClass: MyNgbTimeAdapter }, { provide: NgbDateAdapter, useClass: MyNgbDateAdapter }]
})
export class DateTimePickerComponent implements OnInit {

  ngOnInit(): void {
    //asd
  }

  @Input() submitted = false;
  @Input() formGroup: FormGroup;
  @Input() formControlNameDate: string;
  @Input() formControlNameTime: string;
  @Input() minuteStep = 30;

  @Output() InputClosed = new EventEmitter();

  constructor(@Inject(LOCALE_ID) private locale: string) { }

  get timeView(): string {
    const time = this.formControlTime.value as Date;
    return formatDate(time, "HH:mm", this.locale);
  }

  get formControlDate(): FormControl {
    return this.formGroup.get(this.formControlNameDate) as FormControl;
  }
  get formControlTime(): FormControl {
    return this.formGroup.get(this.formControlNameTime) as FormControl;
  }

  closed(): void {
    this.InputClosed.emit();
  }

}
