import {ChangeDetectorRef, Component, ViewChild} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { FeltSideBaseClass } from "../common/feltside/feltside.component";
import {Tab, TabsComponent} from "../common/tabs/tabs.component";
import { Tabs } from "../common/tabs/tabs.enum";
import { NotificationService } from "../services/notification.service";
import { TabAccess, TilgangService } from "../services/tilgang.service";
import { Tjeneste, TjenesterService } from "./tjenester.service";
import {OppdragFakturalinjerDriftTabComponent} from "../oppdrag/oppdrag-fakturalinjer-drift-tab/oppdrag-fakturalinjer-drift-tab.component";
import {EndringsloggTabComponent} from "../endringslogg-tab-component/endringslogg-tab.component";



@Component({
  selector: "app-tjeneste",
  templateUrl: "../common/feltside/feltside.component.html"
})
export class TjenesteComponent extends FeltSideBaseClass<Tjeneste> {


  idParamName = "tjenesteId";

  constructor(
    private tjenesterService: TjenesterService,
    private tilgangService: TilgangService,
    route: ActivatedRoute,
    notifyService: NotificationService,
    changeDetectorRef: ChangeDetectorRef

  ) {
    super(tjenesterService, notifyService, route, changeDetectorRef);

  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getAvailableTabs(): Tab[] {
    return [
      { key: Tabs.systemer, label: "Systemer", parentRoute: ["/tjenester", this.id + ""] },
      { key: Tabs.endringslogg, label: "Endringslogg", parentRoute: ["/tjenester", this.id + ""] }
    ];
  }
  getTabAccess(): Observable<TabAccess[]> {
    return this.tilgangService.getTabAccess("tjeneste", this.id);
  }

}
