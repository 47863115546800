import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TabSideBaseClass } from "src/app/common/tab-side/tab-side.component";
import { Tabs } from "src/app/common/tabs/tabs.enum";
import { TabsService } from "src/app/common/tabs/tabs.service";
import { KoblingerService, PersonEndringRolle } from "src/app/koblinger/koblinger.service";
import { ModalService } from "src/app/services/modal.service";
import { TilgangService } from "src/app/services/tilgang.service";

@Component({
  selector: "app-endring-utforere",
  templateUrl: "./endring-utforere.component.html",
  styleUrls: ["./endring-utforere.component.scss"]
})
export class EndringUtforereComponent extends TabSideBaseClass<PersonEndringRolle> {
  
  tabName(): Tabs {
    return Tabs.endringer;
  }

  constructor(tabsService: TabsService, service: KoblingerService, tilgangService: TilgangService, route: ActivatedRoute, router: Router, modal: ModalService) {
    super(tabsService, service, tilgangService, modal, route, router);
  }

  loaderObservable(service: KoblingerService, id: number): Observable<PersonEndringRolle[]> {
    return this.service.getPersonEndringForEndring(id);
  }

  pageLinkAccess(service: TilgangService): Observable<boolean> {
    return service.getGlobalRoles().pipe(map(val => val.personer.listAccess));
  }

  removeObservable(service: KoblingerService, item: PersonEndringRolle): Observable<any> {
    return this.service.removePersonEndringRolle(item.endringId, item.personId, item.rolleId);
  }

  kobleTil(): void {

    this.modal.velgPerson().then(person => {
      this.modal.velgPersonRolle().then(rolle => {

        if (this.liste.some(t => t.endringId == this.id && t.personId === person.personId && t.rolleId === rolle.rolleId)) {
          this.modal.warning(`${person.navn} med rolle ${rolle.navn} er allerede koblet til endring`, "Kobling feilet");
          return;
        }

        const per: PersonEndringRolle = { endringId: this.id, personId: person.personId, rolleId: rolle.rolleId };
        this.service.postPersonEndringRolle(per).subscribe(() => {
          this.modal.success("", "Kobling opprettet");
          this.load();
        }, e => {
          console.log(e);
        });
      }, e => {
        console.log(e);
      });
    }, e => {
      console.log(e);
    });
  }

}
