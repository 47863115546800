<div *ngIf="modell" class="inline-form m-2 p-2">
  <div *ngIf="inputType == 'checkbox'" class="form-check">
    <input id="{{key}}" type="checkbox" class="form-check-input" [formControl]="control">
    <label for="{{key}}" class="form-check-label"> {{tekst}}<span *ngIf="control.dirty">*</span> </label>
  </div>
  <div *ngIf="inputType == 'checkbox'">
    <input *ngIf="getPrisLabel(key)" id="sum" type="text" readonly class="form-control-plaintext"
           [value]="pris | currency: 'kr '">
  </div>
  <div *ngIf="inputType == 'number'">
    <label for="{{key}}" class="col-form-label"> {{tekst}}<span *ngIf="control.dirty">*</span> </label>
    <input id="{{key}}" type="number" [ngClass]=" control.invalid ? 'form-control is-invalid' : 'form-control'"
           [formControl]="control">
    <small *ngIf="field?.validationChecks?.required && (!modell[key] && modell[key]!==0)" id="maxLengthHelp"
           class="form-text text-danger float-right">Required</small>

    <input *ngIf="getPrisLabel(key)" id="sum" type="text" readonly class="form-control-plaintext"
           [value]="pris | currency: 'kr '">

  </div>
  <div *ngIf="inputType == 'text'">
    <label for="{{key}}" class="col-form-label"> {{tekst}}<span *ngIf="control.dirty">*</span> </label>
    <input id="{{key}}" type="text" [ngClass]=" control.invalid ? 'form-control is-invalid' : 'form-control'"
           [formControl]="control">
    <small *ngIf="field?.validationChecks?.required && (!modell[key] && modell[key]!==0)" id="maxLengthHelp"
           class="form-text text-danger float-right">Required</small>
    <small *ngIf="field?.validationChecks?.maxLength && modell[key]" id="maxLengthHelp"
           [ngClass]=" modell[key].length > field.validationChecks.maxLength ? 'form-text text-danger' : 'form-text text-muted'">{{modell[key].length}}
      /{{field.validationChecks.maxLength}}</small>
  </div>


</div>
