<div *ngIf="priser.size > 0">
  <div *ngFor="let field of fields">
    <app-prisfelt [formGroup]="formGroup" [field]="field" [modell]="modell" [initialModell]="initialModell"
                  [pris]="priser.get(field)" (valueChanged)="rekalkulerPris(field)"></app-prisfelt>
  </div>

  <div class="inline-form m-2 p-2 column" *ngIf="erOppdrag()">
    <label for="sumMndOppdrag" class="col-form-label">Driftspris pr. måned i oppdragsfase:</label>
    <input id="sumMndOppdrag" type="text" readonly class="form-control-plaintext"
           [value]="prisPrMaaned | currency: 'kr '">
  </div>


  <div class="inline-form m-2 p-2 column">
    <label for="sumTilgjengelighet" class="col-form-label row"
           style="margin-left: 0px;">Tilgjengelighetsnivå="{{tilgjengelighetNivaa}}":</label>
    <input id="sumTilgjengelighet" type="text" readonly class="form-control-plaintext"
           [value]="prisTilgjengelighet | currency: 'kr '">
  </div>

  <div class="inline-form m-2 p-2 column" *ngIf="!erOppdrag()">
    <label for="sumIntegrasjon" class="col-form-label">Integrasjoner pr. år:</label>
    <input id="sumIntegrasjon" type="text" readonly class="form-control-plaintext"
           [value]="prisIntegrasjon | currency: 'kr '">
  </div>

  <div class="inline-form m-2 p-2 column" *ngIf="!erOppdrag()">
    <label for="sumOppdatering" class="col-form-label">Oppdateringer pr. år:</label>
    <input id="sumOppdatering" type="text" readonly class="form-control-plaintext"
           [value]="prisOppdateringer | currency: 'kr '">
  </div>

  <div class="inline-form m-2 p-2 column" *ngIf="harDognbemannetVaktlag()">
    <label for="sumVaktlag" class="col-form-label">Vaktlag pris pr. år:</label>
    <input id="sumVaktlag" type="text" readonly class="form-control-plaintext"
           [value]="prisVaktlagPrAar | currency: 'kr '">
  </div>

  <div class="inline-form m-2 p-2 column">
    <label for="sumAr" class="col-form-label">Driftspris pr. år:</label>
    <input id="sumAr" type="text" readonly class="form-control-plaintext"
           [value]="prisPrAar | currency: 'kr '">
  </div>

  <div class="inline-form m-2 p-2 column" *ngIf="erOppdrag()">
    <label for="sumArOppdrag" class="col-form-label">Driftspris pr. år i oppdragsfase:</label>
    <input id="sumArOppdrag" type="text" readonly class="form-control-plaintext"
           [value]="prisPrAarOppdragsfase | currency: 'kr '">
  </div>

</div>

