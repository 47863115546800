import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TabSideBaseClass } from "src/app/common/tab-side/tab-side.component";
import { Tabs } from "src/app/common/tabs/tabs.enum";
import { TabsService } from "src/app/common/tabs/tabs.service";
import { KoblingerService, OppdragSystem } from "src/app/koblinger/koblinger.service";
import { ModalService } from "src/app/services/modal.service";
import { TilgangService } from "src/app/services/tilgang.service";
import {Itsystem} from "../../itsystemer/itsystemer.service";


@Component({
  selector: "app-systemer-tab",
  templateUrl: "./oppdragsystemer-tab.component.html"
})
export class OppdragSystemerTabComponent extends TabSideBaseClass<OppdragSystem> {

  tabName(): Tabs {
    return Tabs.systemer;
  }

  constructor(tabsService : TabsService,service: KoblingerService, tilgangService: TilgangService, route: ActivatedRoute, modal: ModalService, router: Router) {
    super(tabsService, service, tilgangService, modal, route, router);
  }

  loaderObservable(service: KoblingerService, id: number): Observable<OppdragSystem[]> {
    return service.getOppdragSystemForOppdrag(this.id);
  }

  pageLinkAccess(service: TilgangService): Observable<boolean> {
    return service.getGlobalRoles().pipe(map(val => val.itsystemer.listAccess));
  }

  removeObservable(service: KoblingerService, item: OppdragSystem): Observable<object> {
    return service.removeOppdragSystemForOppdrag(item.oppdragId, item.systemId);
  }

  kobleTil(): void {
    this.modal.velgSystem().then(system => {

      if (this.liste.some(t => t.systemId === system.systemId)) {
        this.modal.warning(`${system.navn} er allerede koblet til oppdrag`, "Kobling feilet");
        return;
      }

      const oppdragSystem: OppdragSystem = { oppdragId: this.id, systemId: system.systemId };
      this.service.postOppdragSystemForOppdrag(oppdragSystem).toPromise().then(() => {
        this.modal.success("System koblet til oppdrag", "kobling vellykket");
        this.load();
      });
    });
  }

  public erUtgaatt(system: Itsystem) : boolean {
    return system.systemstatusId == 5;
  }

  compareFn(first: OppdragSystem, second: OppdragSystem): number {
    if (first.system.systemstatusId != 5 && second.system.systemstatusId != 5) return 0;
    if (first.system.systemstatusId != 5 && second.system.systemstatusId == 5) return -1;
    else return 1;
  }
}
