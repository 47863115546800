import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Field, SearchResults } from "../common/common.model";
import { Endring } from "../itsystem/endring-tab/endringer.service";

@Injectable({
  providedIn: "root"
})
export class FaktureringService {

  private baseUrl: string;

  constructor(private http: HttpClient, @Inject("BASE_URL") private baseHost: string) {
    this.baseUrl = baseHost + "api/faktura/";
  }

  generateExcelReportItsystem(fra?: string, til?: string, sortOrder?: string, visFakturerte = false, visGodkjente = true, systemId?: number, systemtype?: string, pageNumber = 1, pageSize = 1000): Observable<Blob> {
    let searchParams = new HttpParams();
    if (fra) { searchParams = searchParams.append("fra", fra); }
    if (til) { searchParams = searchParams.append("til", til); }
    if (sortOrder) { searchParams = searchParams.append("sortOrder", sortOrder); }
    if (systemId) { searchParams = searchParams.append("systemId", systemId.toString()); }
    if (systemtype) { searchParams = searchParams.append("systemtype", systemtype); }
    searchParams = searchParams.append("visFakturerte", String(visFakturerte));
    searchParams = searchParams.append("visGodkjente", String(visGodkjente));
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get(this.baseHost + "api/report/itsystem-report", { params: searchParams, responseType: "blob" });
  }

  generateExcelReportOppdrag(fra?: string, til?: string, sortOrder?: string, visFakturerte = false, visGodkjente = true, oppdragId?: number, pageNumber = 1, pageSize = 1000): Observable<Blob> {
    let searchParams = new HttpParams();
    if (fra) { searchParams = searchParams.append("fra", fra); }
    if (til) { searchParams = searchParams.append("til", til); }
    if (sortOrder) { searchParams = searchParams.append("sortOrder", sortOrder); }
    if (oppdragId) { searchParams = searchParams.append("oppdragId", oppdragId.toString()); }
    searchParams = searchParams.append("visFakturerte", String(visFakturerte));
    searchParams = searchParams.append("visGodkjente", String(visGodkjente));
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get(this.baseHost + "api/report/oppdrag-report", { params: searchParams, responseType: "blob" });
  }



  generateExcelReportSystemDrift(): Observable<Blob> {
    return this.http.get(this.baseHost + "api/report/driftpriser", { responseType: "blob" });
  }


  searchItsystemFakturaerUser(fra?: string, til?: string, sortOrder?: string, visFakturerte = false, visGodkjente = true, systemId?: number, systemtype: string = "fagsystem", pageNumber = 1, pageSize = 30): Observable<SearchResults<Fakturalinje>> {
    let searchParams = new HttpParams();
    if (fra) { searchParams = searchParams.append("fra", fra); }
    if (til) { searchParams = searchParams.append("til", til); }
    if (sortOrder) { searchParams = searchParams.append("sortOrder", sortOrder); }
    if (systemId) { searchParams = searchParams.append("systemId", systemId.toString()); }
    searchParams = searchParams.append("visFakturerte", String(visFakturerte));
    searchParams = searchParams.append("visGodkjente", String(visGodkjente));
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());
    searchParams = searchParams.append("systemtype", systemtype);

    return this.http.get<SearchResults<Fakturalinje>>(this.baseUrl + "searchitsystem/user", { params: searchParams });
  }

  searchOppdragFakturaerUser(fra?: string, til?: string, sortOrder?: string, visFakturerte = false, visGodkjente = true, oppdragId?: number, pageNumber = 1, pageSize = 30): Observable<SearchResults<Fakturalinje>> {

    let searchParams = new HttpParams();
    if (fra) { searchParams = searchParams.append("fra", fra); }
    if (til) { searchParams = searchParams.append("til", til); }
    if (sortOrder) { searchParams = searchParams.append("sortOrder", sortOrder); }
    if (oppdragId) { searchParams = searchParams.append("oppdragId", oppdragId.toString()); }
    searchParams = searchParams.append("visFakturerte", String(visFakturerte));
    searchParams = searchParams.append("visGodkjente", String(visGodkjente));
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<SearchResults<Fakturalinje>>(this.baseUrl + "searchoppdrag/user", { params: searchParams });
  }



  searchItsystemFakturaer(fra?: string, til?: string, sortOrder?: string, visFakturerte = false, visGodkjente = true, systemId?: number, pageNumber = 1, pageSize = 30): Observable<SearchResults<Fakturalinje>> {
    let searchParams = new HttpParams();
    if (fra) { searchParams = searchParams.append("fra", fra); }
    if (til) { searchParams = searchParams.append("til", til); }
    if (sortOrder) { searchParams = searchParams.append("sortOrder", sortOrder); }
    if (systemId) { searchParams = searchParams.append("systemId", systemId.toString()); }
    searchParams = searchParams.append("systemtype", "Fagsystem");
    searchParams = searchParams.append("visFakturerte", String(visFakturerte));
    searchParams = searchParams.append("visGodkjente", String(visGodkjente));
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<SearchResults<Fakturalinje>>(this.baseUrl + "searchitsystem", { params: searchParams });
  }

  searchProgrampakkeFakturaer(fra?: string, til?: string, sortOrder?: string, visFakturerte = false, visGodkjente = true, systemId?: number, pageNumber = 1, pageSize = 30): Observable<SearchResults<Fakturalinje>> {

    let searchParams = new HttpParams();
    if (fra) { searchParams = searchParams.append("fra", fra); }
    if (til) { searchParams = searchParams.append("til", til); }
    if (sortOrder) { searchParams = searchParams.append("sortOrder", sortOrder); }
    if (systemId) { searchParams = searchParams.append("systemId", systemId.toString()); }
    searchParams = searchParams.append("systemtype", "Programpakke");
    searchParams = searchParams.append("visFakturerte", String(visFakturerte));
    searchParams = searchParams.append("visGodkjente", String(visGodkjente));
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<SearchResults<Fakturalinje>>(this.baseUrl + "searchitsystem", { params: searchParams });
  }

  searchOppdragFakturaer(fra?: string, til?: string, sortOrder?: string, visFakturerte = false, visGodkjente = true, oppdragId?: number, pageNumber = 1, pageSize = 30): Observable<SearchResults<Fakturalinje>> {

    let searchParams = new HttpParams();
    if (fra) { searchParams = searchParams.append("fra", fra); }
    if (til) { searchParams = searchParams.append("til", til); }
    if (sortOrder) { searchParams = searchParams.append("sortOrder", sortOrder); }
    if (oppdragId) { searchParams = searchParams.append("oppdragId", oppdragId.toString()); }
    searchParams = searchParams.append("visFakturerte", String(visFakturerte));
    searchParams = searchParams.append("visGodkjente", String(visGodkjente));
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<SearchResults<Fakturalinje>>(this.baseUrl + "searchoppdrag", { params: searchParams });
  }

  searchOppdragDriftFakturaer(fra?: string, til?: string, sortOrder?: string, visFakturerte = false, visGodkjente = true, oppdragId?: number, pageNumber = 1, pageSize = 30): Observable<SearchResults<OppdragFakturalinjeDrift>> {

    let searchParams = new HttpParams();
    if (fra) { searchParams = searchParams.append("fra", fra); }
    if (til) { searchParams = searchParams.append("til", til); }
    if (sortOrder) { searchParams = searchParams.append("sortOrder", sortOrder); }
    if (oppdragId) { searchParams = searchParams.append("oppdragId", oppdragId.toString()); }
    searchParams = searchParams.append("visFakturerte", String(visFakturerte));
    searchParams = searchParams.append("visGodkjente", String(visGodkjente));
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<SearchResults<OppdragFakturalinjeDrift>>(this.baseUrl + "searchoppdragdrift", { params: searchParams });
  }

  searchSystemDriftFakturaer(fra?: string, systemStatus?: number, systemType?: number, sortOrder?: string, tekst?: string, pageNumber = 1, pageSize = 30): Observable<SearchResults<SystemFakturalinjeDrift>> {
    let searchParams = new HttpParams();
    if (fra) { searchParams = searchParams.append("fra", fra); }
    if (systemStatus) { searchParams = searchParams.append("systemStatus", systemStatus); }
    if (systemType) { searchParams = searchParams.append("systemType", systemType); }
    if (sortOrder) { searchParams = searchParams.append("sortOrder", sortOrder); }
    if (tekst) { searchParams = searchParams.append("tekst", tekst); }
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<SearchResults<SystemFakturalinjeDrift>>(this.baseUrl + "searchsystemdrift", { params: searchParams });
  }


  getItsystemFakturalinje(systemId: number = null, fakturaId: number): Observable<Fakturalinje> {
    return this.http.get<Fakturalinje>(this.baseUrl + `itsystem/${systemId}/faktura/${fakturaId}`);
  }

  getOppdragFakturalinje(oppdragId = 0, fakturaId: number): Observable<Fakturalinje> {
    return this.http.get<Fakturalinje>(this.baseUrl + `oppdrag/${oppdragId}/faktura/${fakturaId}`);
  }

  getOppdragFakturalinjeDrift(fakturaId: number): Observable<OppdragFakturalinjeDrift> {
    return this.http.get<OppdragFakturalinjeDrift>(this.baseUrl + `oppdrag/fakturadrift/${fakturaId}`);
  }






  lagreFakturalinje(faktura: Fakturalinje) {
    if (faktura.fakId) {
      return this.http.put(this.baseUrl + `itsystem/${faktura.systemId}/faktura/${faktura.fakId}`, faktura);
    } else {
      return this.http.post(this.baseUrl + `itsystem/${faktura.systemId}/faktura`, faktura);
    }
  }

  lagreOppdragFakturalinje(faktura: Fakturalinje) {
    if (faktura.fakId) {
      return this.http.put(this.baseUrl + `oppdrag/${faktura.oppdragId}/faktura/${faktura.fakId}`, faktura);
    } else {
      return this.http.post(this.baseUrl + `oppdrag/${faktura.oppdragId}/faktura`, faktura);
    }
  }

  lagreFakturalinjeDrift(faktura: OppdragFakturalinjeDrift) {
    return this.http.put(this.baseUrl + `oppdrag/fakturadrift/${faktura.id}`, faktura);
  }



  getEndringFakturalinjer(systemId: number, endringsId: number): Observable<Fakturalinje[]> {
    return this.http.get<Fakturalinje[]>(this.baseUrl + `itsystem/${systemId}/endring/${endringsId}`);
  }


  // api/faktura/itsystem/{systemid}
  getItsystemFakturalinjer(systemId: number): Observable<Fakturalinje[]> {
    return this.http.get<Fakturalinje[]>(this.baseUrl + `itsystem/${systemId}`);
  }

  getFakturalinjerSystemdrift(): Observable<SystemFakturalinjeDrift[]> {
    return this.http.get<SystemFakturalinjeDrift[]>(this.baseUrl + `fakturalinjersystemdrift`);
  }

  getOppdragFakturalinjer(oppdragId: number): Observable<Fakturalinje[]> {
    return this.http.get<Fakturalinje[]>(this.baseUrl + `oppdrag/${oppdragId}`);
  }

  getOppdragFakturalinjerDrift(oppdragId: number): Observable<OppdragFakturalinjeDrift[]> {
    return this.http.get<OppdragFakturalinjeDrift[]>(this.baseUrl + `oppdrag/${oppdragId}/drift`);
  }

  getFakturalinjerFields(): Observable<Field[]> {
    return this.http.get<Field[]>(this.baseUrl + "fields");
  }

  getFakturalinjerDriftFields(): Observable<Field[]> {
    return this.http.get<Field[]>(this.baseUrl + "fields/drift");
  }

  getPriser(): Observable<Pris[]> {
    return this.http.get<Pris[]>(this.baseHost + "api/grunndata/priser");
  }

  kalkulerFakturapriser(fra: Date | string, til: Date | string): Observable<Fakturalinje> {
    const fakturaPris = { fra: fra, til: til } as FakturaPris;
    return this.http.post<Fakturalinje>(this.baseUrl + "kalkulerPris/", fakturaPris);
  }



  godkjennItsystemFaktura(fakturaId: number, systemId: number): Observable<Fakturalinje> {
    return this.http.put<Fakturalinje>(this.baseUrl + `itsystem/${systemId}/faktura/${fakturaId}/godkjenn`, null, { observe: "body" });
  }

  godkjennItsystemFakturaer(fakturaIder: number[]): Observable<Fakturalinje[]> {
    return this.http.post<Fakturalinje[]>(this.baseUrl + `itsystem/faktura/godkjenn`, fakturaIder);
  }

  godkjennOppdragFaktura(fakturaId: number, oppdragId: number): Observable<Fakturalinje> {
    return this.http.put<Fakturalinje>(this.baseUrl + `oppdrag/${oppdragId}/faktura/${fakturaId}/godkjenn`, null, { observe: "body" });
  }

  godkjennOppdragDriftFaktura(fakturaId: number, oppdragId: number) : Observable<OppdragFakturalinjeDrift> {
    return this.http.put<OppdragFakturalinjeDrift>(this.baseUrl + `oppdrag/${oppdragId}/fakturadrift/${fakturaId}/godkjenn`, null, { observe: "body" });
  }

  godkjennOppdragFakturaer(fakturaIder: number[]): Observable<Fakturalinje[]> {
    return this.http.post<Fakturalinje[]>(this.baseUrl + `oppdrag/faktura/godkjenn`, fakturaIder);
  }

  merkFakturertItsystemFaktura(fakturaId: number, systemId: number): Observable<Fakturalinje> {
    return this.http.put<Fakturalinje>(this.baseUrl + `itsystem/${systemId}/faktura/${fakturaId}/fakturert`, null, { observe: "body" });
  }
  markAlleFakturertItsystemFaktura(fakturaIder: number[]): Observable<Fakturalinje[]> {
    return this.http.post<Fakturalinje[]>(this.baseUrl + `itsystem/faktura/fakturert`, fakturaIder);
  }


  merkFakturertOppdragFaktura(fakturaId: number, oppdragId: number): Observable<Fakturalinje> {
    return this.http.put<Fakturalinje>(this.baseUrl + `oppdrag/${oppdragId}/faktura/${fakturaId}/fakturert`, null, { observe: "body" });
  }
  markAlleFakturertOppdragFaktura(fakturaIder: number[]): Observable<Fakturalinje[]> {
    return this.http.post<Fakturalinje[]>(this.baseUrl + `oppdrag/faktura/fakturert`, fakturaIder);
  }

  merkFakturertOppdragDriftFaktura(fakturaId: number, oppdragId: number): Observable<OppdragFakturalinjeDrift> {
    return this.http.put<OppdragFakturalinjeDrift>(this.baseUrl + `oppdrag/${oppdragId}/fakturadrift/${fakturaId}/fakturert`, null, { observe: "body" });
  }

  // DELETE Fakturalinje
  deleteOppdragFakturlinje(fakturaId: number, oppdragId: number) {
    return this.http.delete(this.baseUrl + `oppdrag/${oppdragId}/faktura/${fakturaId}`);
  }

  deleteItsystemFakturlinje(fakturaId: number, systemId: number) {
    return this.http.delete(this.baseUrl + `itsystem/${systemId}/faktura/${fakturaId}`);
  }

}

export interface FakturaPris {
  fra: Date;
  til: Date;
}

export interface Fakturalinje {
  fakId: number;
  systemId: number;
  systemNavn: string;
  endringId: number;
  endring?: Endring;
  oppdragId: number;
  oppdragNavn: string;
  personNavn: string;
  utfortAv: string;
  personId: number;
  fra: Date | string;
  til: Date | string;
  beskrivelse: string;
  sertifikat: boolean;
  normaltid: number;
  overtid: number;
  sumNormaltid: number;
  sumOvertid: number;
  fastBelop: number;
  godkjent: boolean;
  godkjentDato: Date;
  godkjentAvNavn: string;
  godkjentAv: string;
  godkjentAvId: number;
  fakturert: boolean;
  fakturertDato: Date;
}

export interface Pris {
  prisId: number;
  navn: string;
  kommentar: string;
  beløp: number;
  prisEnhet: string;
  kode: string;
}

export interface OppdragFakturalinjeDrift {
  id: number;
  oppdragId: number;
  oppdragNavn: string;
  fra: Date;
  til: Date;
  maneder: number;
  sumServer: number;
  sumServerTest: number;
  sumSqlDb: number;
  sumOracleDb: number;
  sumIkkeStdDb: number;
  sumAppdrift: number;
  sumTillegg: number;
  godkjent: boolean;
  godkjentDato: Date;
  godkjentAv: string;
  godkjentAvId: number;
  godkjentAvNavn: string;
  fakturert: boolean;
  fakturertDato: Date;
}

export interface SystemFakturalinjeDrift {
  id: number;
  systemId: number;
  oppdragNavn: string;
  fra: Date;
  til: Date;
  sumServer: number;
  sumServerTest: number;
  sumSqlDb: number;
  sumOracleDb: number;
  sumIkkeStdDb: number;
  sumTilgjengelighet: number;
  sumIntegrasjoner: number;
  sumOppdateringer: number;
  sumVaktlag: number;
  sumTillegg: number;
}

export enum FakturaType {
  Itsystem = "Itsystem",
  Fagsystem = "Fagsystem",
  Programpakke = "Programpakke",
  Oppdrag = "Oppdrag",
  OppdragDrift = "OppdragDrift",
  SystemDrift = "SystemDrift"
}

