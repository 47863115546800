import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Field } from "../common.model";


@Component({
  selector: "app-feltliste",
  templateUrl: "./feltliste.component.html"
})
export class FeltlisteComponent implements OnInit {

  private _modell: any;

  @Input()
  public set modell(v: any) {
    this._modell = v;
    Object.assign(this.initialModell, this._modell);
  }

  public get modell(): any {
    return this._modell;
  }

  initialModell: any = {};


  @Input()
  fields: Field[] = [];
  @Input()
  formGroup: FormGroup;

  ngOnInit(): void {
    // Do nothing
  }

}
