<div *ngIf="!harTilgang">
  Du har ikke tilgang til å registrere nytt Itsystem.
</div>


<div *ngIf="harTilgang" class="container">
  <div class="card">
    <div class="card-body">

      <h2>Opprett nytt IT-System</h2>

      <form (ngSubmit)="onSubmit(itForm)" #itForm="ngForm">


        <div *ngIf="!itsystemCreateOptions" class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <div *ngIf="itsystemCreateOptions">

          <div class="form-group mb-4">
            <label for="navn">Navn</label>
            <span *ngIf="navn.invalid" class="badge badge-pill badge-secondary mx-1">Required</span>
            <input type="text" class="form-control" id="navn" name="navn" placeholder="Navn" required
                   maxlength="100" [(ngModel)]="itsystem.navn" #navn="ngModel">
            <small id="navnHelp"
                   class="form-text text-muted float-right">{{itsystem.navn ? itsystem.navn.length : 0}}/100</small>
          </div>


          <div class="form-group mb-4">
            <label for="beskrivelse">Beskrivelse</label>
            <span *ngIf="beskrivelse.invalid" class="badge badge-pill badge-secondary mx-1">Required</span>
            <textarea class="form-control" id="beskrivelse" placeholder="Beskrivelse" rows="3"
                      name="beskrivelse" required maxlength="1000" [(ngModel)]="itsystem.beskrivelse"
                      #beskrivelse="ngModel"></textarea>
            <small id="beskrivelseHelp"
                   class="form-text text-muted float-right">{{itsystem.beskrivelse ? itsystem.beskrivelse.length : 0}}
              /1000</small>
          </div>

          <div class="form-group">
            <label for="kunde">Byrådsavdeling</label>
            <span *ngIf="kunde.invalid" class="badge badge-pill badge-secondary mx-1">Required</span>
            <div class="input-group">
              <select class="custom-select" id="kunde" [(ngModel)]="itsystem.kundeId"
                      name="kunde" #kunde="ngModel"
                      required>
                <option [ngValue]="undefined" selected disabled>Velg Byrådsavdeling</option>
                <option *ngFor="let kunde of itsystemCreateOptions.kunde"
                        [value]="kunde.kundeId"
                        [attr.selected]="kunde.kundeId==itsystem.kundeId">
                  {{kunde.navn}}</option>
              </select>
            </div>
          </div>

          <div class="form-group">
            <label for="systemtype">System Type</label>
            <select class="custom-select" id="systemtype" [(ngModel)]="itsystem.systemtypeId"
                    #systemtype="ngModel" name="systemtype" required>
              <option *ngFor="let system of itsystemCreateOptions.systemtype"
                      [value]="system.systemtypeId"
                      [attr.selected]="system.systemtypeId==itsystem.systemtypeId">
                {{system.type}}</option>
            </select>
          </div>

          <div class="form-group">
            <label for="systemstatus">System Status</label>
            <select class="custom-select" id="systemstatus" [(ngModel)]="itsystem.systemstatusId"
                    #systemstatus="ngModel" name="systemstatus" required>
              <option *ngFor="let systemstatus of itsystemCreateOptions.systemstatus"
                      [value]="systemstatus.systemstatusId"
                      [attr.selected]="systemstatus.systemstatusId==itsystem.systemstatusId">
                {{systemstatus.navn}}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="kritikalitet">Kritikalitet</label>
            <select class="custom-select" id="kritikalitet" [(ngModel)]="itsystem.kritikalitetId"
                    #kritikalitet="ngModel" name="kritikalitet" required>
              <option *ngFor="let kritikalitet of itsystemCreateOptions.kritikalitet"
                      [value]="kritikalitet.kritikalitetId"
                      [attr.selected]="kritikalitet.kritikalitetId==itsystem.kritikalitetId">
                {{kritikalitet.navn}}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="tilgjengelighet">tilgjengelighet</label>
            <div class="input-group">
              <select class="custom-select" id="tilgjengelighet"
                      [(ngModel)]="itsystem.tilgjengelighetId"
                      aria-describedby="help-driftsniva-button" name="tilgjengelighet" #tilgjengelighet="ngModel"
                      required>
                <option *ngFor="let tilgjengelighet of itsystemCreateOptions.tilgjengelighet"
                        [value]="tilgjengelighet.tilgjengelighetId"
                        [attr.selected]="tilgjengelighet.tilgjengelighetId==itsystem.tilgjengelighetId">
                  {{tilgjengelighet.navn}}</option>
              </select>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" id="help-driftsniva-button"
                        data-toggle="modal" data-target="#driftsniva-help"><i
                  class="fas fa-question-circle"></i></button>
              </div>
            </div>
          </div>


          <button class="btn btn-outline-primary" type="submit" [disabled]="!itForm.form.valid">Opprett
            <span *ngIf="isSaving" class="spinner-grow spinner-grow-sm" role="status"
                  aria-hidden="true"></span>
            <span *ngIf="isSaving" class="sr-only">Loading...</span>
          </button>

          <button type="button" class="btn btn-outline-secondary mx-2"
                  (click)="resetItsystem()">Reset
          </button>


          <!-- Klassifiserings Help-Modal -->
          <div class="modal fade" id="klassifisering-help" tabindex="-1" role="dialog"
               aria-labelledby="klassifisering-help-Title" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="klassifisering-help-Title">Klassifisering</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <ul class="list-group list-group-flush">
                    <li *ngFor="let klass of itsystemCreateOptions.programklassifisering"
                        class="list-group-item">
                      <b>{{klass.navn}}</b> {{klass.beskrivelse}}</li>
                  </ul>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>

          <!-- tilgjengelighet Help-Modal -->
          <div class="modal fade" id="driftsniva-help" tabindex="-1" role="dialog"
               aria-labelledby="driftsniva-help-Title" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="driftsniva-help-Title">Tilgjengelighet</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <ul class="list-group list-group-flush">
                    <li *ngFor="let tilgjengelighet of itsystemCreateOptions.tilgjengelighet"
                        class="list-group-item">
                      <b>{{tilgjengelighet.navn}}</b> {{tilgjengelighet.beskrivelse}}</li>
                  </ul>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>


              </div>
            </div>
          </div>

        </div>

      </form>

    </div>
  </div>
</div>
