import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { Field } from "src/app/common/common.model";
import { NyBaseClass } from "src/app/common/ny/ny-base";
import { NotificationService } from "src/app/services/notification.service";
import { TilgangService } from "src/app/services/tilgang.service";
import { Tjeneste, TjenesterService } from "../tjenester.service";

@Component({
  selector: "app-tjenester-ny",
  templateUrl: "../../common/ny/ny-base.html"
})
export class TjenesterNyComponent extends NyBaseClass<Tjeneste> {


  constructor(
    private service: TjenesterService,
    tilgangService: TilgangService,
    route: ActivatedRoute,
    router: Router,
    notificationService: NotificationService
  ) { super(route, router, tilgangService, notificationService); }

  create(model: Tjeneste): Observable<Tjeneste> {
    return this.service.post(model);
  }
  getCreateFields(): Observable<Field[]> {
    return this.service.getCreateFields();
  }
  getTitle(): string {
    return "Tjenester";
  }
  getHeadline(): string {
    return "Opprett ny tjeneste";
  }

  getIdFromDataType(model: Tjeneste): number {
    return model.tjenesteId;
  }

}
