<div class="container">
    <div class="col text-center">
        <button class="btn btn-primary mb-2" type="button" (click)="filtersCollapsed = !filtersCollapsed;"
            data-toggle="collapse" aria-expanded="false" [attr.aria-expanded]="filtersCollapsed"
            aria-controls="filters">
            <i [ngClass]="{'fas fa-caret-up': filtersCollapsed}"></i>
            <i [ngClass]="{'fas fa-caret-down': !filtersCollapsed}"></i>
            <span class="mx-2" *ngIf="filtersCollapsed">Skjul Filtre</span>
            <span class="mx-2" *ngIf="!filtersCollapsed">Vis Filtre</span>
        </button>
    </div>
</div>


<div class="collapse" id="filters" [ngbCollapse]="!filtersCollapsed">
    <div class="card card-body">

        <div class="container">
            <div class="row align-items-center">
                <div class="col">
                    <ul class="list-group list-group-flush">

                        <li class="list-group-item">
                            <div class="input-group my-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="inputGroup-orderSelect">Sortering</span>
                                </div>
                                <select class="form-control" id="orderSelect" [(ngModel)]="filterParams.order"
                                    (change)="filterChanged($event)">
                                    <option selected value="nameAsc">(A-Å)</option>
                                    <option value="nameDesc">(Å-A)</option>
                                </select>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>