import { Directive, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { EMPTY, Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { ModalService } from "../../services/modal.service";
import { TabCreateDeleteAccess, TilgangService } from "../../services/tilgang.service";
import { KoblingerService } from "../../koblinger/koblinger.service";
import { Tabs } from "../tabs/tabs.enum";
import { TabsService } from "../tabs/tabs.service";
import { IntegrasjonsharedService } from "src/app/services/integrasjonshared.service";


@Directive()
export abstract class TabSideBaseClass<T> implements OnInit, OnDestroy {

  id: number;

  originalListe:T[];
  liste: T[];

  showFiltered = false;
  isLoading = false;
  loadingFailed = false;
  linkAccess = false;
  createDeleteAccess: TabCreateDeleteAccess;
  dataChangedObservable: Subscription;

  constructor(
    protected tabsService: TabsService,
    protected service: KoblingerService,
    protected tilgangService: TilgangService,
    protected modal: ModalService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected integrasjonShared?: IntegrasjonsharedService) {
  }


  ngOnDestroy(): void {
    if (this.dataChangedObservable) {
      this.dataChangedObservable.unsubscribe();
    }
  }

  ngOnInit(): void {

    this.route.parent.params.subscribe(params => {

      const urlFragments = this.router.url.substr(1).split("/");

      let parent: string, parentId: string
      [parent, parentId] = urlFragments;
      const tab = this.tabName();

      this.tilgangService.getTabCreateDeleteAccess(parent, +parentId).subscribe(access => {
        this.createDeleteAccess = access.find(ac => ac.page == tab.toLocaleLowerCase());
      });

      this.pageLinkAccess(this.tilgangService).subscribe(linkAccess => {
        this.linkAccess = linkAccess;
      });

      Object.keys(params).forEach(k => {

        console.log(k);

        if (k.toLowerCase().endsWith("id")) {
          this.id = +params[k];
        }
      });

      if (!this.id) {
        throw new Error(`Manglende parent-id i tab ${this.constructor.name}`);
      }

      this.load();
    });


    this.route.queryParams.subscribe(qparams => {
      if (qparams.refResults) {
        this.kobleTil(decodeURIComponent(qparams.refResults));
      }
    });


    this.dataChangedObservable = this.tabsService.getDataChangedObservable(this.tabName())?.subscribe(() => {
      this.load();
    });

  }



  load(): void {
    this.isLoading = true;

    this.loaderObservable(this.service, this.id).pipe(
      map(val => val.sort(this.compareFn))).subscribe(resp => {
        this.originalListe = resp;
        this.liste = this.filterList();
        this.isLoading = false;
      },
        () => {
          this.loadingFailed = true;
        });
  }

  fjern(item: T): void {
    this.isLoading = true;
    this.removeObservable(this.service, item).subscribe(() => {
      this.isLoading = false;
      this.modal.success("", "Kobling fjernet");
      this.load();
    },
      () => {
        this.isLoading = false;
        this.loadingFailed = true;
      });
  }

  compareFn(first: T, second: T): number {
    return 0;
  }

  filterList():T[] {
    return this.originalListe;
  }

  canFilter():boolean{
    return false;
  }

  dataChanged(): void {
    console.log(`Data changed from ${this.tabName()}`);
  }



  abstract kobleTil(searchVal: string): void;

  abstract loaderObservable(service: KoblingerService, id: number): Observable<T[]>;

  abstract pageLinkAccess(service: TilgangService): Observable<boolean>;

  abstract tabName(): Tabs;

  protected removeObservable(service: KoblingerService, item: T): Observable<any> { return EMPTY; }

}
