import {ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { FeltSideBaseClass } from "../common/feltside/feltside.component";
import {Tab, TabsComponent} from "../common/tabs/tabs.component";
import { Tabs } from "../common/tabs/tabs.enum";
import { NotificationService } from "../services/notification.service";
import { TabAccess, TilgangService } from "../services/tilgang.service";
import { Sertifikat, SertifikaterService } from "./sertifikater.service";
import {EndringsloggTabComponent} from "../endringslogg-tab-component/endringslogg-tab.component";

@Component({
  selector: "app-sertifikat",
  templateUrl: "../common/feltside/feltside.component.html"
})
export class SertifikatComponent extends FeltSideBaseClass<Sertifikat> implements OnInit {


  idParamName = "sertifikatId";

  constructor(service: SertifikaterService, private tilgangService: TilgangService, notifyService: NotificationService,
    route: ActivatedRoute, changeDetectorRef: ChangeDetectorRef) {
    super(service, notifyService, route, changeDetectorRef);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getAvailableTabs(): Tab[] {
    return [
      { key: Tabs.systemer, label: "Systemer", parentRoute: ["/sertifikater", this.id + ""] },
      { key: Tabs.endringslogg, label: "Endringslogg", parentRoute: ["/sertifikater", this.id + ""] },
    ];
  }

  getTabAccess(): Observable<TabAccess[]> {
    return this.tilgangService.getTabAccess("sertifikat", this.id);
  }
}
