<app-spinner [loadingData]="isLoading" [loadingFailed]="loadingFailed"></app-spinner>

<button (click)="kobleTil()" type='button' class='btn btn-outline-primary mb-4'><i class="fas fa-plus"></i> Koble til
    leverandør</button>

<div *ngFor="let item of liste" class="list-group-flush">
    <div class="d-flex flex-row">

        <a [routerLink]="['/leverandorer', item.leverandorId]"
            class="list-group-item list-group-item-action flex-column align-items-start">
            <h5 class="mb-1">{{item.leverandor.navn}}</h5>
            <div class="text-muted">{{item.leverandor.url}}</div>
        </a>
    </div>
</div>