<div *ngIf="laster">Laster innhold</div>

<div *ngIf="!laster">
  <div *ngIf="!harTilgang">Du har ikke tilgang til å lese eller registrere personer</div>

  <div *ngIf="harTilgang && !harSkrivetilgang && registrererNy">Du har ikke tilgang til å registrere nye personer</div>

  <div *ngIf="harTilgang && harSkrivetilgang || !registrererNy">

    <div *ngIf="registrererNy">
      <h1>Registrerer ny person</h1>
      <form [formGroup]="nyPersonForm" class="mb-5">
        <div class="form-row">
          <div class="col-md-6">
            <label for="sokBrukernavn">
              Skriv inn epost-adresse til ny person. Dersom adressa er @bergen.kommune.no blir brukerinformasjon hentet
              fra Active Directory
            </label>
            <div class="input-group">
              <input id="sokBrukernavn" formControlName="brukerOppslag" class="form-control"
                [ngClass]="{ 'is-invalid': brukerOppslag().errors }" placeholder="epost" type="email" />
              <div class="input-group-append">
                <button (click)="sjekkEpostadresse()" class="btn btn-outline-secondary">{{erBergenKommuneEpost() ?
                  'Hent info' : 'Ny bruker'}}</button>
              </div>
              <div *ngIf="brukerOppslag().errors?.exists" class="invalid-feedback d-block">
                Epost-adresse finnes allerede
              </div>
              <div *ngIf="brukerOppslag().errors?.unknown" class="invalid-feedback d-block">
                Epost-adressen finnes ikke i Active Directory
              </div>
              <div *ngIf="brukerOppslag().errors?.empty" class="invalid-feedback d-block">
                Må skrive inn brukernavn eller epost-adresse
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>


    <div *ngIf="!registrererNy && person">
      <h1>{{person.navn}}</h1>
    </div>


    <div *ngIf="persondetaljerSynlig">
      <form (ngSubmit)="savePerson()" [formGroup]="personForm">
        <div class="col-12">
          <div class="btn-toolbar my-2" role="toolbar" aria-label="Toolbar with button groups">
            <div class="btn-group mr-4" role="group">

              <button type="submit" class="btn btn-outline-primary px-5" [disabled]="!skjemaErEndret()">
                Lagre
                <span *ngIf="isSaving" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                <span *ngIf="isSaving" class="sr-only">Loading...</span>
              </button>


            </div>
            <div class="btn-group" role="group">
              <button class="btn btn-outline-secondary px-4" type="button" (click)="tilbakestill()"
                [disabled]="!skjemaErEndret()">
                Nullstill
              </button>
            </div>
          </div>
        </div>

        <div class="form-row mb-3">
          <div class="col">
            <div class="form-group">
              <label for="personId">Person-id:</label>
              <input class="form-control" formControlName="personId" id="personId" type="text">
            </div>
          </div>
          <div class="col">
            <label for="brukernavn">Brukernavn:</label>
            <div class="input-group">
              <input class="form-control" formControlName="brukernavn" id="brukernavn" type="text">
              <div *ngIf="personForm.get('brukernavn').value" class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="oppdaterFraAD()">Oppdater fra
                  AD</button>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="navn">
                Navn:
              </label>
              <input class="form-control" formControlName="navn" id="navn" type="text">
            </div>
            <div class="form-group">
              <label for="fornavn">
                Fornavn:
              </label>
              <input class="form-control" formControlName="fornavn" id="fornavn" type="text">
            </div>
            <div class="form-group">
              <label for="etternavn">
                Etternavn:
              </label>
              <input class="form-control" formControlName="etternavn" id="etternavn" type="text">
            </div>
            <div class="form-group">
              <label for="epost">
                Epost:
              </label>
              <input class="form-control" formControlName="epost" id="epost" type="text">
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="telefon">
                Telefon:
              </label>
              <input class="form-control" formControlName="telefon" id="telefon" type="text">
            </div>
            <div class="form-group">
              <label for="mobil">
                mobil:
              </label>
              <input class="form-control" formControlName="mobil" id="mobil" type="text">
            </div>
            <div class="form-group">
              <label for="firma">
                Firma:
              </label>
              <input class="form-control" formControlName="firma" id="firma" type="text">
            </div>
            <div class="form-group">
              <label for="avdeling">
                Avdeling:
              </label>
              <input class="form-control" formControlName="avdeling" id="avdeling" type="text">
            </div>
            <div class="form-group">
              <label for="kontor">
                Kontor:
              </label>
              <input class="form-control" formControlName="kontor" id="kontor" type="text">
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group">
            <div class="form-check">
              <input class="form-check-input" formControlName="sluttet" id="sluttet" type="checkbox">
              <label class="form-check-label" for="sluttet">Sluttet:</label>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

</div>
<app-tabs #tab [tabs]="tabs" [tabsLoading]="tabsLoading" ></app-tabs>
