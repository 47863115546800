<div class="container">
    <div class="col text-center">
        <button class="btn btn-primary mb-2" type="button"
        (click)="filtersCollapsed = !filtersCollapsed;" data-toggle="collapse" aria-expanded="false"
        [attr.aria-expanded]="filtersCollapsed" aria-controls="filters">
        <i [ngClass]="{'fas fa-caret-up': filtersCollapsed}"></i>
        <i [ngClass]="{'fas fa-caret-down': !filtersCollapsed}"></i>
        <span class="mx-2" *ngIf="filtersCollapsed">Skjul Filtre</span>
        <span class="mx-2" *ngIf="!filtersCollapsed">Vis Filtre</span>
    </button>
    </div>
</div>


<div class="collapse" id="filters" [ngbCollapse]="!filtersCollapsed">
    <div class="card card-body">

        <div class="container">
            <div class="row align-items-center">
                <div class="col">
                    <ul class="list-group list-group-flush">

                        <li class="list-group-item">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="showExpired"
                                    [(ngModel)]="filterParams.showExpired" (change)="filterChanged($event)">
                                <label class="form-check-label" for="showExpired">Vis Utgåtte</label>
                            </div>
                        </li>

                    </ul>
                </div>


                <div class="col center-block">

                    <div *ngIf="oppdragStatus" class="input-group my-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroup-oppdragStatus">Oppdrag Status</span>
                        </div>
                        <select class="custom-select" [(ngModel)]="filterParams.oppdragStatus" (change)="filterChanged($event)">
                            <option selected value='0'>Alle</option>
                            <option *ngFor="let o of oppdragStatus" [value]="o.oppdragStatusId"
                                [attr.selected]="o.oppdragStatusId==filterParams.oppdragStatus">
                                {{o.status}}</option>
                        </select>
                    </div>
                    <div *ngIf="oppdragstatussm" class="input-group my-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroup-oppdragStatusSm">Oppdrag Status SM</span>
                        </div>
                        <select class="custom-select" [(ngModel)]="filterParams.oppdragStatusSm" (change)="filterChanged($event)">
                            <option selected value='0'>Alle</option>
                            <option *ngFor="let o of oppdragstatussm" [value]="o.oppdragSmStatusId"
                                [attr.selected]="o.oppdragSmStatusId==filterParams.oppdragStatusSm">
                                {{o.statusSm}}</option>
                        </select>
                    </div>
                    <div *ngIf="oppdragstatusleveranse" class="input-group my-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroup-oppdragStatusLeveranse">Oppdrag Status Leveranse</span>
                        </div>
                        <select class="custom-select" [(ngModel)]="filterParams.oppdragStatusLeveranse" (change)="filterChanged($event)">
                            <option selected value='0'>Alle</option>
                            <option *ngFor="let o of oppdragstatusleveranse" [value]="o.oppdragLevStatusId"
                                [attr.selected]="o.oppdragLevStatusId==filterParams.oppdragStatusLeveranse">
                                {{o.statusLeveranse}}</option>
                        </select>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>