import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-ikketilgang",
  templateUrl: "./ikketilgang.component.html"
})
export class IkketilgangComponent implements OnInit {

  @Input()
  harTilgang: boolean;

  @Input()
  loadingData: boolean;

  @Input()
  overskrift: string;

  ngOnInit(): void {
    // Do nothing
  }

}
