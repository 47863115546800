import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { forkJoin } from "rxjs";
import { FilterParams } from "../../common/common.model";
import { IntegrasjonerService, Overforingsmetode, Personopplysningsniva } from "../integrasjoner.service";

@Component({
  selector: "app-integrasjoner-filter",
  templateUrl: "./integrasjoner-filter.component.html",
  styleUrls: ["./integrasjoner-filter.component.scss"]
})
export class IntegrasjonerFilterComponent implements OnInit {

  @Input()
  loadingData = false;

  @Input()
  filterParams: FilterParams;

  @Output()
  onFilterChange = new EventEmitter<FilterParams>();

  filtersCollapsed: boolean;
  om: Overforingsmetode[];
  pom: Personopplysningsniva[];

  filterChanged(event?: any): void {
    console.log(this.filterParams);
    this.onFilterChange.emit(this.filterParams);
  }

  constructor(private readonly service: IntegrasjonerService) { }

  ngOnInit(): void {

    const omObservable = this.service.getOverforingsMetode();
    const poObserable = this.service.getPersonopplysningsniva();

    forkJoin([omObservable, poObserable]).subscribe(results => {
      this.om = results[0];
      this.pom = results[1];

      console.log(results);
    });
  }
}
