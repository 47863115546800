import { Component } from "@angular/core";
import { ModellFeltComponent } from "../../../common/felter/modellfelt.component";
import { Itsystem } from "../../../itsystemer/itsystemer.service";
import { ModalService } from "../../../services/modal.service";

@Component({
  selector: "app-systemvelgerfelt",
  templateUrl: "./systemvelgerfelt.component.html",
  styleUrls: ["./systemvelgerfelt.component.scss"]
})
export class SystemvelgerfeltComponent extends ModellFeltComponent {

  system: Itsystem;

  constructor(
    private modalService: ModalService
  ) { super(); }

  selectSystemForIntegration() : void {
    this.modalService.velgSystem().then(system => {
      this.system = system;
      this.control.setValue(system.systemId);
      this.control.markAsDirty();
    }, () => {
      // Do nothing
    });
  }

}
