import {Component, OnInit, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import * as moment from "moment";
import {forkJoin, Observable} from "rxjs";
import {AppConfigService} from "../app-config.service";
import {Field, Rolle} from "../common/common.model";
import {
  TjenesteavtalevedleggComponent
} from "../common/modal/report-generator/tjenesteavtalevedlegg/tjenesteavtalevedlegg.component";
import {Tab, TabsComponent} from "../common/tabs/tabs.component";
import {Tabs} from "../common/tabs/tabs.enum";
import {TabsService} from "../common/tabs/tabs.service";
import {EndringsloggTabComponent} from "../endringslogg-tab-component/endringslogg-tab.component";
import {countDirty} from "../global";
import {Itsystem, ItsystemerService, SystemType} from "../itsystemer/itsystemer.service";
import {
  OppdragFakturalinjerDriftTabComponent
} from "../oppdrag/oppdrag-fakturalinjer-drift-tab/oppdrag-fakturalinjer-drift-tab.component";
import {BackNavigationComponent} from "../services/backnavigation.guard";
import {FaktureringService, Pris} from "../services/fakturering.service";
import {ModalService} from "../services/modal.service";
import {NotificationService} from "../services/notification.service";
import {TilgangService} from "../services/tilgang.service";
import {PersonService, SystemRolle} from "../services/person.service";
import {KoblingerService} from "../koblinger/koblinger.service";
import {IntegrasjonerService} from "../integrasjoner/integrasjoner.service";


@Component({
  selector: "app-itsystem",
  templateUrl: "./itsystem.component.html",
  styleUrls: ["./itsystem.component.scss"]
})
export class ItsystemComponent implements OnInit, BackNavigationComponent<Itsystem> {

  constructor(private route: ActivatedRoute,
              private tilgangService: TilgangService,
              private itsystemerService: ItsystemerService,
              private fakturaService: FaktureringService,
              private modalService: ModalService,
              private notificationService: NotificationService,
              private tabsService: TabsService,
              private personService: PersonService,
              private koblingerService: KoblingerService,
              private faktureringSerivice: FaktureringService,
              private integrasjonService: IntegrasjonerService,
              private formBuilder: FormBuilder) {
  }

  sidenErEndret(): boolean {
    return this.formGroup.dirty;
  }

  antallEndringer(): number {
    return countDirty(this.formGroup);
  }

  saveChanges(): Observable<Itsystem> {
    return this.itsystemerService.putItsystem(this.itsystem);
  }


  // loaded from config
  private _driftsDokUrl = AppConfigService.settings.url.basisUrl;

  //

  get driftsDokUrl(): string {
    return this._driftsDokUrl;
  }

  isSaving = false;
  isLoading = false;

  systemId: number;

  itsystem: Itsystem;

  formGroup: FormGroup;

  headingField: Field;

  topFields: Field[] = [];

  fields: Field[] = [];

  programPakke = SystemType.Programpakke;

  mainFieldsColumn1: Field[];
  mainFieldsColumn2: Field[]
  mainFieldsColumn3: Field[]

  prisberegningFields: Field[];

  systemtyper: string[] = ["", "Fagsystem", "Infrastruktur", "Programpakke", "Nettjeneste"];

  systemTypeNavn: string;
  systemTypeId: number;
  visRisikoGjennomgang = false;
  visTestinformasjon = false;
  kanViseTestinformasjon = false;
  disableFakturalinjeButton: boolean;
  tilgangTilFakturalinjeButton: boolean = false;

  tabs: Tab[] = [];
  @ViewChild('tab') tabsComponent: TabsComponent;

  ngOnInit(): void {

    this.formGroup = this.formBuilder.group({
      "prisberegning": this.formBuilder.group({}),
    });

    this.harTilgangTilFakturalinjeButton();

    this.route.params.subscribe(params => {
      this.systemId = +params["systemId"];
      this.isLoading = true;
      this.itsystemerService.getItsystem(this.systemId).subscribe(itsystem => {
        this.itsystem = itsystem;
        this.systemTypeFromId(itsystem.systemtypeId);

        this.itsystemerService.getItsystemFields(this.systemId).subscribe(fields => {
          const systemType = this.systemTypeNavn[0].toUpperCase();
          fields = fields.filter(f => f.systemtyper.includes(systemType));

          this.headingField = this.removeFilteredFromArray(fields.filter(f => f.key == "navn"), fields)[0];

          this.topFields = this.removeFilteredFromArray(fields.filter(f => f.key == "beskrivelse" || f.key == "kommentar" || f.key == "interninfo"), fields);
          this.mainFieldsColumn1 = this.removeFilteredFromArray(fields.filter(f => f.column === 1), fields).sort((f1, f2) => f1.position - f2.position);
          this.mainFieldsColumn2 = this.removeFilteredFromArray(fields.filter(f => f.column === 2), fields).sort((f1, f2) => f1.position - f2.position);
          this.mainFieldsColumn3 = fields.sort((f1, f2) => f1.position - f2.position);

          this.isLoading = false;
        });

        this.itsystemerService.getPrisberegningFields(this.systemId).subscribe(fields => {
          const systemType = this.systemTypeNavn[0].toUpperCase();
          fields = fields.filter(f => f.systemtyper.includes(systemType));
          this.prisberegningFields = fields.sort((f1, f2) => f1.position - f2.position);
          this.isLoading = false;
        });

        if (this.kanViseTestinformasjon) {
          this.visTestinformasjon = this.itsystem.testinformasjon?.testOfficeEndring || this.itsystem.testinformasjon?.testWindowsEndring;
        }

        this.setupTabs(this.systemId);
      }, () => this.isLoading = false);

      this.tilgangService.getRisikogjennomgangAccess(this.systemId).subscribe(risikoAccess => {
        this.visRisikoGjennomgang = risikoAccess;
      });

      this.tilgangService.getTestinformasjonAccess(this.systemId).subscribe(testAccess => {
        this.kanViseTestinformasjon = testAccess;
        if (this.itsystem) {
          this.visTestinformasjon = testAccess && (this.itsystem.testinformasjon?.testWindowsEndring || this.itsystem.testinformasjon?.testOfficeEndring)
        }
      });

    });
  }

  private systemTypeFromId(systemTypeId: number) {
    this.systemTypeId = systemTypeId;
    if (systemTypeId == 1) {
      this.systemTypeNavn = "Fagsystem";
    } else if (systemTypeId == 2) {
      this.systemTypeNavn = "Infrastruktur";
    } else if (systemTypeId == 3) {
      this.systemTypeNavn = "Programpakke";
    } else if (systemTypeId == 4) {
      this.systemTypeNavn = "Nettjeneste";
    } else {
      this.systemTypeNavn = "Ukjent systemtype";
    }
  }

  private removeFilteredFromArray(filtered: Field[], arr: Field[]) {
    filtered.forEach(f => {
      const index = arr.indexOf(f);
      if (index >= 0) {
        arr.splice(index, 1);
      }
    });
    return filtered;
  }


  private updateRemoteChanges(itsystem: Itsystem): void {
    if (itsystem.utgattdato != this.itsystem.utgattdato && moment(itsystem.utgattdato) < moment()) {
      this.tabsService.updateIntegrationData();
    }
    this.itsystem.systemstatusId = itsystem.systemstatusId;
  }


  slaValidUrl(): boolean {
    return this.itsystem.tjenesteavtale?.startsWith("http");
  }

  slaavikValidUrl(): boolean {
    return this.itsystem.avvikPaTilgjengelighet?.startsWith("http");
  }

  lagre(): void {
    this.isSaving = true;
    this.itsystemerService.putItsystem(this.itsystem).subscribe((system) => {
      this.isSaving = false;
      this.updateRemoteChanges(system);
      this.itsystem = system;
      this.notificationService.success(this.itsystem.navn, "Lagret");
      this.visTestinformasjon = this.kanViseTestinformasjon && (this.itsystem.testinformasjon?.testWindowsEndring || this.itsystem.testinformasjon?.testOfficeEndring);
      this.formGroup.markAsPristine();
      let activeTab = this.tabsComponent.getActiveTab();
      if (activeTab instanceof OppdragFakturalinjerDriftTabComponent) {
        activeTab.oppdaterFakturalinjer();
      } else if (activeTab instanceof EndringsloggTabComponent) {
        activeTab.hentEndringslogg();
      }
    }, () => this.isSaving = false);
  }

  tilbakestill(): void {
    window.location.reload();
  }

  opprettFakturalinje(): void {
    this.disableFakturalinjeButton = true;
    this.itsystemerService.oppdaterFakturalinje(this.itsystem).subscribe((system) => {
      this.disableFakturalinjeButton = false;
      this.updateRemoteChanges(system);
      this.notificationService.success(this.itsystem.navn, "Opprettet fakturalinje");
      let activeTab = this.tabsComponent.getActiveTab();
      if (activeTab instanceof OppdragFakturalinjerDriftTabComponent) {
        activeTab.oppdaterFakturalinjer();
      }
    }, () => "Kunne ikke opprette fakturalinje");
  }

  harTilgangTilFakturalinjeButton(): void {
    const valgtRolle = localStorage.getItem("rolleOverride");
    if (valgtRolle != null) {
      this.tilgangTilFakturalinjeButton = valgtRolle.valueOf().includes(SystemRolle.Administrator) || valgtRolle.valueOf().includes(SystemRolle.Avtaleforvalter);
    } else {
      this.koblingerService.getPersonRoller().subscribe(roller => {
        for (let rolle of roller) {
          if (rolle.navn == SystemRolle.Administrator || rolle.navn == SystemRolle.Avtaleforvalter) {
            this.tilgangTilFakturalinjeButton = true;
          }
        }});
    }
  }

  generateTjenesteAvtaleVedlegg(): Promise<any> {

    const modalRef = this.modalService.visModal(TjenesteavtalevedleggComponent);
    const modalComponent = modalRef.componentInstance as TjenesteavtalevedleggComponent;

    const fagSystemObservable = this.itsystemerService.getItsystem(this.systemId);
    const tjenesteavtalevedleggObservable = this.itsystemerService.getVedlegg(this.systemId);
    const prisGrunnlagObservable = this.fakturaService.getPriser();
    const integrasjonsPrisObservable = this.itsystemerService.getIntegrasjonPrisAntall(this.systemId);

    modalComponent.isLoading = true;

    forkJoin([fagSystemObservable, tjenesteavtalevedleggObservable, prisGrunnlagObservable, integrasjonsPrisObservable]).subscribe(results => {

      modalComponent.fagsystem = results[0];
      modalComponent.tjenesteavtalevedlegg = results[1];
      modalComponent.prisGrunnlag = new Map();
      this.getIntegrasjonerForSystem(results[0].systemId, modalComponent);
      this.getPriser(modalComponent);
      this.getOverforingsmetoder(modalComponent);

      const priser = results[2] as Pris[];
      priser.forEach(pris => {
        modalComponent.prisGrunnlag.set(pris.prisId, pris);
      });

      modalComponent.isLoading = false;
      modalComponent.prisIntegrasjon = results[3]

    }, () => {
      modalComponent.isLoading = false;
      modalComponent.errorLoading = true;
    });


    modalRef.result.then(() => {
      // Do nothing
    }, () => {
      // Do nothing
    });

    modalComponent.onConfirm.subscribe(exitStatus => {
      if (exitStatus) {
        modalRef.close(exitStatus);
      } else {
        modalRef.dismiss();
      }
    });

    return modalRef.result;

  }

  getPrisberegning(): FormGroup {
    return this.formGroup.get("prisberegning") as FormGroup;
  }

  getIntegrasjonerForSystem(systemId: number, modalComponent: TjenesteavtalevedleggComponent) {
    this.koblingerService.getIntegrasjonerForSystem(systemId).subscribe(result =>
      modalComponent.integrasjoner = result
    );
  }

  getPriser(modalComponent: TjenesteavtalevedleggComponent) {
    this.faktureringSerivice.getPriser().subscribe(result =>
      modalComponent.priser = result
    );
  }

  getOverforingsmetoder(modalComponent: TjenesteavtalevedleggComponent) {
    this.integrasjonService.getOverforingsMetode().subscribe(result =>
      modalComponent.overforingsmetoder = result
    );
  }

  setupTabs(systemId: number): void {

    const parentRoute = ["/itsystemer", this.itsystem.systemId + ""];

    const fagSystemTabs = [
      {key: Tabs.endringer, label: "Endringer", parentRoute: parentRoute},
      {key: Tabs.fakturering, label: "Fakturalinjer", parentRoute: parentRoute},
      {key: Tabs.oppdrag, label: "Oppdrag", parentRoute: parentRoute},
      {key: Tabs.personer, label: "Personer", parentRoute: parentRoute},
      {key: Tabs.integrasjoner, label: "Integrasjoner", parentRoute: parentRoute},
      {key: Tabs.servere, label: "Servere", disabled: true, parentRoute: parentRoute},
      {key: Tabs.sone, label: "Sone", parentRoute: parentRoute},
      {key: Tabs.miljo, label: "Miljø", parentRoute: parentRoute},
      {key: Tabs.sertifikat, label: "Sertifikat", parentRoute: parentRoute},
      {key: Tabs.leverandorer, label: "Leverandører", parentRoute: parentRoute},
      {key: Tabs.bruksomrade, label: "Bruksområder", parentRoute: parentRoute},
      {key: Tabs.brukere, label: "Brukes av", parentRoute: parentRoute},
      {key: Tabs.endringslogg, label: "Endringslogg", parentRoute: parentRoute}

    ] as Tab[];

    const infrastrukturTabs = [
      {key: Tabs.endringer, label: "Endringer", parentRoute: parentRoute},
      {key: Tabs.oppdrag, label: "Oppdrag", parentRoute: parentRoute},
      {key: Tabs.personer, label: "Personer", parentRoute: parentRoute},
      {key: Tabs.tjenester, label: "Tjenester", parentRoute: parentRoute},
      {key: Tabs.integrasjoner, label: "Integrasjoner", parentRoute: parentRoute},
      {key: Tabs.servere, label: "Servere", disabled: true, parentRoute: parentRoute},
      {key: Tabs.sone, label: "Sone", parentRoute: parentRoute},
      {key: Tabs.miljo, label: "Miljø", parentRoute: parentRoute},
      {key: Tabs.sertifikat, label: "Sertifikat", parentRoute: parentRoute},
      {key: Tabs.leverandorer, label: "Leverandører", parentRoute: parentRoute},
      {key: Tabs.bruksomrade, label: "Bruksområder", parentRoute: parentRoute},
      {key: Tabs.endringslogg, label: "Endringslogg", parentRoute: parentRoute}
    ] as Tab[];

    const programPakkeTabs = [
      {key: Tabs.endringer, label: "Endringer", parentRoute: parentRoute},
      {key: Tabs.fakturering, label: "Fakturalinjer", parentRoute: parentRoute},
      {key: Tabs.oppdrag, label: "Oppdrag", parentRoute: parentRoute},
      {key: Tabs.personer, label: "Personer", parentRoute: parentRoute},
      {key: Tabs.integrasjoner, label: "Integrasjoner", parentRoute: parentRoute},
      {key: Tabs.sone, label: "Sone", parentRoute: parentRoute},
      {key: Tabs.miljo, label: "Miljø", parentRoute: parentRoute},
      {key: Tabs.leverandorer, label: "Leverandører", parentRoute: parentRoute},
      {key: Tabs.bruksomrade, label: "Bruksområder", parentRoute: parentRoute},
      {key: Tabs.brukere, label: "Brukes av", parentRoute: parentRoute},
      {key: Tabs.endringslogg, label: "Endringslogg", parentRoute: parentRoute}
    ] as Tab[];

    const nettjenesteTabs = [
      {key: Tabs.personer, label: "Personer", parentRoute: parentRoute},
      {key: Tabs.integrasjoner, label: "Integrasjoner", parentRoute: parentRoute},
      {key: Tabs.leverandorer, label: "Leverandører", parentRoute: parentRoute},
      {key: Tabs.bruksomrade, label: "Bruksområder", parentRoute: parentRoute},
      {key: Tabs.brukere, label: "Brukes av", parentRoute: parentRoute},
      {key: Tabs.endringslogg, label: "Endringslogg", parentRoute: parentRoute}
    ] as Tab[];

    this.tilgangService.getTabAccess("itsystem", this.systemId).subscribe(t => {

      if (this.systemTypeNavn == "Fagsystem") {
        this.tabs = fagSystemTabs.filter(ft => t.some(tabAccess => tabAccess.key == ft.key));
      }
      if (this.systemTypeNavn == "Infrastruktur") {
        this.tabs = infrastrukturTabs.filter(it => t.some(tabAccess => tabAccess.key == it.key));
      }
      if (this.systemTypeNavn == "Programpakke") {
        this.tabs = programPakkeTabs.filter(pt => t.some(tabAccess => tabAccess.key == pt.key));
      }
      if (this.systemTypeNavn == 'Nettjeneste') {
        this.tabs = nettjenesteTabs.filter(nt => t.some(tabAccess => tabAccess.key == nt.key));
      }
    });
  }

  private ensureColumnArrayExists() {
    if (!this.mainFieldsColumn1) {
      this.mainFieldsColumn1 = [];
    }
    if (!this.mainFieldsColumn2) {
      this.mainFieldsColumn2 = [];
    }
    if (!this.mainFieldsColumn3) {
      this.mainFieldsColumn3 = [];
    }
  }

  protected readonly FormGroup = FormGroup;
}
