import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { FilterParams } from "../common/common.model";

@Injectable({
  providedIn: "root"
})
export class UserSettingsStorageService {


  private itsystemFilterParams: FilterParams;


  constructor(private readonly authService: MsalService) {
    // empty constructor
  }

  private itsystemFilterParamsStorageKey = (): string => `${this.authService.instance?.getActiveAccount()?.username}_itsystem_filter_usersettings`;

  public getItsystemFilterParams(): FilterParams {

    if (!this.itsystemFilterParams) {

      const values = localStorage.getItem(this.itsystemFilterParamsStorageKey());

      if (values) {
        this.itsystemFilterParams = JSON.parse(values);
      }
    }

    return this.itsystemFilterParams;
  }

  public saveItsystemFilterParams(filterParams: FilterParams): void {

    const copy = new FilterParams();
    Object.assign(copy, filterParams);

    copy.numberOfHits = undefined;
    copy.pageNumber = undefined;
    copy.pageSize = undefined;
    copy.totalPages = undefined;
    copy.tekst = undefined;

    localStorage.setItem(this.itsystemFilterParamsStorageKey(), JSON.stringify(copy));
  }


  public clearItsystemFilterParams(): void {
    localStorage.removeItem(this.itsystemFilterParamsStorageKey());
    this.itsystemFilterParams = undefined;
  }

}


