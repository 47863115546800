import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class RolleInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const rolle = localStorage.getItem("rolleOverride");
    if (rolle != null) {
      const newRequest = request.clone({
        setHeaders: {
          "X-ROLLE-OVERRIDE": rolle
        }
      });
      return next.handle(newRequest);
    }
    return next.handle(request);
  }
}
