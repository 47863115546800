import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FilterParams } from "../../common/common.model";
import { ItsystemerService } from "../../itsystemer/itsystemer.service";
import { Kunde } from "../../oppdrag/oppdrag.service";
import { ModalService } from "../../services/modal.service";

@Component({
  selector: "app-risikogjennomganger-filter",
  templateUrl: "./risikogjennomganger-filter.component.html",
  styleUrls: ["./risikogjennomganger-filter.component.scss"]
})
export class RisikogjennomgangerFilterComponent implements OnInit {

  constructor(private modalService: ModalService, private itsystemService: ItsystemerService) { }

  byradsavdelinger: Kunde[];

  @Input()
  loadingData = false;

  @Input()
  filterParams: FilterParams;

  filtersCollapsed: boolean;

  @Output()
  onFilterChange = new EventEmitter<FilterParams>();

  ngOnInit(): void {
    this.itsystemService.getKunder().subscribe(kunder => {
      this.byradsavdelinger = kunder;
    });
  }


  filterChanged(event?: any): void {
    this.onFilterChange.emit(this.filterParams);
  }

  hgChanged(val: number): void {
    this.filterParams.hg = val;
    this.filterChanged();
  }

  velgSystemEierPerson(): void {
    this.modalService.velgPerson().then(person => {
      this.filterParams.systemeier = person.navn;
      this.filterChanged();
    }).catch(() => {
      this.filterParams.systemeier = null;
      this.filterChanged();
    });
  }

  clearSystemEierPerson(): void {
    this.filterParams.systemeier = null;
    this.filterChanged();
  }

  velgSystemKoordinatorPerson(): void {
    this.modalService.velgPerson().then(person => {
      this.filterParams.systemkoordinator = person.navn;
      this.filterChanged();
    }).catch(() => {
      this.filterParams.systemkoordinator = null;
      this.filterChanged();
    });
  }

  clearSystemKoordinatorPerson(): void {
    this.filterParams.systemkoordinator = null;
    this.filterChanged();
  }

}
