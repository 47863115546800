import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Field } from "src/app/common/common.model";
import { IServiceInterface } from "src/app/common/feltside/feltside.component";


@Injectable({
  providedIn: "root"
})
export class EndringerService implements IServiceInterface<Endring> {

  private readonly basePath = "api/endring/";
  private readonly baseUrl: string;

  constructor(private http: HttpClient, @Inject("BASE_URL") private baseHost: string) {
    this.baseUrl = baseHost + this.basePath;
  }


  get(endringId: number,): Observable<Endring> {
    return this.http.get<Endring>(this.baseUrl + endringId);
  }
  put(item: Endring): Observable<Endring> {
    return this.http.put<Endring>(this.baseUrl + item.endringId, item);
  }
  post(item: Endring): Observable<Endring> {
    return this.http.post<Endring>(this.baseUrl, item);
  }
  getFields(): Observable<Field[]> {
    return this.http.get<Field[]>(this.baseUrl + "fields");
  }
  getCreateFields(): Observable<Field[]> {
    return this.http.get<Field[]>(this.baseUrl + "create/fields");
  }

  searchEndringer(name?: string, pageNumber = 1, pageSize = 30): Observable<EndringSearchResult> {

    let searchParams = new HttpParams();

    if (name) { searchParams = searchParams.append("name", name); }
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<EndringSearchResult>(this.baseUrl + "search", { params: searchParams });
  }

  getEndringer(systemId: number): Observable<Endring[]> {
    return this.http.get<Endring[]>(this.baseUrl + systemId + "/endringer");
  }
  getEndring(systemId: number, endringId: number): Observable<Endring> {
    return this.http.get<Endring>(this.baseUrl + systemId, { params: { endringId: endringId.toString() } });
  }




  getEndringFields(systemId: number, endringId: number): Observable<Field[]> {
    return this.http.get<Field[]>(this.baseUrl + systemId + "/fields");
  }


}
export interface EndringSearchResult {
  pageNumber: number;
  totalPages: number;
  items: Endring[];
}


export interface Endring {
  endringId: number;
  systemId: number;
  endringNummer: number;
  saksnummer: string;
  datoUtfort: Date;
  beskrivelse: string;
  kommentar: string;
}

