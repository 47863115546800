import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { ModalService } from "./modal.service";
import { NotificationService } from "./notification.service";

export interface BackNavigationComponent<E = null> {
  antallEndringer() : number;
  sidenErEndret: () => boolean;
  saveChanges(): Observable<E>;
}

@Injectable({
  providedIn: "root"
})
export class BacknavigationGuard implements CanDeactivate<BackNavigationComponent> {

  constructor(private notificationService: NotificationService, private modalService: ModalService) {

  }
  canDeactivate(component: BackNavigationComponent<any | null>, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (component.sidenErEndret && component.sidenErEndret()) {
      return this.modalService.showConfirmation("Lagre endringer?", "Du har ulagrede endringer, vil du lagre før du forlater siden?", true, component.antallEndringer(), component.saveChanges());
    } else {
      return true;
    }
  }

}
