<div class="collapse mb-2" [ngClass]="{'show':tekst.length==0}">
  <h1>Systemoversikt</h1>
  <div class="row">
    <div class="col-12 col-md-9">
      <p>
        Bergen kommune sin samlede oversikt over programmer, systemer og komponenter. Systemoversikten skal gi
        overordnet informasjon om it-systemer og løsninger som benyttes i Bergen kommune sin tjenesteproduksjon.
        Søket er globalt for alt som er registrert i Systemoversikten
      </p>
      <p>
        <i>Oversikten er rollebasert og informasjon blir gjort tilgjengelig ut fra den funksjon du har som ansatt/leder
          eller rolle opp mot et eller flere systemer.</i>
      </p>
    </div>
  </div>
</div>
<input class="form-control form-control-lg my-3" type="text" placeholder="Søk" [(ngModel)]="tekst"
  (keyup)="filterChanged()" name="tekstFilter">

<div *ngIf="loadingData" class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div *ngIf="results">

  <div *ngFor="let item of results.items" class="list-group-flush">

    <div [ngSwitch]="item.innholdstype">

      <a *ngSwitchCase="'Itsystem'" [routerLink]="['/itsystemer',item.id]"
        class="list-group-item list-group-item-action flex-column align-items-start">
        <div class="d-flex w-100 justify-content-end">
          <small>System</small>
        </div>
        <div>
          <h5 class="mb-1">{{item.navn}}</h5>
        </div>
        <p class="mb-1">{{item.beskrivelse}}</p>
      </a>
      <a *ngSwitchCase="'Risikovurdering'" [routerLink]="['/risikogjennomganger',item.id]"
        class="list-group-item list-group-item-action flex-column align-items-start">
        <div class="d-flex w-100 justify-content-end">
          <small>Sikkerhetsinfo</small>
        </div>
        <div>
          <h5 class="mb-1">{{item.navn}}</h5>
        </div>
        <p class="mb-1">Utført: {{item.beskrivelse}}</p>
      </a>
      <a *ngSwitchCase="'Testinformasjon'" [routerLink]="['/testinformasjoner',item.id]"
         class="list-group-item list-group-item-action flex-column align-items-start">
        <div class="d-flex w-100 justify-content-end">
          <small>Testinformasjon</small>
        </div>
        <div>
          <h5 class="mb-1">{{item.navn}}</h5>
        </div>
      </a>
      <a *ngSwitchCase="'Endring'" [routerLink]="['/itsystemer',item.parentId,'endring',item.id]"
        class="list-group-item list-group-item-action flex-column align-items-start">
        <div class="d-flex w-100 justify-content-end">
          <small>Endring</small>
        </div>
        <div>
          <h5 class="mb-1">{{item.navn}}</h5>
        </div>
        <p class="mb-1">Utført: {{item.beskrivelse}}</p>
      </a>
      <a *ngSwitchCase="'Leverandør'" [routerLink]="['/leverandorer',item.id]"
        class="list-group-item list-group-item-action flex-column align-items-start">
        <div class="d-flex w-100 justify-content-end">
          <small>Leverandør</small>
        </div>
        <div>
          <h5 class="mb-1">{{item.navn}}</h5>
        </div>
        <p class="mb-1">{{item.beskrivelse}}</p>
      </a>
      <a *ngSwitchCase="'Oppdrag'" [routerLink]="['/oppdrag',item.id]"
        class="list-group-item list-group-item-action flex-column align-items-start">
        <div class="d-flex w-100 justify-content-end">
          <small>Oppdrag</small>
        </div>
        <div>
          <h5 class="mb-1">{{item.navn}}</h5>
        </div>
        <p class="mb-1">{{item.beskrivelse}}</p>
      </a>
      <a *ngSwitchCase="'Tjeneste'" [routerLink]="['/tjenester',item.id]"
        class="list-group-item list-group-item-action flex-column align-items-start">
        <div class="d-flex w-100 justify-content-end">
          <small>Tjeneste</small>
        </div>
        <div>
          <h5 class="mb-1">{{item.navn}}</h5>
        </div>
        <p class="mb-1">{{item.beskrivelse}}</p>
      </a>



      <div *ngSwitchDefault class="list-group-item list-group-item-action flex-column align-items-start">
        <div class="d-flex w-100 justify-content-end">
          <small>{{item.innholdstype}}</small>
        </div>
        <div>
          <h5 class="mb-1">{{item.navn}}</h5>
        </div>
        <p class="mb-1">{{item.beskrivelse}}</p>

      </div>

    </div>
  </div>
</div>

<div class="collapse mt-5" [ngClass]="{'show':tekst.length==0}">

  <div *ngIf="dataForPerson" class="row">

    <div *ngIf="dataForPerson.oppdragPerson && dataForPerson.oppdragPerson.length>0 && pageAccess?.oppdrag.listAccess" class="col-12 col-md-6 col-lg-4">
      <div class="font-italic">Mine oppdrag</div>
      <div *ngFor="let item of dataForPerson.oppdragPerson" class="list-group">
        <a [routerLink]="['/oppdrag', item.oppdragId]"
          class="list-group-item list-group-item-action flex-column align-items-start">
          <h5 class="mb-1">{{item.oppdrag.navn}}</h5>
          <div class="text-muted">{{item.oppdrag.beskrivelse}} &nbsp;</div>
        </a>
      </div>
    </div>

    <div *ngIf="dataForPerson.personSystemRolle && dataForPerson.personSystemRolle.length >0"
      class="col-12 col-md-6 col-lg-4">
      <div class="font-italic">Mine systemer</div>
      <div *ngFor="let item of dataForPerson.personSystemRolle" class="list-group">
        <a [routerLink]="['/itsystemer', item.systemId]"
          class="list-group-item list-group-item-action flex-column align-items-start">
          <h5 class="mb-1">{{item.system.navn}}</h5>
          <div class="text-muted">{{item.system.beskrivelse}} &nbsp;</div>
        </a>
      </div>
    </div>

    <div *ngIf="dataForPerson.personLeverandorRolle && dataForPerson.personLeverandorRolle.length >0 && pageAccess?.leverandorer.listAccess"
      class="col-12 col-md-6 col-lg-4">
      <div class="font-italic">Mine Leverandører</div>
      <div *ngFor="let item of dataForPerson.personLeverandorRolle" class="list-group">
        <a [routerLink]="['/leverandorer', item.leverandorId]"
          class="list-group-item list-group-item-action flex-column align-items-start">
          <h5 class="mb-1">{{item.leverandor.navn}}</h5>
          <div class="text-muted">{{item.leverandor.url}} &nbsp;</div>
        </a>
      </div>
    </div>

  </div>

</div>
