<div>
  <div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
  </div>

  <div class="modal-body">

    <input class="form-control form-control-lg my-3" type="text" placeholder="Søk" [(ngModel)]="text"
           (keyup)="filterChanged()" name="tekstFilter">

    <app-spinner [loadingData]="isLoading" [loadingFailed]="errorLoading"></app-spinner>

    <div *ngIf="searchResult" class="list-group-flushed">
      <div (click)="confirm(item)" *ngFor="let item of searchResult.items"
           class="list-group-item list-group-item-action flex-column align-items-start">{{item.navn}}</div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="cancel()">Lukk</button>
  </div>
</div>
