import { Component } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Observable } from "rxjs";
import { FilterParams, SearchResults } from "../common/common.model";
import { ListesideBaseClass } from "../common/listeside/listeside.component";
import { TilgangService } from "../services/tilgang.service";
import { UserSettingsStorageService } from "../services/user-settings-storage.service";
import { ItsystemerService, ItsystemSearchResults } from "./itsystemer.service";


@Component({
  selector: "app-itsystemer",
  templateUrl: "./itsystemer.component.html"
})
export class ItsystemerComponent extends ListesideBaseClass {


  overskrift = "Oversikt over IT-systemer";


  constructor(
    private service: ItsystemerService,
    private userSettings: UserSettingsStorageService,
    tilgangService: TilgangService,
    route: ActivatedRoute,
    router: Router
  ) { super(route, router, tilgangService); }

  urlParamsToFilterParams(params: Params, filterParams: FilterParams): FilterParams {
    filterParams = filterParams ? filterParams : new FilterParams();
    filterParams.tekst = params.s ? params.s : "";
    filterParams.order = params.sortOrder ? params.sortOrder : "nameAsc";
    filterParams.pageNumber = params.pageNumber ? params.pageNumber : 1;
    filterParams.pageSize = params.pageSize ? params.pageSize : 30;
    filterParams.showExpired = params.showExpired == "true";
    filterParams.driftetAvAnsattservice = params.driftetAvAnsattservice == "true";
    filterParams.showSeSk = params.showSeSk ? params.showSeSk == "true" : true;
    filterParams.extendedSearch = params.extendedSearch ? params.extendedSearch == "true" : false;
    filterParams.byradsavdeling = params.byradsavdeling ? params.byradsavdeling : "";

    if (params.kilder) {
      const kilder = params.kilder;
      filterParams.kilder = kilder;
      filterParams.showInfrastruktur = kilder.toLowerCase().includes("i");
      filterParams.showProgrampakke = kilder.toLowerCase().includes("p");
      filterParams.showFagsystem = kilder.toLowerCase().includes("f");
      filterParams.showNettjeneste = kilder.toLowerCase().includes("n");
    } else {
      filterParams.showInfrastruktur = true;
      filterParams.showProgrampakke = true;
      filterParams.showFagsystem = true;
      filterParams.showNettjeneste = true;
    }


    if (Object.keys(params).length === 0) {
      // load default filter config from local storage if exists
      const defaultSettings = this.userSettings.getItsystemFilterParams();

      if (defaultSettings) {
        console.log("Fant en itsystem config i local storage... benytter denne");
        console.log(defaultSettings);
        Object.assign(filterParams, defaultSettings);
      }
    }

    return filterParams;
  }


  onFilterChange(filterParams: FilterParams): void {
    console.log(filterParams);
    this.router.navigate([], {
      queryParams:
      {
        "s": filterParams.tekst,
        "sortOrder": filterParams.order,
        "kilder": this.getKilderString(filterParams),
        "showExpired": filterParams.showExpired,
        "driftetAvAnsattservice": filterParams.driftetAvAnsattservice,
        "showSeSk": filterParams.showSeSk,
        "extendedSearch": filterParams.extendedSearch,
        "byradsavdeling": filterParams.byradsavdeling,
        "pageNumber": filterParams.pageNumber,
        "pageSize": filterParams.pageSize
      }
    });
  }

  private getKilderString(filterParams: FilterParams): string {
    let kilder = "";
    if (filterParams.showInfrastruktur) { kilder = kilder + "I"; }
    if (filterParams.showProgrampakke) { kilder = kilder + "P"; }
    if (filterParams.showFagsystem) { kilder = kilder + "F"; }
    if (filterParams.showNettjeneste) { kilder = kilder + "N"; }
    return kilder;
  }


  search(params: FilterParams): Observable<SearchResults<ItsystemSearchResults>> {

    const name = params.tekst as string;
    const order = params.order as string;
    const kilder = this.getKilderString(params);
    const showExpired = params.showExpired as boolean;
    const driftetAvAnsattservice = params.driftetAvAnsattservice as boolean;
    const showSeSk = params.showSeSk as boolean;
    const extendedSearch = params.extendedSearch as boolean;
    const byradsavdeling = params.byradsavdeling as number;
    const pageNumber = params.pageNumber as number;
    const pageSize = params.pageSize as number;


    return this.service.searchItsystemer(name, order, kilder, showExpired, driftetAvAnsattservice, showSeSk, extendedSearch, byradsavdeling, pageNumber, pageSize);
  }



}
