import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FilterParams } from "../../common/common.model";
import { OppdragService, Oppdragstatus, Oppdragstatusleveranse, Oppdragstatussm } from "../oppdrag.service";

@Component({
  selector: "app-oppdrag-filter",
  templateUrl: "./oppdrag-filter.component.html",
  styleUrls: ["./oppdrag-filter.component.scss"]
})
export class OppdragFilterComponent implements OnInit {


  constructor(private oppdragService: OppdragService) { }


  oppdragStatus: Oppdragstatus[];
  oppdragstatussm: Oppdragstatussm[];
  oppdragstatusleveranse: Oppdragstatusleveranse[];

  @Input()
  loadingData = false;

  @Input()
  filterParams: FilterParams;

  filtersCollapsed: boolean;

  @Output()
  onFilterChange = new EventEmitter<FilterParams>();

  ngOnInit(): void {
    this.oppdragService.getOppdragStatus().subscribe(o => {
      this.oppdragStatus = o;
      console.log(this.oppdragStatus);
    });
    this.oppdragService.getOppdragStatusSm().subscribe(o => {
      this.oppdragstatussm = o;
    });
    this.oppdragService.getOppdragstatusleveranse().subscribe(o => {
      this.oppdragstatusleveranse = o;
    });


  }


  filterChanged(event?: any): void {
    console.log(event);
    console.log(this.filterParams);
    this.onFilterChange.emit(this.filterParams);
  }


}





