import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { FilterParams } from "src/app/common/common.model";
import { OppdragPerson } from "src/app/koblinger/koblinger.service";
import { Oppdrag } from "src/app/oppdrag/oppdrag.service";
import { Fakturalinje, FaktureringService, OppdragFakturalinjeDrift } from "src/app/services/fakturering.service";
import { Person } from "src/app/services/person.service";
import { GlobalVariables } from "../../../../global";
import { saveAs } from 'file-saver';

@Component({
  selector: "app-oppdrag-report-generator",
  templateUrl: "./oppdrag-report-generator.component.html",
  styleUrls: ["./oppdrag-report-generator.component.scss"]
})
export class OppdragReportGeneratorComponent implements OnInit {


  @ViewChild("tOppdrag") oppdragTable: ElementRef;
  @ViewChild("tDrift") driftTable: ElementRef;

  readonly dateFormat = GlobalVariables.DATE_FORMAT;

  isLoading = true;
  errorLoading = false;

  oppdrag: Oppdrag;
  fakturalinjer: Fakturalinje[];
  fakturalinjerDrift: OppdragFakturalinjeDrift[];

  prosjektEier: Person;
  leveranseansvarlig: Person;

  filterParams: FilterParams;

  private _oppdragPersoner: OppdragPerson[];

  set oppdragPersoner(oppdragPersoner: OppdragPerson[]) {
    this._oppdragPersoner = oppdragPersoner;
    if (this.oppdragPersoner) {
      this.prosjektEier = this._oppdragPersoner.find(op => op.rolleId == 11)?.person;
      this.leveranseansvarlig = this._oppdragPersoner.find(op => op.rolleId == 10)?.person;
    }
  }

  get oppdragPersoner(): OppdragPerson[] {
    return this._oppdragPersoner;
  }

  @Output() onConfirm = new EventEmitter<boolean>();

  constructor(private fakturaService: FaktureringService) { }

  ngOnInit(): void {
    // Do nothing
  }

  cancel(): void {
    this.onConfirm.emit(false);
  }


  exportExcel(): void {
    this.fakturaService.generateExcelReportOppdrag(
      this.filterParams.fra,
      this.filterParams.til,
      this.filterParams.order,
      this.filterParams.visFakturerte,
      this.filterParams.visGodkjente,
      this.filterParams.oppdragId).subscribe(results => {
      saveAs(results, "rapport.xlsx");
    });
  }

  accNormaltid(): number {
    const sum = this.fakturalinjer.reduce((acc, f) => acc + f.normaltid, 0);
    return sum / 36000000000;
  }
  accOvertid(): number {
    const sum = this.fakturalinjer.reduce((acc, f) => acc + f.overtid, 0);
    return sum / 36000000000;
  }
  accSumNormaltid(): number {
    const sum = this.fakturalinjer.reduce((acc, f) => acc + f.sumNormaltid, 0);
    return sum;
  }
  accSumOvertid(): number {
    const sum = this.fakturalinjer.reduce((acc, f) => acc + f.sumOvertid, 0);
    return sum;
  }

  totalTid(): number { return this.accNormaltid() + this.accOvertid(); }

  accSumFastBelop(): number {
    const sum = this.fakturalinjer.reduce((acc, f) => acc + f.fastBelop, 0);
    return sum;
  }

  totalSumTimer(): number { return this.accSumNormaltid() + this.accSumOvertid() + this.accSumFastBelop(); }

  accSumServer(): number { return this.fakturalinjerDrift.reduce((acc, f) => acc + f.sumServer, 0); }
  accSumServerTest(): number { return this.fakturalinjerDrift.reduce((acc, f) => acc + f.sumServerTest, 0); }
  accSumMsSql(): number { return this.fakturalinjerDrift.reduce((acc, f) => acc + f.sumSqlDb, 0); }
  accSumOracle(): number { return this.fakturalinjerDrift.reduce((acc, f) => acc + f.sumOracleDb, 0); }
  accSumIkkeStd(): number { return this.fakturalinjerDrift.reduce((acc, f) => acc + f.sumIkkeStdDb, 0); }
  accSumAppdrift(): number { return this.fakturalinjerDrift.reduce((acc, f) => acc + f.sumAppdrift, 0); }
  accSumTillegg(): number { return this.fakturalinjerDrift.reduce((acc, f) => acc + f.sumTillegg, 0); }

  totalSumDrift(): number { return this.accSumServer() + this.accSumServerTest() + this.accSumMsSql() + this.accSumOracle() + this.accSumIkkeStd() + this.accSumAppdrift() + this.accSumTillegg(); }


  totalSum(): number { return this.totalSumTimer() + this.totalSumDrift(); }



  onBtnPrintClick(): void {
    const printData = document.getElementById("printable");
    this.addPrintSectionToElement(printData);
  }

  removePrintSection(): void {
    let remove = document.getElementById("printSection");
    while (remove) {
      document.body.removeChild(remove);
      remove = document.getElementById("printSection");
    }
  }

  addPrintSectionToElement(element: HTMLElement): void {

    const domClone = element.cloneNode(true);

    const printSection = document.createElement("div");
    printSection.id = "printSection";
    document.body.appendChild(printSection);

    printSection.innerHTML = "";
    printSection.appendChild(domClone);
    window.print();
  }
}
