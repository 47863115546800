import { Component, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Tabs } from "src/app/common/tabs/tabs.enum";
import { TabsService } from "src/app/common/tabs/tabs.service";
import { ModalService } from "src/app/services/modal.service";
import { TilgangService } from "src/app/services/tilgang.service";
import { TabSideBaseClass } from "../../common/tab-side/tab-side.component";
import { SystemSystem } from "../../integrasjoner/integrasjoner.service";
import { KoblingerService } from "../../koblinger/koblinger.service";
import { IntegrasjonsharedService } from "src/app/services/integrasjonshared.service";


@Component({
  selector: "app-systemsystem-tab",
  templateUrl: "./systemsystem-tab.component.html",
  styleUrls: ["./systemsystem-tab.component.scss"]
})
export class SystemsystemTabComponent extends TabSideBaseClass<SystemSystem> {

  tabName(): Tabs {
    return Tabs.integrasjoner;
  }

  constructor(tabsService : TabsService, service: KoblingerService, tilgangService: TilgangService, modal: ModalService, route: ActivatedRoute, router: Router, integrasjonShared: IntegrasjonsharedService) {
    super(tabsService, service, tilgangService, modal, route, router, integrasjonShared);
  }

  loaderObservable(service: KoblingerService, id: number): Observable<SystemSystem[]> {
    return this.service.getIntegrasjonerForSystem(this.id);
  }

  pageLinkAccess(service: TilgangService): Observable<boolean> {
    return service.getGlobalRoles().pipe(map(val => val.integrasjoner.listAccess));
  }

  removeObservable(service: KoblingerService, item: SystemSystem): Observable<object> {
    return service.removeSystemSystem(item.id, item.systemId1);
  }

  getRetning(retningId: number): string {
    switch (retningId) {
      case 1:
        return "-->";
      case 2:
        return "<--";
      case 3:
        return "--";
      default:
        return "--";
    }
  }

  getRetningSystemId2(retningId: number): string {
    switch (retningId) {
      case 1:
        return "<--";
      case 2:
        return "-->";
      case 3:
        return "--";
      default:
        return "--";
    }
  }

  override compareFn(first: SystemSystem, second: SystemSystem): number {
    if (first.aktiv && second.aktiv) return 0;
    if (first.aktiv && !second.aktiv) return -1;
    else return 1;
  }

  kobleTil(): void {
    this.modal.velgSystem().then(system => {
      const system1Id = this.id;
      const system2Id = system.systemId;

      const item = { systemId1: system1Id, systemId2: system2Id } as SystemSystem;
      this.service.postSystemSystem(item).toPromise().then(() => {
        this.modal.success("", "System koblet til system");
        this.load();
        this.integrasjonShared?.sendEvent();
      });
    });
  }

}
