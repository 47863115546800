import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AdresseService {

  private readonly basePath = "api/adresser/";
  private readonly baseUrl: string;

  constructor(private http: HttpClient, @Inject("BASE_URL") private baseHost: string) {
    this.baseUrl = baseHost + this.basePath;
  }

  findAddressSugestions(adresse: string): Observable<Adresse[]> {

    let searchParams = new HttpParams();
    searchParams = searchParams.append("address", adresse);

    return this.http.get<Adresse[]>(this.baseUrl + "search", { params: searchParams });
  }

  findPoststedByPostkode(postkode: string) {
    const headers = new HttpHeaders().set("Content-Type", "text/plain; charset=utf-8");
    return this.http.get(this.baseUrl + "postkoder/" + postkode, { responseType: "text" });
  }


}


export interface Adresse {
  adressenavn: string;
  nummer: string;
  kommunenavn: string;
  postnummer: string;
  poststed: string;
}
