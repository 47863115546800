import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Tabs } from "src/app/common/tabs/tabs.enum";
import { TabsService } from "src/app/common/tabs/tabs.service";
import { ModalService } from "src/app/services/modal.service";
import { TilgangService } from "src/app/services/tilgang.service";
import { TabSideBaseClass } from "../../common/tab-side/tab-side.component";
import {KoblingerService, OppdragSystem, SystemSertifikat} from "../../koblinger/koblinger.service";
import {Itsystem} from "../../itsystemer/itsystemer.service";


@Component({
  selector: "app-sertifikat-system-tab",
  templateUrl: "./sertifikat-system-tab.component.html"
})

export class SertifikatSystemTabComponent extends TabSideBaseClass<SystemSertifikat> {

  kobleTil(searchVal: string): void {
    throw new Error("Method not implemented.");
  }

  tabName(): Tabs {
    return Tabs.systemer;
  }

  constructor(tabsService: TabsService, service: KoblingerService, tilgangService: TilgangService, modal: ModalService, route: ActivatedRoute, router: Router) {
    super(tabsService, service, tilgangService, modal, route, router);
  }

  loaderObservable(service: KoblingerService, id: number): Observable<SystemSertifikat[]> {
    return this.service.getSystemSertifikatForSertifikat(this.id);
  }

  pageLinkAccess(service: TilgangService): Observable<boolean> {
    return service.getGlobalRoles().pipe(map(val => val.itsystemer.listAccess));
  }
  public erUtgaatt(system: Itsystem) : boolean {
    return system.systemstatusId == 5;
  }

  compareFn(first: SystemSertifikat, second: SystemSertifikat): number {
    if (first.system.systemstatusId != 5 && second.system.systemstatusId != 5) return 0;
    if (first.system.systemstatusId != 5 && second.system.systemstatusId == 5) return -1;
    else return 1;
  }
}
