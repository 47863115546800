import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Tabs } from "src/app/common/tabs/tabs.enum";
import { TabsService } from "src/app/common/tabs/tabs.service";
import { TilgangService } from "src/app/services/tilgang.service";
import { TabSideBaseClass } from "../../common/tab-side/tab-side.component";
import { KoblingerService, PersonSystemRolle } from "../../koblinger/koblinger.service";
import { ModalService } from "../../services/modal.service";

@Component({
  selector: "app-personer-tab",
  templateUrl: "./itsystempersoner-tab.component.html"
})
export class ItsystemPersonerTabComponent extends TabSideBaseClass<PersonSystemRolle> {

  tabName(): Tabs {
    return Tabs.personer;
  }

  constructor(tabsService: TabsService, service: KoblingerService, tilgangService: TilgangService, modal: ModalService, route: ActivatedRoute, router: Router) {
    super(tabsService, service, tilgangService, modal, route, router);
  }

  loaderObservable(service: KoblingerService, id: number): Observable<PersonSystemRolle[]> {
    return service.getPersonSystemRollerForSystem(this.id);
  }

  pageLinkAccess(service: TilgangService): Observable<boolean> {
    return service.getGlobalRoles().pipe(map(val => val.personer.listAccess));
  }

  removeObservable(service: KoblingerService, item: PersonSystemRolle): Observable<object> {
    return service.removePersonSystemRolleForSystem(item.personId, item.systemId, item.rolleId);
  }


  compareFn(first: PersonSystemRolle, second: PersonSystemRolle): number {
    if (!first.person.sluttet && !second.person.sluttet) {
      return 0;
    } else if (!first.person.sluttet && second.person.sluttet) {
      return -1;
    } else {
      return 1;
    }
  }

  kobleTil(): void {

    this.modal.velgPerson().then(person => {
      this.modal.velgPersonRolle().then(rolle => {

        if (this.liste.some(t => t.personId === person.personId && t.rolleId === rolle.rolleId)) {
          this.modal.warning(`${person.navn} med rolle ${rolle.navn} er allerede koblet til system`, "Kobling feilet");
          return;
        }

        this.isLoading = true;

        const psr: PersonSystemRolle = { systemId: this.id, personId: person.personId, rolleId: rolle.rolleId };
        this.service.postPersonSystemRolleForSystem(psr).subscribe(() => {
          this.isLoading = false;
          this.modal.success("System, person og rolle", "Kobling opprettet");
          this.load();
        }, () => { this.isLoading = false });
      }).catch(() => {
        // do nothing
      });
    }).catch(() => {
      // do nothing
    });
  }
}
