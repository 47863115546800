<div>
    <div class="modal-header">
      <h4 class="modal-title">Velg rolle</h4>
    </div>
    <div class="modal-body">
        
        <div *ngIf="roller" class="list-group-flushed">
            <div (click)="confirm(item)"  *ngFor="let item of roller" class="list-group-item list-group-item-action flex-column align-items-start">
                {{item.navn}}
            </div>

        </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="cancel()">Lukk</button>
      
    </div>
</div>