import {HttpClient, HttpParams} from "@angular/common/http";
import {Inject, Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Field, SearchResults} from "../common/common.model";
import {Person} from "../services/person.service";


@Injectable({
  providedIn: "root"
})
export class TestinformasjonService {

  private readonly basePath = "api/testinformasjon/";
  private readonly baseUrl: string;

  constructor(private http: HttpClient, @Inject("BASE_URL") baseHost: string) {
    this.baseUrl = baseHost + this.basePath;
  }


  getTestinformasjon(systemId: number): Observable<Testinformasjon> {
    return this.http.get<Testinformasjon>(this.baseUrl + systemId);
  }

  updateTestinformasjon(testinformasjon: Testinformasjon): Observable<Testinformasjon> {
    return this.http.put<Testinformasjon>(this.baseUrl + testinformasjon.systemId, testinformasjon);
  }

  searchTestinformasjon(
    name?: string,
    systemeier?: string,
    systemkoordinator?: string,
    testansvarlig?: string,
    kundeId?: number,
    kilder?: string,
    sortOrder?: string,
    testOfficeEndring: boolean = false,
    testWindowsEndring: boolean = false,
    testet: boolean = false,
    testForetatt?: string,
    showExpired = false,
    pageNumber = 1,
    pageSize = 30): Observable<SearchResults<TestinformasjonSearchResults>> {
    let searchParams = new HttpParams();
    if (sortOrder) {
      searchParams = searchParams.append("sortOrder", sortOrder);
    }
    if (name) {
      searchParams = searchParams.append("name", name);
    }
    if (systemeier) {
      searchParams = searchParams.append("systemeier", systemeier);
    }
    if (systemkoordinator) {
      searchParams = searchParams.append("systemkoordinator", systemkoordinator);
    }
    if (testansvarlig) {
      searchParams = searchParams.append("testansvarlig", testansvarlig);
    }
    if (kundeId) {
      searchParams = searchParams.append("kundeId", kundeId.toString());
    }
    if (kilder) {
      searchParams = searchParams.append("kilder", kilder);
    }
    searchParams = searchParams.append("testOfficeEndring", String(testOfficeEndring));
    searchParams = searchParams.append("testWindowsEndring", String(testWindowsEndring));
    searchParams = searchParams.append("testet", String(testet));
    if (testForetatt) {
      searchParams = searchParams.append("testForetatt", testForetatt);
    }
    searchParams = searchParams.append("showExpired", String(showExpired));
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<SearchResults<TestinformasjonSearchResults>>(this.baseUrl + "", {params: searchParams});
  }

  getTestinformasjonFields(systemId: number): Observable<Field[]> {
    return this.http.get<Field[]>(`${this.baseUrl + systemId}/fields`);
  }

}

export interface TestinformasjonSearchResults {

  systemId: number;
  navn: string;
  beskrivelse: string;
  testOfficeEndring: boolean;
  testWindowsEndring: boolean;
  testetDato: Date;
  testetOk: boolean;
  systemEier: Person;
  systemkoodinator: Person;
  testansvarlig: Person;
}

export interface Testinformasjon {
  systemId: number;
  navn: string;
  beskrivelse: string;
  kundeId?: number;
  systemTypeId: number;
  systemStatusId: number;
  kilde: string;
  tilgjengelighetId: number;
  testOfficeEndring: boolean;
  testWindowsEndring: boolean;
  testmaskinOffice: string;
  testmaskinWindows: string;
  testresultatId?: number;
  testresultatNavn?: string;
  testetDato?: Date
}

