import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Field, SearchItem, SearchResults } from "../common/common.model";


@Injectable({
  providedIn: "root"
})
export class TjenesterService {

  private readonly basePath = "api/tjeneste/";
  private readonly baseUrl: string;

  constructor(private http: HttpClient, @Inject("BASE_URL") private baseHost: string) {
    this.baseUrl = baseHost + this.basePath;
  }

  search(beskrivelse?: string, sortOrder?: string, showExpired = false, pageNumber = 1, pageSize = 30): Observable<SearchResults<TjenesteSearchResult>> {

    let searchParams = new HttpParams();

    if (beskrivelse) { searchParams = searchParams.append("beskrivelse", beskrivelse); }
    if (sortOrder) { searchParams = searchParams.append("sortOrder", sortOrder); }
    searchParams = searchParams.append("showExpired", String(showExpired));
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<SearchResults<TjenesteSearchResult>>(this.baseUrl, { params: searchParams });
  }

  get(id: number): Observable<Tjeneste> {
    return this.http.get<Tjeneste>(this.baseUrl + id);
  }

  put(tjeneste: Tjeneste): Observable<Tjeneste> {
    return this.http.put<Tjeneste>(this.baseUrl + tjeneste.tjenesteId, tjeneste);
  }

  post(sertifikat: Tjeneste): Observable<Tjeneste> {
    return this.http.post<Tjeneste>(this.baseUrl, sertifikat);
  }

  getFields(tjenesteId: number): Observable<Field[]> {
    return this.http.get<Field[]>(this.baseUrl + tjenesteId + "/fields");
  }
  getCreateFields(): Observable<Field[]> {
    return this.http.get<Field[]>(this.baseUrl + "create/fields");
  }

}


export interface Tjeneste {
  tjenesteId: number;
  navn: string;
  beskrivelse: string;
}

export interface TjenesteSearchResult extends SearchItem {
  utgaatt: boolean;
  utgaattdato?: Date;
}




