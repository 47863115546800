import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Tabs } from './tabs.enum';

@Injectable({
  providedIn: 'root'
})
export class TabsService {

  private integrationTabDataChangedSubject: Subject<void> = new Subject<void>();
  private observableMap: Map<Tabs, Observable<void>> = new Map();

  constructor() {
    this.observableMap.set(Tabs.integrasjoner, this.integrationDataUpdated$);
  }

  integrationDataUpdated$ = this.integrationTabDataChangedSubject.asObservable();


  getDataChangedObservable(tab: Tabs): Observable<void> {
    return this.observableMap.get(tab);
  }

  updateIntegrationData(): void {
    this.integrationTabDataChangedSubject.next();
  }

  updateAll(): void {
    this.integrationTabDataChangedSubject.next();
  }
}
