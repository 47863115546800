<div class="col-12 col-md-9">
  <div class="btn-toolbar my-2" role="toolbar" aria-label="Toolbar with button groups">
    <div class="btn-group mr-4" role="group">
      <button class="btn btn-outline-primary px-5" type="button" (click)="lagre()"
              [disabled]="(enabled != null && !enabled) || (enabled == null && !formGroup.dirty) || formGroup.invalid || isSaving">{{buttonTitle}}
        <span *ngIf="isSaving" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        <span *ngIf="isSaving" class="sr-only">Loading...</span>
      </button>
    </div>
    <div *ngIf="showResetButton" class="btn-group" role="group">
      <button class="btn btn-outline-secondary px-4" type="button" (click)="tilbakestill()"
              [disabled]="!formGroup.dirty">
        Nullstill
      </button>
    </div>
  </div>
</div>
