import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FilterParams } from "../../common.model";

@Component({
  selector: "app-paginator",
  templateUrl: "./paginator.component.html",
  styleUrls: ["./paginator.component.scss"]
})
export class PaginatorComponent implements OnInit {


  @Input()
  filterParams: FilterParams;
  @Output()
  onFilterChange = new EventEmitter<FilterParams>();



  ngOnInit(): void {
    // Do nothing
  }

  pageChanged(): void {
    this.onFilterChange.emit(this.filterParams);
  }

}
