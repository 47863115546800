

<span *ngIf="!open && permission && permission.r">           
  {{modell[key]}} 
</span>

<span *ngIf="!modell[key] && permission && permission.w && !open" class="text-muted">{{tekst}}</span>

<button *ngIf="permission && permission.w" type="button" class="btn btn-link" (click)="toggle()">
  <span class="btn-label"><small class="far fa-edit"></small></span><span class=""></span>    
</button>    


<div *ngIf="open">
  <div class="inline-form mx-2">              
    <div *ngIf="modell" class="my-1" >      
        <textarea id="{{key}}" class="form-control" rows="3" [formControl]="control"></textarea>           
    </div>
  </div>
</div>
