<div class="input-group" [formGroup]="formGroup">
    <input class="form-control col-8" formControlName={{formControlNameDate}} placeholder="yyyy-mm-dd" ngbDatepicker
        [footerTemplate]="footerTemplate" #d="ngbDatepicker" [autoClose]="'outside'" (closed)="closed()"
        [ngClass]="{'is-invalid': submitted && (formGroup.errors || formControlDate.errors)}">

    <input [value]="timeView" readonly class="form-control col-4" placeholder="HH:MM" [ngClass]="{'is-invalid': submitted && (formGroup.errors || formControlTime.errors)}">

    <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
            <i class="fas fa-calendar"></i>
        </button>
    </div>

    <ng-template #footerTemplate>
        <div class="container">
            <ngb-timepicker class="d-flex justify-content-center" formControlName={{formControlNameTime}}
                [minuteStep]="minuteStep">
            </ngb-timepicker>
        </div>
    </ng-template>

</div>



<div *ngIf="submitted && formGroup.invalid">

    <div class="text-danger" *ngIf="formGroup.errors?.ordering">
        <small>Fra må være før til</small>
    </div>


    <div *ngIf="formControlDate.errors?.required;else default" class="text-danger">
        <small>Required</small>
    </div>

    <ng-template #default>
        <div *ngIf="formControlDate.errors" class="text-danger">
            <small>Format issue</small>
        </div>
    </ng-template>

</div>
