import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Itsystem, ItsystemerService, SystemStatus, SystemType } from "src/app/itsystemer/itsystemer.service";
import { OppdragService } from "src/app/oppdrag/oppdrag.service";
import { ModalService } from "src/app/services/modal.service";
import { FilterParams, SearchItem } from "../../common/common.model";

@Component({
  selector: "app-fakturaer-filter",
  templateUrl: "./fakturaer-filter.component.html",
  styleUrls: ["./fakturaer-filter.component.scss"]
})
export class FakturaerFilterComponent implements OnInit {


  @Input()
  loadingData = false;

  @Input()
  filterParams: FilterParams;

  filtersCollapsed: boolean;

  @Output()
  onFilterChange = new EventEmitter<FilterParams>();
  fra: NgbDateStruct;
  til: NgbDateStruct;

  systemStatus: SystemStatus[];
  systemType : SystemType[];

  valgtOppdrag: SearchItem;
  valgtSystem: Itsystem;

  constructor(
    private readonly ngbDateFormatter: NgbDateParserFormatter,
    private readonly modalService: ModalService,
    private readonly systemService: ItsystemerService,
    private readonly oppdragService: OppdragService) { }

  ngOnInit(): void {

    this.systemService.getSystemStatus().subscribe(status => {
      this.systemStatus = status;
    });

    this.systemService.getSystemType().subscribe(type => {
      this.systemType = type;
    });

    if (this.filterParams.fra) {
      this.fra = this.ngbDateFormatter.parse(this.filterParams.fra as string);
    }
    if (this.filterParams.til) {
      this.til = this.ngbDateFormatter.parse(this.filterParams.til as string);
    }
    if (this.filterParams.systemId) {
      this.systemService.getItsystem(+this.filterParams.systemId).subscribe(system => {
        this.valgtSystem = system;
      });
    }
    if (this.filterParams.oppdragId) {
      this.oppdragService.get(+this.filterParams.oppdragId).subscribe(oppdrag => {
        this.valgtOppdrag = {
          id: oppdrag.oppdragId,
          beskrivelse: oppdrag.beskrivelse,
          navn: oppdrag.navn,
          parentId: oppdrag.oppdragId,
          aktivert: oppdrag.oppdragStatusId != 3,
          innholdstype: null
        };
      });
    }
  }

  resetFilters() {
    this.valgtOppdrag = null;
    this.valgtSystem = null;
  }

  filterChanged(event?: any): void {
    this.filterParams.fra = this.fra ? this.ngbDateFormatter.format(this.fra) : undefined;
    this.filterParams.til = this.til ? this.ngbDateFormatter.format(this.til) : undefined;
    this.onFilterChange.emit(this.filterParams);
  }

  velgOppdrag(): void {
    this.modalService.velgOppdrag().then(oppdrag => {
      this.valgtOppdrag = oppdrag;
      this.filterParams.oppdragId = oppdrag.id;
      this.filterChanged();
    }, error => {
      console.log(error);
    });
  }
  clearOppdrag(): void {
    this.valgtOppdrag = null;
    this.filterParams.oppdragId = null;
    this.filterChanged();
  }

  velgSystem(fakturatype: string): void {
    this.modalService.velgSystem(fakturatype).then(system => {
      this.valgtSystem = system;
      this.filterParams.systemId = system.systemId;
      this.filterChanged();
    }, error => { console.log(error) });
  }
  clearSystem(): void {
    this.valgtSystem = null;
    this.filterParams.systemId = null;
    this.filterChanged();
  }

  clearFraDato() {
    this.fra = undefined;
    this.filterChanged(null);
  }

  clearTilDato() {
    this.til = undefined;
    this.filterChanged(null);
  }
}
