import { Component } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { FilterParams } from "../common/common.model";
import { ListesideBaseClass } from "../common/listeside/listeside.component";
import { FakturaDetaljerComponent } from "../fakturering/faktura-detaljer/faktura-detaljer.component";
import { GlobalVariables } from "../global";
import { Fakturalinje, FakturaType, FaktureringService } from "../services/fakturering.service";
import { ModalService } from "../services/modal.service";
import { NotificationService } from "../services/notification.service";
import { FakturaAccess, TilgangService } from "../services/tilgang.service";
import {SystemType} from "../itsystemer/itsystemer.service";

@Component({
  selector: "app-timeforing",
  templateUrl: "./timeforing.component.html",
  styleUrls: ["./timeforing.component.scss"]
})
export class TimeforingComponent extends ListesideBaseClass {

  overskrift = "Timeføring";
  readonly dateFormat = GlobalVariables.DATE_FORMAT;
  deleteId: number;

  oppdragAccess: FakturaAccess;
  systemAccess: FakturaAccess;

  constructor(
    private service: FaktureringService,
    tilgangService: TilgangService,
    private modalService: ModalService,
    private notificationService: NotificationService,
    private fakturaService: FaktureringService,
    route: ActivatedRoute,
    router: Router
  ) { super(route, router, tilgangService); }

  ngOnInit(): void {
    this.tilgangService.getOppdragFakturaAccess().subscribe(oppdragAccess => {
      this.oppdragAccess = oppdragAccess;
    });
    this.tilgangService.getItsystemFakturaAccess().subscribe(systemAccess => {
      this.systemAccess = systemAccess;
    });
    super.ngOnInit();
  }

  nyFakturaItsystem(): NgbModalRef {
    const modalRef = this.modalService.visModal(FakturaDetaljerComponent);
    const modalComponent = modalRef.componentInstance as FakturaDetaljerComponent;
    modalComponent.systemId = +this.filterParams.systemId;
    return modalRef;
  }

  nyFakturaOppdrag(): NgbModalRef {
    const modalRef = this.modalService.visModal(FakturaDetaljerComponent);
    const modalComponent = modalRef.componentInstance as FakturaDetaljerComponent;
    modalComponent.oppdragId = +this.filterParams.oppdragId;
    return modalRef;
  }

  urlParamsToFilterParams(params: Params, filterParams: FilterParams): FilterParams {

    filterParams = filterParams ? filterParams : new FilterParams();

    filterParams.oppdragId = params.oppdragId;
    filterParams.systemId = params.systemId;

    filterParams.order = params.sortOrder ? params.sortOrder : "dateDesc";
    filterParams.pageNumber = params.pageNumber ? params.pageNumber : 1;
    filterParams.pageSize = params.pageSize ? params.pageSize : 30;
    filterParams.visFakturerte = params.visFakturerte !== undefined ? params.visFakturerte == "true" : false;
    filterParams.visGodkjente = params.visGodkjente !== undefined ? params.visGodkjente == "true" : true;
    filterParams.fakturatype = FakturaType[params.fakturatype ? params.fakturatype : "Fagsystem"];
    filterParams.fra = params.fra;
    filterParams.til = params.til;

    return filterParams;
  }

  onFilterChange(filterParams: FilterParams): void {
    this.router.navigate([], {
      queryParams:
      {
        "oppdragId": filterParams.oppdragId,
        "systemId": filterParams.systemId,

        "fra": filterParams.fra,
        "til": filterParams.til,
        "sortOrder": filterParams.order,
        "fakturatype": filterParams.fakturatype,
        "visFakturerte": filterParams.visFakturerte,
        "visGodkjente": filterParams.visGodkjente,
        "pageNumber": filterParams.pageNumber,
        "pageSize": filterParams.pageSize
      }
    });
  }

  redigerFakturalinje(faktura: Fakturalinje): NgbModalRef {
    const modalRef = this.modalService.visModal(FakturaDetaljerComponent);
    const modalComponent = modalRef.componentInstance as FakturaDetaljerComponent;
    modalComponent.systemId = faktura.systemId;
    modalComponent.endringId = faktura.endringId;
    modalComponent.oppdragId = faktura.oppdragId;
    modalComponent.fakturaId = faktura.fakId;
    modalComponent.faktura = Object.assign({}, faktura);;
    return modalRef;
  }

  systemTab() {
    this.filterParams.fakturatype = FakturaType.Fagsystem;
    this.onFilterChange(this.filterParams);
  }

  oppdragTab(): void {
    this.filterParams.fakturatype = FakturaType.Oppdrag;
    this.onFilterChange(this.filterParams);
  }



  slettFakturalinje(fakturaId: number, itemId: number): void {

    this.loadingData = true;
    this.deleteId = fakturaId;

    const deleteObservable = this.filterParams.fakturatype == FakturaType.Oppdrag ?
      this.fakturaService.deleteOppdragFakturlinje(fakturaId, itemId) :
      this.fakturaService.deleteItsystemFakturlinje(fakturaId, itemId);

    deleteObservable.subscribe(() => {
      this.loadingData = false;
      this.modalService.success("", "Fakturalinje fjernet");
      this.ngOnInit();
    }, () => {
      this.loadingData = false;
      this.loadingFailed = true;
      this.deleteId = null;
    });
  }

  search(params: FilterParams): Observable<any> {

    const systemId = params.systemId as number;
    const oppdragId = params.oppdragId as number;

    const fra = params.fra as string;
    const til = params.til as string;
    const order = params.order as string;
    const visFakturerte = params.visFakturerte as boolean;
    const visGodkjente = params.visGodkjente as boolean;
    const fakturatype = params.fakturatype as FakturaType;
    const systemtype = params.systemtype as string;
    const pageNumber = params.pageNumber as number;
    const pageSize = params.pageSize as number;

    switch (fakturatype) {
      case FakturaType.Fagsystem:
        return this.service.searchItsystemFakturaerUser(fra, til, order, visFakturerte, visGodkjente, systemId, systemtype, pageNumber, pageSize);
      case FakturaType.Oppdrag:
        return this.service.searchOppdragFakturaerUser(fra, til, order, visFakturerte, visGodkjente, oppdragId, pageNumber, pageSize);
    }
  }


  changeOrder(): void {
    this.filterParams.order = this.filterParams.order == "dateDesc" ? "dateAsc" : "dateDesc";
    this.onFilterChange(this.filterParams);
  }

  visTimeFakturalinjer(): boolean {
    return this.filterParams.fakturatype === FakturaType.Fagsystem ||
      this.filterParams.fakturatype === FakturaType.Oppdrag;
  }

  private oppdaterFaktura(faktura: Fakturalinje, godkjent: boolean) {
    const indeks = this.searchResults.findIndex(fakturalinje => fakturalinje.fakId === faktura.fakId);
    if (indeks != -1) {
      this.searchResults[indeks] = faktura;
      if (godkjent) {
        this.notificationService.success(`Faktura med faktura-id ${faktura.fakId} er godkjent`, "Faktura godkjent");
      } else {
        this.notificationService.success(`Faktura med faktura-id ${faktura.fakId} er merket som fakturert`, "Faktura fakturert");
      }
    }
  }

  godkjennFakturalinje(faktura: Fakturalinje): void {
    this.loadingData = true;
    if (faktura.oppdragId) {
      this.fakturaService.godkjennOppdragFaktura(faktura.fakId, faktura.oppdragId).subscribe(f => { this.oppdaterFaktura(f, true); this.loadingData = false; });
    } else {
      this.fakturaService.godkjennItsystemFaktura(faktura.fakId, faktura.systemId).subscribe(f => { this.oppdaterFaktura(f, true); this.loadingData = false; });
    }
  }

  merkFakturaFakturert(faktura: Fakturalinje): void {
    this.loadingData = true;
    if (faktura.oppdragId) {
      this.fakturaService.merkFakturertOppdragFaktura(faktura.fakId, faktura.oppdragId).subscribe(f => { this.oppdaterFaktura(f, false); this.loadingData = false; });
    } else {
      this.fakturaService.merkFakturertItsystemFaktura(faktura.fakId, faktura.systemId).subscribe(f => { this.oppdaterFaktura(f, false); this.loadingData = false; });
    }
  }
}
