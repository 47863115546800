import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { EMPTY, Observable } from "rxjs";
import { Field } from "src/app/common/common.model";
import { NyBaseClass } from "src/app/common/ny/ny-base";
import { NotificationService } from "src/app/services/notification.service";
import { PageAccess, TilgangService } from "src/app/services/tilgang.service";
import { Endring, EndringerService } from "../endringer.service";

@Component({
  selector: "app-endring-ny",
  templateUrl: "./endring-ny.component.html"
})
export class EndringNyComponent extends NyBaseClass<Endring> implements OnInit {

  set systemId(systemId: number) {
    this.model["systemId"] = systemId;
  }

  constructor(
    private service: EndringerService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    tilgangService: TilgangService,
    route: ActivatedRoute,
    router: Router,
    notificationService: NotificationService,
    public activeModal: NgbActiveModal
  ) { super(route, router, tilgangService, notificationService); }


  ngOnInit(): void {

    this.formGroup = new FormGroup({});

    this.route.url.subscribe(url => {

      this.urlString = url.join("/");
      this.urlPaths = this.urlString.split("/");

      console.log(url.entries);

      this.tilgangService.getGlobalRoles().subscribe(tilgang => {

        console.log("TILGANG FOR ENDRING");
        console.log(tilgang);
        console.log(this.urlPaths);
        console.log(url);

        const harTilgang = tilgang["endringer"] as PageAccess;
        console.log(harTilgang);

        this.harTilgang = harTilgang?.createAccess;

        this.isLoading = true;
        if (this.harTilgang) {
          this.getCreateFields().subscribe(fields => {

            this.customFields = this.removeFilteredFromArray(this.filterCustomFields(fields), fields);

            this.nonCustomFields = Array.from(fields);

            this.headingField = this.removeFilteredFromArray(fields.filter(f => f.key == "navn"), fields)[0];

            this.kostnadFields = this.removeFilteredFromArray(fields.filter(f => this.faktureringFieldNames().has(f.key)), fields);

            this.topFields = this.removeFilteredFromArray(fields.filter(f => f.key == "beskrivelse" || f.key == "kommentar" || f.key == "interninfo"), fields);

            this.investeringsFields = this.removeFilteredFromArray(fields.filter(f => f.key.startsWith("investering")), fields);

            this.numberFields = this.removeFilteredFromArray(fields.filter(f => f.inputType == "number"), fields);
            this.checkboxFields = this.removeFilteredFromArray(fields.filter(f => f.inputType == "checkbox"), fields);

            this.mainFields = fields;

            this.isLoading = false;

          });

        } else {
          this.errorLoading = true;
        }
      });

      this.setInitialValues();
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  create(model: Endring): Observable<Endring> {
    return this.service.post(model);
  }

  lagre() {
    this.isSaving = true;
    this.create(this.model).subscribe(resp => {
      this.isSaving = false;
      this.formGroup.markAsPristine();
      this.notificationService.success(this.model.navn, "Lagret");
      this.savingComplete.emit(resp);
      this.activeModal.dismiss();
    },
      () => this.isSaving = false);
  }

  getCreateFields(): Observable<Field[]> {
    return this.service.getCreateFields();
  }
  getTitle(): string {
    return "Endringer";
  }
  getHeadline(): string {
    return "Opprett ny endring";
  }
  getIdFromDataType(model: Endring) {
    return model.endringId;
  }

}
