import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { forkJoin, Observable } from "rxjs";
import { FilterParams, SearchResults } from "../common/common.model";
import { ListesideBaseClass } from "../common/listeside/listeside.component";
import { ItsystemReportGeneratorComponent } from "../common/modal/report-generator/itsystem-report-generator/itsystem-report-generator.component";
import { OppdragReportGeneratorComponent } from "../common/modal/report-generator/oppdrag-report-generator/oppdrag-report-generator.component";
import { FakturaDetaljerComponent } from "../fakturering/faktura-detaljer/faktura-detaljer.component";
import { GlobalVariables } from "../global";
import { OppdragService } from "../oppdrag/oppdrag.service";
import { Fakturalinje, FakturaType, FaktureringService, OppdragFakturalinjeDrift } from "../services/fakturering.service";
import { ModalService } from "../services/modal.service";
import { NotificationService } from "../services/notification.service";
import { TilgangService } from "../services/tilgang.service";
import { FakturaerFilterComponent } from "./fakturaer-filter/fakturaer-filter.component";
import { saveAs } from 'file-saver';
import {ItsystemerService} from "../itsystemer/itsystemer.service";

@Component({
  selector: "app-fakturaer",
  templateUrl: "./fakturaer.component.html",
  styleUrls: ["./fakturaer.component.scss"]
})
export class FakturaerComponent extends ListesideBaseClass {

  @ViewChild("filter")
  filterComponent: FakturaerFilterComponent;

  overskrift = "Oversikt over fakturaer";
  readonly dateFormat = GlobalVariables.DATE_FORMAT;
  kanGodkjenne = false;
  kanFakturere = false;

  isGeneratingDriftRapport = false;

  disableOppdaterFakturalinje = false;

  constructor(
    private service: FaktureringService,
    tilgangService: TilgangService,
    private modalService: ModalService,
    private notificationService: NotificationService,
    private fakturaService: FaktureringService,
    private oppdragService: OppdragService,
    private itsystemerService: ItsystemerService,
    route: ActivatedRoute,
    router: Router
  ) { super(route, router, tilgangService); }

  ngOnInit(): void {
    super.ngOnInit();
    this.tilgangService.getFakturaAccess().subscribe(tilgang => {
      this.kanFakturere = tilgang.fakturer;
      this.kanGodkjenne = tilgang.godkjenn;
    });
  }


  urlParamsToFilterParams(params: Params, filterParams: FilterParams): FilterParams {
    filterParams = filterParams ? filterParams : new FilterParams();

    filterParams.oppdragId = params.oppdragId;
    filterParams.systemId = params.systemId;

    filterParams.order = params.sortOrder ? params.sortOrder : "dateDesc";
    filterParams.pageNumber = params.pageNumber ? params.pageNumber : 1;
    filterParams.pageSize = params.pageSize ? params.pageSize : 30;
    filterParams.visFakturerte = params.visFakturerte !== undefined ? params.visFakturerte == "true" : false;
    filterParams.visGodkjente = params.visGodkjente !== undefined ? params.visGodkjente == "true" : true;
    filterParams.fakturatype = FakturaType[params.fakturatype ? params.fakturatype : "Fagsystem"];
    filterParams.systemStatus = params.systemStatus;
    filterParams.systemType = params.systemType;
    filterParams.fra = params.fra;
    filterParams.til = params.til;

    return filterParams;
  }

  onFilterChange(filterParams: FilterParams): void {
    console.log(filterParams);
    this.router.navigate([], {
      queryParams:
      {
        "oppdragId": filterParams.oppdragId,
        "systemId": filterParams.systemId,
        "tekst": filterParams.tekst,

        "fra": filterParams.fra,
        "til": filterParams.til,
        "sortOrder": filterParams.order,
        "fakturatype": filterParams.fakturatype,
        "visFakturerte": filterParams.visFakturerte,
        "visGodkjente": filterParams.visGodkjente,
        "systemStatus": filterParams.systemStatus,
        "systemType": filterParams.systemType,
        "pageNumber": filterParams.pageNumber,
        "pageSize": filterParams.pageSize
      }
    });
  }

  redigerFakturalinje(faktura: Fakturalinje): NgbModalRef {
    const modalRef = this.modalService.visModal(FakturaDetaljerComponent);
    const modalComponent = modalRef.componentInstance as FakturaDetaljerComponent;
    modalComponent.systemId = faktura.systemId;
    modalComponent.endringId = faktura.endringId;
    modalComponent.oppdragId = faktura.oppdragId;
    modalComponent.fakturaId = faktura.fakId;
    modalComponent.faktura = Object.assign({}, faktura);
    return modalRef;
  }

  search(params: FilterParams): Observable<any> {

    const systemId = params.systemId as number;
    const oppdragId = params.oppdragId as number;

    const tekst = params.tekst as string;
    const fra = params.fra as string;
    const til = params.til as string;
    const order = params.order as string;
    const visFakturerte = params.visFakturerte as boolean;
    const visGodkjente = params.visGodkjente as boolean;
    const systemStatus = params.systemStatus as number;
    const systemType = params.systemType as number;
    const fakturatype = params.fakturatype as FakturaType;
    const pageNumber = params.pageNumber as number;
    const pageSize = params.pageSize as number;

    switch (fakturatype) {
      case FakturaType.Fagsystem:
        return this.service.searchItsystemFakturaer(fra, til, order, visFakturerte, visGodkjente, systemId, pageNumber, pageSize);
      case FakturaType.Programpakke:
        return this.service.searchProgrampakkeFakturaer(fra, til, order, visFakturerte, visGodkjente, systemId, pageNumber, pageSize);
      case FakturaType.Oppdrag:
        return this.service.searchOppdragFakturaer(fra, til, order, visFakturerte, visGodkjente, oppdragId, pageNumber, pageSize);
      case FakturaType.OppdragDrift:
        return this.service.searchOppdragDriftFakturaer(fra, til, order, visFakturerte, visGodkjente, oppdragId, pageNumber, pageSize);
      case FakturaType.SystemDrift:
        return this.service.searchSystemDriftFakturaer(fra, systemStatus, systemType, order, tekst, pageNumber, pageSize);
    }
  }

  private resetFilterParams() {
    this.filterParams.pageNumber = 1;
    this.filterParams.systemId = undefined;
    this.filterParams.oppdragId = undefined;
    this.filterParams.systemStatus = undefined;
    this.filterParams.systemType = undefined;
  }

  changeOrder(): void {
    this.filterParams.order = this.filterParams.order == "dateDesc" ? "dateAsc" : "dateDesc";
    this.onFilterChange(this.filterParams);
  }

  visTimeFakturalinjer(): boolean {
    return this.filterParams.fakturatype === FakturaType.Fagsystem ||
      this.filterParams.fakturatype === FakturaType.Programpakke ||
      this.filterParams.fakturatype === FakturaType.Oppdrag;
  }

  visDriftFakturalinjer(): boolean {
    return this.filterParams.fakturatype === FakturaType.OppdragDrift ||
      this.filterParams.fakturatype === FakturaType.SystemDrift;
  }

  systemTab(): void {
    this.resetFilterParams();
    this.filterParams.fakturatype = FakturaType.Fagsystem;
    this.onFilterChange(this.filterParams);
    this.filterComponent.resetFilters();
  }

  systemDriftTab(): void {
    this.filterParams.fakturatype = FakturaType.SystemDrift;
    this.onFilterChange(this.filterParams);
  }

  programpakkeTab(): void {
    this.resetFilterParams();
    this.filterParams.fakturatype = FakturaType.Programpakke;
    this.onFilterChange(this.filterParams);
    this.filterComponent.resetFilters();
  }

  oppdragTab(): void {
    this.resetFilterParams();
    this.filterParams.fakturatype = FakturaType.Oppdrag;
    this.onFilterChange(this.filterParams);
    this.filterComponent.resetFilters();
  }

  oppdragDriftTab(): void {
    this.resetFilterParams();
    this.filterParams.fakturatype = FakturaType.OppdragDrift;
    this.onFilterChange(this.filterParams);
    this.filterComponent.resetFilters();
  }



  private oppdaterFakturaer(fakturaer: Fakturalinje[], godkjent: boolean) {
    fakturaer.forEach(f => {
      const indeks = this.searchResults.findIndex(fakturalinje => fakturalinje.fakId === f.fakId);
      if (indeks != -1) {
        this.searchResults[indeks] = f;
      }
    });

    if (godkjent) {
      this.notificationService.success(`${fakturaer.length} fakturaer er merket godkjent`, "Faktura godkjent");
    } else {
      this.notificationService.success(`${fakturaer.length} fakturaer er merket som fakturert`, "Faktura fakturert");
    }
  }



  private oppdaterFaktura(faktura: Fakturalinje, godkjent: boolean) {
    const indeks = this.searchResults.findIndex(fakturalinje => fakturalinje.fakId === faktura.fakId);
    if (indeks != -1) {
      this.searchResults[indeks] = faktura;
      if (godkjent) {
        this.notificationService.success(`Faktura med faktura-id ${faktura.fakId} er godkjent`, "Faktura godkjent");
      } else {
        this.notificationService.success(`Faktura med faktura-id ${faktura.fakId} er merket som fakturert`, "Faktura fakturert");
      }
    }
  }

  private oppdaterDriftFaktura(faktura: OppdragFakturalinjeDrift, godkjent: boolean) {
    const indeks = this.searchResults.findIndex(fakturalinje => fakturalinje.id === faktura.id);
    if (indeks != -1) {
      this.searchResults[indeks] = faktura;
      if (godkjent) {
        this.notificationService.success(`Faktura med faktura-id ${faktura.id} er godkjent`, "Faktura godkjent");
      } else {
        this.notificationService.success(`Faktura med faktura-id ${faktura.id} er merket som fakturert`, "Faktura fakturert");
      }
    }
  }


  godkjennFakturalinje(faktura: Fakturalinje): void {
    this.loadingData = true;
    if (faktura.oppdragId) {
      this.fakturaService.godkjennOppdragFaktura(faktura.fakId, faktura.oppdragId).subscribe(f => { this.oppdaterFaktura(f, true); this.loadingData = false; });
    } else {
      this.fakturaService.godkjennItsystemFaktura(faktura.fakId, faktura.systemId).subscribe(f => { this.oppdaterFaktura(f, true); this.loadingData = false; });
    }
  }

  godkjennFakturalinjeOppdragDrift(faktura: OppdragFakturalinjeDrift): void {
    this.fakturaService.godkjennOppdragDriftFaktura(faktura.id, faktura.oppdragId).subscribe(f => { this.oppdaterDriftFaktura(f, true); this.loadingData = false; });
  }

  merkFakturaFakturert(faktura: Fakturalinje): void {
    this.loadingData = true;
    if (faktura.oppdragId) {
      this.fakturaService.merkFakturertOppdragFaktura(faktura.fakId, faktura.oppdragId).subscribe(f => { this.oppdaterFaktura(f, false); this.loadingData = false; });
    } else {
      this.fakturaService.merkFakturertItsystemFaktura(faktura.fakId, faktura.systemId).subscribe(f => { this.oppdaterFaktura(f, false); this.loadingData = false; });
    }
  }

  merkFakturalinjeOppdragDrift(faktura: OppdragFakturalinjeDrift): void {
    this.fakturaService.merkFakturertOppdragDriftFaktura(faktura.id, faktura.oppdragId).subscribe(f => { this.oppdaterDriftFaktura(f, false); this.loadingData = false; });
  }

  godkjennAlle(fakturaer: Fakturalinje[], oppdrag: boolean): void {

    const fakIds = fakturaer.filter(faktura => !faktura.godkjent).map(faktura => faktura.fakId);

    if (fakIds.length === 0) {
      this.modalService.warning("Ingen fakturaer å godkjenne", "");
      return;
    }

    this.loadingData = true;
    if (oppdrag) {
      this.fakturaService.godkjennOppdragFakturaer(fakIds).subscribe(f => {
        this.oppdaterFakturaer(f, true);
        this.loadingData = false;
      });
    } else {
      this.fakturaService.godkjennItsystemFakturaer(fakIds).subscribe(f => {
        this.oppdaterFakturaer(f, true);
        this.loadingData = false;
      });
    }
  }

  fakturerAlle(fakturaer: Fakturalinje[], oppdrag: boolean): void {
    const fakIds = fakturaer.filter(faktura => !faktura.fakturert).map(faktura => faktura.fakId);

    if (fakIds.length === 0) {
      this.modalService.warning("Ingen fakturaer å fakturere", "");
      return;
    }

    this.loadingData = true;
    if (oppdrag) {
      this.fakturaService.markAlleFakturertOppdragFaktura(fakIds).subscribe(f => {
        this.oppdaterFakturaer(f, false);
        this.loadingData = false;
      });
    } else {
      this.fakturaService.markAlleFakturertItsystemFaktura(fakIds).subscribe(f => {
        this.oppdaterFakturaer(f, false);
        this.loadingData = false;
      });
    }
  }

  generateItsystemerReport(): Promise<any> {
    const filterParams = {} as FilterParams;
    filterParams.fra = this.filterParams.fra;
    filterParams.til = this.filterParams.til;
    filterParams.visFakturerte = this.filterParams.visFakturerte;
    filterParams.visGodkjente = this.filterParams.visGodkjente;
    filterParams.fakturatype = this.filterParams.fakturatype;
    filterParams.order = this.filterParams.order;
    filterParams.pageSize = 1000;
    if (this.filterParams.systemId) {
      filterParams.systemId = this.filterParams.systemId;
    }

    const modalRef = this.modalService.visModal(ItsystemReportGeneratorComponent);
    const modalComponent = modalRef.componentInstance as ItsystemReportGeneratorComponent;
    modalComponent.filterParams = filterParams;
    this.search(filterParams).subscribe(result => {
      modalComponent.setFakturalinjer(result.items);
      modalRef.result.then(() => {
        // Do nothing
      }, error => {
        console.log(`Modal closed unexpectedly ${error}`);
        modalComponent.removePrintSection();
      });

      modalComponent.onConfirm.subscribe(exitStatus => {
        if (exitStatus) {
          modalRef.close(exitStatus);
        } else {
          modalRef.dismiss();
        }
      });

    });
    return modalRef.result;
  }

  generateOppdragReport(): Promise<any> {

    const filterParamsOppdragTimer = {} as FilterParams;
    const filterParamsOppdragDrift = {} as FilterParams;

    Object.assign(filterParamsOppdragTimer, this.filterParams);
    Object.assign(filterParamsOppdragDrift, this.filterParams);

    filterParamsOppdragTimer.fakturatype = FakturaType.Oppdrag;
    filterParamsOppdragTimer.pageSize = 1000;

    filterParamsOppdragDrift.fakturatype = FakturaType.OppdragDrift;
    filterParamsOppdragDrift.pageSize = 1000;

    const timerObservable = this.search(filterParamsOppdragTimer) as Observable<SearchResults<Fakturalinje>>;
    const driftObserable = this.search(filterParamsOppdragDrift) as Observable<SearchResults<OppdragFakturalinjeDrift>>;

    const modalRef = this.modalService.visModal(OppdragReportGeneratorComponent);
    const modalComponent = modalRef.componentInstance as OppdragReportGeneratorComponent;

    modalComponent.filterParams = filterParamsOppdragTimer;

    const oppdragObservable = this.oppdragService.get(+this.filterParams.oppdragId);
    const personerObservable = this.oppdragService.getOppdragPersonRoller(+this.filterParams.oppdragId);

    modalComponent.isLoading = true;

    forkJoin([oppdragObservable, personerObservable, driftObserable, timerObservable]).subscribe(results => {

      modalComponent.oppdrag = results[0];
      modalComponent.oppdragPersoner = results[1];
      modalComponent.fakturalinjerDrift = results[2].items;
      modalComponent.fakturalinjer = results[3].items;

      console.log(results);

      modalComponent.isLoading = false;

    }, () => { modalComponent.isLoading = false; modalComponent.errorLoading = true; });

    modalRef.result.then(() => {
      // Do nothing
    }, () => { modalComponent.removePrintSection(); });

    modalComponent.onConfirm.subscribe(exitStatus => {
      if (exitStatus) {
        modalRef.close(exitStatus);
      } else {
        modalRef.dismiss();
      }
    });

    return modalRef.result;
  }


  generateDriftReport(): void {
    this.isGeneratingDriftRapport = true;
    this.service.generateExcelReportSystemDrift().subscribe(results => {
      saveAs(results, "rapport.xlsx");
      this.isGeneratingDriftRapport = false;
    }, () => {
      this.isGeneratingDriftRapport = false;
    });
  }

  oppdaterFakturalinjer(): void {
    this.fakturaService.getFakturalinjerSystemdrift().subscribe(fakturalinjer => {
      this.disableOppdaterFakturalinje = true;

      for (const [i, fakturalinje] of fakturalinjer.entries()) {
        this.itsystemerService.getItsystem(fakturalinje.systemId).subscribe(itsystem => {
          this.itsystemerService.registrerFakturalinje(itsystem).subscribe(
            it => {
              if (i === fakturalinjer.length - 1) {
                this.disableOppdaterFakturalinje = false;
                this.visOppdaterteFakturalinjer();
                this.notificationService.success(`${fakturalinjer.length} fakturalinjer er oppdatert`, "Oppdatert fakturalinjer");
              }
            },
            () => {
              this.disableOppdaterFakturalinje = false;
              this.notificationService.error(`Noe gikk galt under oppdatering av fakturalinjer`, "Kunne ikke oppdatere fakturalinjer");
            });
        });
      }
    })
  }

  visOppdaterteFakturalinjer() {
    this.search(this.filterParams)
      .subscribe(result => {
        this.filterParams.pageNumber = result.pageNumber;
        this.filterParams.totalPages = result.totalPages;
        this.filterParams.numberOfHits = result.numberOfHits;
        this.searchResults = result.items;
        this.loadingData = false;
      }, () => { this.loadingFailed = true; });
  }
}
