import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {TabSideBaseClass} from "../../common/tab-side/tab-side.component";
import {TabsService} from "../../common/tabs/tabs.service";
import {BegrepIntegrasjon, KoblingerService} from "../../koblinger/koblinger.service";
import {TilgangService} from "../../services/tilgang.service";
import {ModalService} from "../../services/modal.service";
import {Observable} from "rxjs";
import {Tabs} from "../../common/tabs/tabs.enum";
import {map} from "rxjs/operators";


@Component({
  selector: 'app-integrasjon-begrep-tab',
  templateUrl: './integrasjon-begrep-tab.component.html'
})
export class IntegrasjonBegrepTabComponent extends TabSideBaseClass<BegrepIntegrasjon>{
  isLoading = false;
  loadingFailed = false;

  liste: BegrepIntegrasjon[];

  constructor(tabsService: TabsService, service: KoblingerService, tilgangService: TilgangService, modal: ModalService,
              route: ActivatedRoute, router: Router) {
    super(tabsService, service, tilgangService, modal, route, router);
  }

  kobleTil(): void {
    this.modal.velgBegrep().then(begrep => {
      if (this.liste.some(t => t.begrepId == begrep.begrepId)) {
        this.modal.warning(`${begrep.begrep1} er allerede koblet til integrasjon`, "Kobling feilet");
        return;
      }

      const item : BegrepIntegrasjon = { begrepId: begrep.begrepId, integrasjonId: this.id };

      this.service.opprettBegrepIntegrasjon(item).toPromise().then(() => {
        this.modal.success("", "Begrep koblet til integrasjon");
        this.load();
      })
    })
  }

  loaderObservable(service: KoblingerService, id: number): Observable<BegrepIntegrasjon[]> {
    return service.getBegrepIntegrasjoner(id);
  }

  pageLinkAccess(service: TilgangService): Observable<boolean> {
    return service.getGlobalRoles().pipe(map(val => val.integrasjoner.listAccess));
  }

  tabName(): Tabs {
    return Tabs.begrep;
  }


  protected removeObservable(service: KoblingerService, item: BegrepIntegrasjon): Observable<any> {
    return service.fjernBegrepIntegrasjon(item.integrasjonId, item.begrepId);
  }
}
