import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

export interface IAppConfig {
    url: {
        basisUrl: string;
    };
}

@Injectable()
export class AppConfigService {

    static settings: IAppConfig;

    constructor(private httpClient: HttpClient) { }

    load() {
        const configFile = "assets/config/config.json";

        return new Promise<void>((resolve) => {
            this.httpClient.get(configFile).toPromise().then((response: IAppConfig) => {
                AppConfigService.settings = <IAppConfig>response;

                console.log("Config Loaded");
                console.log(AppConfigService.settings);
                resolve();

            }).catch((response: any) => {
                console.log(`Could not load the config file ${response}`);
                resolve();
                // reject();
            });
        });
    }
}
