import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SearchItem, SearchResults } from "../common/common.model";
import {Bruksomrade, Begrep, Miljo, Sone, Resultatenhet} from "../koblinger/koblinger.service";
import { SertifikatSearchResult } from "../sertifikater/sertifikater.service";
import { PersonSearchResult } from "./person.service";

@Injectable({
  providedIn: "root"
})
export class LimitedSearchService {

  constructor(private http: HttpClient, @Inject("BASE_URL") private baseUrl: string) { }


  searchPersoner(
    text: string,
    pageNumber = 1,
    pageSize = 8): Observable<PersonSearchResult> {

    let searchParams = new HttpParams();

    searchParams = searchParams.append("text", text);
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<PersonSearchResult>(this.baseUrl + "api/limitedSearch/personsystemrolle", { params: searchParams });
  }

  autocompletePerson(text: string): Observable<PersonSearchResult> {
    let searchParams = new HttpParams();
    searchParams = searchParams.append("text", text);
    return this.http.get<PersonSearchResult>(this.baseUrl + "api/limitedSearch/autocomplete", { params: searchParams });
  }

  searchOppdrag(
    beskrivelse?: string,
    sortOrder?: string,
    showExpired = true,
    oppdragStatus = 0,
    oppdragStatusSm = 0,
    oppdragStatusLeveranse = 0,
    pageNumber = 1,
    pageSize = 8): Observable<SearchResults<SearchItem>> {

    let searchParams = new HttpParams();

    if (beskrivelse) { searchParams = searchParams.append("beskrivelse", beskrivelse); }
    if (sortOrder) { searchParams = searchParams.append("sortOrder", sortOrder); }

    searchParams = searchParams.append("showExpired", String(showExpired));

    searchParams = searchParams.append("oppdragStatus", oppdragStatus.toString());
    searchParams = searchParams.append("oppdragStatusSm", oppdragStatusSm.toString());
    searchParams = searchParams.append("oppdragStatusLeveranse", oppdragStatusLeveranse.toString());

    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<SearchResults<SearchItem>>(this.baseUrl + "api/limitedSearch/oppdrag", { params: searchParams });
  }

  searchSertifikat(
    beskrivelse?: string,
    sortOrder?: string,
    sertifikatStatus?: string,
    pageNumber = 1,
    pageSize = 30): Observable<SearchResults<SertifikatSearchResult>> {

    let searchParams = new HttpParams();

    if (beskrivelse) { searchParams = searchParams.append("beskrivelse", beskrivelse); }
    if (sortOrder) { searchParams = searchParams.append("sortOrder", sortOrder); }
    if (sertifikatStatus) { searchParams = searchParams.append("sertifikatStatus", sertifikatStatus); }
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<SearchResults<SertifikatSearchResult>>(this.baseUrl + "api/limitedSearch/sertifikat", { params: searchParams });
  }

  searchLeverandor(beskrivelse: string, sortOrder: string, pageNumber: number, pageSize: number): Observable<SearchResults<SearchItem>> {
    let searchParams = new HttpParams();

    if (beskrivelse) { searchParams = searchParams.append("beskrivelse", beskrivelse); }
    if (sortOrder) { searchParams = searchParams.append("sortOrder", sortOrder); }
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<SearchResults<SearchItem>>(this.baseUrl + "api/limitedSearch/leverandor", { params: searchParams });
  }

  searchMiljo(beskrivelse: string, sortOrder: string, pageNumber: number, pageSize: number): Observable<SearchResults<Miljo>> {
    let searchParams = new HttpParams();

    if (beskrivelse) { searchParams = searchParams.append("beskrivelse", beskrivelse); }
    if (sortOrder) { searchParams = searchParams.append("sortOrder", sortOrder); }
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<SearchResults<Miljo>>(this.baseUrl + "api/limitedSearch/miljo", { params: searchParams });
  }

  searchSone(beskrivelse: string, sortOrder: string, pageNumber: number, pageSize: number): Observable<SearchResults<Sone>> {
    let searchParams = new HttpParams();

    if (beskrivelse) { searchParams = searchParams.append("beskrivelse", beskrivelse); }
    if (sortOrder) { searchParams = searchParams.append("sortOrder", sortOrder); }
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<SearchResults<Sone>>(this.baseUrl + "api/limitedSearch/sone", { params: searchParams });
  }

  searchBegrep(beskrivelse: string, pageNumber: number, pageSize: number): Observable<SearchResults<Begrep>>  {
    let searchParams = new HttpParams();
    searchParams = searchParams.append("beskrivelse", beskrivelse);
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<SearchResults<Begrep>>(this.baseUrl + "api/limitedSearch/begrep", { params: searchParams });
  }

  searchBruksomrade(tekst: string, pageNumber: number, pageSize: number) : Observable<SearchResults<Bruksomrade>> {
    let searchParams = new HttpParams();
    searchParams = searchParams.append("tekst", tekst);
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<SearchResults<Bruksomrade>>(this.baseUrl + "api/limitedSearch/bruksomrade", { params: searchParams });
  }

  searchBrukere(tekst: string, pageNumber: number, pageSize: number): Observable<SearchResults<Resultatenhet>> {
    let searchParams = new HttpParams();

    searchParams = searchParams.append("tekst", tekst);
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<SearchResults<Resultatenhet>>(this.baseUrl + "api/limitedSearch/brukere", { params: searchParams });
  }
}
