import {Directive, EventEmitter, OnInit, Output} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable} from "rxjs";
import {SystemType} from "../../itsystemer/itsystemer.service";
import {NotificationService} from "../../services/notification.service";
import {PageAccess, TilgangService} from "../../services/tilgang.service";
import {Field} from "../common.model";

@Directive()
export abstract class NyBaseClass<E> implements OnInit {

  protected urlString: string;
  protected urlPaths: string[];

  @Output()
  savingComplete = new EventEmitter<E>();

  isSaving = false;
  isLoading = true;
  errorLoading = false;
  harTilgang = true;
  createFields: Field[];

  ref: string;

  model = {} as E | any | Object;

  formGroup: FormGroup;

  nonCustomFields: Field[] = [];
  customFields: Field[] = [];
  headingField: Field;
  topFields: Field[] = [];
  mainFields: Field[] = [];
  numberFields: Field[] = [];
  checkboxFields: Field[] = [];

  investeringsFields: Field[];
  kostnadFields: Field[];

  programpakke = SystemType.Programpakke;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected tilgangService: TilgangService,
    protected notificationService: NotificationService
  ) {
  }


  ngOnInit(): void {

    this.formGroup = new FormGroup({});

    this.route.url.subscribe(url => {

      this.urlString = url.join("/");
      this.urlPaths = this.urlString.split("/");

      console.log(url.entries);

      this.tilgangService.getGlobalRoles().subscribe(tilgang => {

        const harTilgang = tilgang[this.urlPaths[0]] as PageAccess;
        console.log(harTilgang);

        this.harTilgang = harTilgang?.createAccess;

        this.isLoading = true;
        if (this.harTilgang) {
          this.getCreateFields().subscribe(fields => {

            this.customFields = this.removeFilteredFromArray(this.filterCustomFields(fields), fields);

            this.nonCustomFields = Array.from(fields);

            this.headingField = this.removeFilteredFromArray(fields.filter(f => f.key == "navn"), fields)[0];

            this.kostnadFields = this.removeFilteredFromArray(fields.filter(f => this.faktureringFieldNames().has(f.key)), fields);

            this.topFields = this.removeFilteredFromArray(fields.filter(f => f.key == "beskrivelse" || f.key == "kommentar" || f.key == "interninfo"), fields);

            this.investeringsFields = this.removeFilteredFromArray(fields.filter(f => f.key.startsWith("investering")), fields);

            this.numberFields = this.removeFilteredFromArray(fields.filter(f => f.inputType == "number"), fields);
            this.checkboxFields = this.removeFilteredFromArray(fields.filter(f => f.inputType == "checkbox"), fields);

            this.mainFields = fields;

            this.isLoading = false;

          });

        } else {
          this.errorLoading = true;
        }
      });

      this.setInitialValues();
    });

    this.route.queryParams.subscribe(params => {
      this.ref = params.ref as string;
    });
  }


  lagre(): void {
    this.isSaving = true;
    this.create(this.model).subscribe(resp => {
      this.isSaving = false;
      // redirect to modified page
      if (this.ref) {
        this.router.navigate([decodeURIComponent(this.ref)], {queryParams: {refResults: encodeURIComponent(this.model.navn)}})
      } else {
        this.router.navigate([this.urlPaths[0] + "/" + this.getIdFromDataType(resp)]);
      }

      this.notificationService.success(this.model.navn, "Lagret");
      this.formGroup.markAsPristine();
    }, () => this.isSaving = false);
  }

  filterCustomFields(fields: Field[]): Field[] {
    return [];
  }

  setInitialValues(): void {
    // Do nothing
  }

  protected faktureringFieldNames(): Set<string> {
    return new Set<string>(["antallServere", "antallServereTest", "antallOracleBaser",
      "antallMsSqlBaser", "antallMariaDbBaser", "antallMongoDbBaser", "antallPostgreSqlBaser",
      "antallIntegrasjoner", "antallBrukere", "antallServereTest",
      "tilleggDriftspris", "tilleggKommentar", "tilleggDriftsprisEkstern", "vaktlag"]);
  }

  protected removeFilteredFromArray(filtered: Field[], arr: Field[]): Field[] {
    filtered.forEach(f => {
      const index = arr.indexOf(f);
      if (index >= 0) {
        arr.splice(index, 1);
      }

    });
    return filtered;
  }

  abstract create(model: E): Observable<E>;

  abstract getCreateFields(): Observable<Field[]>;

  abstract getTitle(): string;

  abstract getHeadline(): string;

  abstract getIdFromDataType(model: E): number;

}
