import { Component } from "@angular/core";
import { ListesideFiltreComponent } from "src/app/common/listeside/filtre/filtre.component";

@Component({
  selector: "app-sertifikater-filter",
  templateUrl: "./sertifikater-filter.component.html",
  styleUrls: ["./sertifikater-filter.component.scss"]
})
export class SertifikaterFilterComponent extends ListesideFiltreComponent {

  constructor() { super(); }

  ngOnInit(): void {
    // Do nothing
  }

}
