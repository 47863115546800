import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { FakturaDetaljerComponent } from "../../../fakturering/faktura-detaljer/faktura-detaljer.component";
import { Fakturalinje, FaktureringService } from "../../../services/fakturering.service";
import { ModalService } from "../../../services/modal.service";
import { NotificationService } from "../../../services/notification.service";
import { TilgangService } from "../../../services/tilgang.service";

@Component({
  selector: "app-fakturering-tab",
  templateUrl: "./fakturering-tab.component.html",
  styleUrls: ["./fakturering-tab.component.scss"]
})
export class FaktureringTabComponent implements OnInit {

  systemId: number;
  endringId: number;
  oppdragId: number;
  fakturalinjer: Fakturalinje[];
  activeFakturalinjer: Fakturalinje[];

  kanGodkjenneFaktura = false;
  kanMerkeFakturert = false;
  kanRedigere = false;
  kanSlette = false;

  isLoading = false;
  deleteId: number;
  errorLoading = false;

  @Input()
  visibleListSize = 10;

  private numberOfVisibleElements = 0;

  constructor(private route: ActivatedRoute, private fakturaService: FaktureringService,
    public modalService: ModalService, private tilgangService: TilgangService,
    public notificationService: NotificationService) { }

  ngOnInit(): void {
    this.route.parent.url.subscribe(urlSegments => {
      urlSegments.forEach(segment => {
        if (segment.path.includes("oppdrag")) {
          this.hentOppdragFakturalinjer();
          this.tilgangService.getOppdragFakturaAccess().subscribe(access => {
            this.kanGodkjenneFaktura = access.godkjenn;
            this.kanMerkeFakturert = access.fakturer;
            this.kanRedigere = access.rediger;
            this.kanSlette = access.delete;
          });
          return;
        }
        if (segment.path.includes("itsystemer")) {
          this.hentItsystemFakturalinjer();
          this.tilgangService.getItsystemFakturaAccess().subscribe(access => {
            this.kanGodkjenneFaktura = access.godkjenn;
            this.kanMerkeFakturert = access.fakturer;
            this.kanRedigere = access.rediger;
            this.kanSlette = access.delete;
          });
          return;
        }
      });
    });
  }


  private hentItsystemFakturalinjer() {
    this.isLoading = true;
    this.route.parent.params.subscribe(params => {
      this.systemId = parseInt(params["systemId"]);
      this.endringId = parseInt(params["endringId"]);

      if (this.endringId) {
        this.fakturaService.getEndringFakturalinjer(this.systemId, this.endringId).subscribe(fakturalinjer => {
          this.fakturalinjer = fakturalinjer;
          this.sortFakturaLinjer(this.fakturalinjer);
          this.loadMoreActiveElements();
          this.isLoading = false;
        }, () => this.errorLoading = true);

      } else {
        this.fakturaService.getItsystemFakturalinjer(this.systemId).subscribe(fakturalinjer => {
          this.fakturalinjer = fakturalinjer;
          this.sortFakturaLinjer(this.fakturalinjer);
          this.loadMoreActiveElements();
          this.isLoading = false;
        }, () => this.errorLoading = true);
      }
    });
  }

  private hentOppdragFakturalinjer() {
    this.isLoading = true;
    this.route.parent.params.subscribe(params => {
      this.oppdragId = parseInt(params["oppdragId"]);

      this.fakturaService.getOppdragFakturalinjer(this.oppdragId).subscribe(fakturalinjer => {
        this.fakturalinjer = fakturalinjer;
        this.sortFakturaLinjer(this.fakturalinjer);
        this.loadMoreActiveElements();
        this.isLoading = false;
      }, () => this.errorLoading = true);
    });
  }

  private sortFakturaLinjer(fakturalinjer: Fakturalinje[]): void {
    this.fakturalinjer.sort((a, b) => {
      return a.fra < b.fra ? 1 : -1;
    });
  }

  loadMoreActiveElements(): void {
    this.numberOfVisibleElements += this.visibleListSize;
    this.activeFakturalinjer = this.fakturalinjer.slice(0, this.numberOfVisibleElements);
  }

  nyFakturalinje(): NgbModalRef {
    const modalRef = this.modalService.visModal(FakturaDetaljerComponent);
    const modalComponent = modalRef.componentInstance as FakturaDetaljerComponent;
    modalComponent.systemId = this.systemId;
    modalComponent.endringId = this.endringId;
    modalComponent.oppdragId = this.oppdragId;
    return modalRef;
  }

  redigerFakturalinje(faktura: Fakturalinje): NgbModalRef {
    const modalRef = this.modalService.visModal(FakturaDetaljerComponent);
    const modalComponent = modalRef.componentInstance as FakturaDetaljerComponent;
    modalComponent.systemId = this.systemId;
    modalComponent.endringId = this.endringId;
    modalComponent.oppdragId = this.oppdragId;
    modalComponent.fakturaId = faktura.fakId;
    modalComponent.faktura = Object.assign({}, faktura);;
    return modalRef;
  }

  slettFakturalinje(fakturaId: number): void {

    this.isLoading = true;
    this.deleteId = fakturaId;

    const deleteObservable = this.oppdragId ?
      this.fakturaService.deleteOppdragFakturlinje(fakturaId, this.oppdragId) :
      this.fakturaService.deleteItsystemFakturlinje(fakturaId, this.systemId);

    deleteObservable.subscribe(() => {
      this.isLoading = false;
      this.modalService.success("", "Fakturalinje fjernet");
      this.ngOnInit();
    }, () => {
      this.isLoading = false;
      this.errorLoading = true;
      this.deleteId = null;
    });
  }

  private oppdaterFaktura(faktura: Fakturalinje, godkjent: boolean) {
    const indeks = this.activeFakturalinjer.findIndex(fakturalinje => fakturalinje.fakId === faktura.fakId);
    if (indeks != -1) {
      this.activeFakturalinjer[indeks] = faktura;
      if (godkjent) {
        this.notificationService.success(`Faktura med faktura-id ${faktura.fakId} er godkjent`, "Faktura godkjent");
      } else {
        this.notificationService.success(`Faktura med faktura-id ${faktura.fakId} er merket som fakturert`, "Faktura fakturert");
      }
    }
  }

  godkjennFakturalinje(fakturaId: number): void {
    if (this.oppdragId) {
      this.fakturaService.godkjennOppdragFaktura(fakturaId, this.oppdragId).subscribe(faktura => this.oppdaterFaktura(faktura, true));
    } else {
      this.fakturaService.godkjennItsystemFaktura(fakturaId, this.systemId).subscribe(faktura => this.oppdaterFaktura(faktura, true));
    }
  }

  merkFakturaFakturert(fakturaId: number): void {
    if (this.oppdragId) {
      this.fakturaService.merkFakturertOppdragFaktura(fakturaId, this.oppdragId).subscribe(faktura => this.oppdaterFaktura(faktura, false));
    } else {
      this.fakturaService.merkFakturertItsystemFaktura(fakturaId, this.systemId).subscribe(faktura => this.oppdaterFaktura(faktura, false));
    }
  }

}
