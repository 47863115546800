import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TabSideBaseClass } from "src/app/common/tab-side/tab-side.component";
import { Tabs } from "src/app/common/tabs/tabs.enum";
import { TabsService } from "src/app/common/tabs/tabs.service";
import { SystemStatusEnum } from "src/app/itsystemer/itsystemer.service";
import { KoblingerService, PersonSystemRolle } from "src/app/koblinger/koblinger.service";
import { ModalService } from "src/app/services/modal.service";
import { TilgangService } from "src/app/services/tilgang.service";



@Component({
  selector: "app-personsystemer-tab",
  templateUrl: "./personsystemer-tab.component.html"
})
export class PersonSystemerTabComponent extends TabSideBaseClass<PersonSystemRolle> {


  utgattSystem = SystemStatusEnum.Utgatt;

  tabName(): Tabs {
    return Tabs.systemer; 
  }

  constructor(tabsService: TabsService, service: KoblingerService, tilgangService: TilgangService, route: ActivatedRoute, modal: ModalService, router: Router) {
    super(tabsService, service, tilgangService, modal, route, router);
  }

  loaderObservable(service: KoblingerService, id: number): Observable<PersonSystemRolle[]> {
    return service.getPersonSystemRollerForPerson(id);
  }

  pageLinkAccess(service: TilgangService): Observable<boolean> {
    return service.getGlobalRoles().pipe(map(val => val.itsystemer.listAccess));
  }

  override compareFn(first: PersonSystemRolle, second: PersonSystemRolle): number {
    if(first.system?.systemstatusId == second.system?.systemstatusId) return 0;
    if(first.system?.systemstatusId < second.system?.systemstatusId) return -1;
    else return 1;
  }

  override filterList(): PersonSystemRolle[] {
      return this.originalListe.filter(p => p.system.systemstatusId != this.utgattSystem)
  }

  override canFilter(): boolean {
      return true;
  }

  kobleTil(): void {
    this.modal.velgSystem().then(system => {
      this.modal.velgPersonRolle().then(rolle => {
        const psr: PersonSystemRolle = { personId: this.id, systemId: system.systemId, rolleId: rolle.rolleId };
        this.service.postPersonSystemRolleForPerson(psr).toPromise().then(() => {
          this.modal.success("System koblet til person og rolle", "kobling vellykket");
          this.load();
        });
      });
    });
  }
}
