import {ChangeDetectorRef, Directive, OnInit, ViewChild} from "@angular/core";
import {FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {Observable} from "rxjs";
import {TabAccess} from "src/app/services/tilgang.service";
import {NotificationService} from "../../services/notification.service";
import {Field} from "../common.model";
import {Tab, TabsComponent} from "../tabs/tabs.component";
import {EndringsloggTabComponent} from "../../endringslogg-tab-component/endringslogg-tab.component";
import {BackNavigationComponent} from "src/app/services/backnavigation.guard";
import {countDirty} from "src/app/global";


export interface IServiceInterface<T> {

  get(id: number): Observable<T>;

  put(item: T);

  post(item: T);

  getFields(id?: number): Observable<Field[]>;

  getCreateFields(id?: number): Observable<Field[]>;

}


@Directive()
export abstract class FeltSideBaseClass<T> implements OnInit, BackNavigationComponent<T> {

  abstract idParamName: string;
  @ViewChild('tab') tabsComponent: TabsComponent;

  isSaving = false;

  id: number;

  item: T;


  fields: Field[];

  textAreaCol: Field[];

  buttons: Field[];

  col1: Field[];

  col2: Field[];

  checkboxCol: Field[];

  tabs: Tab[];
  tabsLoading = false;

  formGroup = new FormGroup({});

  retainOrder: boolean;

  constructor(protected service: IServiceInterface<T>, protected notifyService: NotificationService,
              protected route: ActivatedRoute, protected changeDetectorRef: ChangeDetectorRef, retainOrder = false) {
    this.retainOrder = retainOrder;
  }

  ngAfterViewChecked(): void {
    if (this.formGroup.get("faktureres") != null) {
      this.disableOrEnableField();
    }
    this.changeDetectorRef.detectChanges();
  }


  faktureresCheckedLogic($event?): void {
    //find checkbox fakturere
    if ($event.target.id == 'faktureres') {
      this.disableOrEnableField();
    }
  }

  sidenErEndret(): boolean {
    return this.formGroup.dirty;
  }

  antallEndringer(): number {
    return countDirty(this.formGroup);
  }

  saveChanges(): Observable<T> {
    return this.service.put(this.item);
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {

      this.id = +params[this.idParamName];
      this.setupTabs();


      this.service.get(this.id).subscribe(item => {
        this.item = item;

      }, () => {
        console.log("Error fetching risikogjenngang, ERROR HANDLE HERE MANNEN!");
      });

      this.getFieldsObservable().subscribe(fields => {
        if (!this.retainOrder) {
          fields = this.sortFieldsByTypeAndLabel(fields);
        }
        this.fields = fields;
        this.organizeFields([].concat(fields));
      });
    });
  }


  getFieldsObservable(): Observable<Field[]> {
    return this.service.getFields(this.id);
  }

  organizeFields(fields: Field[]): void {
    if (fields.length > 0) {

      this.textAreaCol = this.removeFilteredFromArray(fields.filter(f => f.inputType === "textarea"), fields);

      this.checkboxCol = this.removeFilteredFromArray(fields.filter(f => f.inputType === "checkbox"), fields);

      this.buttons = this.removeFilteredFromArray(fields.filter(f => f.inputType === "button"), fields);

      const half = Math.ceil(fields.length / 2);
      this.col1 = fields.splice(0, half);
      this.col2 = fields.splice(-half);


      const navnField = fields.filter(f => f.key == "navn")[0];
      if (navnField) {
        navnField.validators = [Validators.required, Validators.minLength(2)];
      }
    }
  }

  protected sortFieldsByTypeAndLabel(fields: Field[]): Field[] {
    const sortKeys = {"textarea": "a", "text": "b", "select": "c", "date": "d", "number": "e", "checkbox": "f"};

    fields.forEach(f => {
      f["sortKey"] = sortKeys[f.inputType] ? sortKeys[f.inputType] : `x_${f.text}`;
    });

    fields = fields.sort((a, b) => (a["sortKey"] > b["sortKey"]) ? 1 : -1);
    return fields;
  }

  protected removeFilteredFromArray(filtered: Field[], arr: Field[]): Field[] {
    filtered.forEach(f => {
      const index = arr.indexOf(f);
      if (index >= 0) {
        arr.splice(index, 1);
      }

    });
    return filtered;
  }


  lagre(): void {

    this.isSaving = true;
    this.service.put(this.item).subscribe(resp => {
      this.isSaving = false;
      this.item = resp;
      this.formGroup.markAsPristine();
      if (this.tabsComponent != null) {
        let activeTab = this.tabsComponent.getActiveTab();
        if (activeTab instanceof EndringsloggTabComponent) {
          activeTab.hentEndringslogg();
        }
      }

      this.notifyService.success("Oppdatering", "Suksess");
    }, () => {
      this.isSaving = false;
    });
  }


  setupTabs(): void {
    this.tabsLoading = true;
    this.getTabAccess().subscribe(t => {
      this.tabs = this.getAvailableTabs().filter(ft => t.some(tabAccess => tabAccess.key == ft.key));
      this.tabsLoading = false;
    });
  }

  abstract getAvailableTabs(): Tab[];

  abstract getTabAccess(): Observable<TabAccess[]>;

  disableOrEnableField() {

    if (this.formGroup.controls.faktureres?.value) {
      this.formGroup.controls.aktiv.disable();
      this.formGroup.get("aktiv").disable();
      this.formGroup.get("metodeId").disable();
      return;
    }
    if (this.fields.filter(f => f.key === 'aktiv')[0]?.permission.w) {
      this.formGroup.get("aktiv").enable();
    }
    if (this.fields.filter(f => f.key === 'metodeId')[0]?.permission.w) {
      this.formGroup.get("metodeId").enable();
    }
  }
}

