import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LeverandorSystemRolle, KoblingerService } from "../../koblinger/koblinger.service";



@Component({
  selector: "app-leverandor-rolle",
  templateUrl: "./leverandor-rolle.component.html"
})
export class LeverandorRolleComponent implements OnInit {

  @Input()
  systemId: number;

  liste: LeverandorSystemRolle[];


  constructor(
    private readonly route: ActivatedRoute,
    private readonly service: KoblingerService) { }

  ngOnInit(): void {
    if (this.systemId) {
      this.load();
    }
  }

  load(): void {
    this.service.getLeverandorSystemRollerForSystem(this.systemId).subscribe(resp => {

      this.liste = resp;
    });
  }

}
