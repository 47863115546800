import {Component, OnInit} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {Field} from "../common/common.model";
import {NotificationService} from "../services/notification.service";
import {TilgangService} from "../services/tilgang.service";
import {Testinformasjon, TestinformasjonService} from "./testinformasjon.service";
import {BackNavigationComponent} from "../services/backnavigation.guard";
import {countDirty} from "../global";
import {Observable} from "rxjs";

@Component({
  selector: "app-testinformasjon",
  templateUrl: "./testinformasjon.component.html",
  styleUrls: ["./testinformasjon.component.scss"]
})
export class TestinformasjonComponent implements OnInit, BackNavigationComponent<Testinformasjon> {

  constructor(private testinformasjonService: TestinformasjonService, private tilgangService: TilgangService, private notifyService: NotificationService, private route: ActivatedRoute) {
  }


  isSaving = false;

  hasWriteAccess = false;

  systemId: number;

  testinformasjon: Testinformasjon;

  column1: Field[] = [];
  column2: Field[] = [];

  formGroup = new FormGroup({});


  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.systemId = +params["systemId"];


      this.testinformasjonService.getTestinformasjon(this.systemId).subscribe(r => {
        this.testinformasjon = r;
      });

      this.testinformasjonService.getTestinformasjonFields(this.systemId).subscribe(fields => {
        this.column1 = fields.filter(f => f.column == 1).sort((f1, f2) => f1.position - f2.position);
        this.column2 = fields.filter(f => f.column == 2).sort((f1, f2) => f1.position - f2.position);
      });
    });

  }

  lagre(): void {
    console.log("Lagre");
    this.isSaving = true;

    this.testinformasjonService.updateTestinformasjon(this.testinformasjon).subscribe(resp => {
      this.isSaving = false;
      console.log(resp);
      this.formGroup.markAsPristine();
      this.notifyService.success("Testinformasjon ble oppdatert", "Testinformasjon oppdatert");
    }, () => {
      this.isSaving = false;
    });
  }

  tilbakestill(): void {
    window.location.reload();
  }

  sidenErEndret(): boolean {
    return this.formGroup.dirty;
  }

  antallEndringer(): number {
    return countDirty(this.formGroup);
  }

  saveChanges(): Observable<Testinformasjon> {
    return this.testinformasjonService.updateTestinformasjon(this.testinformasjon);
  }

}
