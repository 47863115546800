import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Tabs } from "src/app/common/tabs/tabs.enum";
import { TabsService } from "src/app/common/tabs/tabs.service";
import { ModalService } from "src/app/services/modal.service";
import { TilgangService } from "src/app/services/tilgang.service";
import { TabSideBaseClass } from "../../common/tab-side/tab-side.component";
import { KoblingerService, SystemTjeneste } from "../../koblinger/koblinger.service";


@Component({
  selector: "app-itsystemtjenester-tab",
  templateUrl: "./itsystemtjenester-tab.component.html"

})
export class ItsystemTjenesterTabComponent extends TabSideBaseClass<SystemTjeneste> {
  
  kobleTil(searchVal: string): void {
    throw new Error("Method not implemented.");
  }

  tabName(): Tabs {
    return Tabs.tjenester;
  }

  constructor(tabsService: TabsService, service: KoblingerService, tilgangService: TilgangService, modal: ModalService, route: ActivatedRoute, router: Router) {
    super(tabsService, service, tilgangService, modal, route, router);
  }

  loaderObservable(service: KoblingerService, id: number): Observable<SystemTjeneste[]> {
    return this.service.getSystemTjenesterForSystem(this.id);
  }

  pageLinkAccess(service: TilgangService): Observable<boolean> {
    return service.getGlobalRoles().pipe(map(val => val.tjenester.listAccess));
  }
}
