import {HttpClient, HttpParams} from "@angular/common/http";
import {Inject, Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Field, SearchResults} from "../common/common.model";
import {Person} from "../services/person.service";


@Injectable({
  providedIn: "root"
})
export class RisikogjennomgangService {

  private readonly basePath = "api/risiko/";
  private readonly baseUrl: string;

  constructor(private http: HttpClient, @Inject("BASE_URL") baseHost: string) {
    this.baseUrl = baseHost + this.basePath;
  }


  getRisikogjennomgang(systemId: number): Observable<Sikkerhetsinfo> {
    return this.http.get<Sikkerhetsinfo>(this.baseUrl + systemId);
  }

  // TODO does not exists in backend
  addRisikoGjennomgang(risikogjennomgang: Sikkerhetsinfo): Observable<Sikkerhetsinfo> {
    return this.http.post<Sikkerhetsinfo>(this.baseUrl, risikogjennomgang);
  }

  updateRisikogjennomgang(risikogjennomgang: Sikkerhetsinfo): Observable<Sikkerhetsinfo> {
    return this.http.put<Sikkerhetsinfo>(this.baseUrl + risikogjennomgang.systemId, risikogjennomgang);
  }

  searchRisikogjennomgang(
    name?: string,
    systemeier?: string,
    systemkoordinator?: string,
    kundeId?: number,
    sortOrder?: string,
    hgForetatt?: string,
    showExpired = false,
    hg = 2,
    pageNumber = 1,
    pageSize = 30): Observable<SearchResults<RisikogjennomgangSearchResults>> {
    let searchParams = new HttpParams();
    if (sortOrder) { searchParams = searchParams.append("sortOrder", sortOrder); }
    if (name) { searchParams = searchParams.append("name", name); }
    if (systemeier) { searchParams = searchParams.append("systemeier", systemeier); }
    if (systemkoordinator) { searchParams = searchParams.append("systemkoordinator", systemkoordinator); }
    if (kundeId) { searchParams = searchParams.append("kundeId", kundeId.toString()); }
    if (hgForetatt) { searchParams = searchParams.append("hgForetatt", hgForetatt); }
    if (sortOrder) {
      searchParams = searchParams.append("sortOrder", sortOrder);
    }
    if (name) {
      searchParams = searchParams.append("name", name);
    }
    if (systemeier) {
      searchParams = searchParams.append("systemeier", systemeier);
    }
    if (systemkoordinator) {
      searchParams = searchParams.append("systemkoordinator", systemkoordinator);
    }
    if (kundeId) {
      searchParams = searchParams.append("kundeId", kundeId.toString());
    }
    if (hgForetatt) {
      searchParams = searchParams.append("hgForetatt", hgForetatt);
    }
    searchParams = searchParams.append("showExpired", String(showExpired));
    searchParams = searchParams.append("hg", hg.toString());
    searchParams = searchParams.append("pageNumber", pageNumber.toString());
    searchParams = searchParams.append("pageSize", pageSize.toString());

    return this.http.get<SearchResults<RisikogjennomgangSearchResults>>(this.baseUrl, {params: searchParams});
  }

  getRisikogjennomgangFields(systemId: number): Observable<Field[]> {
    return this.http.get<Field[]>(this.baseUrl + systemId + "/fields");
  }

}


export interface RisikogjennomgangSearchResults {

  systemId: number;
  navn: string;

  hgUtfort: Date;

  tilgjengelighetId: number;
  tilgjengelighetNavn: string;

  leverandorId: number;
  leverandorNavn: string;

  systemEier: Person;
  systemkoodinator: Person;

}

export interface Sikkerhetsinfo {
  systemId: number;
  navn: string;
  anskaffet?: number;
  bk360referanse?: string;
  databehandleravtale: boolean;
  databehandleravtaleDokument?: string;
  datalagringssted?: string;
  datautenforEos: boolean;
  dpiaforetatt: boolean;
  dpiadokumentasjon?: string;
  eksternleverandortilgangDokumentert: boolean;
  endringsdokumentasjon?: string;
  endringsrutiner?: string;
  hgutfort: Date;
  integrasjonerDokumentert: boolean;
  mfabenyttes: boolean;
  personopplysninger: boolean;
  rbac: boolean;
  rbacdokumentert: boolean;
  rbacdokumentasjon?: boolean;
  rosUtfort: boolean;
  rosDokumentEksisterer: boolean;
  rosForetatt?: Date;
  rosDokument?: string;
  sensitivePersonopplysninger: boolean;
  ssobenyttes: boolean;
  tilgangsoversiktMulig: boolean;
  tilgangsrevisjonForetatt?: Date;
  tilgangsrevisjonDokumentert: boolean;
  tilgangsrevisjonDokumentasjon?: string;
  tilgangsprosessDokumentert: boolean;
  tilgangsprosessDokumentasjon?: string;
  tjenesterTilEksterne: boolean;
  tjenesterTilEksterneDokumentert: boolean;
  tjenesterTilEksterneDokumentasjon?: string;
  arligTilgangsrevisjon: boolean;
  oppdateringerFulgt: boolean;
  kundeId?: number;
  skalHaDatabehandleravtale: boolean;
}

