import { Directive, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Observable } from "rxjs";
import { PageAccess, TilgangService } from "src/app/services/tilgang.service";
import { FilterParams } from "../common.model";

@Directive()
export abstract class ListesideBaseClass implements OnInit {


  abstract overskrift: string;

  searchResults: any[];

  filterParams = new FilterParams();

  loadingData = false;
  loadingFailed = false;
  filtersCollapsed = false;

  harTilgang = false;

  private subscription: any = null;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected tilgangService: TilgangService
  ) { }

  ngOnInit(): void {

    this.route.url.subscribe(url => {

      const urlString = url.join("/");

      this.tilgangService.getGlobalRoles().subscribe(tilgang => {

        const harTilgang = tilgang[urlString] as PageAccess;

        console.log(harTilgang);

        this.harTilgang = harTilgang.listAccess;

        if (this.harTilgang) {
          this.route.queryParams
            .subscribe(params => {
              this.loadingData = true;

              this.filterParams = this.urlParamsToFilterParams(params, this.filterParams);

              if (this.subscription) {
                this.subscription.unsubscribe();
              }

              this.subscription = this.search(this.filterParams)
                .subscribe(result => {
                  this.filterParams.pageNumber = result.pageNumber;
                  this.filterParams.totalPages = result.totalPages;
                  this.filterParams.numberOfHits = result.numberOfHits;
                  this.searchResults = result.items;
                  this.loadingData = false;
                }, () => { this.loadingFailed = true; });
            });
        }
      });
    });
  }

  urlParamsToFilterParams(params: Params, filterParams: FilterParams): FilterParams {

    filterParams = filterParams ? filterParams : new FilterParams();
    filterParams.tekst = params.beskrivelse ? params.beskrivelse : "";
    filterParams.order = params.sortOrder ? params.sortOrder : "nameAsc";
    filterParams.pageNumber = params.pageNumber ? params.pageNumber : 1;
    filterParams.pageSize = params.pageSize ? params.pageSize : 30;

    return filterParams;
  }


  onFilterChange(filterParams: FilterParams): void {
    console.log(filterParams);
    this.filterParams = filterParams;
    this.router.navigate([], {
      queryParams: {
        "beskrivelse": this.filterParams.tekst,
        "sortOrder": this.filterParams.order,
        "pageNumber": filterParams.pageNumber,
        "pageSize": filterParams.pageSize
      }
    });
  }

  abstract search(params: FilterParams): Observable<any>;

}

