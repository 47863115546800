<div *ngIf="harTilgang">

  <h1>{{overskrift}}</h1>

  <app-soketekstfilter (onFilterChange)="onFilterChange($event)" [filterParams]="filterParams"></app-soketekstfilter>

  <app-risikogjennomganger-filter (onFilterChange)="onFilterChange($event)" [filterParams]="filterParams">

  </app-risikogjennomganger-filter>

  <app-paginator (onFilterChange)="onFilterChange($event)" [filterParams]="filterParams"></app-paginator>

  <app-spinner [loadingData]="loadingData"></app-spinner>

  <div *ngIf="searchResults">
    <p class="text-center">Antall treff ({{filterParams.numberOfHits}})</p>

    <div class="search-results">
      <div *ngFor="let risikogjennomgang of searchResults" class="list-group-flush">
        <a [routerLink]="['/risikogjennomganger',risikogjennomgang.systemId]"
          class="list-group-item list-group-item-action flex-column align-items-start">
          <h5>{{risikogjennomgang.navn}}
            <span class="text-muted">({{risikogjennomgang.systemId}})</span>
            <i *ngIf="risikogjennomgang.hgutfort" class="fas fa-check float-right" data-toggle="tooltip"
              data-placement="bottom" title="HG Utført"></i>
          </h5>

          <div class="row">
            <div class="col-md">
              <p *ngIf="risikogjennomgang.systemKoodinator">Systemkoordinator:
                {{risikogjennomgang.systemKoodinator?.navn }}
              </p>
              <p *ngIf="risikogjennomgang.systemEier">Systemeier:
                {{ risikogjennomgang.systemEier?.navn }}</p>
            </div>

            <div class="col-md">
              <p *ngIf="risikogjennomgang.leverandorNavn">Leverandør: {{ risikogjennomgang?.leverandorNavn }} </p>
              <p *ngIf="risikogjennomgang.tilgjengelighetNavn"> Tilgjengelighet: {{risikogjennomgang.tilgjengelighetNavn}} </p>
            </div>
          </div>

          <div *ngIf="risikogjennomgang.hgutfort" class="text-muted">
            Sikkerhetsinfo oppdatert: {{risikogjennomgang.hgutfort| date:'dd.MM.yyyy'}}
          </div>
        </a>

      </div>
    </div>
  </div>
</div>

<app-ikketilgang [harTilgang]="harTilgang" [loadingData]="loadingData" [overskrift]="overskrift"></app-ikketilgang>
