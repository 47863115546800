<div class="container">

  <div class="col text-center">
    <button class="btn btn-primary mb-2" type="button" (click)="filtersCollapsed = !filtersCollapsed;"
      data-toggle="collapse" aria-expanded="false" [attr.aria-expanded]="filtersCollapsed" aria-controls="filters">
      <i [ngClass]="{'fas fa-caret-up': filtersCollapsed}"></i>
      <i [ngClass]="{'fas fa-caret-down': !filtersCollapsed}"></i>
      <span class="mx-2" *ngIf="filtersCollapsed">Skjul Filtre</span>
      <span class="mx-2" *ngIf="!filtersCollapsed">Vis Filtre</span>
    </button>
  </div>

</div>


<div class="collapse" id="filters" [ngbCollapse]="!filtersCollapsed">
  <div class="card">
    <div class="card-body">

      <div class="container">
        <div class="row align-items-center">
          <div class="col">
            <ul class="list-group list-group-flush">

              <li class="list-group-item">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="showSeSk" [(ngModel)]="filterParams.showSeSk"
                    (change)="filterChanged($event)">
                  <label class="form-check-label" for="showSeSk">Vis SE/SK (treg)</label>
                </div>
              </li>

              <li class="list-group-item">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="extendedSearch" [(ngModel)]="filterParams.extendedSearch"
                    (change)="filterChanged($event)">
                  <label class="form-check-label" for="extendedSearch">Søk i kommentarer og beskrivelse (treg)</label>
                </div>
              </li>

              <li class="list-group-item">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="showExpired"
                    [(ngModel)]="filterParams.showExpired" (change)="filterChanged($event)">
                  <label class="form-check-label" for="showExpired">Vis Utgåtte</label>
                </div>
              </li>

              <li class="list-group-item">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="driftetAvAnsattservice"
                    [(ngModel)]="filterParams.driftetAvAnsattservice" (change)="filterChanged($event)">
                  <label class="form-check-label" for="driftetAvAnsattservice">Driftet av Ansattservice</label>
                </div>
              </li>

              <li class="list-group-item">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="showInfrastruktur"
                    [(ngModel)]="filterParams.showInfrastruktur" (change)="filterChanged($event)" />
                  <label class="form-check-label" for="showInfrastruktur">Vis Infrastruktur</label>
                </div>
              </li>

              <li class="list-group-item">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="showProgrampakke"
                    [(ngModel)]="filterParams.showProgrampakke" (change)="filterChanged($event)" />
                  <label class="form-check-label" for="showProgrampakke">Vis Programpakke</label>
                </div>
              </li>

              <li class="list-group-item">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="showFagsystem"
                    [(ngModel)]="filterParams.showFagsystem" (change)="filterChanged($event)" />
                  <label class="form-check-label" for="showFagsystem">Vis Fagsystem</label>
                </div>
              </li>

              <li class="list-group-item">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="showNettjeneste"
                    [(ngModel)]="filterParams.showNettjeneste" (change)="filterChanged($event)" />
                  <label class="form-check-label" for="showNettjeneste">Vis Nettjeneste</label>
                </div>
              </li>

              <li class="list-group-item">

                <div class="input-group my-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-orderSelect">Sortering</span>
                  </div>
                  <select class="form-control" id="orderSelect" [(ngModel)]="filterParams.order"
                    (change)="filterChanged($event)">
                    <option selected value="nameAsc">(A-Å)</option>
                    <option value="nameDesc">(Å-A)</option>
                  </select>
                </div>

                <div *ngIf="byradsavdelinger" class="input-group my-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-kundeSelect">Kunde</span>
                  </div>
                  <select class="custom-select" [(ngModel)]="filterParams.byradsavdeling"
                    (change)="filterChanged($event)">
                    <option selected value=''>Alle</option>
                    <option *ngFor="let b of byradsavdelinger" [value]="b.kundeId"
                      [attr.selected]="b.kundeId==filterParams.byradsavdeling">
                      {{b.navn}}</option>
                  </select>
                </div>

              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>

    <div class="card-footer">

      <div class="float-right">

        <button type="button" class="btn btn-outline btn-link" (click)="saveFilter()">
          Bruk som standard filter<i class="fas fa-save ml-4"></i> </button>

        <button *ngIf="filterExists()" type="button" class="btn btn-outline btn-link" (click)="clearFilter()">
          Tilbakestill standard filter<i class="fas fa-times ml-4"></i> </button>

      </div>

      <p *ngIf="filterSaved" [@fadeOut]>Filter lagret <i class="fas fa-check ml-2"></i></p>
      <p *ngIf="filterDelted" [@fadeOut]>Filter slettet <i class="fas fa-times ml-2"></i></p>

    </div>
  </div>
</div>
