import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { Tabs } from "src/app/common/tabs/tabs.enum";
import { TabsService } from "src/app/common/tabs/tabs.service";
import { ModalService } from "src/app/services/modal.service";
import { TilgangService } from "src/app/services/tilgang.service";
import { TabSideBaseClass } from "../../common/tab-side/tab-side.component";
import { SoneSystem, KoblingerService } from "../../koblinger/koblinger.service";


@Component({
  selector: "app-itsystemtjenester-tab",
  templateUrl: "./itsystemsone-tab.component.html"

})
export class ItsystemSoneTabComponent extends TabSideBaseClass<SoneSystem> {

  tabName(): Tabs {
    return Tabs.sone;
  }

  constructor(tabsService: TabsService, service: KoblingerService, tilgangService: TilgangService, modal: ModalService, route: ActivatedRoute, router: Router) {
    super(tabsService, service, tilgangService, modal, route, router);
  }

  loaderObservable(service: KoblingerService, id: number): Observable<SoneSystem[]> {
    return this.service.getSoneSystemForSystem(id);
  }

  pageLinkAccess(service: TilgangService): Observable<boolean> {
    return of(true);
  }

  removeObservable(service: KoblingerService, item: SoneSystem): Observable<object> {
    return service.removeSoneSystemForSystem(item.soneId, item.systemId);
  }


  kobleTil(): void {
    this.modal.velgSone().then(sone => {

      if (this.liste.some(t => t.soneId === sone.soneId)) {
        this.modal.warning(`${sone.navn} er allerede koblet til system`, "Kobling feilet");
        return;
      }

      const item: SoneSystem = { systemId: this.id, soneId: sone.soneId };


      this.service.postSoneSystemForSystem(item).toPromise().then(() => {
        this.modal.success("", "Sone koblet til system");
        this.load();
      });
    });
  }
}
