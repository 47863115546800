<ng-container *ngIf="text && text.length>maxLength; else small">
    <textarea #textLarge id="{{id}}"
        [ngClass]="fControl.invalid ? 'form-control is-invalid' : 'form-control'"
        rows="{{numRows}}" [formControl]="fControl" (keyup)="calculateRows()"></textarea>
</ng-container>
<ng-template #small>
    <input #textSmall id="{{id}}" type="text"
        [ngClass]="fControl.invalid ? 'form-control is-invalid' : 'form-control'"
        [formControl]="fControl"
        (keyup)="calculateRows()">
</ng-template>