import { Component, Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import {Bruksomrade, Begrep, Miljo, Sone} from "../../../koblinger/koblinger.service";
import { LimitedSearchService } from "../../../services/limited-search.service";
import { TilgangService } from "../../../services/tilgang.service";
import { ItsystemerService, ItsystemSearchResults, SystemType } from "../../../itsystemer/itsystemer.service";
import { Resultatenhet } from "../../../koblinger/koblinger.service";
import { Person } from "../../../services/person.service";
import { SearchItem, SearchResults } from "../../common.model";



@Directive()
export abstract class SokeVelgerComponent<T> implements OnInit, OnDestroy {

  abstract onConfirm: EventEmitter<T | boolean>;
  abstract title: string;
  abstract searchResult: SearchResults<SearchItem | T>;
  abstract text: string = '';

  isLoading = false;
  errorLoading = false;
  canCreate = false;

  constructor(private readonly router: Router, private readonly activatedRoute: ActivatedRoute) {

  }
  ngOnDestroy(): void {
    this.router.navigate([],
      {
        queryParams: {
          refResults: null
        },
        queryParamsHandling: 'merge'
      });
  }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(qparams => {
      if (qparams.refResults) {
        this.text = decodeURIComponent(qparams.refResults);
        this.filterChanged();
      }
    });

    this.filterChanged();
    this.createRights().subscribe(canCreate => {
      this.canCreate = canCreate;
    });

  }

  filterChanged(): void {
    this.isLoading = true;
    this.searchFunc().subscribe(r => {
      this.searchResult = r;
      this.isLoading = false;
    }, () => { this.errorLoading = true; });
  }

  currentUrlEncoded(): string {
    return encodeURIComponent(this.router.url);
  }

  getSystemtype(item: SearchItem | ItsystemSearchResults | T): string {
    switch ("systemtypeId" in item ? item.systemtypeId : 0){
      case SystemType.Fagsystem:
        return "(Fagsystem)";
      case SystemType.Infrastruktur:
        return "(Infrastruktur)";
      case SystemType.Nettjeneste:
        return "(Nettjeneste)";
      case SystemType.Programpakke:
        return "(Programpakke)";
      default:
        return "";
    }
  }

  abstract cancel(): void;
  abstract confirm(item: T): void;
  abstract searchFunc(): Observable<SearchResults<SearchItem | T>>;
  abstract createRights(): Observable<boolean>;
  abstract createLink(): string;

  isDeaktivert(item : T): boolean {
    return false;
  }
}

@Component({
  selector: "app-leverandor-velger",
  templateUrl: "./sokevelger.component.html"
})
export class LeverandorVelgerComponent extends SokeVelgerComponent<SearchItem> {

  createRights(): Observable<boolean> {
    return this.tilgangService.getGlobalRoles().pipe(map(val => val.leverandorer.createAccess));
  }
  createLink(): string {
    return "/leverandorer/ny";
  }

  title = "Velg leverandør";

  @Input() danger: boolean;
  @Output() onConfirm = new EventEmitter<SearchItem | boolean>();

  searchResult: SearchResults<SearchItem>;
  text: string = '';

  constructor(router: Router, route: ActivatedRoute, private readonly service: LimitedSearchService, private readonly tilgangService: TilgangService) { super(router, route); }

  searchFunc(): Observable<SearchResults<SearchItem>> {
    return this.service.searchLeverandor(this.text, null, 1, 8);
  }

  cancel(): void {
    this.onConfirm.emit(false);
  }

  confirm(leverandor: SearchItem): void {
    this.onConfirm.emit(leverandor);
  }
}

@Component({
  selector: "app-person-velger",
  templateUrl: "./personvelger.component.html"
})
export class PersonVelgerComponent extends SokeVelgerComponent<Person> {

  createRights(): Observable<boolean> {
    return this.tilgangService.getGlobalRoles().pipe(map(val => val.personer.createAccess));
  }
  createLink(): string {
    return "/personer/ny";
  }

  title = "Velg person";

  @Input() danger: boolean;
  @Output() onConfirm = new EventEmitter<Person | boolean>();

  searchResult: SearchResults<Person>;
  text: string = '';

  constructor(router: Router, route: ActivatedRoute, private readonly service: LimitedSearchService, private readonly tilgangService: TilgangService) { super(router, route); }

  searchFunc(): Observable<SearchResults<SearchItem | Person>> {
    return this.service.searchPersoner(this.text, 1, 8);
  }

  cancel(): void {
    this.onConfirm.emit(false);
  }

  confirm(person: Person): void {
    this.onConfirm.emit(person);
  }
}


@Component({
  selector: "app-itsystem-velger",
  templateUrl: "./sokevelger.component.html"
})
export class ItsystemVelgerComponent extends SokeVelgerComponent<ItsystemSearchResults> {

  createRights(): Observable<boolean> {
    return this.tilgangService.getGlobalRoles().pipe(map(val => val.itsystemer.createAccess));
  }
  createLink(): string {
    return "/itsystemer/ny";
  }

  title = "Velg system";
  systemtype: string = null;
  @Input() danger: boolean;
  @Output() onConfirm = new EventEmitter<ItsystemSearchResults | boolean>();

  searchResult: SearchResults<ItsystemSearchResults>;
  text: string = '';

  constructor(router: Router, route: ActivatedRoute, private service: ItsystemerService, private readonly tilgangService: TilgangService) { super(router, route); }

  searchFunc(): Observable<SearchResults<SearchItem | ItsystemSearchResults>> {
    return this.service.simpleSearchItsystemer(this.text, this.systemtype, null, 1, 8);
  }

  cancel(): void {
    this.onConfirm.emit(false);
  }

  confirm(system: ItsystemSearchResults): void {
    this.onConfirm.emit(system);
  }

  isDeaktivert(item: ItsystemSearchResults): boolean {
    return item.utgatt;
  }
}

@Component({
  selector: "app-oppdrag-velger",
  templateUrl: "./sokevelger.component.html"
})
export class OppdragVelgerComponent extends SokeVelgerComponent<SearchItem> {

  createRights(): Observable<boolean> {
    return this.tilgangService.getGlobalRoles().pipe(map(val => val.oppdrag.createAccess));
  }
  createLink(): string {
    return "/oppdrag/ny";
  }

  title = "Velg oppdrag";

  @Input() danger: boolean;
  @Output() onConfirm = new EventEmitter<SearchItem | boolean>();

  searchResult: SearchResults<SearchItem>;
  text: string = '';

  constructor(router: Router, route: ActivatedRoute, private readonly service: LimitedSearchService, private readonly tilgangService: TilgangService) { super(router, route); }

  searchFunc(): Observable<SearchResults<SearchItem>> {
    return this.service.searchOppdrag(this.text, "", true, 0, 0, 0, 1, 8);
  }

  cancel(): void {
    this.onConfirm.emit(false);
  }

  confirm(oppdrag: SearchItem): void {
    this.onConfirm.emit(oppdrag);
  }

  isDeaktivert(item: SearchItem): boolean {
    return !item.aktivert;
  }
}

@Component({
  selector: "app-miljo-velger",
  templateUrl: "./sokevelger.component.html"
})
export class MiljoVelgerComponent extends SokeVelgerComponent<Miljo> {

  createRights(): Observable<boolean> {
    return of(false);
  }
  createLink(): string {
    return "#";
  }

  title = "Velg miljø";

  @Input() danger: boolean;
  @Output() onConfirm = new EventEmitter<Miljo | boolean>();

  searchResult: SearchResults<Miljo>;
  text: string = '';

  constructor(router: Router, route: ActivatedRoute, private service: LimitedSearchService) { super(router, route); }

  searchFunc(): Observable<SearchResults<Miljo>> {
    return this.service.searchMiljo(this.text, "", 1, 20);
  }

  cancel(): void {
    this.onConfirm.emit(false);
  }

  confirm(miljo: Miljo): void {
    this.onConfirm.emit(miljo);
  }
}

@Component({
  selector: "app-sone-velger",
  templateUrl: "./sokevelger.component.html"
})
export class SoneVelgerComponent extends SokeVelgerComponent<Sone> {

  createRights(): Observable<boolean> {
    return of(false);
  }
  createLink(): string {
    return "#";
  }

  title = "Velg sone";

  @Input() danger: boolean;
  @Output() onConfirm = new EventEmitter<Sone | boolean>();

  searchResult: SearchResults<Sone>;
  text: string = '';

  constructor(router: Router, route: ActivatedRoute, private service: LimitedSearchService) { super(router, route); }

  searchFunc(): Observable<SearchResults<Sone>> {
    return this.service.searchSone(this.text, "", 1, 30);
  }

  cancel(): void {
    this.onConfirm.emit(false);
  }

  confirm(sone: Sone): void {
    this.onConfirm.emit(sone);
  }
}


@Component({
  selector: "app-sertifikat-velger",
  templateUrl: "./sokevelger.component.html"
})
export class SertifikatVelgerComponent extends SokeVelgerComponent<SearchItem> {

  createRights(): Observable<boolean> {
    return this.tilgangService.getGlobalRoles().pipe(map(val => val.sertifikater.createAccess));
  }
  createLink(): string {
    return "/sertifikater/ny";
  }

  title = "Velg sertifikat";

  @Input() danger: boolean;
  @Output() onConfirm = new EventEmitter<SearchItem | boolean>();

  searchResult: SearchResults<SearchItem>;
  text: string = '';

  constructor(router: Router, route: ActivatedRoute, private readonly service: LimitedSearchService, private readonly tilgangService: TilgangService) { super(router, route); }

  searchFunc(): Observable<SearchResults<SearchItem>> {
    return this.service.searchSertifikat(this.text, "", "", 1, 8);
  }

  cancel(): void {
    this.onConfirm.emit(false);
  }

  confirm(sertifikat: SearchItem): void {
    this.onConfirm.emit(sertifikat);
  }

  isDeaktivert(item: SearchItem): boolean {
    return !item.aktivert;
  }
}

@Component({
  selector: "app-begrep-velger",
  templateUrl: "./begrepvelger.component.html"
})
export class BegrepVelgerComponent extends SokeVelgerComponent<Begrep> {

  createRights(): Observable<boolean> {
    return this.tilgangService.getGlobalRoles().pipe(map(val => val.integrasjoner.createAccess));
  }
  createLink(): string {
    return "/begreper/ny";
  }

  title = "Velg Begrep";

  @Input() danger: boolean;
  @Output() onConfirm = new EventEmitter<Begrep | boolean>();

  searchResult: SearchResults<Begrep>;
  text: string = '';

  constructor(router: Router, route: ActivatedRoute, private readonly service: LimitedSearchService, private readonly tilgangService: TilgangService) { super(router, route); }

  searchFunc(): Observable<SearchResults<Begrep>> {
    return this.service.searchBegrep(this.text, 1, 8);
  }

  cancel(): void {
    this.onConfirm.emit(false);
  }

  confirm(begrep: Begrep): void {
    this.onConfirm.emit(begrep);
  }
}

@Component({
  selector: "app-bruksomrade-system-velger",
  templateUrl: "./bruksomradevelger.component.html"
})
export class BruksomradeVelgerComponent extends SokeVelgerComponent<Bruksomrade> {

  createRights(): Observable<boolean> {
    return this.tilgangService.getGlobalRoles().pipe(map(val => val.integrasjoner.createAccess));
  }

  createLink(): string {
    return "/bruksomrade/ny";
  }

  title = "Velg bruksområde";

  @Input() danger: boolean;
  @Output() onConfirm = new EventEmitter<Bruksomrade | boolean>();

  searchResult: SearchResults<Bruksomrade>;
  text: string = '';

  constructor(router: Router, route: ActivatedRoute, private readonly service: LimitedSearchService, private readonly tilgangService: TilgangService) {
    super(router, route);
  }

  searchFunc(): Observable<SearchResults<Bruksomrade>> {
    return this.service.searchBruksomrade(this.text, 1, 8);
  }

  cancel(): void {
    this.onConfirm.emit(false);
  }

  confirm(bruksomrade: Bruksomrade): void {
    this.onConfirm.emit(bruksomrade);
  }
}

@Component({
  selector: "app-sone-velger",
  templateUrl: "./brukervelger.component.html"
})
export class BrukerVelgerComponent extends SokeVelgerComponent<Resultatenhet> {

  createRights(): Observable<boolean> {
    return of(false);
  }
  createLink(): string {
    return "#";
  }

  title = "Velg enhet";

  @Input() danger: boolean;
  @Output() onConfirm = new EventEmitter<Resultatenhet | boolean>();

  searchResult: SearchResults<Resultatenhet>;
  text: string = '';

  constructor(router: Router, route: ActivatedRoute, private service: LimitedSearchService) { super(router, route); }

  searchFunc(): Observable<SearchResults<Resultatenhet>> {
    return this.service.searchBrukere(this.text, 1, 30);
  }

  cancel(): void {
    this.onConfirm.emit(false);
  }

  confirm(bruker: Resultatenhet): void {
    this.onConfirm.emit(bruker);
  }
}
