<div class="mb-5">
  <div class="col-8 col-sm-12">

    <ul class="nav nav-tabs nav-justified">

      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active' : !active}">
          <i class="fas fa-ellipsis-h" [ngClass]="{'fa-spin': tabsLoading}"></i>
        </a>
      </li>

      <li class="nav-item" *ngFor="let tab of tabs">
        <a *ngIf="!tab.disabled" class="nav-link" [ngClass]="{'active' : active==tab.key}"
          [routerLink]="tab.parentRoute.concat(tab.key)">{{tab.label}}</a>
        <span *ngIf="tab.disabled" class="nav-link">{{tab.label}}</span>
      </li>

    </ul>

    <div class="tab-content" id="tab">
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>

  </div>
</div>
