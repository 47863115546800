import {ChangeDetectorRef, Component} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Observable} from "rxjs";

import {FeltSideBaseClass} from "../common/feltside/feltside.component";
import {Tab} from "../common/tabs/tabs.component";
import {NotificationService} from "../services/notification.service";
import {TabAccess, TilgangService} from "../services/tilgang.service";


import {IntegrasjonerService, SystemSystem} from "./integrasjoner.service";
import {Field} from "../common/common.model";
import {EndringsloggTabComponent} from "../endringslogg-tab-component/endringslogg-tab.component";


@Component({
  selector: "app-tjeneste",
  templateUrl: "./integrasjon.component.html"
})
export class IntegrasjonComponent extends FeltSideBaseClass<SystemSystem> {

  idParamName = "id";

  constructor(
    service: IntegrasjonerService,
    private tilgangService: TilgangService,
    route: ActivatedRoute,
    notifyService: NotificationService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(service, notifyService, route, changeDetectorRef, true);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }


  getAvailableTabs(): Tab[] {
    return [
      {key: "begrep", label: "Begrep", parentRoute: ["/integrasjoner/", this.id + ""]}
    ];
  }

  getTabAccess(): Observable<TabAccess[]> {
    return this.tilgangService.getTabAccess("integrasjon", this.id);
  }

  getSwapSystem1And2Button(): Field | null {
    return this.buttons.filter(f => f.key === "byttSystem1OgSystem2")[0] ?? null
  }

  swapSystem1AndSystem2() {
    this.isSaving = true;
    (this.service as IntegrasjonerService).swapSystem1AndSystem2(this.item).subscribe(systemSystem => {
      this.item = systemSystem;
      this.formGroup.markAsPristine();
      if (this.tabsComponent != null) {
        let activeTab = this.tabsComponent.getActiveTab();
        if (activeTab instanceof EndringsloggTabComponent) {
          activeTab.hentEndringslogg();
        }
      }
      this.isSaving = false;
      this.notifyService.success("Bytte av system1 og system2", "Suksess");
    }, () => {
      this.notifyService.error("Bytte av system1 og system2", "Feilet")
      this.isSaving = false;
    });
  }
}
