<app-spinner [loadingData]="isLoading" [loadingFailed]="loadingFailed"></app-spinner>

<div class="row mb-4">

    <button [disabled]="!createDeleteAccess?.create" (click)="kobleTil()" type='button'
        class='btn btn-outline-primary mr-2'><i class="fas fa-plus"></i> Koble
        til system</button>

    <div *ngIf="canFilter()" class="btn-group-toggle" data-toggle="buttons">
        <label class="btn btn-outline-primary">
            <input type="checkbox" checked autocomplete="off" [(ngModel)]="showFiltered"> Vis utgåtte
        </label>
    </div>

</div>


<div *ngFor="let item of showFiltered ? originalListe : liste" class="list-group-flush">
    <div class="d-flex flex-row">

        <a [routerLink]="['/itsystemer', item.systemId]"
            [ngClass]="item.system.systemstatusId != utgattSystem ? 'list-group-item list-group-item-action flex-column align-items-start' : 'list-group-item list-group-item-action flex-column align-items-start bg-light' ">
            <h5 class="mb-1">{{item.system.navn}}
                <i *ngIf="item.system.systemstatusId == utgattSystem" placement="top" ngbTooltip="Utgått"
                    class="far fa-times-circle"></i>
            </h5>
            <div class="text-muted">{{item.system.beskrivelse}}</div>
        </a>

    </div>
</div>