import { Component } from "@angular/core";
import { RisikogjennomgangService } from "../risikogjennomgang/risikogjennomgang.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { TilgangService } from "../services/tilgang.service";
import { ItsystemerService } from "../itsystemer/itsystemer.service";
import { ListesideBaseClass } from "../common/listeside/listeside.component";
import { Observable } from "rxjs";
import { FilterParams } from "../common/common.model";

@Component({
  selector: "app-risikogjennomganger",
  templateUrl: "./risikogjennomganger.component.html",
  styleUrls: ["./risikogjennomganger.component.scss"]
})
export class RisikogjennomgangerComponent extends ListesideBaseClass {

  overskrift = "Oversikt over sikkerhetsinfo";

  constructor(
    protected risikoService: RisikogjennomgangService,
    protected itsystemService: ItsystemerService,
    protected tilgangService: TilgangService,
    protected router: Router,
    protected route: ActivatedRoute
  ) { super(route, router, tilgangService); }


  onFilterChange(filterParams: FilterParams) {

    this.router.navigate(["/risikogjennomganger"], {
      queryParams:
      {
        "s": filterParams.tekst,
        "systemeier": filterParams.systemeier,
        "systemkoordinator": filterParams.systemkoordinator,
        "kundeId": filterParams.byradsavdeling,
        "sortOrder": filterParams.order,
        "showExpired": filterParams.showExpired,
        "hg": filterParams.hg,
        "hgForetatt": filterParams.hgForetatt,
        "pageNumber": filterParams.pageNumber,
        "pageSize": filterParams.pageSize
      }
    });
  }

  search(params: FilterParams): Observable<any> {

    const name = params.tekst as string;
    const systemeier = params.systemeier as string;
    const systemkoordinator = params.systemkoordinator as string;
    const byradsavdeling = params.byradsavdeling as number;
    const sortOrder = params.order as string;
    const hgForetatt = params.hgForetatt as string;
    const showExpired = params.showExpired as boolean;
    const hg = params.hg as number;
    const pageNumber = params.pageNumber as number;
    const pageSize = params.pageSize as number;

    return this.risikoService.searchRisikogjennomgang(name, systemeier, systemkoordinator, byradsavdeling,
      sortOrder, hgForetatt, showExpired, hg, pageNumber, pageSize);
  }

  urlParamsToFilterParams(params: Params, filterParams: FilterParams): FilterParams {

    filterParams = filterParams ? filterParams : new FilterParams();

    filterParams.tekst = params.s ? params.s : "";
    filterParams.showExpired = params.showExpired == "true";

    filterParams.showInfrastruktur = true;
    filterParams.showProgrampakke = true;
    filterParams.showFagsystem = true;
    filterParams.showNettjeneste = true;

    filterParams.systemeier = params.systemeier ? params.systemeier : "";
    filterParams.systemkoordinator = params.systemkoordinator ? params.systemkoordinator : "";

    filterParams.byradsavdeling = params.kundeId ? params.kundeId : "";
    filterParams.hgForetatt = params.hgForetatt ? params.hgForetatt : "alle";
    filterParams.order = params.sortOrder ? params.sortOrder : "nameAsc";
    filterParams.hg = params.hg ? params.hg : 2;
    filterParams.pageNumber = params.pageNumber ? params.pageNumber : 1;
    filterParams.pageSize = params.pageSize ? params.pageSize : 30;

    return filterParams;
  }

}
