<div>

    <div id="printable">

        <div class="modal-header">
            <h4 class="modal-title">Fakturering Itsystem </h4>
            <!--             <button type="button" (click)="true" data-toggle="tooltip" data-placement="right" title="Åpne i print-mode"
                class="btn btn-secondary"><i class="fas fa-external-link-alt"></i></button> -->
        </div>
        <div class="modal-body">

            <app-spinner [loadingData]="isLoading" [loadingFailed]="errorLoading" [loadingText]="'Genererer Rapport'">
            </app-spinner>

            <div *ngFor="let itsystem of itsystemer">

                <h3><u>{{itsystem.navn}}</u></h3>
                <p>Ressursnummer : {{itsystem.ressursnr}}</p>
                <p>Ansvarssted : {{itsystem.ansvarssted}}</p>
                <p>Saksnummer : {{itsystem.saksnummer}}</p>
                <p>Tjeneste : {{itsystem.tjeneste}}</p>
                <b>
                    <hr />
                </b>
                <hr />
                <h5>Timer</h5>
                <hr />

                <div class="container">
                    <div class="table-responsive">
                        <table #tItsystem class="table" id="tItsystem">
                            <thead>
                                <tr>
                                    <th scope="col">Navn</th>
                                    <th scope="col">Fra</th>
                                    <th scope="col">Til</th>
                                    <th scope="col">Normaltid</th>
                                    <th scope="col">Overtid</th>
                                    <th scope="col">Total tid</th>
                                    <th scope="col">Fast beløp</th>
                                    <th scope="col">Beskrivelse</th>
                                    <th scope="col">Endr.nr</th>
                                    <th scope="col">Saksnr</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let fakturalinje of fakturalinjer[itsystem.systemId]; let i = index">
                                    <td>{{fakturalinje.personNavn}}</td>
                                    <td>{{fakturalinje.fra | date: dateFormat}}</td>
                                    <td>{{fakturalinje.til | date: dateFormat}}</td>
                                    <td>{{(fakturalinje.normaltid / 36000000000).toFixed(2)}}</td>
                                    <td>{{(fakturalinje.overtid / 36000000000).toFixed(2)}}</td>
                                    <td>{{((fakturalinje.normaltid + fakturalinje.overtid)/36000000000).toFixed(2)}}
                                    </td>
                                    <td>{{fakturalinje.fastBelop | currency: 'kr'}}</td>
                                    <td>
                                        <p style="word-wrap: break-word; max-width: 120px;">{{fakturalinje.beskrivelse}}</p>
                                    </td>
                                    <td>{{fakturalinje?.endring?.endringNummer}}</td>
                                    <td>{{fakturalinje?.endring?.saksnummer}}</td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr class="table-secondary">
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th scope="col">Normaltid</th>
                                    <th scope="col">Overtid</th>
                                    <th scope="col">Totaltid</th>
                                    <th scope="col">Sum normaltid</th>
                                    <th scope="col">Sum overtid</th>
                                    <th scope="col">Sum fast beløp</th>
                                    <th scope="col">Totalt</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="table-secondary">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{{accNormaltid(itsystem.systemId).toFixed(2)}}</td>
                                    <td>{{accOvertid(itsystem.systemId).toFixed(2)}}</td>
                                    <td>{{totalTid(itsystem.systemId).toFixed(2)}}</td>
                                    <td>{{accSumNormaltid(itsystem.systemId)| currency: 'kr'}}</td>
                                    <td>{{accSumOvertid(itsystem.systemId)| currency: 'kr'}}</td>
                                    <td>{{accSumFastBelop(itsystem.systemId)| currency: 'kr'}}</td>
                                    <td><b>{{totalSum(itsystem.systemId)| currency: 'kr'}}</b></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="modal-footer">

        <button type="button" class="btn btn-outline-secondary" (click)="exportExcel()" placement="top"
            ngbTooltip="Eksporter til Excel" [openDelay]="400" [closeDelay]="200"><i
                class="fas fa-file-excel"></i></button>

        <button type="button" class="btn btn-outline-secondary" (click)="onBtnPrintClick()" placement="top"
            ngbTooltip="Print" [openDelay]="400" [closeDelay]="200"><i class="fas fa-print"></i></button>

        <button type="button" class="btn btn-outline-secondary ml-4" (click)="cancel()" placement="top"
            ngbTooltip="Lukk" [openDelay]="400" [closeDelay]="200">Lukk</button>
    </div>

</div>
