import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-lagreknapp",
  templateUrl: "./lagreknapp.component.html"

})
export class LagreknappComponent implements OnInit {

  @Input()
  buttonTitle = "Lagre";

  @Input()
  isSaving: boolean;

  @Input()
  enabled: boolean | null = null

  @Input()
  formGroup: FormGroup;

  @Input()
  showResetButton = true;

  @Output()
  onLagre = new EventEmitter<any>();


  ngOnInit(): void {
    // Do nothing
  }

  lagre(): void {
    this.onLagre.emit();
  }

  tilbakestill(): void {
    window.location.reload();
  }
}
