import { Component, OnInit } from "@angular/core";
import { GlobalPageAccess, TilgangService, PageAccess } from "./services/tilgang.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],

})
export class AppComponent implements OnInit {

  title = "SystemoversiktV2";
  menuOpen = false;
  collapseHeight = 600;
  collapseWidth = 600;


  rolesFailed = false;

  private globalAccess: GlobalPageAccess;

  private meny: MenuItem[] = [
    { route: "itsystemer", title: "IT-systemer", icon: "fas fa-laptop fa-lg" },
    { route: "risikogjennomganger", title: "Sikkerhetsinfo", icon: "fas fa-shield-alt fa-lg" },
    { route: "testinformasjoner", title: "Testinformasjon", icon: "fas fa-tasks fa-lg" },
    { route: "oppdrag", title: "Oppdrag", icon: "fas fa-briefcase fa-lg" },
    { route: "fakturaer", title: "Fakturering", icon: "fas fa-receipt fa-lg" },
    { route: "leverandorer", title: "Leverandører", icon: "fas fa-truck-loading fa-lg" },
    { route: "personer", title: "Personer", icon: "fas fa-address-book fa-lg" },
    { route: "sertifikater", title: "Sertifikater", icon: "fas fa-certificate fa-lg" },
    { route: "tjenester", title: "Tjenester", icon: "fas fa-concierge-bell fa-lg" },
    { route: "integrasjoner", title: "Integrasjoner", icon: "fas fa-puzzle-piece fa-lg" },
    { route: "timeforing", title: "Timeføring", icon: "fas fa-clock fa-lg" }
  ];

  filtrertMeny: MenuItem[] = null;

  constructor(private tilgangService: TilgangService) {

  }


  ngOnInit(): void {

    this.tilgangService.getGlobalRoles().subscribe(g => {
        this.globalAccess = g;
        this.rolesFailed = false;
        this.filtrertMeny = this.meny.filter(m => {
          const access = this.globalAccess[m.route] as PageAccess;
          return access?.listAccess;
        });
      },
      error => {
        console.log(`Error getting global roles (after 3 attempts) : ${error} Force retry by user`);
        this.rolesFailed = true;
      });

    const overlay = document.getElementsByClassName('overlay')[0];
    overlay.addEventListener("click", () => this.toggleMenu());
  }


  toggleMenu(): void {
    document.getElementById("sidebar").classList.toggle("active");
    document.getElementById("overlay").classList.toggle("active");
  }

  closeMenu():void{
    document.getElementById("sidebar").classList.remove("active");
    document.getElementById("overlay").classList.remove("active");
  }

}

export class MenuItem {

  title: string;
  route: string;
  icon: string;

}
